import React from 'react';
import PropTypes from 'prop-types';
import { FormInstance } from 'antd/lib/form';

import './ArticleEditor.scss';
import { ARTICLE_IMAGE_URL } from '../../../../other/config';
import ArticleEditorBanner from '../../../../components/common/articles/ArticleEditor/ArticleEditorBanner';
import ArticleEditorHeader from '../../../../components/common/articles/ArticleEditor/ArticleEditorHeader';
import ArticleForm from './ArticleForm';
import EditorControls from '../../../../components/common/articles/EditorControls/EditorControls';
import { editorProps, TArticleEditorProps } from '../helpers';

class ArticleEditor extends React.PureComponent<TArticleEditorProps> {
  static propTypes;
  private form: FormInstance;

  assignForm = (form) => {
    if (form && form.form) {
      this.form = form.form;
    }
  };

  handleCancel = () => this.props.onCancel(this.props.id);
  handleDraft = () => this.props.onSubmit(true);
  handleSubmit = () => this.props.onSubmit();
  handleUpload = (file: File): void => this.props.onUpload(file, this.props.id);

  render() {
    const {
      banner,
      fields,
      languages,
      onChange,
      source,
      uploadProgress
    } = this.props;
    const imgUrl = banner && `${ARTICLE_IMAGE_URL}/${banner.path}`;

    return (
      <div className="ArticleEditor">
        <ArticleEditorHeader onCancel={this.handleCancel} title="New article" />

        <ArticleEditorBanner
          imgUrl={imgUrl}
          onUpload={this.handleUpload}
          source={source}
          showUploadList={false}
          uploadProgress={uploadProgress}
        />

        <div className="ArticleEditor__holder">
          <ArticleForm
            fields={fields}
            languages={languages}
            onChange={onChange}
            onSubmit={this.handleSubmit}
            ref={this.assignForm}
          >
            <EditorControls
              onCancel={this.handleCancel}
              onDraft={this.handleDraft}
            />
          </ArticleForm>
        </div>
      </div>
    );
  }
}

ArticleEditor.propTypes = {
  ...editorProps,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};

export default ArticleEditor;
