import React, { ReactElement } from 'react';
import { FormInstance } from 'antd/lib/form';

import './EventEditor.scss';
import ArticleEditorBanner from '../../../components/common/articles/ArticleEditor/ArticleEditorBanner';
import ArticleEditorControls from '../../../components/common/articles/ArticleEditor/ArticleEditorControls';
import ArticleEditorHeader from '../../../components/common/articles/ArticleEditor/ArticleEditorHeader';
import { editorProps, TEventEditorProps } from './forms/helpers';
import EventMainForm from './forms/EventMainForm';
import { MEDIA_BASE_URL } from '../../../other/config';
import ScheduleEditor from './forms/ScheduleEditor/ScheduleEditor';

const EventEditor = (props: TEventEditorProps): ReactElement => {
  let form: FormInstance;
  const {
    banner,
    fields,
    id,
    onCancel,
    onChange,
    onItemAdd,
    onItemRemove,
    onItemUpdate,
    onSubmit,
    onUpload,
    schedule,
    source,
    uploadProgress,
    utcOffset
  } = props;

  const imgUrl = banner ? `${MEDIA_BASE_URL}/${banner.path}` : null;
  const dates = fields.dates ? fields.dates.value : null;

  const handleDraft = () => onSubmit(true);
  const handleSubmit = () => form.submit();
  const handleUpload = (file: File): void => onUpload(file, id);
  const saveFormRef = (formRef) => (form = formRef);

  const editor = id && (
    <EventMainForm
      fields={fields}
      onChange={onChange}
      onFormRef={saveFormRef}
      onSubmit={onSubmit}
    >
      <ScheduleEditor
        dates={dates}
        onItemAdd={onItemAdd}
        onItemRemove={onItemRemove}
        onItemUpdate={onItemUpdate}
        schedule={schedule}
        utcOffset={utcOffset}
      />
    </EventMainForm>
  );

  return (
    <div className="EventEditor">
      <ArticleEditorHeader onCancel={onCancel} title="New Event" />

      <ArticleEditorBanner
        imgUrl={imgUrl}
        onUpload={handleUpload}
        source={source}
        showUploadList={false}
        uploadProgress={uploadProgress}
      />

      <div className="EventEditor__holder">
        {editor}

        <ArticleEditorControls
          onCancel={onCancel}
          onDraft={handleDraft}
          onPublish={handleSubmit}
        />
      </div>
    </div>
  );
};

EventEditor.propTypes = editorProps;
export default EventEditor;
