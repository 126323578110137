import '../plugins/Leaflet.PolylineMeasure.css';
import * as _w from '../plugins/Leaflet.PolylineMeasure'; /*eslint-disable-line*/ // do not remove!
import * as _x from '../plugins/Leaflet.Graticule'; /*eslint-disable-line*/ // do not remove!
import * as _y from '../plugins/Leaflet.MouseCoordinates'; /*eslint-disable-line*/ // do not remove!
import * as _z from 'leaflet.fullscreen'; /*eslint-disable-line*/ // do not remove!
// import foSalmon from './FO_salmon.json';
import { hideBottomTools, shouldShowForecast } from './tooling';
import {
  isIOS,
  isIOS13,
  isMobile,
  isTouchDevice
} from '../../../components/helpers/helpers';

const polyOptions = {
  position: 'topright',
  unit: 'nauticalmiles',
  showBearings: true,
  clearMeasurementsOnStop: false,
  showClearControl: true,
  showUnitControl: false,
  measureControlTitleOn: 'Turn on Distance Measure',
  measureControlTitleOff: 'Turn off Distance Measure'
};

export function addControls(map) {
  if (!isIOS() || isIOS13()) window.L.control.mouseCoordinates().addTo(map);
  window.L.control
    .fullscreen({
      position: 'bottomleft',
      title: 'Fullscreen View',
      titleCancel: 'Exit Fullscreen',
      forceSeparateButton: true,
      fullscreenElement: document.getElementsByClassName('VesselsMap')[0]
    })
    .addTo(map);

  isMobile() || window.L.control.polylineMeasure(polyOptions).addTo(map);
}

export function makeGrid(map) {
  window.L.latlngGraticule({
    showLabel: true,
    zoomInterval: [
      { start: 2, end: 3, interval: 30 },
      { start: 4, end: 4, interval: 10 },
      { start: 5, end: 7, interval: 5 },
      { start: 8, end: 10, interval: 1 }
    ]
  }).addTo(map);
}

const mapDefaultInitOptions = {
  preferCanvas: true,
  minZoom: 3,
  zoomControl: true,
  maxBounds: window.L.latLngBounds(
    window.L.latLng(-90, -200),
    window.L.latLng(90, 200)
  ),
  maxBoundsViscosity: 1
};

const mapInitOptions = isTouchDevice()
  ? {
      ...mapDefaultInitOptions,
      zoomDelta: 0.25,
      zoomSnap: 0
    }
  : mapDefaultInitOptions;

/**
 * Performs all the dirty work on the EEZ map initialization.
 */
export function setUpEEZMap(domId) {
  const map = window.L.map(domId, mapInitOptions);

  window.L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    id: 'osm'
  }).addTo(map);

  window.L.tileLayer
    .wms('https://api-test.fishfacts.fo/map', {
      layers: 'MarineRegions:eez',
      format: 'image/png',
      transparent: true
    })
    .addTo(map);

  // window.L.tileLayer.wms('https://gis.us.fo/arcgis/services/aling/us_aling/MapServer/WMSServer', {
  // 	layers: '1',
  // 	format: 'image/png',
  // 	transparent: true,
  // }).addTo(map);

  makeGrid(map);
  addControls(map);
  return map;
}

export function setUpSeabedMap(domId) {
  const map = window.L.map(domId, mapInitOptions);

  window.L.tileLayer
    .wms(
      'https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?',
      {
        layers: 'GEBCO_LATEST',
        format: 'image/png'
      }
    )
    .addTo(map);

  makeGrid(map);
  addControls(map);
  return map;
}

/**
 * Performs all the dirty work on the Windy map initialization.
 */
export function setUpWindyMap(api, currentMapLayer) {
  const { overlays, map, store } = api;
  store.set('graticule', true);
  overlays.wind.setMetric('m/s');

  addControls(map);

  // For layers where weather forecast is shown, we need to hide the controls at the bottom to avoid clash.
  setTimeout(() => hideBottomTools(shouldShowForecast(currentMapLayer)));
}
