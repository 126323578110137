import React from 'react';
import PropTypes from 'prop-types';

import './VideosEditor.scss';
import AbstractEditor from '../other/AbstractEditor';
import ImageInput2 from '../../../../components/common/inputs/ImageInput2/ImageInput2';
import ModalWrapper from '../../../../components/common/modals/ModalWrapper';
import ProductForm from '../ProductsEditor/ProductForm';
import ProgressBar2 from '../../../../components/common/ProgressBar/ProgressBar2';
import { PROVIDER_IMAGE_URL } from '../../../../other/config';
import VideoInput2 from '../../../../components/common/inputs/VideoInput2/VideoInput2';
import VideosList from './VideosList';
import { TVideo } from '../../../../types/media';


type Props = {
  onPosterUpload: (file: File, videoId: number) => void;
  onVideoUpload: (file: File, videoId: number) => void;
  posterUploadProgress?: number;
  videoUploadProgress?: number;
  videos: TVideo[];
};


class VideosEditor extends AbstractEditor<TVideo, Props> {
  static propTypes;

  handlePosterUpload = (file: File) => {
    const { onPosterUpload, selectedItem } = this.props;
    onPosterUpload(file, selectedItem.id);
  };
  handleVideoUpload = (file: File) => {
    const { onVideoUpload, selectedItem } = this.props;
    onVideoUpload(file, selectedItem.id);
  };

  getFormValues(): Record<string, string> {
    const { selectedItem } = this.props;
    if (!selectedItem) return {};

    const { poster, title, video } = selectedItem;
    const posterUrl: string = poster ? `${PROVIDER_IMAGE_URL}/${poster.path}` : null;
    const videoUrl: string = video ? `${PROVIDER_IMAGE_URL}/${video.path}` : null;
    return { title, posterUrl, videoUrl }
  }

  render() {
    const { onAdd, onHandledObjectUpdate, onRemove, posterUploadProgress, selectedItem, videoUploadProgress, videos } = this.props;
    const isModalVisible = !!selectedItem;
    const values = this.getFormValues();

    return (
      <div className="VideosEditor">
        <VideosList videos={videos} onAdd={onAdd} onEdit={this.handleEdit} onRemove={onRemove} />

        <ModalWrapper isOpen={isModalVisible} onCancel={this.handleCancel} title="Video info">
          <div className="VideosEditor__main">
            <VideoInput2 onUpload={this.handleVideoUpload} videoUrl={values.videoUrl}>
              <ProgressBar2 progress={videoUploadProgress} />
            </VideoInput2>

            <ImageInput2 className="VideosEditor__poster"
                         imgUrl={values.posterUrl}
                         onUpload={this.handlePosterUpload}
                         placeholder="Drop a preview poster here or tap to add" >

              <ProgressBar2 progress={posterUploadProgress} />
            </ImageInput2>
          </div>

          <ProductForm
            onCancel={this.handleCancel}
            onHandledObjectUpdate={onHandledObjectUpdate}
            onSubmit={this.handleSubmit}
            values={values}
          />
        </ModalWrapper>
      </div>
    );
  }

}


VideosEditor.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPosterUpload: PropTypes.func.isRequired,
  onVideoUpload: PropTypes.func.isRequired,
  posterUploadProgress: PropTypes.number,
  videoUploadProgress: PropTypes.number,
  videos: PropTypes.array,
};

export default VideosEditor;
