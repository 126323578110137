import React, { FC, memo } from 'react';
import PropTypes from 'prop-types';
import { Burger } from 'react-fishfacts/dist';

import './Header.scss';
import AccountLink from 'components/common/AccountLink/AccountLink';
import Logo from 'components/common/logos/Logo/Logo';
import { ROUTES } from 'other/config';
import Search from 'components/common/inputs/Search/Search';

type Props = {
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  toggleAside: () => void;
};

const Header: FC<Props> = ({ isAsideOpen, toggleAside, isLoggedIn }) => {
  const handleBurgerClick = () => toggleAside();

  return (
    <header className="Header">
      <div className="app-container">
        <Burger
          className="Header__burger"
          isActive={isAsideOpen}
          onClick={handleBurgerClick}
        />
        <Logo className="Header__logo" homeRoute={ROUTES.HOME} />
        {isLoggedIn && <Search className="Header__search" />}
        <AccountLink className="Header__accountLink" />
      </div>
    </header>
  );
};

Header.propTypes = {
  isAsideOpen: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  toggleAside: PropTypes.func.isRequired
};

export default memo(Header);
