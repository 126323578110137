import { ALL_VALUE, TLabelValue } from 'react-fishfacts/dist';
import { createSelector } from 'reselect';

import { APP_NAME } from '../../../other/config';
import { ESupportedApps, TVesselType } from '../../../types/dictionaries';

export const ALL: TLabelValue = {
  label: 'All types',
  value: ALL_VALUE
};

const transformNestedArray = (options): TLabelValue[] => {
  if (!options || options.length === 0) return [];

  return (
    options
      .filter(({ supportedApps }: TVesselType) =>
        supportedApps.includes(APP_NAME.toUpperCase() as ESupportedApps)
      )
      .flatMap(({ id, value: { en_GB }, subTypes }: TVesselType) => {
        const subs =
          subTypes && subTypes.length ? transformNestedArray(subTypes) : [];

        return subs.concat([
          {
            label: en_GB,
            value: id.toString()
          }
        ]);
      })
      // Remove 'Fishing vessel' parent type.
      .filter(({ value }: TLabelValue) => value !== '1')
  );
};

export const selectVesselTypesOptions = createSelector(
  (options: TVesselType[]) => options,
  transformNestedArray
);
