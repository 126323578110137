import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { RouteProps } from 'react-router-dom';

import {
  approveRejectMeetingRequestAction,
  bookCancelMeetingAction,
  clearEventAction,
  fetchEventAction,
  removeEventAction
} from '../../../store/events/events/eventsActions';
import deleteConfirm from '../../../components/common/modals/deleteConfirm';
import EventArticle from './EventArticle';
import { gotoEventEditor } from '../../../store/events/eventEditor/eventEditorActions';
import LoaderOverlay from '../../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import { ROUTES } from '../../../other/config';
import store from '../../../store/store';

import {
  EScheduleItemStatus,
  TEvent,
  TScheduleItem
} from '../../../types/event';
import { eventArticleProps, TEventScheduleBaseProps } from './helpers';
import { TState } from '../../../store/appStateModel';
import { TUserInfo } from '../../../types/userInfo';

type Props = TEventScheduleBaseProps & {
  clearEvent: () => void;
  editEvent: (id: number) => void;
  event: TEvent;
  eventId: number;
  getEvent: (id: number) => void;
  isLoading: boolean;
  removeEvent: (event: TEvent) => void;
  shouldScrollToSchedule: boolean;
};

class EventWrapper extends React.Component<Props> {
  static propTypes: Record<string, any>;

  componentDidMount() {
    const { eventId, getEvent } = this.props;
    getEvent(eventId);
  }
  // If we came to this page from the provider's page by clicking the 'Book meeting',
  // we want to scroll to the schedule.
  componentDidUpdate() {
    const { event, shouldScrollToSchedule } = this.props;
    if (!event || !shouldScrollToSchedule) return;

    const container = document.getElementById('Main__container');
    const schedule = document.getElementsByClassName('EventSchedule');

    if (container && schedule && schedule.length > 0) {
      container.scrollTo({
        // @ts-ignore
        top: schedule[0].offsetTop - 20,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
  componentWillUnmount(): void {
    this.props.clearEvent();
  }

  handleEdit = () => {
    const {
      event: { id },
      editEvent
    } = this.props;
    editEvent(id);
  };

  handleRemove = () => {
    const { event, removeEvent } = this.props;
    removeEvent(event);
  };

  render() {
    const {
      event,
      isLoading,
      isOwner,
      onApproveReject,
      onBookCancel,
      userId
    } = this.props;

    return (
      <LoaderOverlay className="Main__content--narrow" isLoading={isLoading}>
        <EventArticle
          event={event}
          editEvent={this.handleEdit}
          isOwner={isOwner}
          onApproveReject={onApproveReject}
          onBookCancel={onBookCancel}
          removeEvent={this.handleRemove}
          userId={userId}
        />
      </LoaderOverlay>
    );
  }
}

EventWrapper.propTypes = {
  ...eventArticleProps,
  clearEvent: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  getEvent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  shouldScrollToSchedule: PropTypes.bool
};

const removeConfirm = (event: TEvent) => {
  const bookedSlot: TScheduleItem = event.schedule.find(
    ({ status }: TScheduleItem) => status === EScheduleItemStatus.BOOKED
  );
  if (bookedSlot) {
    return notification.warning({
      message: 'Event cannot be removed',
      description:
        'This event cannot be removed since it has one or more booked time slots. If you want to remove it anyway, please contact us directly.',
      duration: 7
    });
  }
  deleteConfirm('Remove this event permanently?', () =>
    store.dispatch(removeEventAction(event.id))
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearEvent: () => dispatch(clearEventAction()),
  editEvent: (id: number) => dispatch(gotoEventEditor(id)),
  getEvent: (id: number) => dispatch(fetchEventAction(id)),
  onApproveReject: (item: TScheduleItem, shouldReject?: boolean) =>
    dispatch(approveRejectMeetingRequestAction(item, shouldReject)),
  onBookCancel: (item: TScheduleItem, shouldCancel?: boolean) =>
    dispatch(bookCancelMeetingAction(item, shouldCancel)),
  removeEvent: (event: TEvent) => removeConfirm(event)
});

const mapStateToProps = (
  state: TState,
  ownProps: {
    location;
    match: RouteProps;
  }
) => {
  const {
    events: { event, isPending },
    session: { user }
  } = state;

  const eventId: number = parseInt(ownProps.match.params.id);
  const userInfo: TUserInfo = user ? user.userInfo : ({} as any);
  const eventsIds: ReadonlyArray<number> = userInfo.eventsId || [];
  const shouldScrollToSchedule =
    ownProps.location.state &&
    ownProps.location.state.prevPath === ROUTES.SERVICE_PROVIDER;

  return {
    event: event,
    eventId: eventId,
    isLoading: isPending,
    isOwner: event && eventsIds.includes(event.id),
    shouldScrollToSchedule: shouldScrollToSchedule,
    userId: userInfo.id
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventWrapper);
