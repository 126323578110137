import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteProps } from 'react-router-dom';

import { getStatus, removeConfirm } from '../helpers';
import LoaderOverlay from '../../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import NewsArticle from './NewsArticle';
import {
  fetchArticleAction,
  clearArticleAction,
  fetchNewsAction
} from '../../../store/news/news/newsActions';
import { ROUTES } from '../../../other/config';

import { EPromoStatus } from '../../../types/promotion';
import { TArticle } from '../../../types/article';
import { TState } from '../../../store/appStateModel';

type Props = {
  article: TArticle;
  articleId: number;
  canEdit: boolean;
  clearArticle: () => void;
  editArticle: (id: number) => void;
  getArticle: (id: number) => void;
  getNews: () => void;
  isLoading: boolean;
  removeArticle: (id: number) => void;
  status?: EPromoStatus;
};

class NewsArticleWrapper extends React.Component<Props, null> {
  static propTypes: Object;

  componentDidMount() {
    const { articleId, getNews, getArticle } = this.props;
    getNews();
    getArticle(articleId);
  }
  componentDidUpdate(prevProps: Props) {
    const { articleId, getArticle } = this.props;
    if (prevProps.articleId !== articleId) {
      getArticle(articleId);
    }
  }
  componentWillUnmount(): void {
    this.props.clearArticle();
  }

  handleEdit = () => {
    const {
      article: { id },
      editArticle
    } = this.props;
    editArticle(id);
  };

  handleRemove = () => {
    const {
      article: { id },
      removeArticle
    } = this.props;
    removeArticle(id);
  };

  render() {
    const { article, canEdit, isLoading } = this.props;

    return (
      <LoaderOverlay className="Main__content--narrow" isLoading={isLoading}>
        {article ? (
          <NewsArticle
            article={article}
            canEdit={canEdit}
            editArticle={this.handleEdit}
            removeArticle={this.handleRemove}
          />
        ) : (
          <h4>Not found</h4>
        )}
      </LoaderOverlay>
    );
  }
}

NewsArticleWrapper.propTypes = {
  article: PropTypes.object,
  canEdit: PropTypes.bool,
  clearArticle: PropTypes.func.isRequired,
  editArticle: PropTypes.func.isRequired,
  getArticle: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  removeArticle: PropTypes.func.isRequired,
  status: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
  clearArticle: () => dispatch(clearArticleAction()),
  getNews: () => dispatch(fetchNewsAction()),
  editArticle: (id: number) => dispatch(push(`${ROUTES.NEWS_EDITOR}/${id}`)),
  getArticle: (id: number) => dispatch(fetchArticleAction(id)),
  removeArticle: (id: number) => removeConfirm(id)
});

const mapStateToProps = (state: TState, ownProps: { match: RouteProps }) => {
  const {
    news: { article, isPending },
    promo: { articles },
    session: { user }
  } = state;

  const articleId: number = parseInt(ownProps.match.params.id);
  const newsIds: ReadonlyArray<number> = user ? user.userInfo.newsId : [];

  return {
    canEdit: article && newsIds.includes(article.id),
    article,
    articleId,
    isLoading: isPending,
    status: getStatus(articleId, articles, newsIds)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsArticleWrapper);
