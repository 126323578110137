import React from 'react';
import { LoaderOverlay } from 'react-fishfacts/dist';

import './PromotionControl.scss';
import PromotionCost from './PromotionCost';
import PromotionForm from './PromotionForm/PromotionForm';
import PromotionInfo from './PromotionInfo';
import { ROUTES } from '../../../../other/config';

import { TFormFields } from '../../../../types/formFields';
import { promotionProps, TPromotionProps } from './PromoWrapper';

type Props = TPromotionProps & {
  onCalculate: (promotion: TFormFields) => void;
};

class PromotionControl extends React.Component<Props> {
  static propTypes;

  render() {
    const {
      article,
      cost,
      countries,
      isCostLoading,
      isLoading,
      onCalculate,
      onPay,
      report,
      serviceOptions,
      vesselTypesOptions,
      ...restOfProps
    } = this.props;

    const formProps = {
      ...restOfProps,
      countries,
      serviceOptions,
      vesselTypesOptions
    };

    const content = article ? (
      <PromotionInfo
        article={article}
        report={report}
        serviceOptions={serviceOptions}
        vesselTypesOptions={vesselTypesOptions}
      />
    ) : (
      <PromotionForm onChange={onCalculate} onSubmit={onPay} {...formProps}>
        <PromotionCost cost={cost} isPending={isCostLoading} />
      </PromotionForm>
    );

    return (
      <div className="PromotionControl">
        <LoaderOverlay
          className="PromotionControl__content"
          isLoading={isLoading}
          isTransparent={true}
        >
          <div id={ROUTES.PROMO_FORM} className="Expander">
            <h3 className="PromotionForm__title">Promotion</h3>
            {content}
          </div>
        </LoaderOverlay>
      </div>
    );
  }
}

PromotionControl.propTypes = promotionProps;
export default PromotionControl;
