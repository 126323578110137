import React, { CSSProperties, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Upload, message } from 'antd';

import './ImageInput2.scss';
import IconCustom from '../../IconCustom/IconCustom';

const MAX_SIZE = {
  label: '20MB',
  value: 20971520
};
type Props = {
  children: ReactElement;
  className?: string;
  imgUrl?: string;
  onUpload: (file: File) => void;
  placeholder?: string;
  showUploadList?: boolean;
};

const ImageInput2: React.FC<Props> = ({
  children,
  className,
  imgUrl,
  onUpload,
  placeholder,
  showUploadList
}): ReactElement => {
  const cls: string = classnames('ImageInput2', className);
  const style: CSSProperties = imgUrl
    ? { backgroundImage: 'url(' + encodeURI(imgUrl) + ')' }
    : null;

  const handleUpload = ({ file: { originFileObj } }) => {
    if (originFileObj.size > MAX_SIZE.value) {
      return message.error(`File size should be less than ${MAX_SIZE.label}!`);
    }
    onUpload(originFileObj);
  };

  const props: {} = {
    accept: 'image/*',
    customRequest: Function.prototype,
    fileList: null,
    multiple: false,
    name: 'file',
    showUploadList: showUploadList,
    onChange: handleUpload
  };

  return (
    <div className={cls}>
      <Upload.Dragger {...props}>
        <div className="ImageInput2__placeholder">
          <div className="ImageInput2__info">
            <div className="ant-upload-drag-icon">
              <IconCustom type="photo" style={{ fontSize: 22 }} />
            </div>
            <div className="ant-upload-text">{placeholder}</div>
          </div>

          <div className="ImageInput2__image" style={style} />
          {children}
        </div>
      </Upload.Dragger>
    </div>
  );
};

ImageInput2.defaultProps = {
  placeholder: 'Drop a photo here or tap to add',
  showUploadList: true
};
ImageInput2.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showUploadList: PropTypes.bool
};

export default ImageInput2;
