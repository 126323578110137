import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import './SpeciesTable.scss';
import { LOCALE } from '../../../../other/config';
import { TFishingArea, TSpecie } from '../../../../types/specie';

type Props = {
  species: TSpecie[];
};


const SpeciesTable = ({ species }: Props): ReactElement | null => {
  if (!species || !species.length) return null;

  const getQuota = (amount: number, days: number): string => days
    ? `${amount} tons / ${days} days`
    : `${amount} tons`;

  const renderSpecies = (sp: TSpecie): ReactElement => {
    const { amount, comment, days, fishingArea, specie, type } = sp;
    const areas: string[] = fishingArea.map((area: TFishingArea) =>
      area.fishingArea.value[LOCALE] + ' '
    );

    const info = comment && (
      <Tooltip
        overlayClassName="SpeciesTable__card"
        placement="right"
        title={comment}
      >
        {/*<Icon className="SpeciesTable__info" type="info-circle" />*/}
      </Tooltip>
    );

    return (
      <tr key={Math.random()}>
        <td>
          {specie.value[LOCALE]}{' '}
          {info}
        </td>

        <td>{areas}</td>
        <td>{getQuota(amount, days)}</td>
        <td>{type.value[LOCALE]}</td>
      </tr>
    )
  };

  return (
    <td colSpan={4}>
      <table className="SpeciesTable">
        <tbody>
          {species.map(renderSpecies)}
        </tbody>
      </table>
    </td>
  );
};


SpeciesTable.propTypes = {
  species: PropTypes.array
};

export default SpeciesTable;
