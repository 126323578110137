import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './Logo.scss';
import { ROUTES } from '../../../../other/config';

type Props = {
  imageUrl?: string;
  altName?: string;
  className?: string;
  homeRoute?: string;
};

const Logo: FunctionComponent<Props> = ({
  imageUrl,
  altName,
  homeRoute,
  className
}) => {
  const logoClassName = classnames('Logo', className);

  return (
    <Link className={logoClassName} to={homeRoute}>
      <img className="Logo__image" src={imageUrl} alt={altName} />
    </Link>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  altName: PropTypes.string,
  homeRoute: PropTypes.string
};

Logo.defaultProps = {
  imageUrl: '/assets/logo/Logo.svg',
  altName: 'FishFacts',
  homeRoute: ROUTES.HOME
};

export default Logo;
