import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PostInfo.scss';
import { getDate } from '../../../../other/helpers';
import { ROUTES } from '../../../../other/config';

const PostInfo = ({ date, source }) => {
  const { id, name } = source || {};
  const companyLink = `${ROUTES.SERVICE_PROVIDER}/${id}`;
  const _date = getDate(date);

  return (
    <div className="PostInfo">
      {source && (
        <h5 className="PostInfo__issuer">
          Posted by <Link to={companyLink}>{name}</Link> ∙{' '}
        </h5>
      )}
      <em className="PostInfo__date">{_date}</em>
    </div>
  );
};

PostInfo.propTypes = {
  date: PropTypes.string.isRequired,
  source: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};

export default PostInfo;
