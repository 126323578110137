import { TAction } from 'react-fishfacts/dist';

/**
 * Creates a set of request-success-error async action creators by given actions group name.
 * Each of the creators accepts optional data or error-data parameters.
 * S - domain state slice (helps checking payload type).
 */
export function createAsyncActions<S>(
  dispatch: Function,
  actionGroupName: string
): {
  // The three below dispatch actions
  request: (data?: Partial<S>) => {}; // Actually it returns what `createAsyncActions` returns, to allow chaining.
  success: (data?: Partial<S>) => void;
  error: (e: any, data?: Partial<S>) => PromiseLike<never>;
  // The below are action creators and meant for tests:
  _request: (data?: Partial<S>) => TAction<S, string>;
  _success: (data?: Partial<S>) => TAction<S, string>;
  _error: (e: any, data?: Partial<S>) => TAction<S, string>;
} {
  if (typeof actionGroupName !== 'string') {
    throw new TypeError('ActionFactory: bad actionGroupName type');
  }

  const _request = (data: Partial<S> = {}): TAction<S, string> => ({
    type: `${actionGroupName}_STARTED`,
    payload: {
      isPending: true,
      error: null,
      ...data
    }
  });

  const _success = (data: Partial<S> = {}): TAction<S, string> => ({
    type: `${actionGroupName}_DONE`,
    payload: {
      isPending: false,
      error: null,
      ...data
    }
  });

  const _error = (error: Error, data: Partial<S> = {}): TAction<S, string> => {
    const type = `${actionGroupName}_FAILED`;
    window.console.error(`${type}\n${error}`);

    return {
      type: type,
      payload: {
        isPending: false,
        error: error,
        ...data
      }
    };
  };

  const actions = {
    request: (data?: Partial<S>) => {
      dispatch(_request(data));
      return actions;
    },
    success: (data?: Partial<S>) => dispatch(_success(data)),
    error: (error: Error, data?: Partial<S>) => dispatch(_error(error, data)),
    _request: _request,
    _success: _success,
    _error: _error
  };
  return actions;
}
