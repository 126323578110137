import React from 'react';
import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PlanCard.scss';


const PlanCard =({ isSelected, plan, onClick }) => {
	const { description, id, price, priceTerm } = plan;
	const handleClick = (e) => {
	  onClick(id);
	  e.stopPropagation();
  };
	
	const priceValue = new Intl.NumberFormat({
		maximumFractionDigits: 0
	}).format(price);
	
	const checkedOverlay = (
		<div className="PlanCard__chosen">
			<div className="PlanCard__icon--holder">
				<FontAwesomeIcon
					className="PlanCard__icon"
					icon={faCheckCircle}
					size="3x"
				/>
			</div>
		</div>
	);
	
	
	return (
		<article className="PlanCard--holder" onClick={handleClick}>
			<div className="PlanCard" onClick={handleClick}>
				<div className="PlanCard__price">
					<small>&euro;&nbsp;</small>{priceValue}
				</div>
				
				<div className="PlanCard__term">/ {priceTerm.toLowerCase()}</div>
				<div className="PlanCard__description">{description}</div>
				
				{isSelected && checkedOverlay}
			</div>
		</article>
	);
};


PlanCard.propTypes = {
	isSelected: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	plan: PropTypes.shape({
		id: PropTypes.number,
		description: PropTypes.string,
		price: PropTypes.number,
		priceTerm: PropTypes.string,
	}).isRequired,
};

export default PlanCard;
