import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoaderOverlay, TVessel } from 'react-fishfacts/dist';

import FavManager from './FavManager';
import VesselCompare from './VesselCompare';
import VesselListWrapper from './VesselListWrapper';
import VesselsPageFilter from 'components/VesselsPageFilter/VesselsPageFilter';

type Props = {
  applyVesselsFilter: () => void;
  compareVessels: () => void;
  isFilterSet: boolean;
  isPending: boolean;
  vesselsComparator: {
    vesselA: TVessel;
    vesselB: TVessel;
  };
  vesselComparisonAdd: (vessel: TVessel) => void;
  vesselComparisonRemove: (vesselId: number) => void;
  vessels?: TVessel[];
};

class VesselListPage extends React.Component<Props> {
  static propTypes;

  componentDidMount(): void {
    this.props.applyVesselsFilter();
  }

  render() {
    const {
      compareVessels,
      isFilterSet,
      isPending,
      vessels,
      vesselsComparator,
      vesselComparisonAdd,
      vesselComparisonRemove
    } = this.props;

    const ifComparator = vesselsComparator.vesselA || vesselsComparator.vesselB;

    const compClass = classnames('Vessel__mainWrapper', {
      'Vessel__mainWrapper--panel': ifComparator,
      'Vessel__mainWrapper--panel-active': isFilterSet && !ifComparator
    });

    return (
      <div className={compClass}>
        <VesselsPageFilter />

        {isFilterSet && vessels.length > 0 && <FavManager vessels={vessels} />}

        <VesselCompare
          compareVessels={compareVessels}
          vesselsComparator={vesselsComparator}
          vesselComparisonRemove={vesselComparisonRemove}
        />

        <LoaderOverlay isLoading={isPending}>
          <VesselListWrapper
            vesselsComparator={vesselsComparator}
            vesselComparisonAdd={vesselComparisonAdd}
            vesselComparisonRemove={vesselComparisonRemove}
            vessels={vessels}
          />
        </LoaderOverlay>
      </div>
    );
  }
}

VesselListPage.propTypes = {
  compareVessels: PropTypes.func.isRequired,
  isFilterSet: PropTypes.bool,
  isPending: PropTypes.bool,
  vesselsComparator: PropTypes.shape({
    vesselA: PropTypes.object,
    vesselB: PropTypes.object
  }),
  vesselComparisonAdd: PropTypes.func.isRequired,
  vesselComparisonRemove: PropTypes.func.isRequired,
  vessels: PropTypes.array
};

export default VesselListPage;
