import { basicInitialState } from '../../_utils/basicInitialState';
import { TProductEditorState } from './productEditor';

export const productEditorInitialState: TProductEditorState = {
  ...basicInitialState,
  published: false,
  time: null,
  source: null,
  title: null,
  shortDescription: null,
  id: null,
  longDescription: null,
  longDescriptionMedia: null,
  banner: null,
  logo: null,
  fields: {},
  uploadProgress: null
};
