import React from 'react';
import PropTypes from 'prop-types';
import { LoaderOverlay } from 'react-fishfacts/dist';
import { Prompt } from 'react-router-dom';

import { editorProps, TEventEditorProps } from './forms/helpers';
import EventEditor from './EventEditor';
import { showValidationWarning } from '../../../other/formAndValidation/showValidationWarning';
import { TNewsEditorState } from '../../../store/news/newsEditor/newsEditorModel';

const confirmation = () => `Discard changes and leave the page?`;
type Props = TNewsEditorState &
  TEventEditorProps & {
    eventId: number;
    getEvent: (eventId: number) => void;
    isEmpty: boolean;
    onCancel: (eventId?: number) => void;
    onLeave: () => void;
    onRemove: (eventId: number) => void;
  };

class EventEditorWrapper extends React.Component<Props> {
  static propTypes;
  componentDidMount() {
    const { getEvent, eventId } = this.props;
    getEvent(eventId);
  }

  componentWillUnmount(): void {
    const { id, isEmpty, onLeave, onRemove } = this.props;
    isEmpty && onRemove(id);
    onLeave();
  }

  handleCancel = () => {
    const { id, isEmpty, onCancel } = this.props;
    onCancel(isEmpty ? null : id);
  };

  handleSubmit = (isDraft: boolean): void => {
    const { fields, onSubmit } = this.props;
    if (isDraft) return onSubmit(true);
    showValidationWarning(fields) && onSubmit();
  };

  render() {
    const {
      isModified,
      isPending,
      getEvent,
      onSubmit,
      ...restOfProps
    } = this.props;
    const isLoading: boolean = isPending && !restOfProps.id;
    const editorProps = {
      ...restOfProps,
      isModified,
      onCancel: this.handleCancel
    };

    return (
      <LoaderOverlay isLoading={isLoading}>
        <Prompt when={isModified} message={confirmation} />
        {restOfProps.id && (
          <EventEditor onSubmit={this.handleSubmit} {...editorProps} />
        )}
      </LoaderOverlay>
    );
  }
}

EventEditorWrapper.propTypes = {
  ...editorProps,
  getEvent: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool,
  isModified: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired,
  onLeave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EventEditorWrapper;
