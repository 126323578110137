import React from 'react';
import PropTypes from 'prop-types';

import LoaderOverlay from '../../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import ProviderEditor from './ProviderEditor';
import { providerEditorProps, TProviderEditorProps } from './other/helpers';

type Props = TProviderEditorProps & {
  getProvider: (providerId?: number) => void;
  onLeave: () => void;
  providerId?: number;
};

class ProviderEditorWrapper extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { getProvider, providerId } = this.props;
    getProvider(providerId);
  }
  componentWillUnmount() {
    this.props.onLeave();
  }

  render() {
    const {
      categories,
      countries,
      editorState,
      events,
      onAddMediaObject,
      onEventEdit,
      onEventRemove,
      onFieldChange,
      onHandledObjectUpdate,
      onObjectChange,
      onObjectHandle,
      onProductEdit,
      onRemove,
      onSubmit,
      onUpload
    } = this.props;
    const { isModified, isPending } = editorState;
    const isLoading: boolean = isPending && isModified;

    return (
      <LoaderOverlay
        className="ProviderEditor__loader"
        isLoading={isLoading}
        isTransparent={isModified}
      >
        <ProviderEditor
          categories={categories}
          countries={countries}
          editorState={editorState}
          events={events}
          onAddMediaObject={onAddMediaObject}
          onEventEdit={onEventEdit}
          onEventRemove={onEventRemove}
          onFieldChange={onFieldChange}
          onHandledObjectUpdate={onHandledObjectUpdate}
          onObjectChange={onObjectChange}
          onObjectHandle={onObjectHandle}
          onProductEdit={onProductEdit}
          onRemove={onRemove}
          onSubmit={onSubmit}
          onUpload={onUpload}
        />
      </LoaderOverlay>
    );
  }
}

ProviderEditorWrapper.propTypes = {
  ...providerEditorProps,
  getProvider: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  providerId: PropTypes.number
};

export default ProviderEditorWrapper;
