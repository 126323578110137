import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withTracker } from 'react-fishfacts/dist';

// import AboutPage from '../pages/AboutPage';
import AnalyticsPage from '../pages/analytics/AnalyticsPage';
import AuthoredRoute from './common/AuthoredRoute';
import Company from '../pages/company/Company';
// import Companies from '../pages/companies/index';
import CompareVessel from '../pages/vessel/VesselsComparison/VesselsComparison';
import ContactPage from '../pages/ContactPage';
import DisclaimerPage from '../pages/DisclaimerPage';
import EventEditorPage from '../pages/events/EventEditor/EventEditorPage';
import EventPage from '../pages/events/EventPage/EventPage';
import EventsListPage from '../pages/events/EventsList/EventsListPage';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/LoginPage';

import NewsArticlePage from '../pages/news/NewsArticlePage/NewsArticlePage';
import NewsEditorPage from '../pages/news/NewsEditor/NewsEditorPage';
import NewsListPage from '../pages/news/NewsList/NewsListPage';

import Page404 from '../pages/Page404/Page404';
import PersonPage from '../pages/Person/PersonPage';
import ProductArticlePage from '../pages/serviceProviders/ProductPage/ProductPage';
import ProductEditorPage from '../pages/serviceProviders/ProductEditorPage/ProductEditorPage';
import PrivateRoute from './common/PrivateRoute';

import Services from '../pages/serviceProviders/ServiceBranch/ServiceBranch';
import ServiceCategory from '../pages/serviceProviders/ServiceCategory/ServiceCategory';
import ServiceEditorPage from '../pages/serviceProviders/ServiceEditor/ProviderEditorPage';
import ServicePage from '../pages/serviceProviders/Service/ServicePage';
import ServicePlan from '../pages/serviceProviders/ServicePlan/ServicePlan';
import ServiceRegistrationPage from '../pages/serviceProviders/ServiceRegistrationPage/ServiceRegistrationPage';

import Species from '../pages/Species';
import TestPage from '../pages/TestPage/TestPage';
import User from '../pages/User/UserPage';

import VesselListPageConnected from '../pages/vessel/VesselsList/VesselListPageConnected';
import VesselPageConnected from '../pages/vessel/Vessel/VesselPageConnected';
import VesselsMapPage from '../pages/Map/VesselsMapPage';

import { ROUTES } from '../other/config';
import { isProdMode } from './helpers/helpers';
import { EUserAuthorities } from '../store/session/sessionModel';

const analyticsRoles = [EUserAuthorities.VIEW_ANALYTICS];
const companyPageRoles = [EUserAuthorities.VIEW_OWNER];
const isProduction = isProdMode();
const newsRoles = [EUserAuthorities.CREATE_NEWS];
const serviceRoles = [EUserAuthorities.SERVICE_PROVIDER];
const viewMapPermissions = [EUserAuthorities.VIEW_MAP];

class RouteSwitch extends React.PureComponent {
  render() {
    const { isLoggedIn } = this.props;
    const defaultRedirect = isLoggedIn ? ROUTES.NEWS : ROUTES.HOME;
    const HomePage = isLoggedIn ? NewsListPage : Home;

    return (
      <Switch>
        <Route exact path="/" component={withTracker(HomePage)} />
        {/*<Route path={ROUTES.ABOUT} component={withTracker(AboutPage)} />*/}
        {/*<Route path={routes.ADVERTISE} component={withTracker(AboutPage)} />*/}
        {/*<Route path={ROUTES.COMPANIES} component={withTracker(Companies)} />*/}
        <PrivateRoute
          path={ROUTES.COMPARE}
          component={withTracker(CompareVessel)}
        />
        <Route path={ROUTES.CONTACT} component={withTracker(ContactPage)} />
        <Route
          path={ROUTES.DISCLAIMER}
          component={withTracker(DisclaimerPage)}
        />
        <Route path={ROUTES.LOGIN} component={withTracker(Login)} />
        <PrivateRoute path="/species" component={withTracker(Species)} />
        <PrivateRoute exact path={ROUTES.USER} component={withTracker(User)} />

        <AuthoredRoute
          roles={viewMapPermissions}
          exact
          path={ROUTES.MAP}
          component={withTracker(VesselsMapPage)}
        />

        <AuthoredRoute
          roles={companyPageRoles}
          path="/company/:companyId"
          component={withTracker(Company)}
        />

        <PrivateRoute path="/person/:id" component={withTracker(PersonPage)} />

        <Route
          path={ROUTES.SERVICE_BRANCH + '/:id'}
          component={withTracker(Services)}
        />
        <Route
          path={ROUTES.SERVICE_CATEGORY + '/:id'}
          component={withTracker(ServiceCategory)}
        />
        <Route
          path={`${ROUTES.SERVICE_PROVIDER}/:providerId/products/:productId/edit`}
          component={withTracker(ProductEditorPage)}
        />
        <Route
          path={`${ROUTES.SERVICE_PROVIDER}/:providerId/products/:productId`}
          component={withTracker(ProductArticlePage)}
        />
        <Route
          path={ROUTES.SERVICE_PROVIDER + '/:id'}
          component={withTracker(ServicePage)}
        />

        <PrivateRoute
          path="/vessel/:vesselId"
          component={withTracker(VesselPageConnected)}
        />
        <PrivateRoute
          exact
          path={ROUTES.VESSELS}
          component={withTracker(VesselListPageConnected)}
        />
        <PrivateRoute
          path={ROUTES.VESSELS_FLEET}
          component={withTracker(VesselListPageConnected)}
        />

        <AuthoredRoute
          roles={serviceRoles}
          path={ROUTES.SERVICE_PLAN}
          component={withTracker(ServicePlan)}
        />
        <AuthoredRoute
          roles={serviceRoles}
          exact
          path={ROUTES.SERVICE_EDITOR}
          component={withTracker(ServiceEditorPage)}
        />
        <AuthoredRoute
          roles={serviceRoles}
          path={ROUTES.SERVICE_EDITOR + '/:id'}
          component={withTracker(ServiceEditorPage)}
        />
        <AuthoredRoute
          roles={serviceRoles}
          path={ROUTES.SERVICE_REGISTRATION}
          component={withTracker(ServiceRegistrationPage)}
        />

        <AuthoredRoute
          roles={newsRoles}
          exact
          path={ROUTES.NEWS_EDITOR}
          component={withTracker(NewsEditorPage)}
        />
        <AuthoredRoute
          roles={newsRoles}
          path={ROUTES.NEWS_EDITOR + '/:id'}
          component={withTracker(NewsEditorPage)}
        />

        <Route
          roles={serviceRoles}
          exact
          path={ROUTES.NEWS}
          component={withTracker(NewsListPage)}
        />
        <Route
          roles={serviceRoles}
          path={ROUTES.NEWS + '/:id'}
          component={withTracker(NewsArticlePage)}
        />

        <AuthoredRoute
          roles={serviceRoles}
          exact
          path={ROUTES.EVENT_EDITOR}
          component={withTracker(EventEditorPage)}
        />
        <AuthoredRoute
          roles={serviceRoles}
          path={ROUTES.EVENT_EDITOR + '/:id'}
          component={withTracker(EventEditorPage)}
        />
        <Route
          exact
          path={ROUTES.EVENTS}
          component={withTracker(EventsListPage)}
        />
        <Route
          path={ROUTES.EVENTS + '/:id'}
          component={withTracker(EventPage)}
        />

        <AuthoredRoute
          roles={analyticsRoles}
          path={ROUTES.ANALYTICS}
          component={withTracker(AnalyticsPage)}
        />

        <Route path={ROUTES.PAGE404} component={withTracker(Page404)} />

        {isProduction || <Route path={ROUTES.TEST} component={TestPage} />}
        <Redirect to={defaultRedirect} />
      </Switch>
    );
  }
}

RouteSwitch.propTypes = {
  isLoggedIn: PropTypes.bool
};

export default RouteSwitch;
