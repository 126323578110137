import React, { useState, FC } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import './ContactUsButton.scss';
import { ENDPOINTS } from 'other/config';
import http from 'services/http';

const successMessage =
  'Thank you for contacting us. We will get back to you as soon as possible.';

type Props = {
  serviceProviderId: number;
};

const ContactUsButton: FC<Props> = ({ serviceProviderId }) => {
  const [isPending, setIsPending] = useState(false);

  const handleClick = async () => {
    const url = `${ENDPOINTS.SERVICE_PROVIDER}/${serviceProviderId}/contactMe`;
    let errorMessage = '';

    try {
      setIsPending(true);

      await http.send({
        method: 'POST',
        url
      });
    } catch ({ message }) {
      errorMessage = message;
    }

    const modalType = errorMessage ? 'error' : 'success';
    const modal = Modal[modalType]({
      content: errorMessage || successMessage,
      centered: true,
      maskClosable: true,
      onCancel: () => modal.destroy()
    });

    setIsPending(false);
  };

  return (
    <Button
      className="ContactUsButton"
      disabled={isPending}
      size="large"
      onClick={handleClick}
    >
      Contact us
    </Button>
  );
};

ContactUsButton.propTypes = {
  serviceProviderId: PropTypes.number.isRequired
};

export default ContactUsButton;
