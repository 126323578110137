import { createSelector } from 'reselect';
import { TLabelValue } from 'react-fishfacts/dist';

import { APP_NAME } from '../../../../../../other/config';
import {
  ESupportedApps,
  TVesselType
} from '../../../../../../types/dictionaries';

const transformNestedArray = (options): TLabelValue[] => {
  return options
    .filter(({ supportedApps }: TVesselType) =>
      supportedApps.includes(APP_NAME.toUpperCase() as ESupportedApps)
    )
    .flatMap(({ id, value: { en_GB }, subTypes }: TVesselType) => [
      { label: en_GB, value: id.toString() },
      ...(subTypes && subTypes.length ? transformNestedArray(subTypes) : [])
    ])
    .filter(({ value }: TLabelValue) => value !== '1');
};

const getVesselTypesOptions = (options: TVesselType[]): TLabelValue[] =>
  transformNestedArray(options);

export const selectVesselTypesOptions = createSelector(
  (options: TVesselType[]) => options,
  getVesselTypesOptions
);
