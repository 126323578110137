import { createSelector } from 'reselect';
import { TPromoted } from '../../../types/promotion';

export const WIDTH_THRESHOLD_SMALL = 568;
export const WIDTH_THRESHOLD_MIDDLE = 768;

export function getChunkSize(width) {
  if (width < WIDTH_THRESHOLD_SMALL) return 1;
  if (width < WIDTH_THRESHOLD_MIDDLE) return 2;
  return 3;
}

/**
 * Returns an array with arrays of the given size.
 * @param myArray {Array} Array to split
 * @param chunkSize {Number} Size of every group
 */
export function chunkArray(myArray, chunkSize) {
  if (!myArray) return [];
  const results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }
  return results;
}

/**
 * Shuffle an array.
 * @param array {Array} Array to shuffle
 */
export function shuffleArray(array) {
  return array.sort(() => 0.5 - Math.random());
}

type TArgs = {
  newsIds: ReadonlyArray<number>;
  promoIds: number[];
};

const articlesSelector = ({
  articles,
  newsIds
}: {
  articles: TPromoted[];
  newsIds: ReadonlyArray<number>;
}): TArgs => ({
  newsIds: newsIds,
  promoIds: articles
    .map(({ news }: TPromoted): number => news && news.id)
    .filter(Number)
});

export const selectArticleIds = createSelector(
  articlesSelector,
  ({ newsIds, promoIds }: TArgs): number[] =>
    newsIds.filter((id: number) => !promoIds.includes(id))
);
