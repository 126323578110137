import React from 'react';
import PropTypes from 'prop-types';

import './Video.scss';
import withAuth from '../../../components/withAuth';

const Video = ({ isLoggedIn }) => {
  if (isLoggedIn) return null;

  return (
    <section className="Video">
      <div className="app-container">
        <div className="Video__holder">
          <div className="VideoResponsiveFrame">
            <iframe
              allow="autoplay; fullscreen; picture-in-picture; camera; microphone; display-capture"
              aria-label="Video"
              className="vidyard-iframe-rMJRiaruqUTzWiKLvVybQ3"
              height="100%"
              width="100%"
              scrolling="no"
              src="https://play.vidyard.com/NRjmnobvmbdmeYDx8qs5Wy?disable_popouts=1&amp;v=4.2.20&amp;type=inline"
              title="Video"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

Video.propTypes = {
  isLoggedIn: PropTypes.bool
};

export default withAuth(Video);
