import React, { ReactElement, ReactNode } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ForecastPlayer.scss';
import { hideBottomTools, hideWeatherForecast } from '../../utils/tooling';
import IconCustom from '../../../../components/common/IconCustom/IconCustom';
import { isMobile } from '../../../../components/helpers/helpers';
import TimeLine from './TimeLine';
import { EMapLayer } from '../../../../types/mapLayer';

type PlayerProps = {
  currentMapLayer: EMapLayer;
};
type Props = {
  currentMapLayer: EMapLayer;
};
type State = {
  isPlayerVisible: boolean;
};

class Player extends React.PureComponent<PlayerProps, State> {
  state: State = { isPlayerVisible: true };

  componentDidMount() {
    hideBottomTools(true);
    hideWeatherForecast(false);
  }

  componentDidUpdate(prevProps: PlayerProps) {
    if (prevProps.currentMapLayer !== this.props.currentMapLayer) {
      hideBottomTools(true);
      hideWeatherForecast(false);
      this.setState({ isPlayerVisible: true });
    }
  }

  componentWillUnmount(): void {
    hideBottomTools(false);
  }

  handleClick = () => {
    const { isPlayerVisible } = this.state;
    hideBottomTools(!isPlayerVisible);
    hideWeatherForecast(isPlayerVisible);
    this.setState({ isPlayerVisible: !isPlayerVisible });
  };

  render() {
    const { isPlayerVisible } = this.state;
    const days: number =
      this.props.currentMapLayer === EMapLayer.WAVES ? 8 : 10;

    const cls: string = classnames('ForecastPlayer__button', {
      'ForecastPlayer--active': isPlayerVisible,
      'ForecastPlayer__button--mobile': isMobile()
    });

    const guts: ReactNode = (
      <>
        <div className="ForecastPlayer__indicator" />
        <TimeLine days={days} />
      </>
    );

    return (
      <Button className={cls} onClick={this.handleClick}>
        <IconCustom type="weather" title="Weather" style={{ fontSize: 22 }} />
        {isPlayerVisible && guts}
      </Button>
    );
  }
}

const ForecastPlayer = ({ currentMapLayer }: Props): ReactElement => {
  const shouldShowPlayer: boolean =
    currentMapLayer === EMapLayer.TEMP ||
    currentMapLayer === EMapLayer.WIND ||
    currentMapLayer === EMapLayer.WAVES;

  if (shouldShowPlayer) return <Player currentMapLayer={currentMapLayer} />;

  const cls: string = classnames('ForecastPlayer__button', {
    'ForecastPlayer__button--mobile': isMobile()
  });

  return (
    <Button className={cls} disabled={!shouldShowPlayer}>
      <IconCustom type="weather" title="Weather" style={{ fontSize: 22 }} />
    </Button>
  );
};

ForecastPlayer.propTypes = {
  currentMapLayer: PropTypes.string.isRequired
};
export default ForecastPlayer;
