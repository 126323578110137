import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ProductsList.scss';
import CardWrapper from '../other/CardWrapper';
import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import Expander from '../../../../components/common/Expander/Expander';
import ProductCard from '../../Service/Products/ProductCard';
import { TProduct } from '../../../../types/product';

type Props = {
  onAdd: () => void;
  onEdit: (product: TProduct) => void;
  onRemove: (productId: number) => void;
  products: TProduct[];
};

class ProductsList extends React.PureComponent<Props> {
  static propTypes;

  render() {
    const { products, onAdd, onEdit, onRemove } = this.props;
    const slides =
      products && products.length ? (
        products.map(
          (product: TProduct): ReactElement => {
            const handleEdit = () => onEdit(product);
            return (
              <CardWrapper
                id={product.id}
                key={product.id}
                onEdit={handleEdit}
                onRemove={onRemove}
              >
                <ProductCard product={product} />
              </CardWrapper>
            );
          }
        )
      ) : (
        <EmptyList
          placeholder="Add your first product"
          placeholderImgUrl="/assets/placeholders/product_card_placeholder.svg"
        />
      );

    return (
      <section className="ServiceProvider__group">
        <Expander
          buttonText="Add product"
          itemWidth={220}
          onAdd={onAdd}
          title="Products"
        >
          {slides}
        </Expander>
      </section>
    );
  }
}

ProductsList.propTypes = {
  onAdd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  products: PropTypes.array
};

export default ProductsList;
