import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  changeTrackPeriodAction,
  fetchTrackAction,
  purgeTracksAction,
  removeTrackAction,
  updateTrackPointsAction
} from '../../store/map/tracks/tracksActions';
import { fetchProvidersAction } from '../../store/provider/providers/providersActions';
import MapWrapper from './MapWrapper';
import {
  purgeEntitiesAction,
  toggleServiceSelectAction,
  updateServiceCardRefAction,
  updateVesselCardsRefsAction
} from '../../store/map/mapEntities/mapEntitiesActions';
import { setVesselFilterAction } from '../../store/vessel/vesselsFilter/vesselsFilterActions';
import { showProviderPageAction } from '../../store/provider/provider/providerActions';
import { showVesselPageAction } from '../../store/vessel/vessel/vesselActions';
import { unwatchLocationsAction } from '../../store/map/vesselsLocations/vesselsLocationsActions';
import { updateMapOptionsAction } from '../../store/map/mapOptions/mapOptionsActions';

import { ESettingsSelectOptions } from '../../services/settings';
import { EVesselsFilterActions } from '../../store/vessel/vesselsFilter/vesselsFilterConstants';
import { TFleetShort } from '../../types/fleet';
import { TMapOptions } from './types/mapOptions';
import { TState } from '../../store/appStateModel';
import { TUser } from '../../store/session/sessionModel';

/**
 * Determine fleets IDs from URL search, filter out those not belonging to the user.
 */
function getRequestedFleets(user: TUser): number[] {
  const fleets: TFleetShort[] = user ? user.userInfo.fleets : ([] as any);

  const fleetsIds: number[] = fleets.map(({ id }: TFleetShort): number => id);
  const fleetsQuery = queryString.parse(window.location.search).fleets;

  return (fleetsQuery || '')
    .split(',')
    .map((f: string): number | typeof NaN => parseInt(f))
    .filter(Boolean)
    .filter((id: number) => fleetsIds.includes(id))
    .sort();
}

const mapStateToProps = (state: TState) => {
  const {
    dictionaries,
    mapEntities: { mapEntriesMode, highlightedEntity, vesselCardsRefs },
    mapOptions: {
      center,
      colorDescriptor,
      layer,
      vesselNameDisplay,
      vesselPhotoDisplay,
      zoom
    },
    providers: { addressArray },
    session: { user },
    tracks: { tracks, trackPoints },
    vesselsFilter: { filterFleet },
    vesselsLocations: { locations }
  } = state;

  const mapOptions: TMapOptions = {
    center: center,
    colorDescriptor: colorDescriptor,
    currentMapLayer: layer,
    showCard: vesselPhotoDisplay !== ESettingsSelectOptions.TRACK_ONLY,
    showPicture: vesselPhotoDisplay === ESettingsSelectOptions.SHOW,
    showShipName: vesselNameDisplay === ESettingsSelectOptions.SHOW,
    trackMode: mapEntriesMode,
    zoom: zoom
  };

  return {
    currentFleets: filterFleet,
    dictionaries: dictionaries,
    mapOptions,
    providers: addressArray,
    requestedFleets: getRequestedFleets(user),
    selectedEntry: highlightedEntity,
    tracks,
    trackPoints,
    vesselCardsRefs,
    vessels: locations
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      clearEntities: purgeEntitiesAction,
      fetchProviders: fetchProvidersAction,
      goToProviderPage: showProviderPageAction,
      goToVesselPage: showVesselPageAction,
      onServiceCardClose: toggleServiceSelectAction,
      onTrackAdd: fetchTrackAction,
      onTrackRemove: removeTrackAction,
      removeAllTracks: purgeTracksAction,
      unwatchLocations: unwatchLocationsAction,
      updateMapOptions: updateMapOptionsAction,
      updateServiceCardRef: updateServiceCardRefAction,
      updateTrackPeriod: changeTrackPeriodAction,
      updateTrackPoints: updateTrackPointsAction,
      updateVesselCardsRefs: updateVesselCardsRefsAction
    },
    dispatch
  ),
  setFleetsFilter: (fleetsIds: number[]) =>
    dispatch(setVesselFilterAction(EVesselsFilterActions.SET_FLEET, fleetsIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapWrapper);
