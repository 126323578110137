import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import connect from 'react-redux/es/connect/connect';

import './VesselMapControl.scss';
import ForecastPlayer from './ForecastPlayer/ForecastPlayer';
import IconCustom from '../../../components/common/IconCustom/IconCustom';
import MapFilter from './MapFilter/MapFilter';
import MapLegend from './MapLegend/MapLegend';
import SwipeMenu, {
  SWIPE_BOTTOM
} from '../../../components/common/SwipeMenu/SwipeMenu';
import { ETrackPeriod } from '../../../types/trackPeriod';

const MenuIcon = () => <IconCustom type="route" />;

class VesselsMapControl extends React.PureComponent {
  state = {
    activeBtnRoute: false,
    activeBtnRuller: false
  };

  handleTrackPeriodChange = (event) =>
    this.props.onPeriodChange(event.target.value);

  handleBtnRoute = () =>
    this.setState({ activeBtnRoute: !this.state.activeBtnRoute });

  handleBtnRuller = () =>
    this.setState({ activeBtnRuller: !this.state.activeBtnRuller });

  render() {
    const {
      currentMapLayer,
      isLogged,
      legends,
      onColorChange,
      serviceCategories,
      trackPeriod
    } = this.props;

    const authorisedContentPeriod = (
      <>
        <div className="VesselsMapControl__selectors">
          <label>
            Track period:&nbsp;
            <select
              className="VesselsMapControl__select"
              value={trackPeriod}
              onChange={this.handleTrackPeriodChange}
            >
              <option value={ETrackPeriod.HOURS_6}>6 hours</option>
              <option value={ETrackPeriod.HOURS_24}>24 hours</option>
              <option value={ETrackPeriod.DAYS_3}>3 days</option>
              <option value={ETrackPeriod.DAYS_7}>7 days</option>
              <option value={ETrackPeriod.DAYS30}>30 days</option>
              <option value={ETrackPeriod.DAYS90}>90 days</option>
            </select>
          </label>
        </div>
      </>
    );

    return (
      <>
        <SwipeMenu side={SWIPE_BOTTOM} shift={70} menuIcon={MenuIcon}>
          <div className="VesselsMapControl">
            {isLogged && authorisedContentPeriod}
          </div>
        </SwipeMenu>

        <section className="VesselsMapControl__top-right">
          <MapFilter />
          <ForecastPlayer currentMapLayer={currentMapLayer} />
          <MapLegend
            isLogged={isLogged}
            legends={legends}
            onColorChange={onColorChange}
            serviceCategories={serviceCategories}
          />
        </section>

        {/* TODO: later */}
        <div className="VesselsMapControl__btnsHolder app-d-none">
          <Button
            type={this.state.activeBtnRoute ? 'primary' : ''}
            shape="round"
            onClick={this.handleBtnRoute}
          >
            <IconCustom type="route" title="Route" style={{ fontSize: 22 }} />
          </Button>

          <Button
            type={this.state.activeBtnRuller ? 'primary' : ''}
            onClick={this.handleBtnRuller}
            shape="circle"
            className="app-ant-btn-circle"
          >
            <IconCustom type="ruller" title="Ruller" style={{ fontSize: 22 }} />
          </Button>
        </div>
      </>
    );
  }
}

VesselsMapControl.propTypes = {
  isLogged: PropTypes.bool,
  currentMapLayer: PropTypes.string.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object),
  onColorChange: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  serviceCategories: PropTypes.array,
  trackPeriod: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const {
    serviceBranches: { serviceCategories },
    session: { user },
    tracks: { trackPeriod }
  } = state;
  return {
    isLogged: !!user,
    serviceCategories,
    trackPeriod
  };
};

export default connect(mapStateToProps)(VesselsMapControl);
