import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoaderOverlay } from 'react-fishfacts/dist';
import * as Sentry from '@sentry/browser';

import Main, { mainComponentPropTypes } from './components/Main/Main';
import { prefetchDataAction } from './store/dictionaries/dictionariesActions';
import { searchTypedAction } from './store/search/searchActions';
import { TState } from './store/appStateModel';

type Props = {
  clearSearch: () => void;
  isLoggedIn: boolean;
  prefetchData: () => void;
  isSessionPending: boolean;
};

class App extends React.Component<Props> {
  static propTypes;

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) =>
        scope.setExtra(key, errorInfo[key])
      );
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <LoaderOverlay className="App" isLoading={this.props.isSessionPending}>
        <Main {...(this.props as any)} />
      </LoaderOverlay>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSearch: searchTypedAction,
      prefetchData: prefetchDataAction
    },
    dispatch
  );

const mapStateToProps = ({ session }: TState) => {
  const { isPending, user } = session;

  return {
    isSessionPending: isPending,
    isLoggedIn: !!(user && user.token)
  };
};

App.propTypes = {
  ...mainComponentPropTypes,
  isSessionPending: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
