import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Select } from 'antd';

import './ServiceRegistration.scss';
import { countryProps, formItems1, formItems2 } from './helpers';

import { TCompanyRegistration } from '../../../types/companyRegistration';
import {
  commonFormProps,
  TLabelValue,
  typeSearchFilter
} from '../../../other/formAndValidation/formUtils';

type Props = {
  countries: TLabelValue[];
  onChange: () => void;
  onSubmit: (fields: TCompanyRegistration) => void;
};

const RegistrationForm = ({
  countries,
  onChange,
  onSubmit
}: Props): ReactElement => (
  <div className="ServicePlan">
    <h4 className="ServicePlan__title">Register Company</h4>

    <section className="ServicePlan__main">
      <figure className="ServicePlan__figure">
        <img src="/assets/logo/serviceLogo.png" alt="FishFacts" />
      </figure>

      <Form
        {...commonFormProps}
        className="ServicePlan__form"
        onFieldsChange={onChange}
        onFinish={onSubmit}
      >
        {formItems1}

        <Form.Item {...countryProps}>
          <Select
            filterOption={typeSearchFilter}
            options={countries}
            showSearch
          />
        </Form.Item>

        {formItems2}

        <div className="ServiceRegistration__form">
          <Button
            className="app-btn-green ServiceRegistration__submit"
            htmlType="submit"
          >
            Continue
          </Button>
        </div>
      </Form>
    </section>
  </div>
);

RegistrationForm.propTypes = {
  countries: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RegistrationForm;
