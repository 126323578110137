import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button, Tabs } from 'antd';

import '../VesselMapControl.scss';
import { getServiceLegends } from './helpers';
import IconCustom from '../../../../components/common/IconCustom/IconCustom';
import MapLegendHeader from '../MapLegendHeader';
import ShipLegends from './ShipLegends';

import { TMapLegend } from '../../types/common';
import { TMapOptionsState } from '../../../../store/map/mapOptions/mapOptionsModel';
import { TServiceCategory } from '../../../../types/service';

type Props = {
  isLogged: boolean;
  legends: TMapLegend[];
  onColorChange: (options: Partial<TMapOptionsState>) => void;
  serviceCategories: TServiceCategory[];
};
type State = {
  isVisible: boolean;
};

class MapLegend extends React.PureComponent<Props, State> {
  static propTypes;
  state: State = {
    isVisible: false
  };

  toggleDrawerVisibility = () =>
    this.setState({ isVisible: !this.state.isVisible });

  render() {
    const { isLogged, legends, onColorChange, serviceCategories } = this.props;
    const serviceLegends: ReactNode = getServiceLegends(serviceCategories);

    return (
      <div className="RightSideDrawer">
        <Button
          className="MapLegend__btn"
          onClick={this.toggleDrawerVisibility}
        >
          <IconCustom type="map-info" title="Legend" style={{ fontSize: 22 }} />
        </Button>

        <Drawer
          closable={false}
          className="MapLegend__drawer RightSideDrawer__main"
          placement="right"
          visible={this.state.isVisible}
          onClose={this.toggleDrawerVisibility}
        >
          <MapLegendHeader onClose={this.toggleDrawerVisibility} />

          <div className="RightSideDrawer__content MapLegend__content">
            <Tabs type="card">
              {isLogged && (
                <Tabs.TabPane tab="Vessels" key="1">
                  <ShipLegends
                    legends={legends}
                    onColorChange={onColorChange}
                  />
                </Tabs.TabPane>
              )}
              <Tabs.TabPane tab="Services" key="2">
                {serviceLegends}
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Drawer>
      </div>
    );
  }
}

MapLegend.propTypes = {
  isLogged: PropTypes.bool,
  legends: PropTypes.arrayOf(PropTypes.object),
  onColorChange: PropTypes.func.isRequired,
  serviceCategories: PropTypes.array
};

export default MapLegend;
