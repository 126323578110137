import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { message, Upload } from 'antd';

import './VideoInput2.scss';
import { getVideoMime } from '../../../helpers/helpers';
import IconCustom from '../../IconCustom/IconCustom';

const acceptedTypes = ['video/webm', 'video/ogg', 'video/mp4'];
const MAX_SIZE = {
  label: '200MB',
  value: 209715200
};
type Props = {
  children: ReactElement;
  className?: string;
  onUpload: (file: File) => void;
  placeholder?: string;
  videoUrl?: string;
};

const VideoInput2 = ({
  children,
  className,
  videoUrl,
  onUpload,
  placeholder
}: Props): ReactElement => {
  const cls: string = classnames('VideoInput2', className);
  const fileType: string = getVideoMime(videoUrl);

  const handleUpload = ({ file: { originFileObj } }) => {
    if (originFileObj.size > MAX_SIZE.value) {
      return message.error(`File size should be less than ${MAX_SIZE.label}!`);
    } else if (!acceptedTypes.includes(originFileObj.type)) {
      return message.error(
        'Unsupported video type! Please, use WEBM, OGG or MP4.'
      );
    } else {
      onUpload(originFileObj);
    }
  };

  const props: {} = {
    accept: 'video/*',
    customRequest: Function.prototype,
    fileList: null,
    multiple: false,
    name: 'file',
    onChange: handleUpload
  };

  return (
    <div className={cls}>
      <Upload.Dragger {...props}>
        {videoUrl && (
          <div className="VideoInput2__video-holder">
            <video className="VideoInput2__video">
              <source src={videoUrl} type={fileType} />
            </video>
          </div>
        )}

        <div className="VideoInput2__placeholder">
          <div className="VideoInput2__info">
            <div className="ant-upload-drag-icon">
              <IconCustom type="photo" style={{ fontSize: 22 }} />
            </div>
            <div className="ant-upload-text">{placeholder}</div>
          </div>

          {children}
        </div>
      </Upload.Dragger>
    </div>
  );
};

VideoInput2.defaultProps = {
  placeholder: 'Drop a video here or tap to add'
};
VideoInput2.propTypes = {
  className: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  videoUrl: PropTypes.string
};

export default React.memo(VideoInput2);
