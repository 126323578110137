import { basicInitialState } from '../../_utils/basicInitialState';
import { TEventEditorState } from './eventEditor';

export const eventEditorInitialState: TEventEditorState = {
  ...basicInitialState,
  id: null,
  banner: null,
  end: null,
  isVirtual: true,
  location: null,
  longDescription: null,
  longDescriptionMedia: null,
  published: null,
  schedule: null,
  shortDescription: null,
  source: null,
  start: null,
  thumbnail: null,
  title: null,
  fields: {
    // dates: {
    //   value: [] as any
    // }
  },
  uploadProgress: null,
  utcOffset: '+01:00'
};
