import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './ProviderInfo.scss';
import IconCustom from '../../../../components/common/IconCustom/IconCustom';
import ModalWrapper from '../../../../components/common/modals/ModalWrapper';
import ProviderInfoForm from './ProviderInfoForm';
import { TProviderEditorState } from '../../../../store/provider/providerEditor/providerEditor';

type Props = {
  onSubmit: (payload: Partial<TProviderEditorState>) => void;
  values: Partial<TProviderEditorState>;
};
type State = {
  isModalVisible: boolean;
};

class ProviderInfo extends React.PureComponent<Props, State> {
  static propTypes;
  state: State = { isModalVisible: false };

  handleCancel = () => this.setState({ isModalVisible: false });

  handleSubmit = (values: Partial<TProviderEditorState>) => {
    this.setState({ isModalVisible: false });
    this.props.onSubmit(values);
  };

  showModal = () => this.setState({ isModalVisible: true });

  render() {
    const { isModalVisible } = this.state;
    const { children, values } = this.props;
    const { longDescription, name, shortDescription, website } = values;

    return (
      <div className="app-d-flex app-flex-row ProviderInfo">
        {children}

        <section className="ProviderInfo__content">
          <div className="ProviderInfo__site">
            <a href={website}>{website}</a>
          </div>
          <h2 className="ProviderInfo__name">{name}</h2>
          <p className="ProviderInfo__short">{shortDescription}</p>
          <p className="ProviderInfo__long">{longDescription}</p>
        </section>

        <Button
          className="ProviderInfo__btnEdit"
          shape="round"
          onClick={this.showModal}
        >
          <IconCustom type="pencil-create" style={{ fontSize: 20 }} />
        </Button>

        <ModalWrapper
          isOpen={isModalVisible}
          onCancel={this.handleCancel}
          title="General info"
        >
          <ProviderInfoForm
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            values={values}
          />
        </ModalWrapper>
      </div>
    );
  }
}

ProviderInfo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    longDescription: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    website: PropTypes.string
  }).isRequired
};

export default ProviderInfo;
