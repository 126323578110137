import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form, Select, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment, { Moment } from 'moment';

import './PromotionForm.scss';
import CountriesSelector from './CountriesSelector';
import {
  datePickerProps,
  endRules,
  getInitials,
  PromotionFormProps,
  startRules,
  today
} from './helpers';
import { formFields2Dict } from '../../../../../other/formAndValidation/formUtils';
import { getServiceIds, getTypeIds } from '../helpers';
import ServiceCategoriesSelector from './ServiceCategoriesSelector';
import { timezones } from '../../../../events/EventEditor/forms/timezones';
import VesselTypesSelector from './VesselTypesSelector';

class PromotionForm extends React.PureComponent<PromotionFormProps> {
  static propTypes;
  private form: FormInstance;
  private serviceCategoriesIds: string[];
  private vesselTypesIds: string[];

  componentDidMount(): void {
    const {
      countries,
      onChange,
      serviceOptions,
      vesselTypesOptions
    } = this.props;

    this.serviceCategoriesIds = getServiceIds(serviceOptions);
    this.vesselTypesIds = getTypeIds(vesselTypesOptions);
    onChange(getInitials(countries, serviceOptions, vesselTypesOptions));
  }

  handleChange = (_, fields /*: TFormField[]*/) =>
    this.props.onChange(formFields2Dict(fields));

  handleRef = (form) => {
    if (form) {
      this.form = form;
    }
  };

  handleSubmit = () => this.form.validateFields().then(this.props.onSubmit);

  getDisabledStartRange = (date: Moment): boolean => {
    const endValue = this.form.getFieldValue('end');
    if (!endValue) return false;

    const dayBeforeEnd = endValue.clone().subtract(1, 'day');
    return (
      date.isBefore(today, 'day') ||
      date.isAfter(moment().add(1, 'year')) ||
      date.isAfter(dayBeforeEnd, 'day')
    );
  };

  getDisabledEndRange = (date: Moment): boolean => {
    const startValue = this.form.getFieldValue('start');
    if (!startValue) return false;

    const nextDayAfterStart = startValue.clone().add(1, 'day');
    return (
      date.isBefore(nextDayAfterStart, 'day') ||
      date.isAfter(nextDayAfterStart.add(1, 'year'))
    );
  };

  render() {
    const {
      children,
      countries,
      fields,
      serviceOptions,
      vesselTypesOptions
    } = this.props;

    if (!fields) return null;

    const fieldsArr = Object.values(fields) as any;

    return (
      <Form
        className="PromotionForm"
        fields={fieldsArr}
        onFieldsChange={this.handleChange}
        onFinish={this.handleSubmit}
        ref={this.handleRef}
      >
        <div className="PromotionForm__row">
          <div className="PromotionForm__col">
            <h3 className="PromotionForm__title">Audience</h3>

            <div className="BasicFilter__row">
              <label className="BasicFilter__label">Countries</label>
              <Form.Item name="countries">
                <CountriesSelector
                  className="BasicFilter__select"
                  options={countries}
                />
              </Form.Item>
            </div>

            <div className="BasicFilter__row">
              <label className="BasicFilter__label">Vessel Types</label>
              <Form.Item name="vesselTypes">
                <VesselTypesSelector
                  options={vesselTypesOptions}
                  optionsIds={this.vesselTypesIds}
                />
              </Form.Item>
            </div>

            <div className="BasicFilter__row">
              <label className="BasicFilter__label">Service Categories</label>
              <Form.Item name="serviceCategories">
                <ServiceCategoriesSelector
                  options={serviceOptions}
                  optionsIds={this.serviceCategoriesIds}
                />
              </Form.Item>
            </div>
          </div>

          <div className="PromotionForm__col">
            <h3 className="PromotionForm__title">Duration</h3>
            <Row gutter={24}>
              <Col md={24} lg={16}>
                <div className="BasicFilter__row">
                  <label className="BasicFilter__label">Start</label>
                  <Form.Item name="start" rules={startRules}>
                    <DatePicker
                      disabledDate={this.getDisabledStartRange}
                      {...datePickerProps}
                    />
                  </Form.Item>
                </div>

                <div className="BasicFilter__row">
                  <label className="BasicFilter__label">End</label>
                  <Form.Item name="end" rules={endRules}>
                    <DatePicker
                      disabledDate={this.getDisabledEndRange}
                      {...datePickerProps}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col md={24} lg={8}>
                <div className="BasicFilter__row">
                  <label className="BasicFilter__label">GMT shift</label>
                  <Form.Item name="utcOffset">
                    <Select options={timezones} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {children}
      </Form>
    );
  }
}

PromotionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serviceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  vesselTypesOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PromotionForm;
