import React from 'react';
import { Link } from 'react-router-dom';

import { TVesselExtras } from 'react-fishfacts/dist';

type TExtendedFieldData = string | number | boolean | TExtendedFields;

type TExtendedFields =
  | {
      [key: string]: TExtendedFieldData;
    }
  | TExtendedFieldData[];

const parseTabData = (tabData: TExtendedFields): TExtendedFields => {
  if (typeof tabData === 'object') {
    if (Object.keys(tabData).length) {
      const fields = Array.isArray(tabData)
        ? Object.values(filterFields(tabData)).sort()
        : filterFields(tabData);

      return Object.keys(fields).length && fields;
    }

    return;
  }

  return tabData;
};

const filterFields = (extendedFields: TExtendedFields): TExtendedFields => {
  return Object.keys(extendedFields).reduce((result, tab) => {
    const tabData = extendedFields[tab];

    if (tabData) {
      const parsedTabData = parseTabData(tabData);

      if (parsedTabData) {
        result[tab] = parsedTabData;
      }
    }

    return result;
  }, {});
};

export const filterExtendedFields = (
  extendedFields: TVesselExtras
): TVesselExtras => {
  return filterFields(extendedFields as TExtendedFields) as TVesselExtras;
};

type Item = { id: number; name: string; serviceProviderId: number };

export const getItemFieldByIdFinder = (items: Item[]) => (
  itemId: number
): string | JSX.Element => {
  const item = items.find(({ id }) => id === itemId);

  if (item) {
    return getServiceProviderLink(item.serviceProviderId, item.name);
  }

  return '';
};

export const getServiceProviderLink = (
  serviceProviderId: number,
  name: string
): string | JSX.Element =>
  serviceProviderId ? (
    <Link to={`/service/provider/${serviceProviderId}`}>{name}</Link>
  ) : (
    name
  );

export const transformToArray = (object) =>
  Array.isArray(object) ? object : [object];
