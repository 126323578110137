import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import './MapLegend.scss';
import ShipIcon from '../ShipIcon/ShipIcon';

import { EColorDescriptor } from '../../types/colorDescriptor';
import { TMapLegend } from '../../types/common';
import { TMapOptionsState } from '../../../../store/map/mapOptions/mapOptionsModel';

const style = {
  transform: 'rotate(180deg) scale(1.2)',
  height: '4px',
  width: '7px'
};
const partStyle = {
  height: '3px',
  width: '9px'
};

type Props = {
  legends: TMapLegend[];
  onColorChange: (options: Partial<TMapOptionsState>) => void;
};

const ShipLegends = ({ legends, onColorChange }: Props) => {
  const handleColorChange = (event: any): void =>
    onColorChange({
      colorDescriptor: event
        ? EColorDescriptor.COUNTRIES
        : EColorDescriptor.VESSEL_TYPES
    });

  return (
    <>
      <label className="app-d-flex">
        <span className="app-pr-4 app-pl-4">Color:</span>
        <Switch
          checkedChildren="Country"
          unCheckedChildren="Fisheries"
          defaultChecked
          onChange={handleColorChange}
          className="app-flex-fill"
        />
      </label>

      <div className="MapLegend__list">
        {legends.map(
          ({ colors, label }: TMapLegend): ReactNode => (
            <div key={label} className="MapLegend__legend MapLegend__vessel">
              <ShipIcon partStyle={partStyle} colors={colors} style={style} />
              <span className="MapLegend__label">{label}</span>
            </div>
          )
        )}
      </div>
    </>
  );
};

ShipLegends.propTypes = {
  legends: PropTypes.arrayOf(PropTypes.object),
  onColorChange: PropTypes.func.isRequired
};

export default React.memo(ShipLegends);
