import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ProductCard.scss';
import DraftOverlay from '../../../../components/common/overlays/DraftOverlay/DraftOverlay';
import { MEDIA_BASE_URL, ROUTES } from '../../../../other/config';
import { TProduct } from '../../../../types/product';

type Props = {
  product: TProduct;
};

const ProductCard = ({ product }: Props): ReactElement => {
  const { banner, id, published, source, thumbnail, title } = product;
  const _banner = thumbnail || banner;

  const uri = `${ROUTES.SERVICE_PROVIDER}/${source.id}/products/${id}`;
  const imgUrl: string = _banner
    ? `${MEDIA_BASE_URL}/${_banner.path}`
    : '/assets/placeholders/product_placeholder.svg';

  const img: ReactNode = (
    <figure className="ProductCard__photo">
      {imgUrl && <img src={imgUrl} alt={title} />}
      <DraftOverlay isShown={!published} />
    </figure>
  );

  return (
    <Link className="ProductCard" to={uri}>
      {img}
      <h5 className="ProductCard__name">{title}</h5>
    </Link>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    published: PropTypes.bool,
    source: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    thumbnail: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    title: PropTypes.string
  }).isRequired
};

export default ProductCard;
