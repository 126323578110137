import { push } from 'connected-react-router';
import { TAction, THttpResponse } from 'react-fishfacts/dist';

import { createAsyncActions } from '../../_utils/createAsyncActions';
import { ENDPOINTS, ROUTES, SUB_POINTS } from '../../../other/config';
import { eventsInitialState } from './eventsInitialState';
import { fetchLongMediaDescription } from '../../_utils/longMediaDescriptionUtils';
import http from '../../../services/http';
import { updateSessionAction } from '../../session/sessionActions';

import { EEventsActions } from './eventsConstants';
import {
  EScheduleItemStatus,
  TEvent,
  TScheduleItem
} from '../../../types/event';
import { TEventsState } from './eventsModel';
import { TState } from '../../appStateModel';

export let fetchEventsSet, fetchEventSet, removeSet, approveSet, bookSet;

/**
 * Fetches events list.
 */
export function fetchEventsAction() {
  return (dispatch, getState) => {
    const { events } = getState() as TState;
    if (events.events) return;

    fetchEventsSet = createAsyncActions<TEventsState>(
      dispatch,
      EEventsActions.FETCH_EVENTS
    ).request();

    http
      .send(ENDPOINTS.EVENT)
      .then(({ data }: THttpResponse<TEvent[]>) =>
        fetchEventsSet.success({
          events: data
        })
      )
      .catch(fetchEventsSet.error);
  };
}

/**
 * Retrieves event by ID.
 */
export function fetchEventAction(eventId: number) {
  return (dispatch, getState) => {
    if (!eventId) return;

    const url = `${ENDPOINTS.EVENT}/${eventId}`;
    fetchEventSet = createAsyncActions<TEventsState>(
      dispatch,
      EEventsActions.FETCH_EVENT
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TEvent>) => {
        fetchEventSet.success({ isPending: true });
        dispatch(fetchEventBodyAction(data));
      })
      .catch(fetchEventSet.error);
  };
}

/**
 * Helper action; fetches event body (longDescription part).
 */
export function fetchEventBodyAction(event: TEvent) {
  return (dispatch) => {
    const {
      longDescriptionMedia: { path }
    } = event;

    fetchLongMediaDescription(path).then((eventBody: string) => {
      const update = {
        ...event,
        longDescription: eventBody
      };
      dispatch({
        type: EEventsActions.EVENT_BODY_COMPOSED,
        payload: { event: update, isPending: false }
      });
    });
  };
}

/**
 * Resets the model to the initial state.
 */
export const resetEventsAction = (): TAction<TEventsState, EEventsActions> => ({
  type: EEventsActions.CLEAR_EVENTS,
  payload: eventsInitialState
});

/**
 * Clears article.
 */
export const clearEventAction = (): TAction<TEventsState, EEventsActions> => ({
  type: EEventsActions.CLEAR_EVENT,
  payload: { event: null }
});

export const saveScrollTop = (
  scrollTop: number
): TAction<TEventsState, EEventsActions> => ({
  type: EEventsActions.SAVE_SCROLL_TOP,
  payload: { scrollTop }
});

/**
 * Removes an event from both the store and server.
 * @param {number} eventId
 * @param {boolean} shouldStay -- whether we shall redirect to the events list or stay where we are.
 */
export function removeEventAction(eventId: number, shouldStay?: boolean) {
  return (dispatch, getState) => {
    const {
      events: { events }
    }: TState = getState();

    removeSet = createAsyncActions<TEventsState>(
      dispatch,
      EEventsActions.REMOVE_EVENT
    ).request();

    http
      .send({
        method: 'DELETE',
        url: `${ENDPOINTS.EVENT}/${eventId}`
      })
      .then(() => {
        const list: TEvent[] = events
          ? events.filter(({ id }: TEvent) => id !== eventId)
          : null;
        removeSet.success({
          event: null,
          events: list
        });

        // update session so as to sync user's events
        dispatch(updateSessionAction());

        if (!shouldStay) {
          dispatch(push(ROUTES.EVENTS));
          dispatch(resetEventsAction());
        }
      })
      .catch(removeSet.error);
  };
}

/**
 * The event owner approves\rejects a meeting request.
 * @param item
 * @param shouldReject
 */
export function approveRejectMeetingRequestAction(
  item: TScheduleItem,
  shouldReject?: boolean
) {
  return (dispatch, getState) => {
    const {
      events: { event }
    }: TState = getState();

    const url = `${ENDPOINTS.EVENT}/${event.id}/schedule/${item.id}`;
    const suffix: string = shouldReject
      ? SUB_POINTS.REJECT_BOOKING
      : SUB_POINTS.APPROVE_BOOKING;

    approveSet = createAsyncActions<TEventsState>(
      dispatch,
      EEventsActions.APPROVE_REJECT_MEETING
    ).request();

    http
      .send({
        method: 'POST',
        url: url + suffix
      })
      .then(() => {
        const updatedItem: TScheduleItem = {
          ...item,
          status: shouldReject
            ? EScheduleItemStatus.AVAILABLE
            : EScheduleItemStatus.BOOKED
        };
        const scheduleUpdate: TScheduleItem[] = event.schedule.map(
          (_item: TScheduleItem) => {
            if (item.id !== _item.id) return _item;
            return updatedItem;
          }
        );
        const _event: TEvent = {
          ...event,
          schedule: scheduleUpdate
        };
        approveSet.success({
          event: _event
        });
      })
      .catch(approveSet.error);
  };
}

/**
 * A user can book a meeting or later cancel that meeting.
 * @param item
 * @param shouldCancel
 */
export function bookCancelMeetingAction(
  item: TScheduleItem,
  shouldCancel?: boolean
) {
  return (dispatch, getState) => {
    const {
      events: { event }
    }: TState = getState();

    const url = `${ENDPOINTS.EVENT}/${event.id}/schedule/${item.id}`;
    const suffix: string = shouldCancel
      ? SUB_POINTS.CANCEL_MEETING
      : SUB_POINTS.BOOK_MEETING;

    bookSet = createAsyncActions<TEventsState>(
      dispatch,
      EEventsActions.BOOK_CANCEL_MEETING
    ).request();

    http
      .send({
        method: 'POST',
        url: url + suffix
      })
      .then(() => {
        const updatedItem: TScheduleItem = {
          ...item,
          status: shouldCancel
            ? EScheduleItemStatus.AVAILABLE
            : EScheduleItemStatus.PENDING
        };
        const scheduleUpdate: TScheduleItem[] = event.schedule.map(
          (_item: TScheduleItem) => {
            if (item.id !== _item.id) return _item;
            return updatedItem;
          }
        );
        const _event: TEvent = {
          ...event,
          schedule: scheduleUpdate
        };
        bookSet.success({
          event: _event
        });
      })
      .catch(bookSet.error);
  };
}
