import { areEqual, deepClone, TMinMax } from 'react-fishfacts/dist';

import { applyVesselsFilterAction } from '../vessels/vesselsActions';
import { isMap } from '../../../components/helpers/helpers';
import store, { history } from '../../store';
import { toggleVesselSelectAction } from '../../map/mapEntities/mapEntitiesActions';
import { VESSELS_FILTER, writeSettings } from '../../../services/settings';
import {
  vesselsFilterBlankState,
  vesselsFilterInitialState
} from './vesselsFilterInitialState';
import { watchLocationsAction } from '../../map/vesselsLocations/vesselsLocationsActions';

import { EVesselsFilterActions } from './vesselsFilterConstants';
import { TState } from '../../appStateModel';
import { TVesselsFilterState } from './vesselsFilterModel';

type TVal = TMinMax | string[] | number[];

/**
 * Writes filter settings and applies them.
 * @param settings
 * @private
 */
function _applyFilter(settings: TVesselsFilterState): void {
  store.dispatch(applyVesselsFilterAction());
  writeSettings({ [VESSELS_FILTER]: settings });
}

/**
 * Defines whether current settings are different from the initial ones disregarding `isFilterSet` field value.
 * @param settings
 */
export function isVesselsFilterSet(
  settings: TVesselsFilterState = vesselsFilterInitialState
): boolean {
  const sets = deepClone<TVesselsFilterState>(settings);
  const inits = deepClone<TVesselsFilterState>(vesselsFilterBlankState) || {};
  // @ts-ignore
  delete sets['isFilterSet'];
  // @ts-ignore
  delete inits['isFilterSet'];
  return !areEqual(sets, inits);
}

/**
 * Actions to set VesselFilter parameters.
 * @param actionType
 * @param value
 * @returns {{type: *, payload: ({filterFleet}|{filterCountry}|{filterLength}|{filterEnginePower}|{filterBruttoTons}|{filterEngineModel}|*)}}
 */
export function setVesselFilterAction(
  actionType: EVesselsFilterActions,
  value: TVal
) {
  return (dispatch, getState) => {
    const { vesselsFilter: settings } = getState() as TState;
    // It's called 'partial' because we still need to update 'isFilterSet'.
    const partialUpdate: TVesselsFilterState = {
      ...settings,
      [getPayloadKey(actionType)]: value
    };
    const update = {
      ...partialUpdate,
      isFilterSet: isVesselsFilterSet(partialUpdate)
    };

    dispatch({
      type: actionType,
      payload: update
    });

    if (isMap()) {
      dispatch(watchLocationsAction());
      dispatch(toggleVesselSelectAction(null));

      if (actionType === EVesselsFilterActions.SET_FLEET) {
        const search =
          (value as any).length > 0
            ? `?fleets=${(value as any).join(',')}`
            : '';
        setTimeout(() => history.replace({ search: search }));
      }
    }

    _applyFilter(update);
  };
}

/**
 * Resets the filter to the defaults.
 * @returns {Function}
 */
export function resetVesselFilterAction() {
  return (dispatch) => {
    history.push({ search: '' });

    dispatch({
      type: EVesselsFilterActions.RESET_FILTER,
      payload: vesselsFilterBlankState
    });
    _applyFilter(vesselsFilterBlankState);
  };
}

/**
 * Created for the purpose of updating `isFilterSet` flag.
 */
export function refreshFilterAction() {
  return (dispatch) =>
    dispatch({
      type: EVesselsFilterActions.REFRESH_FILTER,
      payload: {
        isFilterSet: isVesselsFilterSet()
      }
    });
}

/**
 * Returns payload key by given action type.
 * @param actionType
 */
function getPayloadKey(actionType: EVesselsFilterActions): string {
  switch (actionType) {
    case EVesselsFilterActions.SET_COUNTRY:
      return 'filterCountry';
    case EVesselsFilterActions.SET_FLEET:
      return 'filterFleet';
    case EVesselsFilterActions.SET_LENGTH:
      return 'filterLength';
    case EVesselsFilterActions.SET_ENGINE_POWER:
      return 'filterEnginePower';
    case EVesselsFilterActions.SET_TONNAGE:
      return 'filterBruttoTons';
    case EVesselsFilterActions.SET_ENGINE_MODEL:
      return 'filterEngineModel';
    case EVesselsFilterActions.SET_VESSEL_TYPE:
      return 'filterVesselType';
    case EVesselsFilterActions.SET_SPECIES:
      return 'filterSpecies';
    case EVesselsFilterActions.SET_YEAR:
      return 'filterBuildYear';
    default:
      throw new Error('Unknown VesselFilter action type: ' + actionType);
  }
}
