import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import './ServicePlan.scss';
import {
  fetchSubscriptionsAction,
  selectSubscriptionAction
} from '../../../store/companyRegistration/companyRegistrationActions';
import PlanList from './PlanList';
import { ROUTES } from '../../../other/config';

class ServicePlan extends React.Component {
  componentDidMount() {
    this.props.fetchSubscriptions();
  }

  render() {
    const { buyPlan, plans, selectedPlanId } = this.props;
    const isDisabled = selectedPlanId < 1;

    return (
      <div className="ServicePlan">
        <h4 className="ServicePlan__title">Register Company</h4>

        <section className="ServicePlan__main">
          <figure className="ServicePlan__figure">
            <img src="/assets/logo/serviceLogo.png" alt="FishFacts" />
          </figure>

          <p className="ServicePlan__text">
            FishFacts is a database with information about vessels, fishing
            rights, ownership and financial statements. FishFacts provides
            decision makers and service sectors, a complete overview of all
            important information and documentations about fisheries in Norway,
            Iceland, the Faroe Island, EU, Greenland and Russia.
          </p>

          {plans ? <PlanList {...this.props} /> : <h5>Loading...</h5>}
        </section>

        {plans && (
          <section className="ServicePlan__button--holder">
            <button
              className="button ServicePlan__button"
              disabled={isDisabled}
              onClick={buyPlan}
            >
              BUY
            </button>
          </section>
        )}
      </div>
    );
  }
}

ServicePlan.propTypes = {
  buyPlan: PropTypes.func.isRequired,
  fetchSubscriptions: PropTypes.func.isRequired,
  plans: PropTypes.array,
  selectedPlanId: PropTypes.number,
  selectPlan: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {
    companyRegistration: { selectedPlanId, subscriptions }
  } = state;

  return {
    plans: subscriptions,
    selectedPlanId
  };
};

const mapDispatchToProps = (dispatch) => ({
  buyPlan: () => dispatch(push(ROUTES.SERVICE_REGISTRATION)),
  fetchSubscriptions: () => dispatch(fetchSubscriptionsAction()),
  selectPlan: (...args) => dispatch(selectSubscriptionAction(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicePlan);
