const popupOptions = {
	autoClose: false,
	closeOnClick: false,
	closeOnEscapeKey: false
};


export class ServicePopup {
	
	constructor(options) {
		this.options = options;
		this._create();
	}
	
	_create() {
		const { map, onClose, service } = this.options;
		const content = this._getContent(service);
		const handleClose = () => {
			onClose();
			this.remove();
		};
		
		this.popup = window.L.popup({ ...popupOptions })
			.setLatLng(service.position)
			.setContent(content)
			.openOn(map);
		this.popup._closeButton.addEventListener('click', handleClose);
	}
	
	remove() {
		this.popup.remove();
	}
	
	_getContent() {
		const { address, city, country, name, postcode, title } = this.options.service;
		
		const popup = document.createElement('DIV');
		popup.classList.add('PointMark__info');
		popup.innerHTML = `
			<p>${title}</p>
			<p>${address}, ${city}, ${postcode}</p>
		`;
		
		const header = document.createElement('DIV');
		header.classList.add('PointMark');
		header.innerHTML = `
			<h3 class="ellipsis PointMark__name" title=${name}>
				<figure class="PointMark__flag">
					<img src="/assets/flags/${country.iso}.png" alt="Flag" />
				</figure>
				${name}
			</h3>
		`;
		
		popup.prepend(header);
		return popup;
	}
	
}
