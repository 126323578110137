import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';
import { LoadingOutlined } from '@ant-design/icons';

import './LoginForm.scss';
import { loginRules, passwordRules } from 'react-fishfacts/dist';

type Props = FormProps & {
  canStoreCredentials: boolean;
  displayError: string;
  isPending: boolean;
  onPasswordRestore?: () => void;
  onSubmit: (
    username: string,
    password: string,
    shouldKeepLogged: boolean
  ) => void;
};

export const LoginForm = (props: Props): ReactElement => {
  const {
    canStoreCredentials,
    displayError,
    isPending,
    onPasswordRestore,
    onSubmit
  } = props;
  const initial = { shouldKeepLogged: true };

  const handleSubmit = ({ username, password, shouldKeepLogged }) => {
    isPending || onSubmit(username, password, shouldKeepLogged);
  };

  const keepLogged: ReactNode = (
    <Form.Item name="shouldKeepLogged" valuePropName="checked">
      <Checkbox>Remember me</Checkbox>
    </Form.Item>
  );

  return (
    <Form className="LoginForm" onFinish={handleSubmit} initialValues={initial}>
      <h2 className="LoginForm__title">Log in</h2>
      {displayError && (
        <p className="app-error-msg LoginForm__error">{displayError}</p>
      )}

      <Form.Item name="username" rules={loginRules} validateTrigger="onBlur">
        <Input placeholder="Username" size="large" />
      </Form.Item>

      <Form.Item name="password" rules={passwordRules} validateTrigger="onBlur">
        <Input.Password placeholder="Password" size="large" />
      </Form.Item>

      <div className="LoginForm__row">
        {canStoreCredentials && keepLogged}
        {onPasswordRestore && (
          <Button
            className="app-btn-reset"
            onClick={onPasswordRestore}
            type="link"
          >
            Forgot password?
          </Button>
        )}
      </div>

      <Button
        className="LoginForm__submit"
        htmlType="submit"
        type="primary"
        size="large"
      >
        {isPending ? <LoadingOutlined /> : 'Submit'}
      </Button>
    </Form>
  );
};

LoginForm.propTypes = {
  canStoreCredentials: PropTypes.bool,
  displayError: PropTypes.string,
  isPending: PropTypes.bool.isRequired,
  onPasswordRestore: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};
