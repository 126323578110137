import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ArticleTitle.scss';
import { getDate } from '../../../../other/helpers';
import { ROUTES } from '../../../../other/config';
import { TArticleSource } from '../../../../types/article';

type Props = {
  source?: TArticleSource;
  time: string;
  title: string;
};

const ArticleTitle = ({ source, time, title }: Props) => {
  const date: string = getDate(time);
  const issuerPageUrl: string = source
    ? `${ROUTES.SERVICE_PROVIDER}/${source.id}`
    : null;

  const info: ReactNode = source && (
    <em className="ArticleTitle__created">
      Posted by{' '}
      <Link className="ArticleTitle__link" to={issuerPageUrl}>
        {source.name}
      </Link>
      , {date}
    </em>
  );

  return (
    <>
      <h1 className="ArticleTitle__title">{title}</h1>
      {info}
    </>
  );
};

ArticleTitle.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  time: PropTypes.string,
  title: PropTypes.string
};

export default ArticleTitle;
