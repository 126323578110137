import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ALL_VALUE,
  num2StrArr,
  removeAllOption,
  Select,
  strArr2Num,
  TLabelValue,
  TEngineShort
} from 'react-fishfacts/dist';

import { setVesselFilterAction } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterActions';
import { optionsSelector } from '../../helpers/optionsSelector';

import { TState } from '../../../../../../store/appStateModel';
import { EVesselsFilterActions } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterConstants';

const ALL: TLabelValue = {
  label: 'All engines',
  value: ALL_VALUE
};

type Props = {
  engines: TEngineShort[];
  enginesFilterValue: number[];
  selectModels: (options: number[]) => void;
};

const EnginesFilter = ({
  engines,
  enginesFilterValue,
  selectModels
}: Props) => {
  const options = [ALL, ...optionsSelector(engines)];
  const value = num2StrArr(enginesFilterValue);
  const onChange = (values) => {
    const arg = strArr2Num(removeAllOption(values)).filter(Boolean);
    selectModels(arg);
  };

  return <Select value={value} options={options} onChange={onChange} />;
};

const mapStateToProps = ({
  dictionaries: { engines },
  vesselsFilter: { filterEngineModel: enginesFilterValue }
}: TState) => ({
  engines,
  enginesFilterValue
});

const mapDispatchToProps = (dispatch) => ({
  selectModels: (value) =>
    dispatch(
      setVesselFilterAction(EVesselsFilterActions.SET_ENGINE_MODEL, value)
    )
});

EnginesFilter.propTypes = {
  engines: PropTypes.arrayOf(PropTypes.object).isRequired,
  enginesFilterValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectModels: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EnginesFilter);
