import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CloseButton } from 'react-fishfacts/dist';
import './EditorControls.scss';

type Props = {
  isDisabled?: boolean;
  onCancel: () => void;
  onDraft: () => void;
  onPublish?: () => void;
};

const EditorControls = ({
  isDisabled,
  onCancel,
  onDraft,
  onPublish
}: Props) => (
  <section className="EditorControls">
    <Button
      disabled={isDisabled}
      onClick={onDraft}
      size="large"
      className="app-btn-min-w-170"
    >
      Save as draft
    </Button>

    <Button
      disabled={isDisabled}
      htmlType="submit"
      onClick={onPublish}
      type="primary"
      size="large"
      className="app-btn-min-w-170"
    >
      Publish
    </Button>

    <CloseButton
      className="EditorControls__cancel"
      onClick={onCancel}
      title="Cancel"
    />
  </section>
);

EditorControls.propTypes = {
  isDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  onPublish: PropTypes.func
};

export default EditorControls;
