import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import {
  checkCompanyRegistrationAction,
  registerCompanyAction
} from '../../../store/companyRegistration/companyRegistrationActions';
import RegistrationForm from './RegistrationForm';

class ServiceRegistrationWrapper extends React.Component {
  state = { isTouched: false };

  componentDidMount() {
    this.props.checkStatus();
  }

  confirmation = () => `Discard changes and quit registration?`;

  handleChange = () =>
    this.state.isTouched || this.setState({ isTouched: true });

  handleRegister = (...args) => {
    this.setState({ isTouched: false });
    this.props.registerService(...args);
  };

  render() {
    const { countries } = this.props;

    return (
      <section className="ProviderEditor">
        <Prompt when={this.state.isTouched} message={this.confirmation} />
        <RegistrationForm
          countries={countries}
          onChange={this.handleChange}
          onSubmit={this.handleRegister}
        />
      </section>
    );
  }
}

ServiceRegistrationWrapper.propTypes = {
  checkStatus: PropTypes.func.isRequired,
  countries: PropTypes.array,
  registerService: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {
    dictionaries: { countries }
  } = state;
  return {
    countries: countries
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkCompanyRegistrationAction()),
  registerService: (...args) => dispatch(registerCompanyAction(...args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceRegistrationWrapper);
