import { ActionFactory, TAction, THttpResponse } from 'react-fishfacts/dist';
import { LatLng } from 'leaflet';

import { areEqual } from '../../../other/helpers';
import { ENDPOINTS } from '../../../other/config';
import http from '../../../services/http';
import { setEntriesModeAction } from '../mapEntities/mapEntitiesActions';
import store from '../../store';

import { ETrackPeriod } from '../../../types/trackPeriod';
import { ETracksActions } from './tracksConstants';
import { TState } from '../../appStateModel';
import { TTrackPoint } from '../../../pages/Map/types/common';
import { TTracksState } from './tracksModel';
import { TVesselTrack, TVesselTrackPoint } from '../../../types/vessel';

const af = new ActionFactory<TTracksState, ETracksActions>(store);
const trackSet = af.createAsyncActions(ETracksActions.FETCH_TRACK);

/**
 * Retrieves a track record for a specified vessel. On response swaps a stale record if any.
 */
export function fetchTrackAction(vesselId: number, isUpdate?: boolean) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    const url = `${ENDPOINTS.VESSELS}/${vesselId}/path?trackType=${tracks.trackPeriod}`;

    trackSet.request();
    http.send(url).then(handleTrack(vesselId, isUpdate)).catch(trackSet.error);
  };
}

/**
 * The handler for the action above.
 */
const handleTrack = (vesselId: number, isUpdate: boolean) => ({
  data
}: THttpResponse<TVesselTrackPoint[]>): void => {
  let update: TVesselTrack[];
  const {
    tracks: { tracks = [] }
  } = store.getState() as TState;

  if (isUpdate) {
    update = tracks.map((tr: TVesselTrack) => {
      if (tr.vesselId !== vesselId) return tr;
      return {
        data: data,
        vesselId: vesselId
      };
    });
  } else {
    update = [...tracks, { data, vesselId }];
  }

  trackSet.success({ tracks: update });
};

/**
 * Updates the track records with respect to given track period.
 */
export function changeTrackPeriodAction(trackPeriod: ETrackPeriod) {
  return (dispatch, getState) => {
    const {
      tracks: { tracks = [] }
    } = getState() as TState;

    dispatch({
      type: ETracksActions.CHANGE_TRACK_PERIOD,
      payload: { trackPeriod: trackPeriod }
    } as TAction<TTracksState, ETracksActions>);

    tracks.forEach(({ vesselId }: TVesselTrack) =>
      dispatch(fetchTrackAction(vesselId, true))
    );
  };
}

/**
 * Removes the track record of a given vessel.
 */
export function removeTrackAction(vesselId: number) {
  return (dispatch, getState) => {
    const {
      tracks: { tracks }
    } = getState() as TState;
    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.REMOVE_TRACK,
      payload: {
        tracks: tracks.filter((tr: TVesselTrack) => tr.vesselId !== vesselId)
      }
    };

    dispatch(action);
    dispatch(updateTrackPointsAction(null, vesselId));
    dispatch(setEntriesModeAction());
  };
}

/**
 * Removes all the tracks.
 */
export function purgeTracksAction() {
  return (dispatch) => {
    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.PURGE_TRACKS,
      payload: { tracks: [] }
    };
    dispatch(action);
    dispatch(setEntriesModeAction());
  };
}

/**
 * Updates track points.
 * @param trackPoint - object to be pushed to the array.
 * @param id - the ID of vessel points of which we want to delete.
 * @param _latlng - the LatLng of a single popup we want to close.
 */
export function updateTrackPointsAction(
  trackPoint: TTrackPoint,
  id?: number,
  _latlng?: LatLng
) {
  return (dispatch, getState) => {
    const { tracks } = getState() as TState;
    let points;
    // single popup is closed
    if (id && _latlng) {
      points = tracks.trackPoints.filter(
        ({ latlng, vesselId }: TTrackPoint) =>
          !(vesselId === id && areEqual(latlng, _latlng))
      );

      // multiple popups are closed, means the whole track is removed
    } else if (id) {
      points = tracks.trackPoints.filter(
        ({ vesselId }: TTrackPoint) => vesselId !== id
      );

      // track is clicked, will show a popup if there isn't.
    } else {
      if (
        JSON.stringify(tracks.trackPoints).includes(JSON.stringify(trackPoint))
      ) {
        return;
      }
      points = [...tracks.trackPoints, trackPoint];
    }

    const action: TAction<TTracksState, ETracksActions> = {
      type: ETracksActions.UPDATE_TRACK_POINTS,
      payload: { trackPoints: points }
    };
    dispatch(action);
  };
}
