import { getVesselPopup } from './getVesselPopup';


const defaultOptions = {
	mapOptions: {
		isExtended: false,
	},
  onClick: Function.prototype,
  onClose: Function.prototype
};
const POPUP_OFFSET = {
  default: [120, 0],
  NE: [120, 0],
  SE: [0, 120],
  SW: [-240, 0],
  NW:[0, -120]
};


export class VesselPopup {

  _popupOffset = 'default';

  constructor(options) {
    this.options = { ...defaultOptions, ...options };
    this._create();
  }

  _create() {
    const { map, markerOptions, onClick, onClose, popupOptions, vessel } = this.options;
    const { isExtended, isInFavourites, onFavClick, showPicture } = markerOptions;
    const content = getVesselPopup({
			isExtended,
			isInFavourites,
			onDescriptionClick: onClick,
			onFavClick,
			onTitleClick: this._movePopup,
			showPicture,
			vessel,
    });
    const { latitude, longitude, id } = vessel;

    this.popup = window.L.popup({
			autoPan: false,
			vesselId: id,
			...popupOptions,
		})
      .setLatLng([latitude, longitude])
      .setContent(content)
      .openOn(map);
    
    this.popup._closeButton.addEventListener('click',
			() => onClose(id, isExtended, new window.L.LatLng(latitude, longitude))
		);
  }

  /**
   * Moves popup around its position. Fired on the popup title click.
   * @private
   */
  _movePopup = (e) => {
    e.stopPropagation();
    this._popupOffset = this._getOffset();
		const [ox, oy] = POPUP_OFFSET[this._popupOffset];

    const { x, y } = this.options.map.latLngToContainerPoint(this.popup.getLatLng());
    this.popup.setLatLng(this.options.map.containerPointToLatLng({
      x: x + ox,
      y: y + oy
    }));
  };

  /**
   * Returns [x, y] offset depending on its current value.
   * @returns {*}
   * @private
   */
  _getOffset() {
    switch(this._popupOffset) {
			case 'NW':
        return 'default';
      case 'NE':
        return 'SE';
      case 'SE':
        return 'SW';
      case 'SW':
        return 'NW';
			default:
        return 'NE';
    }
  }
  
  remove() {
  	this.popup.remove();
	}

}
