import React from 'react';
import PropTypes from 'prop-types';

import {
  ECraneType,
  EFishPump,
  EWinchMethod,
  EWinchType,
  TDeckEquipment
} from 'react-fishfacts/dist';

type Props = {
  deckEquipment: TDeckEquipment;
  getManufacturerFieldById: (number) => string | JSX.Element;
};

const DeckEquipment = ({ deckEquipment, getManufacturerFieldById }: Props) => {
  const { winches, cranes, fishHandling, fishPump } = deckEquipment;

  return (
    <table className="ExtendedFields__table">
      <tbody>
        {winches && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Winches</th>
            </tr>
            {winches.map(
              ({ manufacturerId, type, method, quantity, tonnage }, index) => (
                <React.Fragment key={`${type}-${manufacturerId}`}>
                  {manufacturerId && (
                    <tr>
                      <th>Provider</th>
                      <td>{getManufacturerFieldById(manufacturerId)}</td>
                    </tr>
                  )}
                  {method && (
                    <tr>
                      <th>Method</th>
                      <td>{EWinchMethod[method]}</td>
                    </tr>
                  )}
                  {type && (
                    <tr>
                      <th>Type</th>
                      <td>{EWinchType[type]}</td>
                    </tr>
                  )}
                  {quantity && (
                    <tr>
                      <th>Quantity</th>
                      <td>{quantity}</td>
                    </tr>
                  )}
                  {tonnage && (
                    <tr>
                      <th>Tonnage</th>
                      <td>{tonnage}</td>
                    </tr>
                  )}
                  {index + 1 < winches.length && (
                    <tr>
                      <td colSpan={2} height={25} />
                    </tr>
                  )}
                </React.Fragment>
              )
            )}
          </>
        )}

        {cranes && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Cranes</th>
            </tr>
            {cranes.map(
              ({ manufacturerId, type, quantity, power, details }, index) => (
                <React.Fragment key={`${type}-${manufacturerId}`}>
                  {manufacturerId && (
                    <tr>
                      <th>Provider</th>
                      <td>{getManufacturerFieldById(manufacturerId)}</td>
                    </tr>
                  )}
                  {type && (
                    <tr>
                      <th>Type</th>
                      <td>{ECraneType[type]}</td>
                    </tr>
                  )}
                  {power && (
                    <tr>
                      <th>Power</th>
                      <td>{power} t/m</td>
                    </tr>
                  )}
                  {quantity && (
                    <tr>
                      <th>Quantity</th>
                      <td>{quantity}</td>
                    </tr>
                  )}
                  {details && (
                    <tr>
                      <th>Details</th>
                      <td>{details}</td>
                    </tr>
                  )}
                  {index + 1 < cranes.length && (
                    <tr>
                      <td colSpan={2} height={25} />
                    </tr>
                  )}
                </React.Fragment>
              )
            )}
          </>
        )}

        {fishPump && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Fish pump</th>
            </tr>
            {fishPump.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(fishPump.manufacturerId)}</td>
              </tr>
            )}
            {fishPump.type && (
              <tr>
                <th>Type</th>
                <td>{EFishPump[fishPump.type]}</td>
              </tr>
            )}
          </>
        )}

        {fishHandling && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Fish handling</th>
            </tr>
            {fishHandling.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(fishHandling.manufacturerId)}</td>
              </tr>
            )}
            {fishHandling.details && (
              <tr>
                <th>Details</th>
                <td>{fishHandling.details}</td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

DeckEquipment.propTypes = {
  deckEquipment: PropTypes.shape({
    winhes: PropTypes.array,
    cranes: PropTypes.array,
    fishPump: PropTypes.object,
    fishHandling: PropTypes.object
  }).isRequired,
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default DeckEquipment;
