import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TVessel } from 'react-fishfacts/dist';

import './VesselInfo.scss';
import { countryMap } from '../../../../other/countryMap';
import { LOCALE } from '../../../../other/config';

type Props = {
  vessel: TVessel;
};

const VesselInfo = ({ vessel }: Props) => {
  const {
    buildYear,
    callSign,
    flag,
    vesselType,
    harbourNumber,
    imo,
    mmsi,
    registrationDate,
    registrationNumber,
    port,
    built: { vesselYard }
  } = vessel;

  const fishery: string = vesselType ? vesselType.value[LOCALE] : null;
  const divider: string = fishery && buildYear ? ' / ' : null;
  const country = countryMap[flag];

  const getProviderLink = () => {
    if (!vesselYard) return null;
    if (vesselYard.serviceProviderId) {
      return (
        <Link to={`/service/provider/${vesselYard.serviceProviderId}`}>
          {vesselYard.name}
        </Link>
      );
    }
    return vesselYard.name;
  };

  return (
    <div className="VesselInfo">
      <div className="VesselInfo__type">
        {fishery}
        {divider}
        {!!buildYear && buildYear}
        <br />
        {getProviderLink()}
      </div>

      <table className="VesselInfo__table">
        <tbody>
          {country && (
            <tr>
              <th>Country</th>
              <td>{country}</td>
            </tr>
          )}
          {registrationDate && (
            <tr>
              <th>Registration Date</th>
              <td>{registrationDate}</td>
            </tr>
          )}
          {registrationNumber && (
            <tr>
              <th>Vessel Reg. No.</th>
              <td>{registrationNumber}</td>
            </tr>
          )}
          {harbourNumber && (
            <tr>
              <th>Harbour No.</th>
              <td>{harbourNumber}</td>
            </tr>
          )}
          {mmsi && (
            <tr>
              <th>MMSI No.</th>
              <td>{mmsi}</td>
            </tr>
          )}
          {imo && (
            <tr>
              <th>IMO</th>
              <td>{imo}</td>
            </tr>
          )}
          {callSign && (
            <tr>
              <th>Call Sign</th>
              <td>{callSign}</td>
            </tr>
          )}
          {port && (
            <tr>
              <th>Port</th>
              <td>{port}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

VesselInfo.propTypes = {
  vessel: PropTypes.shape({
    photo: PropTypes.object,
    flag: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default VesselInfo;
