import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './NewsCard.scss';
import { ARTICLE_IMAGE_URL, ROUTES } from 'other/config';
import CommonBanner from 'components/common/banners/CommonBanner';
import PostInfo from '../PostInfo/PostInfo';
import PromoButton from 'components/common/buttons/PromoButton/PromoButton';

import { EPromoStatus } from 'types/promotion';
import { TArticle } from 'types/article';

type Props = {
  article: Readonly<TArticle>;
  horizontal?: boolean;
  status?: EPromoStatus;
};

const NewsCard = ({ article, horizontal, status }: Props): ReactElement => {
  const {
    banner,
    id,
    published,
    shortDescription,
    source,
    time,
    title
  } = article;

  const bannerUrl = banner ? `${ARTICLE_IMAGE_URL}/${banner.path}` : null;
  const isHorizontal = horizontal !== undefined ? horizontal : false;
  const path = `${ROUTES.NEWS}/${id}`;
  const _source = isHorizontal ? null : source;

  const mainCls: string = classnames('NewsCard', {
    'NewsCard--horizontal': isHorizontal
  });

  const titleCls: string = classnames('NewsCard__title', {
    'NewsCard__title--draft': !published
  });

  const descCls: string = classnames('NewsCard__description', {
    'NewsCard__description--draft': !published
  });

  return (
    <article className={mainCls}>
      <CommonBanner
        className="NewsCard__banner"
        imgUrl={bannerUrl}
        isDraft={!published}
        path={path}
      />

      <div className="NewsCard__holder">
        <div className="NewsCard__header">
          {title && (
            <Link to={path} className="NewsCard__text">
              <h5 className={titleCls}>{title}</h5>
            </Link>
          )}

          <PostInfo date={time} source={_source} />
        </div>

        {shortDescription && (
          <Link to={path} className="NewsCard__text">
            <p className={descCls}>{shortDescription}</p>
          </Link>
        )}

        {status && (
          <div className="NewsCard__promo">
            <PromoButton articleId={id} size="small" status={status} />
          </div>
        )}
      </div>
    </article>
  );
};

NewsCard.propTypes = {
  article: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    published: PropTypes.bool.isRequired,
    shortDescription: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  horizontal: PropTypes.bool,
  status: PropTypes.string
};

export default React.memo(NewsCard);
