import React from 'react';

import './ProviderEditor.scss';
import AddressEditor from './AddressEditor/AddressEditor';
import CategorySelector from './CategorySelector/CategorySelector';
import ContactEditor from './ContactEditor/ContactEditor';
import EventButton from '../Service/EventButton/EventButton';
import EventsList from './EventsList/EventsList';
import ProductsList from './ProductsEditor/ProductsList';
import ProviderEditorBanner from './ProviderEditorBanner/ProviderEditorBanner';
import { providerEditorProps, TProviderEditorProps } from './other/helpers';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import VideosEditor from './VideosEditor/VideosEditor';

import { EProviderMediaObjectType, TProviderAddress } from '../../../types/providers';
import { TPerson } from '../../../types/person';
import { TProduct } from '../../../types/product';
import { TVideo } from '../../../types/media';


class ProviderEditor extends React.PureComponent<TProviderEditorProps> {
  static propTypes;

  handleBannerUpload = (file: File) => this.props.onUpload(file, EProviderMediaObjectType.BANNER);
  handleContactUpload = (file: File, objectId: number) => this.props.onUpload(file, EProviderMediaObjectType.CONTACT, objectId);
  handleLogoUpload = (file: File) => this.props.onUpload(file, EProviderMediaObjectType.LOGO);
  handlePosterUpload = (file: File, videoId: number) => this.props.onUpload(file, EProviderMediaObjectType.POSTER, videoId);
  handleVideoUpload = (file: File, videoId: number) => this.props.onUpload(file, EProviderMediaObjectType.VIDEO, videoId);

  addAddress = () => this.props.onAddMediaObject(EProviderMediaObjectType.ADDRESS);
  addContact = () => this.props.onAddMediaObject(EProviderMediaObjectType.CONTACT);
  addVideo = () => this.props.onAddMediaObject(EProviderMediaObjectType.VIDEO);

  handleAddress = (obj?: TProviderAddress) => this.props.onObjectHandle(EProviderMediaObjectType.ADDRESS, obj);
  handleContact = (obj?: TPerson) => this.props.onObjectHandle(EProviderMediaObjectType.CONTACT, obj);
  handleProduct = (obj?: TProduct) => this.props.onProductEdit(this.props.editorState.id, obj ? obj.id : null);
  handleVideo = (obj?: TVideo) => this.props.onObjectHandle(EProviderMediaObjectType.VIDEO, obj);

  removeAddress = (objectId: number) => this.props.onRemove(EProviderMediaObjectType.ADDRESS, objectId);
  removeContact = (objectId: number) => this.props.onRemove(EProviderMediaObjectType.CONTACT, objectId);
  removeProduct = (objectId: number) => this.props.onRemove(EProviderMediaObjectType.PRODUCT, objectId);
  removeVideo = (objectId: number) => this.props.onRemove(EProviderMediaObjectType.VIDEO, objectId);

  updateAddresses = (location: TProviderAddress) => this.props.onObjectChange(EProviderMediaObjectType.ADDRESS, location);
  updateBranches = (branchId: number) => this.props.onObjectChange(EProviderMediaObjectType.BRANCH, branchId);
  updateContacts = (contact: TPerson) => this.props.onObjectChange(EProviderMediaObjectType.CONTACT, contact);
  updateVideo = (video: TVideo) => this.props.onObjectChange(EProviderMediaObjectType.VIDEO, video);

  render() {
    const {
      categories, countries,
      editorState, events,
      onEventEdit, onEventRemove,
      onFieldChange, onHandledObjectUpdate
    } = this.props;
    const {
      addresses, banner,
      branches, contacts,
      handledObject, handledObjectType,
      logo, longDescription,
      name, products, published,
      settings, shortDescription,
      uploadProgress, videos, website
    } = editorState;

    const { allowedBranches, allowedCountries } = settings || {};
    const infoValues = { longDescription, name, shortDescription, website };

    const bannerUploadProgress = handledObjectType === EProviderMediaObjectType.BANNER ? uploadProgress : null;
    const contactUploadProgress = handledObjectType === EProviderMediaObjectType.CONTACT ? uploadProgress : null;
    const logoUploadProgress = handledObjectType === EProviderMediaObjectType.LOGO ? uploadProgress : null;
    const posterUploadProgress = handledObjectType === EProviderMediaObjectType.POSTER ? uploadProgress : null;
    const videoUploadProgress = handledObjectType === EProviderMediaObjectType.VIDEO ? uploadProgress : null;

    const handledAddress: any = handledObjectType === EProviderMediaObjectType.ADDRESS ? handledObject : null;
    const handledContact: any = handledObjectType === EProviderMediaObjectType.CONTACT ? handledObject : null;
    const handledVideo: any = handledObjectType === EProviderMediaObjectType.VIDEO ? handledObject : null;

    return (
      <div className="ProviderEditor">
        <ProviderEditorBanner
          banner={banner}
          bannerUploadProgress={bannerUploadProgress}
          isPublished={published}
          logo={logo}
          logoUploadProgress={logoUploadProgress}
          onBannerUpload={this.handleBannerUpload}
          onLogoUpload={this.handleLogoUpload}
          onTogglePublish={onFieldChange}
        />

        <ProviderInfo onSubmit={onFieldChange} values={infoValues}>
          <EventButton className="ProviderEditor__eventButton" isOwner={true} />
        </ProviderInfo>

        <CategorySelector
          branchesLimit={allowedBranches}
          categories={categories}
          currentBranches={branches}
          onChange={this.updateBranches}
        />

        <AddressEditor
          addresses={addresses}
          countries={countries}
          countriesLimit={allowedCountries}
          onAdd={this.addAddress}
          onEdit={this.handleAddress}
          onHandledObjectUpdate={onHandledObjectUpdate}
          onRemove={this.removeAddress}
          onSubmit={this.updateAddresses}
          selectedItem={handledAddress}
        />

        <ContactEditor
          contacts={contacts}
          onAdd={this.addContact}
          onEdit={this.handleContact}
          onHandledObjectUpdate={onHandledObjectUpdate}
          onRemove={this.removeContact}
          onSubmit={this.updateContacts}
          onUpload={this.handleContactUpload}
          selectedItem={handledContact}
          uploadProgress={contactUploadProgress}
        />

        <ProductsList
          products={products}
          onAdd={this.handleProduct}
          onEdit={this.handleProduct}
          onRemove={this.removeProduct}
        />

        <VideosEditor
          onAdd={this.addVideo}
          onEdit={this.handleVideo}
          onHandledObjectUpdate={onHandledObjectUpdate}
          onRemove={this.removeVideo}
          onSubmit={this.updateVideo}
          onPosterUpload={this.handlePosterUpload}
          onVideoUpload={this.handleVideoUpload}
          posterUploadProgress={posterUploadProgress}
          selectedItem={handledVideo}
          videoUploadProgress={videoUploadProgress}
          videos={videos}
        />

        <EventsList events={events} onEdit={onEventEdit} onRemove={onEventRemove} />
      </div>
    );
  }

}


ProviderEditor.propTypes = providerEditorProps;
export default ProviderEditor;
