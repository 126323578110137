import { Rule } from 'antd/es/form';

export type TItemProps = {
  label?: string;
  name: string;
  placeholder?: string;
  rows?: number;
  rules?: Rule[];
  validateTrigger?: string; // actually 'onBlur' | 'onChange';
};

export function deepClone<T>(data: T): T {
  if (!data) return data;
  return JSON.parse(JSON.stringify(data));
}

export function getDate(dateString) {
  return dateString ? dateString.substring(0, 10).replace(/-/g, '/') : '';
}

/**
 * Converts minutes to HH:mm, preserves sign.
 * @param mins
 * @returns {string}
 */
export function minutesToHHmm(mins) {
  function pad(num) {
    return ('0' + num).slice(-2);
  }

  const sign = Math.sign(mins) > 0 ? '+' : '-';
  const _mins = Math.abs(mins);
  const h = Math.floor(_mins / 60);
  const m = _mins % 60;
  return `${sign}${pad(h)}:${pad(m)}`;
}

export function isFalsy(value) {
  return (
    value === false || value === void 0 || value === null || Number.isNaN(value)
  );
}

export function isDictionary(x) {
  if (!x) return false;
  return typeof x === 'object' && !x.filter;
}

export function areEqual(x, y) {
  return (x && JSON.stringify(x)) === (y && JSON.stringify(y));
}

export function isProdHost(): boolean {
  return (
    window.location.hostname.startsWith('www') ||
    window.location.hostname.startsWith('app')
  );
}

export function log(x: any): void {
  window.console.log(JSON.stringify(x, null, 2));
}
