import { TMedia } from './media';

export type TEvent = {
  id: number;
  banner: TMedia;
  end: string;
  isVirtual: boolean;
  location?: string;
  longDescription?: string;
  longDescriptionMedia?: TMedia;
  published: boolean;
  schedule: TScheduleItem[];
  shortDescription: string;
  source: TArticleSource;
  start: string;
  thumbnail: TMedia;
  title: string;
  utcOffset: string;
};

export type TEventScheduleGroup = {
  date: string;
  id: number;
  items: TScheduleItem[];
};

export type TScheduleItem = {
  id: number;
  appointee?: TAppointee;
  end: string;
  notes?: string;
  start: string;
  status: EScheduleItemStatus;
  title: string;
};

export type TAppointee = {
  companyId: number;
  companyName: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  // photo: TMedia;
  source: TArticleSource;
};

export enum EScheduleItemStatus {
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED',
  PENDING = 'PENDING',
  SELF_BOOKED = 'SELF_BOOKED'
}

export enum TArticleOwner {
  SERVICE_PROVIDER = 'SERVICE_PROVIDER'
}

export type TArticleSource = {
  id: number;
  type: TArticleOwner.SERVICE_PROVIDER;
  name: string;
  logo: TMedia;
};
