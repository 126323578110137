export enum EVesselsFilterActions {
  RESET_FILTER = 'vesselsFilter/RESET_FILTER',
  REFRESH_FILTER = 'vesselsFilter/REFRESH_FILTER',
  SET_TONNAGE = 'vesselsFilter/SET_TONNAGE',
  SET_COUNTRY = 'vesselsFilter/SET_COUNTRY',
  SET_FLEET = 'vesselsFilter/SET_FLEET',
  SET_ENGINE_MODEL = 'vesselsFilter/SET_ENGINE_MODEL',
  SET_ENGINE_POWER = 'vesselsFilter/SET_ENGINE_POWER',
  SET_VESSEL_TYPE = 'vesselsFilter/SET_VESSEL_TYPE',
  SET_LENGTH = 'vesselsFilter/SET_LENGTH',
  SET_SPECIES = 'vesselsFilter/SET_SPECIES',
  SET_YEAR = 'vesselsFilter/SET_YEAR'
}
