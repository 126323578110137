import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './PromotedArticle.scss';
import LatestArticle from './LatestArticle';
import { TPromoted } from '../../../../types/promotion';

type Props = {
  article?: Readonly<TPromoted>;
};

const PromotedArticle = ({ article }: Props) => {
  if (!article) return null;

  const { news, start } = article;

  const isActive: boolean = moment(start).isBefore(moment());

  const label = (
    <div className="PromotedArticle__label">
      Promoted
      {isActive || (
        <div className="PromotedArticle__text">
          <sup>*</sup> Only visible to you now
        </div>
      )}
    </div>
  );

  return (
    <article className="PromotedArticle">
      <LatestArticle article={news} />
      {label}
    </article>
  );
};

PromotedArticle.propTypes = {
  article: PropTypes.shape({
    news: PropTypes.object.isRequired,
    start: PropTypes.string.isRequired
  })
};

export default React.memo(PromotedArticle);
