import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';

import '../ServiceBranch/ServiceBranch.scss';
import { getServiceCategoryAction } from '../../../store/serviceBranches/serviceBranchesActions';
import GoBackButton from '../../../components/common/buttons/GoBackButton/GoBackButton';
import { LOCALE } from '../../../other/config';
import ProviderCountryFilter from '../../../components/ProviderCountryFilter/ProviderCountryFilter';
import ServiceCard from './ServiceCard';

import { TProvider } from '../../../types/providers';
import { TState } from '../../../store/appStateModel';
import LoaderOverlay from '../../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import EmptyList from '../../../components/common/EmptyList/EmptyList';

type Props = {
  categoryId: number;
  categoryName: string;
  getCategory: (categoryId: number) => void;
  isLoading: boolean;
  providers: TProvider[];
};

class ServiceCategory extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { categoryId, getCategory } = this.props;
    getCategory(categoryId);
  }

  render() {
    const { categoryName, providers, isLoading } = this.props;

    const categories = (
      <div className="Services__list">
        {providers.map((p) => (
          <ServiceCard key={p.id} provider={p} />
        ))}
      </div>
    );

    return (
      <LoaderOverlay isLoading={isLoading}>
        <section className="Services">
          <GoBackButton
            type="text"
            size="small"
            className="Services__GoBackButton"
          />
          <header className="Main__header Services__header">
            <h4 className="Main__headerTitle">{categoryName}</h4>
            <ProviderCountryFilter />
          </header>

          {providers.length ? (
            categories
          ) : (
            <EmptyList
              placeholder="The list is empty"
              placeholderImgUrl="/assets/placeholders/empty_serviceprovider.svg"
            />
          )}
        </section>
      </LoaderOverlay>
    );
  }
}

ServiceCategory.propTypes = {
  categoryId: PropTypes.number,
  categoryName: PropTypes.string.isRequired,
  getCategory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  providers: PropTypes.array
};

const mapStateToProps = (
  { serviceBranches: { categoryFiltered, isPending } }: TState,
  ownProps: { match: RouteProps }
) => {
  const { providers, value } = categoryFiltered || {};

  return {
    isLoading: isPending || !categoryFiltered,
    categoryId: parseInt(ownProps.match.params.id),
    categoryName: value ? value[LOCALE] : 'Loading...',
    providers: providers || []
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCategory: getServiceCategoryAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCategory);
