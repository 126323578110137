import {
  createAsyncActions,
  TAction,
  THttpResponse
} from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import { ENDPOINTS, ROUTES } from '../../other/config';
import http from '../../services/http';
import { updateSessionAction } from '../session/sessionActions';

import { ECompanyRegistrationActions } from './companyRegistrationConstants';
import {
  TCompanyRegistration,
  TCompanySubscription
} from '../../types/companyRegistration';
import { TCompanyRegistrationState } from './companyRegistrationModel';
import { TState } from '../appStateModel';

export let regSet;
export let subSet;

/**
 * Checking user's providers before showing the registration page.
 */
export function checkCompanyRegistrationAction() {
  return (dispatch, getState) => {
    const {
      session: { user }
    } = getState() as TState;

    const idArr: ReadonlyArray<number> =
      user && user.userInfo.serviceProvidersId;
    const hasProviders = !!(idArr && idArr.length);

    // If user already has a registered provider -> go editing it. Otherwise go to registration page.
    const path = hasProviders
      ? `${ROUTES.SERVICE_EDITOR}/${idArr[0]}`
      : ROUTES.SERVICE_REGISTRATION;

    if (
      !hasProviders &&
      window.location.pathname === ROUTES.SERVICE_REGISTRATION
    ) {
      return;
    }

    dispatch(push(path));
  };
}

export function registerCompanyAction(data: TCompanyRegistration) {
  return (dispatch) => {
    regSet = createAsyncActions<TCompanyRegistrationState>(
      dispatch,
      ECompanyRegistrationActions.REGISTER_COMPANY
    ).request();

    http
      .send({
        body: data,
        method: 'POST',
        url: ENDPOINTS.USER_COMPANY
      })
      .then(({ data }) => {
        regSet.success({ registrationData: data });
        dispatch(updateSessionAction());
        dispatch(push(ROUTES.SERVICE_EDITOR));
      })
      .catch(regSet.error);
  };
}

export function fetchSubscriptionsAction() {
  return (dispatch) => {
    subSet = createAsyncActions<TCompanyRegistrationState>(
      dispatch,
      ECompanyRegistrationActions.FETCH_SUBSCRIPTIONS
    ).request();

    http
      .send(ENDPOINTS.SERVICE_SUBSCRIPTIONS)
      .then(({ data }: THttpResponse<TCompanySubscription[]>) =>
        subSet.success({
          subscriptions: data
        })
      )
      .catch(subSet.error);
  };
}

export function selectSubscriptionAction(
  id: number
): TAction<TCompanyRegistrationState, ECompanyRegistrationActions> {
  return {
    type: ECompanyRegistrationActions.SELECT_SUBSCRIPTION,
    payload: { selectedPlanId: id }
  };
}
