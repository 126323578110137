import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading } from '../../Loading/Loading';
import NewsCard from '../../../../pages/news/NewsList/articles/NewsCard';
import {
  fetchRecentNewsAction,
  newsRecentClear
} from '../../../../store/news/newsRecent/newsRecentActions';

import './RecentArticles.scss';
import { TArticle } from '../../../../types/article';
import { TState } from '../../../../store/appStateModel';

type Props = {
  articleId?: number | null;
  clearNews: () => void;
  getNews: (
    providerId: number,
    publishedOnly?: boolean,
    fetchMore?: boolean
  ) => void;
  news: TArticle[];
  name: string;
  providerId: number;
  publishedOnly: boolean;
  total: number;
};

class RecentArticles extends React.PureComponent<Props> {
  static defaultProps;
  static propTypes;

  componentDidMount() {
    const { getNews, providerId, publishedOnly } = this.props;

    getNews(providerId, publishedOnly);
  }

  componentWillUnmount() {
    const { clearNews } = this.props;

    clearNews();
  }

  fetchMoreData = () => {
    const { getNews, providerId, publishedOnly } = this.props;

    getNews(providerId, publishedOnly, true);
  };

  render() {
    const { total, news: fetchedNews, name, articleId } = this.props;
    if (!fetchedNews.length) return null;

    // the current article will be among articles. We need to filter it out.
    const news = articleId
      ? fetchedNews.filter(({ id }: TArticle) => id !== articleId)
      : fetchedNews;
    const hasMore = fetchedNews.length < total;
    const loader = <Loading className="RecentArticles__loader" />;

    return (
      <div className="RecentArticles">
        <h2 className="RecentArticles__title">More news from {name}</h2>

        <InfiniteScroll
          dataLength={news.length}
          next={this.fetchMoreData}
          hasMore={hasMore}
          scrollableTarget="Main__container"
          loader={loader}
        >
          {news.map((article) => (
            <NewsCard key={article.id} article={article} horizontal />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = ({
  newsRecent: { news, page, size, total },
  provider: { provider },
  session: { user }
}: TState) => {
  const providerIds = user ? user.userInfo.serviceProvidersId : [];
  const isOwner = provider && providerIds.includes(provider.id);

  return {
    news,
    page,
    size,
    total,
    publishedOnly: !isOwner
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNews: (providerId: number, publishedOnly: boolean, fetchMore: boolean) =>
    dispatch(fetchRecentNewsAction(providerId, publishedOnly, fetchMore)),
  clearNews: () => dispatch(newsRecentClear)
});

RecentArticles.defaultProps = {
  articleId: null
};

RecentArticles.propTypes = {
  name: PropTypes.string,
  news: PropTypes.arrayOf(PropTypes.object),
  publishedOnly: PropTypes.bool,
  total: PropTypes.number,
  articleId: PropTypes.number,
  providerId: PropTypes.number,
  getNews: PropTypes.func,
  clearNews: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentArticles);
