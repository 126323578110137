import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './ProviderEditorBanner.scss';
import BannerMenuOverlay from 'components/common/overlays/BannerMenuOverlay/BannerMenuOverlay';
import ImageInput2 from 'components/common/inputs/ImageInput2/ImageInput2';
import { isMobile } from 'components/helpers/helpers';
import ProgressBar2 from 'components/common/ProgressBar/ProgressBar2';
import { PROVIDER_IMAGE_URL } from 'other/config';

import { EProviderMediaObjectType } from 'types/providers';
import { TMedia } from 'types/media';
import { TProviderEditorState } from 'store/provider/providerEditor/providerEditor';

type Props = {
  banner: TMedia;
  bannerUploadProgress?: number;
  isPublished: boolean;
  logo: TMedia;
  logoUploadProgress?: number;
  onBannerUpload: (file: File) => void;
  onLogoUpload: (file: File) => void;
  onTogglePublish: (payload: Partial<TProviderEditorState>) => void;
  uploadObjectType?: EProviderMediaObjectType;
};

const ProviderEditorBanner = (props: Props): ReactElement => {
  const {
    banner,
    bannerUploadProgress,
    isPublished,
    logo,
    logoUploadProgress,
    onBannerUpload,
    onLogoUpload,
    onTogglePublish
  } = props;

  const bannerUrl: string = banner
    ? `${PROVIDER_IMAGE_URL}/${banner.path}`
    : null;
  const logoUrl: string = logo ? `${PROVIDER_IMAGE_URL}/${logo.path}` : null;
  const text = isPublished ? 'Unpublish' : 'Publish';

  const handlePublishedChange = () =>
    onTogglePublish({
      published: !isPublished
    });

  const actions: ReactElement = (
    <div className="ArticleBanner__actions">
      <Button
        className="ArticleBanner__button"
        onClick={handlePublishedChange}
        type="link"
      >
        {text}
      </Button>
    </div>
  );

  const controls: ReactNode = isMobile() ? (
    <BannerMenuOverlay
      actions={actions}
      className="ProviderEditorBanner__menu"
    />
  ) : (
    <div className="ProviderEditorBanner__buttons">
      <Button onClick={handlePublishedChange} shape="round" type="primary">
        {text}
      </Button>
    </div>
  );

  return (
    <header className="ProviderEditorBanner">
      <ImageInput2 imgUrl={bannerUrl} onUpload={onBannerUpload}>
        <ProgressBar2 progress={bannerUploadProgress} />
      </ImageInput2>
      {controls}

      <div className="ProviderEditorBanner__logoHolder">
        <ImageInput2 imgUrl={logoUrl} onUpload={onLogoUpload}>
          <ProgressBar2 progress={logoUploadProgress} />
        </ImageInput2>
      </div>
    </header>
  );
};

ProviderEditorBanner.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }),
  bannerUploadProgress: PropTypes.number,
  isPublished: PropTypes.bool.isRequired,
  logo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }),
  logoUploadProgress: PropTypes.number,
  onBannerUpload: PropTypes.func.isRequired,
  onLogoUpload: PropTypes.func.isRequired,
  onTogglePublish: PropTypes.func.isRequired
};

export default React.memo(ProviderEditorBanner);
