import { EColorDescriptor } from '../../../pages/Map/types/colorDescriptor';
import { EMapLayer } from '../../../types/mapLayer';
import {
  ESettingsSelectOptions,
  getMapSettings
} from '../../../services/settings';
import { TMapOptionsState } from './mapOptionsModel';

const settings = getMapSettings() as TMapOptionsState;
export const mapOptionsInitialState: TMapOptionsState = {
  center: settings.center || null,
  zoom: settings.zoom || null,

  colorDescriptor: EColorDescriptor.COUNTRIES,
  layer: settings.layer || EMapLayer.EEZ,
  vesselNameDisplay: settings.vesselNameDisplay || ESettingsSelectOptions.SHOW,
  vesselPhotoDisplay: settings.vesselPhotoDisplay || ESettingsSelectOptions.SHOW
};
