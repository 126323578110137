import React from 'react';
import PropTypes from 'prop-types';

import './ServiceLogo.scss';
import BrandLogo from '../BrandLogo/BrandLogo';
import { ROUTES } from '../../../../other/config';
import { TMedia } from '../../../../types/media';

type Props = {
  id: number;
  logo: TMedia;
  name: string;
};


const ServiceLogo = ({ id, logo, name }: Props) => {
  if (!logo) {
    return (
      <strong className="ServiceLogo__fallback">{name}</strong>
    );
  }

  const url: string = `${ROUTES.SERVICE_PROVIDER}/${id}`;
  return <BrandLogo className="ServiceLogo" logo={logo} url={url}/>
};


ServiceLogo.propTypes = {
  id: PropTypes.number.isRequired,
  // @ts-ignore
  logo: PropTypes.shape({
    path: PropTypes.string.isRequired
  }),
  name: PropTypes.string.isRequired,
};

export default ServiceLogo;
