import React from 'react';
import * as ReactDOM from 'react-dom';
import { Modal } from 'antd';

import './ModalPhoto.scss';
import {
  addEventHandler,
  removeEventHandler
} from '../../../pages/Map/utils/helpers';
import IconCustom from '../IconCustom/IconCustom';

const bodyStyle = {
  background: 'transparent',
  padding: '0'
};
const buttonStyle = {
  color: 'white',
  fontSize: '24px',
  padding: '7px'
};
const closeIcon = (
  <IconCustom title="Close preview" type="close" style={buttonStyle} />
);

/**
 * Renders a modal view of a picture with given `src`;
 */
export class ModalPhoto {
  private container: HTMLDivElement;
  private readonly src: string;
  private static readonly testId = 'modal-photo';

  constructor(src: string) {
    if (!src || typeof src !== 'string') {
      throw new TypeError('ModalPhoto: `src` must be a string!');
    }

    this.destroy = this.destroy.bind(this);
    this.src = src;
    this.render();
  }

  private render(): void {
    this.container = document.createElement('div');
    this.container.dataset.testid = ModalPhoto.testId;
    document.body.appendChild(this.container);
    addEventHandler('keyup', document.documentElement, this.close);

    const modal = (
      <Modal
        bodyStyle={bodyStyle}
        centered={true}
        closeIcon={closeIcon}
        destroyOnClose={true}
        footer={null}
        onCancel={this.destroy}
        visible={true}
        width="100%"
        wrapClassName="ModalPhoto"
      >
        <div className="app-d-flex ModalPhoto__container">
          <img className="ModalPhoto__img" src={this.src} alt="Illustration" />
        </div>
      </Modal>
    );

    ReactDOM.render(modal, this.container);
  }

  private close(e) {
    e.keyCode === 27 && this.destroy();
  }

  destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(this.container);
    removeEventHandler('keyup', document.documentElement, this.close);

    if (unmountResult && this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
  }
}
