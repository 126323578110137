import { ARTICLE_IMAGE_URL } from '../../other/config';
import { createAsyncActions } from './createAsyncActions';
import store from '../store';
import { TBasicState } from './basicState';

enum ELMD {
  FETCH_DESCRIPTION = 'mediaDescription/FETCH_DESCRIPTION'
}

/**
 * An action creator aimed to fetch news\product article long media description html file.
 * Note, that the dispatched actions do not effect state -- they are for logging only.
 */
export function fetchLongMediaDescription(path: string): Promise<string> {
  const actions = createAsyncActions<TBasicState>(
    store.dispatch,
    ELMD.FETCH_DESCRIPTION
  ).request() as any;

  return retrieveLongMediaDescription(path)
    .then((resp: string) => {
      actions.success();
      return resp === '0' ? '' : resp;
    })
    .catch(actions.error);
}

/**
 * Long description retriever.
 */
export function retrieveLongMediaDescription(path: string): Promise<string> {
  const url = `${ARTICLE_IMAGE_URL}/${path}?q=${Math.random()}`;

  return fetch(url, {
    credentials: 'omit'
  }).then((resp: Response) => resp.text());
}
