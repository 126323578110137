import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PersonPositions.scss';
import { LOCALE } from '../../../other/config';

import { TArticleSource } from '../../../types/article';
import { TBusinessPosition } from '../../../types/person';

type Props = {
  positions?: TBusinessPosition[];
  position?: string;
  source?: TArticleSource;
};


const PersonPositions = ({ position, positions, source }: Props): ReactElement => {
  const path = source ? `/service/provider/${source.id}` : null;

  const Position = ({ companyId, companyName, role }: TBusinessPosition): ReactElement => (
    <tr key={Math.random()}>
      <td>
        <Link to={`/company/${companyId}`}>
          {companyName}
        </Link>
      </td>

      <td>
        {role.value[LOCALE]}
      </td>
    </tr>
  );

  return (
    <table className="PersonPositions">
      <thead>
      <tr>
        <th>Company:</th>
        <th>Position:</th>
      </tr>
      </thead>

      <tbody>
        {positions && (
          positions.map(Position)
        )}

        {source && (
          <tr>
            <td>
              <Link to={path}>
                {source.name}
              </Link>
            </td>

            <td>
              {position}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};


PersonPositions.propTypes = {
  positions: PropTypes.array
};
export default PersonPositions;
