import React from 'react';
import PropTypes from 'prop-types';

import { transformToArray } from '../../../../helpers/helpers';

import { TProcessingFactory } from 'react-fishfacts/dist';

type Props = {
  processingFactory: TProcessingFactory | TProcessingFactory[];
  getManufacturerFieldById: (number) => string | JSX.Element;
};

const ProcessingFactory = ({
  processingFactory,
  getManufacturerFieldById
}: Props) => {
  if (!processingFactory) return null;

  const array = transformToArray(processingFactory);

  return (
    <>
      <tr className="ExtendedFields__paragraph">
        <th colSpan={2}>Processing factory</th>
      </tr>

      {array.map(({ manufacturerId, details }, index) => (
        <React.Fragment key={`processingFactory-${manufacturerId}-${index}`}>
          <tr className="ExtendedFields__listItem">
            <th colSpan={2}>Processing factory {index + 1}</th>
          </tr>
          {manufacturerId && (
            <tr>
              <th>Provider</th>
              <td>{getManufacturerFieldById(manufacturerId)}</td>
            </tr>
          )}
          {details && (
            <tr>
              <th>Details</th>
              <td>{details}</td>
            </tr>
          )}
          {index + 1 < array.length && (
            <tr>
              <th>&nbsp;</th>
              <td> </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

ProcessingFactory.propTypes = {
  processingFactory: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default ProcessingFactory;
