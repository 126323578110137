import {
  ActionFactory,
  deepClone,
  errorAction,
  okAction,
  THttpResponse,
  TMinMax,
  TVessel
} from 'react-fishfacts/dist';

import { ENDPOINTS } from '../../../other/config';
import http from '../../../services/http';
import store from '../../store';

import { EVesselsActions } from './vesselsConstants';
import { TFleet } from '../../../types/fleet';
import { TState } from '../../appStateModel';
import { TVesselsFilterState } from '../vesselsFilter/vesselsFilterModel';
import { TVesselsState } from './vesselsModel';

const af = new ActionFactory<TVesselsState, EVesselsActions>(store);
const fetchSet = af.createAsyncActions(EVesselsActions.FETCH_VESSELS);
const sortVessels = (a: TVessel, b: TVessel): number =>
  a.name.localeCompare(b.name);

export function fetchVesselsAction() {
  return (dispatch) => {
    fetchSet.request();

    http
      .send(ENDPOINTS.VESSELS)
      .then(({ data: vessels }: THttpResponse<TVessel[]>) => {
        const sorted: TVessel[] = vessels.sort(sortVessels);
        fetchSet.success({
          selectedVessels: sorted,
          vessels: sorted
        });
        dispatch(applyVesselsFilterAction());
      })
      .catch(fetchSet.error);
  };
}

export function applyVesselsFilterAction() {
  return (dispatch, getState) => {
    const {
      fleets: { fleets },
      vesselsFilter,
      vessels: { vessels }
    } = getState() as TState;

    if (!vessels) return;
    if (!vesselsFilter.isFilterSet) {
      dispatch({
        type: EVesselsActions.VESSELS_FILTER_SUCCESS,
        payload: { selectedVessels: vessels }
      });
    }

    try {
      const selectedVessels: TVessel[] = applyFilter(
        vesselsFilter,
        vessels,
        fleets
      );
      dispatch(
        okAction(EVesselsActions.VESSELS_FILTER_SUCCESS, { selectedVessels })
      );
    } catch (e) {
      dispatch(errorAction(EVesselsActions.VESSELS_FILTER_FAIL, e));
    }
  };
}

function applyFilter(
  settings: TVesselsFilterState,
  vessels: TVessel[],
  fleets: TFleet[]
): TVessel[] {
  let arr: TVessel[] = deepClone(vessels);
  if (arr.length === 0) return arr;
  const { isFilterSet, ..._settings } = deepClone<TVesselsFilterState>(
    settings
  );

  Object.keys(_settings).forEach((key: string): void => {
    const filterFunction = filterMap[key];
    if (!filterFunction)
      throw new ReferenceError(`No function found for "${key}"`);

    arr = arr.filter(filterFunction(_settings[key], fleets));
  });
  return arr;
}

const bruttoFilter = ({ min, max }: TMinMax): Function => (
  item: TVessel
): boolean => {
  const value: number = item.vesselSizes.bruttotons;
  return value <= max && value >= min;
};

const enginePowerFilter = ({ min, max }: TMinMax): Function => (
  item: TVessel
): boolean => {
  const value: number = item.engine.power;
  return value <= max && value >= min;
};

const fleetFilter = (
  selectedFleetIds: number[],
  fleets: TFleet[]
): Function => {
  // If no fleets have been selected, there is no need to do anything: any vessel passes :).
  if (selectedFleetIds.length === 0) return () => true;
  // Otherwise, we pick the selected fleets and convert them into arrays of vessels' ids.
  const selectedVesselIdsOfFleets: number[][] = fleets
    .filter(({ id }: TFleet): boolean => selectedFleetIds.includes(id))
    .map(({ vesselIds }: TFleet): number[] => vesselIds);

  return ({ id }: TVessel): boolean =>
    selectedVesselIdsOfFleets.some((arr: number[]): boolean =>
      arr.includes(id)
    );
};

const lengthFilter = ({ min, max }: TMinMax): Function => (
  item: TVessel
): boolean => {
  const value: number = item.vesselSizes.lengthOverall;
  return value <= max && value >= min;
};

const yearFilter = ({ min, max }: TMinMax): Function => (
  item: TVessel
): boolean => {
  const value: number = item.buildYear;
  return value <= max && value >= min;
};

const countryFilter = (countries: string[]): Function => ({
  flag
}: TVessel): boolean =>
  countries.length > 0 ? countries.includes(flag) : true;

const engineModelFilter = (modelIds: number[]): Function => ({
  engine
}: TVessel): boolean =>
  modelIds.length > 0 ? modelIds.includes(engine.manufacturerId) : true;

const vesselTypeFilter = (vesselTypeIds: number[]): Function => ({
  vesselType: { id, parentId }
}: TVessel): boolean => {
  if (vesselTypeIds.length) {
    return !!vesselTypeIds.find(
      (vesselTypeId: number) => vesselTypeId === id || vesselTypeId === parentId
    );
  }

  return true;
};

// eslint-disable-next-line
const speciesFilter = ({ id }): Function => (item: TVessel): boolean => true; // todo clarify

const filterMap: Record<string, Function> = {
  filterBruttoTons: bruttoFilter,
  filterBuildYear: yearFilter,
  filterCountry: countryFilter,
  filterEngineModel: engineModelFilter,
  filterEnginePower: enginePowerFilter,
  filterVesselType: vesselTypeFilter,
  filterFleet: fleetFilter,
  filterLength: lengthFilter,
  filterSpecies: speciesFilter
};
