import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { IconCustom, LoaderOverlay } from 'react-fishfacts/dist';
import { RouteComponentProps } from 'react-router-dom';

import './EventsList.scss';
import EmptyList from 'components/common/EmptyList/EmptyList';
import EventCard from './EventCard';
import {
  fetchEventsAction,
  saveScrollTop
} from 'store/events/events/eventsActions';
import { ROUTES } from 'other/config';

import { TEvent } from 'types/event';
import { TState } from 'store/appStateModel';

type Props = {
  getEvents: () => void;
  history: RouteComponentProps;
  isLoading: boolean;
  isOwner: boolean;
  list?: TEvent[];
  saveScrollTop: (scrollTop: number) => void;
  scrollTop: number;
};

class ListWrapper extends React.Component<Props, null> {
  static propTypes: Record<string, any>;
  private scrollTop: number;
  private unsubscribe: () => void;

  componentDidMount() {
    this.props.getEvents();
    this.unsubscribe = this.props.history.listen((location) => {
      if (!location.pathname.startsWith(ROUTES.EVENTS)) {
        this.unsubscribe();
        this.scrollTop = 0;
      }
    });
  }

  componentWillUnmount() {
    this.props.saveScrollTop(this.scrollTop);
  }

  render() {
    const { isLoading, isOwner, list } = this.props;

    const header: ReactNode = (
      <header className="Main__header NewsList__header">
        <h2 className="Main__headerTitle">Events</h2>

        {isOwner && (
          <Button href={ROUTES.EVENT_EDITOR} shape="round" size="large">
            <IconCustom type="plus" />
            Add event
          </Button>
        )}
      </header>
    );

    const content =
      isLoading || !list ? (
        <EmptyList
          placeholder="Add your first event"
          placeholderImgUrl="/assets/placeholders/empty_events.svg"
        />
      ) : (
        list.map((event: TEvent) => <EventCard event={event} key={event.id} />)
      );

    return (
      <div className="NewsListWrapper">
        {header}
        <LoaderOverlay isLoading={isLoading}>{content}</LoaderOverlay>
      </div>
    );
  }
}

ListWrapper.propTypes = {
  getEvents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  list: PropTypes.array,
  saveScrollTop: PropTypes.func.isRequired,
  scrollTop: PropTypes.number
};

const mapStateToProps = (state: TState) => {
  const {
    events: { events, isPending, scrollTop },
    session: { user }
  } = state;

  const eventsIds: ReadonlyArray<number> = user
    ? user.userInfo.eventsId || []
    : [];
  const isOwner: boolean =
    user &&
    user.userInfo.serviceProvidersId &&
    user.userInfo.serviceProvidersId.length > 0;

  return {
    isLoading: isPending,
    isOwner,
    list:
      events &&
      events.filter(
        (event: TEvent) =>
          // user can see only published or their own drafts
          event.published || eventsIds.includes(event.id)
      ),
    scrollTop: scrollTop || 0
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEvents: fetchEventsAction,
      saveScrollTop
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListWrapper);
