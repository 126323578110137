import { basicInitialState } from '../_utils/basicInitialState';
import { TDictionariesState } from './dictionariesModel';

export const dictionariesInitialState: TDictionariesState = {
  ...basicInitialState,
  countries: [],
  countriesISO: [],
  fisheriesTypes: [],
  engines: [],
  languages: [],
  promotionCountries: [],
  species: [],
  vesselTypes: [],
  manufacturers: []
};
