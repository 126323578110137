import { push } from 'connected-react-router';
import { Uploader, TAction, THttpResponse } from 'react-fishfacts/dist';

import { API_URL, ENDPOINTS, ROUTES } from '../../../other/config';
import { articleBlueprint, ENewsEditorActions } from './newsEditorConstants';
import { bf2 } from '../../../other/formAndValidation/formUtils';
import { clearNewsAction } from '../news/newsActions';
import { createAsyncActions } from '../../_utils/createAsyncActions';
import { getToken } from '../../../services/auth';
import http from '../../../services/http';
import { newsEditorInitialState } from './newsEditorInitialState';
import { retrieveLongMediaDescription } from '../../_utils/longMediaDescriptionUtils';
import { submitPromotionAction } from '../../promo/promoActions';
import { updateSessionAction } from '../../session/sessionActions';

import { TArticle } from '../../../types/article';
import { TFormFields } from '../../../types/formFields';
import { TNewsEditorState } from './newsEditorModel';
import { TState } from '../../appStateModel';

export let createSet, fetchSet, fetchBodySet, submitSet;

/**
 * Dispatches actions depending on article-under-change existence.
 */
export function editArticleAction(newsId: number) {
  return (dispatch, getState) => {
    const {
      session: { user }
    }: TState = getState();
    // Fast escape on create mode.
    if (!newsId) return dispatch(createArticleBlueprintAction());

    // if user attempts to edit another's article -- sends him home
    const idArr: ReadonlyArray<number> =
      user && user.userInfo.newsId ? user.userInfo.newsId : [];
    if (!idArr.includes(newsId)) return dispatch(push(ROUTES.NEWS));

    dispatch(fetchArticleAction(newsId));
  };
}

/**
 * Posts a dump news article, so as to associate any media with the article ID.
 */
export function createArticleBlueprintAction() {
  return (dispatch) => {
    dispatch(clearEditorAction());
    createSet = createAsyncActions<TNewsEditorState>(
      dispatch,
      ENewsEditorActions.CREATE_ARTICLE_BLUEPRINT
    ).request();

    http
      .send({
        body: articleBlueprint,
        method: 'POST',
        url: ENDPOINTS.NEWS
      })
      .then(({ data }: THttpResponse<TArticle>) => {
        createSet.success(data as any);
        dispatch(assignFormFieldsAction());
        // update session so as to sync user's articles
        dispatch(updateSessionAction());
      })
      .catch(createSet.error);
  };
}

/**
 * Retrieves news article by ID.
 */
export function fetchArticleAction(id: number) {
  return (dispatch) => {
    fetchSet = createAsyncActions<TNewsEditorState>(
      dispatch,
      ENewsEditorActions.FETCH_ARTICLE
    ).request();

    http
      .send(`${ENDPOINTS.NEWS}/${id}`)
      .then(({ data }: THttpResponse<TArticle>) => {
        const article = {
          ...data,
          languageIso: 'language' in data ? data.language.iso : null
        };
        fetchSet.success({
          ...(article as any),
          isPending: true
        });
        dispatch(fetchArticleBodyAction(article.longDescriptionMedia.path));
      })
      .catch(fetchSet.error);
  };
}

/**
 * Helper action; fetches news article body (longDescription part).
 */
export function fetchArticleBodyAction(path: string) {
  return (dispatch) => {
    fetchBodySet = createAsyncActions<TNewsEditorState>(
      dispatch,
      ENewsEditorActions.FETCH_ARTICLE_BODY
    ).request();

    retrieveLongMediaDescription(path)
      .then((longDescription: string) => {
        fetchBodySet.success({ longDescription });
        dispatch(assignFormFieldsAction());
      })
      .catch(fetchBodySet.error);
  };
}

/**
 * Assigns values to be used as form fields in the article editor.
 */
export function assignFormFieldsAction() {
  return (dispatch, getState) => {
    const {
      newsEditor: { languageIso, longDescription, shortDescription, title }
    }: TState = getState();

    const fields = bf2([
      { languageIso: languageIso === null ? 'EN' : languageIso },
      { longDescription: longDescription },
      { shortDescription: shortDescription },
      { title: title }
    ]);

    dispatch({
      type: ENewsEditorActions.ASSIGN_FORM_FIELDS,
      payload: { fields }
    });
  };
}

export const updateFormFieldsAction = (
  fields: TFormFields
): TAction<TNewsEditorState, ENewsEditorActions> => ({
  type: ENewsEditorActions.UPDATE_FIELD,
  payload: { fields }
});

/**
 * Clears up the article editor.
 */
export const clearEditorAction = (): TAction<
  TNewsEditorState,
  ENewsEditorActions
> => ({
  type: ENewsEditorActions.CLEAR_EDITOR,
  payload: newsEditorInitialState
});

/**
 * Submits changes to a news article. Purges state and redirects to the article static page on success.
 */
export function submitArticleAction(isDraft?: boolean, isPromotion?: boolean) {
  return (dispatch, getState) => {
    const {
      newsEditor: { fields, id }
    }: TState = getState();

    const {
      languageIso,
      longDescription,
      shortDescription,
      title
    }: TFormFields = fields;
    const url = `${ENDPOINTS.NEWS}/${id}`;

    submitSet = createAsyncActions<TNewsEditorState>(
      dispatch,
      ENewsEditorActions.SUBMIT_ARTICLE
    ).request();

    const body: Partial<TArticle> = {
      languageIso: languageIso.value,
      longDescription: longDescription.value || '',
      published: isPromotion ? false : !isDraft,
      shortDescription: shortDescription.value,
      title: title.value
    };

    http
      .send({
        body: body,
        method: 'PUT',
        url: url
      })
      .then(() => {
        submitSet.success();
        dispatch(clearEditorAction());
        dispatch(clearNewsAction);

        isPromotion
          ? dispatch(submitPromotionAction(id))
          : dispatch(push(`${ROUTES.NEWS}/${id}`));
      })
      .catch(submitSet.error);
  };
}

const uploadProgressAction = (
  uploadProgress: number
): TAction<TNewsEditorState, ENewsEditorActions> => ({
  type: ENewsEditorActions.BANNER_UPLOAD_PROGRESS,
  payload: { uploadProgress }
});

/**
 * Uploads a banner image. Associates it with an article-under-change.
 */
export function newsBannerUpload(file: File, articleId: number) {
  return (dispatch) => {
    const url = `${API_URL}${ENDPOINTS.NEWS}/${articleId}/banner`;
    const uploadSet = createAsyncActions<TNewsEditorState>(
      dispatch,
      ENewsEditorActions.BANNER_UPLOAD
    );

    const onError = (e: Error) => {
      dispatch(uploadProgressAction(null));
      uploadSet.error(e);
    };
    const onProgress = (progress: number): void =>
      dispatch(uploadProgressAction(progress));
    const onSuccess = ({ data }): void => {
      dispatch(uploadProgressAction(null));
      uploadSet.success({ banner: data });
    };

    uploadSet.request();

    new Uploader({
      onError: onError,
      onProgress: onProgress,
      onSuccess: onSuccess as any,
      token: getToken
    }).upload(file, url);
  };
}

/**
 * Helps avoiding a bug, when we remove an article and it redirects us to the article page on the editor leave.
 */
export function cancelEditingAction(articleId: number) {
  return (dispatch, getState) => {
    const {
      news: { news }
    }: TState = getState();

    const target: TArticle = (news || []).find(
      ({ id }: TArticle) => id === articleId
    );

    dispatch(push(target ? `${ROUTES.NEWS}/${articleId}` : ROUTES.NEWS));
  };
}
