import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './FishingQuotes.scss';
import { isTouchDevice } from '../../../../components/helpers/helpers';
import { TFishingQuote } from '../../../../types/fishingLicence';

type Props = {
  quotes: TFishingQuote[];
};

const FishingQuotes = ({ quotes }: Props): ReactElement | null => {
  if (!quotes || !quotes.length) return null;

  const rows: ReactElement[] = quotes.map(({ id, size, type }: TFishingQuote) => {
    const inner = {
      __html: size.replace(/\n/g, '<br>')
    };
    return (
      <tr key={id}>
        <td>{type}</td>
        <td dangerouslySetInnerHTML={inner}/>
      </tr>
    )
  });

  const classes = isTouchDevice()
    ? "FishingQuotes__scrollHorizontal FishingQuotes__touchDevice"
    : "FishingQuotes__scrollHorizontal";

  return (
    <div className="FishingQuotes">
      <h3 className="FishingQuotes__title">Quotes</h3>

      <div className={classes}>
        <table className="FishingQuotes__table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
        </table>

        <div className="FishingQuotes__scrollVertical">
          <table className="FishingQuotes__table">
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};


FishingQuotes.propTypes = {
  quotes: PropTypes.array
};

export default FishingQuotes;
