import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PlusOutlined from '@ant-design/icons/es/icons/PlusOutlined';
import { Button } from 'antd';

import './ServiceBranch.scss';
import { ROUTES } from '../../../other/config';

const ServiceBranchHeader = ({ canCreate, providerId }) => {
  const [text, link, icon] = providerId
    ? ['Edit service provider', `${ROUTES.SERVICE_EDITOR}/${providerId}`, null]
    : ['Register company', ROUTES.SERVICE_PLAN, <PlusOutlined />];

  return (
    <header className="Main__header Services__header">
      <h2 className="Main__headerTitle">Service Providers</h2>

      {canCreate && (
        <Link className="Services__register" to={link}>
          <Button
            className="Services__button"
            icon={icon}
            type="primary"
            shape="round"
            size="large"
          >
            {text}
          </Button>
        </Link>
      )}
    </header>
  );
};

ServiceBranchHeader.propTypes = {
  canCreate: PropTypes.bool,
  providerId: PropTypes.number
};

export default ServiceBranchHeader;
