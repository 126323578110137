import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './EventButton.scss';
import { ROUTES } from 'other/config';

type Props = {
  className?: string;
  isOwner: boolean;
  nearestEventId?: number;
};

const EventButton: React.FC<Props> = ({
  className,
  isOwner,
  nearestEventId
}): ReactElement => {
  const cssClass = classnames('EventButton', className);

  if (isOwner) {
    return (
      <Button href={ROUTES.EVENT_EDITOR} className={cssClass}>
        Create event
      </Button>
    );
  }

  if (!nearestEventId) return null;

  const where = {
    pathname: `${ROUTES.EVENTS}/${nearestEventId}`,
    state: {
      prevPath: ROUTES.SERVICE_PROVIDER
    }
  };

  return (
    <Link to={where}>
      <Button className={cssClass}>Book meeting</Button>
    </Link>
  );
};

EventButton.propTypes = {
  className: PropTypes.string,
  isOwner: PropTypes.bool,
  nearestEventId: PropTypes.number
};

export default EventButton;
