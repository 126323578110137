import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';

import './AddressEditor.scss';
import AbstractEditor from '../other/AbstractEditor';
import AddressForm from './AddressForm';
import AddressList from './AddressList';
import { ERR_MESSAGE_DURATION } from '../../../../other/config';
import ModalWrapper from '../../../../components/common/modals/ModalWrapper';

import { TCountry } from '../../../../types/dictionaries';
import { TProviderAddress } from '../../../../types/providers';

type Props = {
  addresses: TProviderAddress[];
  countries: TCountry[];
  countriesLimit: number;
};


class AddressEditor extends AbstractEditor<TProviderAddress, Props> {
  static defaultProps;

  handleSubmit = (values: Partial<TProviderAddress>) => {
    const { countriesLimit, onSubmit, selectedItem } = this.props;
    if (this.checkCountriesLimit(values.country as any)) {
      message.error(
        `As per your subscription type, the limit is ${countriesLimit} country/countries!`,
        ERR_MESSAGE_DURATION
      )
    } else {
      onSubmit({
        ...selectedItem,
        ...values
      });
    }
  };

  checkCountriesLimit(country: string): boolean {
    const { addresses, countriesLimit } = this.props;
    const countries = new Set();

    countries.add(country);
    addresses.forEach((a: TProviderAddress) => a.country && countries.add(a.country.iso));

    return countries.size > countriesLimit;
  }

  render() {
    const { addresses, countries, onAdd, onRemove, selectedItem } = this.props;
    const isModalVisible = !!selectedItem;

    const { address, city, country, postcode, title } = selectedItem || {};
    const values = {
      address,
      city,
      country: country ? country.iso : void 0,
      postcode,
      title
    } as any;

    return (
      <div className="AddressEditor">
        <AddressList addresses={addresses} onAdd={onAdd} onEdit={this.handleEdit} onRemove={onRemove} />

        <ModalWrapper isOpen={isModalVisible} onCancel={this.handleCancel} title="Location info">
          <AddressForm
            countries={countries}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            values={values}
          />
        </ModalWrapper>
      </div>
    );
  }

}

AddressEditor.defaultProps = {
  countriesLimit: 1
};

AddressEditor.propTypes = {
  ...AbstractEditor.propTypes,
  addresses: PropTypes.array,
  countries: PropTypes.array,
  countriesLimit: PropTypes.number,
};

export default AddressEditor;
