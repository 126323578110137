import React, { ReactElement } from 'react';

import './PersonContacts.scss';
import { TPersonContact } from '../../../types/person';

type Props = {
  contact?: TPersonContact;
};


const PersonContacts = ({ contact }: Props): ReactElement | null => {
  if (!contact) return null;

  const { email, mobile, telephone } = contact;

  return (
    <table className="PersonContacts">
      <thead>
        <tr>
          {telephone && (<th>Phone:</th>)}
          {email && (<th>Email:</th>)}
        </tr>
      </thead>

      <tbody>
        <tr>
          {telephone && (<td>
            <a className="PersonContacts__link" href={`tel:${telephone}`}>
              {telephone}
            </a>
          </td>)}

          {email && (<td>
            <a className="PersonContacts__link" href={`mailto:${email}`}>
              {email}
            </a>
          </td>)}
        </tr>
      </tbody>

      <thead>
        <tr>
          {mobile && (<th>Mobile:</th>)}
        </tr>
      </thead>
      <tbody>
        <tr>
          {mobile && (<td>
            <a className="PersonContacts__link" href={`tel:${mobile}`}>
              {mobile}
            </a>
          </td>)}
        </tr>
      </tbody>
    </table>
  );
};

export default PersonContacts;
