import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import './MapLink.scss';
import { BANNER_LINK, ROUTES } from '../../../../other/config';
import { isMobile } from '../../../../components/helpers/helpers';

const MapLink: React.FC = (): ReactElement | null => {
  if (!isMobile()) return null;

  const figureStyle = {
    backgroundImage: 'url(/assets/home/Map.png)'
  };

  return (
    <div className="MapLink">
      <div className="app-d-xs-block app-d-none app-mb-1">
        <a href={BANNER_LINK} rel="noopener noreferrer">
          <img
            src="/assets/banner/fishfacts/analytics/344x90.jpg"
            alt="Banner"
            width="100%"
          />
        </a>
      </div>

      <Link to={ROUTES.MAP} className="CategoryList__wrapper">
        <div className="CategoryList__item">
          <figure className="CategoryList__item--figure" style={figureStyle} />
        </div>
        <h3 className="CategoryList__name">Map</h3>
      </Link>
    </div>
  );
};

export default React.memo(MapLink);
