import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';

import './VesselsComparison.scss';
import { highlightVesselAction } from '../../../store/map/mapEntities/mapEntitiesActions';
import { mapPhotos } from '../helpers';
import { ROUTES } from '../../../other/config';
import VesselPage2 from '../Vessel/VesselPage2';
import withAuth from '../../../components/withAuth';

class VesselComparison extends React.Component {
  render() {
    const {
      canViewOwner,
      engines,
      isPending,
      manufacturers,
      showOnMap,
      userPermissions,
      vesselsComparator: { licenceA, licenceB, vesselAData, vesselBData }
    } = this.props;

    if (isPending) {
      return (
        <div className="VesselComparison">
          <h3>Loading...</h3>
        </div>
      );
    }

    if (!vesselAData && !vesselBData) {
      return <Redirect to={ROUTES.HOME} />;
    }

    const photosA = vesselAData.photo && mapPhotos([vesselAData.photo]);
    const photosB = vesselBData.photo && mapPhotos([vesselBData.photo]);

    return (
      <div className="VesselComparison">
        <VesselPage2
          canViewOwner={canViewOwner}
          engines={engines}
          fishingRights={licenceA}
          manufacturers={manufacturers}
          photos={photosA}
          showOnMap={showOnMap}
          userPermissions={userPermissions}
          vessel={vesselAData}
        />
        <VesselPage2
          canViewOwner={canViewOwner}
          engines={engines}
          fishingRights={licenceB}
          manufacturers={manufacturers}
          photos={photosB}
          showOnMap={showOnMap}
          userPermissions={userPermissions}
          vessel={vesselBData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    canViewOwner: ownProps.canViewCompany,
    engines: state.dictionaries.engines,
    isPending: state.vesselsComparator.isPending,
    manufacturers: state.dictionaries.manufacturers,
    userPermissions: ownProps.rights,
    vesselsComparator: state.vesselsComparator
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showOnMap: (id) => {
      dispatch(push(ROUTES.MAP));
      dispatch(highlightVesselAction(id));
    }
  };
};

VesselComparison.propTypes = {
  canViewOwner: PropTypes.bool,
  engines: PropTypes.array,
  isPending: PropTypes.bool,
  manufacturers: PropTypes.array,
  showOnMap: PropTypes.func.isRequired,
  userPermissions: PropTypes.array.isRequired,
  vesselsComparator: PropTypes.shape({
    licenceA: PropTypes.object,
    licenceB: PropTypes.object,
    vesselAData: PropTypes.object,
    vesselBData: PropTypes.object
  })
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(VesselComparison)
);
