import { createSelector } from 'reselect';
import { TLabelValue } from 'react-fishfacts/dist';

type Option = {
  id: number;
  name: string;
};

const getFleetsOptions = (options: Option[]): TLabelValue[] =>
  options.map(({ id, name }) => ({ label: name, value: id.toString() }));

export const optionsSelector = createSelector(
  (options: Option[]) => options,
  getFleetsOptions
);
