import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import './MapOptions.scss';
import { updateMapOptionsAction } from '../../../../../store/map/mapOptions/mapOptionsActions';

import { ESettingsSelectOptions } from '../../../../../services/settings';
import { TMapOptionsState } from '../../../../../store/map/mapOptions/mapOptionsModel';
import { TState } from '../../../../../store/appStateModel';

type Props = {
  mapOptions: TMapOptionsState;
  setOptions: (options: Partial<TMapOptionsState>) => void;
};

const MapOptions = ({ mapOptions, setOptions }: Props): ReactElement => {
  const handlePictureChoice = (value: ESettingsSelectOptions): void =>
    setOptions({ vesselPhotoDisplay: value });

  return (
    <div className="MapOptions__wrapper">
      <h2 className="MapOptions__label">Map Options</h2>

      <label className="MapOptions__label--sub">On vessel click</label>
      <Select
        className="MapOptions__select"
        onChange={handlePictureChoice}
        value={mapOptions.vesselPhotoDisplay}
      >
        <Select.Option value={ESettingsSelectOptions.SHOW}>
          Show photo
        </Select.Option>
        <Select.Option value={ESettingsSelectOptions.HIDE}>
          Don't show photo
        </Select.Option>
        <Select.Option value={ESettingsSelectOptions.TRACK_ONLY}>
          Show track only
        </Select.Option>
      </Select>
    </div>
  );
};

MapOptions.propTypes = {
  mapOptions: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired
};

const mapStateToProps = (state: TState) => ({
  mapOptions: state.mapOptions
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setOptions: updateMapOptionsAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MapOptions);
