import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './TimeSlot.scss';

type Props = {
  endTime: string;
  startTime: string;
  utcOffset: string;
};


const TimeSlot = ({ endTime, startTime, utcOffset }: Props): ReactElement => {
  const end = moment(endTime).format('HH:mm');
  const start = moment(startTime).format('HH:mm');

  return (
    <div className="TimeSlot">
      {start} - {end} <small>{utcOffset} GMT</small>
    </div>
  );
};


TimeSlot.propTypes = {
  endTime: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
};

export default TimeSlot;
