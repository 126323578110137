import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TVessel } from 'react-fishfacts/dist';

import '../ServiceProvider.scss';
import Expander from 'components/common/Expander/Expander';
import ShortVessel from 'components/common/vessel/ShortVessel/ShortVessel';
import withFleets from 'components/common/vessel/withFleets/withFleets';
import { TVesselOfCompany } from 'types/vessel';

type Props = {
  favVesselsIds: number[];
  onFavClick: (vessels: TVessel[]) => void;
  vessels?: TVesselOfCompany[];
};

const Vessels = ({
  favVesselsIds,
  onFavClick,
  vessels
}: Props): ReactElement | null => {
  if (!vessels || vessels.length === 0) return null;

  const vesselslist = vessels.map((vessel) => (
    <ShortVessel
      favVesselsIds={favVesselsIds}
      key={vessel.vesselId}
      onFavClick={onFavClick}
      vessel={vessel}
    />
  ));

  return (
    <section className="ServiceProvider__group">
      <Expander itemWidth={220} title="Vessels">
        {vesselslist}
      </Expander>
    </section>
  );
};

Vessels.propTypes = {
  favVesselsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  vessels: PropTypes.array.isRequired
};

export default withFleets(Vessels);
