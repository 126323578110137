import { push } from 'connected-react-router';
import { TAction, THttpResponse, Uploader } from 'react-fishfacts/dist';

import { API_URL, ENDPOINTS, ROUTES } from '../../../other/config';
import { bf2 } from '../../../other/formAndValidation/formUtils';
import { getToken } from '../../../services/auth';
import http from '../../../services/http';
import { productEditorInitialState } from './productEditorInitialState';
import { retrieveLongMediaDescription } from '../../_utils/longMediaDescriptionUtils';

import { createAsyncActions } from '../../_utils/createAsyncActions';
import {
  EProductEditorActions,
  productBlueprint
} from './productEditorConstants';
import { TArticle } from '../../../types/article';
import { TFormFields } from '../../../types/formFields';
import { TProduct } from '../../../types/product';
import { TProductEditorState } from './productEditor';
import { TState } from '../../appStateModel';

export let fetchSet, editSet, fetchBodySet, submitSet, uploadSet;

/**
 * Simply redirects to the product editor page.
 */
export function gotoProductEditorAction(providerId: number, productId: number) {
  return (dispatch) =>
    dispatch(
      push(
        `${ROUTES.SERVICE_PROVIDER}/${providerId}/products/${productId}/edit`
      )
    );
}

/**
 * Fetches the product.
 */
export function fetchProductAndEditAction(
  providerId: string,
  productId: string
) {
  return (dispatch, getState) => {
    // if user attempts to edit another's product -- goto
    const {
      session: { user }
    }: TState = getState();
    const idArr: ReadonlyArray<number> = user
      ? user.userInfo.serviceProvidersId
      : [];

    if (!idArr.includes(parseInt(providerId))) {
      const redirectTo = `${ROUTES.SERVICE_PROVIDER}/${providerId}/products/${productId}`;
      return dispatch(push(redirectTo));
    }

    const url: string = `${ENDPOINTS.SERVICE_PROVIDER}/${providerId}/product/${productId}`;
    fetchSet = createAsyncActions<TProductEditorState>(
      dispatch,
      EProductEditorActions.FETCH_PRODUCT
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TProduct>) => {
        if (data.longDescriptionMedia) {
          fetchSet.success({
            ...(data as any),
            isPending: true
          });
          dispatch(fetchProductBodyAction(data));
        } else {
          const { shortDescription, title } = data;
          const payload: Partial<TProductEditorState> = initializeFields(
            '',
            shortDescription,
            title
          );
          fetchSet.success({
            ...(data as any),
            ...payload
          });
        }
      })
      .catch(fetchSet.error);
  };
}

/**
 * Redirects to the product editor if products exists. Otherwise creates a brand new product prior to the redirect.
 */
export function editProductAction(providerId: number, productId?: number) {
  return (dispatch) => {
    if (productId) {
      return dispatch(gotoProductEditorAction(providerId, productId));
    }

    const url: string = `${ENDPOINTS.SERVICE_PROVIDER}/${providerId}/product`;
    editSet = createAsyncActions<TProductEditorState>(
      dispatch,
      EProductEditorActions.CREATE_PRODUCT
    ).request();

    http
      .send({
        body: productBlueprint,
        method: 'POST',
        url: url
      })
      .then(({ data }: THttpResponse<TProduct>) => {
        editSet.success();
        dispatch(gotoProductEditorAction(providerId, data.id));
      })
      .catch(editSet.error);
  };
}

/**
 * Fetches the long media description for a given product.
 * On response it assigns fields meant for editing.
 */
export function fetchProductBodyAction(product: TProduct) {
  return (dispatch) => {
    const { longDescriptionMedia, shortDescription, title } = product;
    fetchBodySet = createAsyncActions<TProductEditorState>(
      dispatch,
      EProductEditorActions.FETCH_PRODUCT_BODY
    ).request();

    retrieveLongMediaDescription(longDescriptionMedia.path)
      .then((longDescription: string) => {
        const ld = longDescription === '0' ? '' : longDescription;
        const payload: Partial<TProductEditorState> = initializeFields(
          ld,
          shortDescription,
          title
        );
        fetchBodySet.success(payload);
      })
      .catch(fetchBodySet.error);
  };
}

/** Initializes fields meant for editing. */
function initializeFields(
  longDescription: string,
  shortDescription: string,
  title: string
): Partial<TProductEditorState> {
  const fields: TFormFields = bf2([
    { longDescription: longDescription },
    { shortDescription: shortDescription },
    { title: title }
  ]);
  return {
    fields: fields,
    longDescription: longDescription
  };
}

export const updateFormFieldsAction = (
  fields: TFormFields
): TAction<TProductEditorState, EProductEditorActions> => ({
  type: EProductEditorActions.UPDATE_FIELD,
  payload: { fields }
});

/**
 * Clears up the article editor.
 */
export const clearEditorAction = (): TAction<
  TProductEditorState,
  EProductEditorActions
> => ({
  type: EProductEditorActions.CLEAR_EDITOR,
  payload: productEditorInitialState
});

/**
 * Submits changes to a product. Purges state and redirects to the product static page on success.
 */
export function submitProductAction(isDraft: boolean) {
  return (dispatch, getState) => {
    const {
      productEditor: { fields, id, source }
    }: TState = getState();

    const { longDescription, shortDescription, title }: TFormFields = fields;
    const url = `${ENDPOINTS.SERVICE_PROVIDER}/${source.id}/product/${id}`;
    submitSet = createAsyncActions<TProductEditorState>(
      dispatch,
      EProductEditorActions.SUBMIT_PRODUCT
    ).request();

    const body: Partial<TArticle> = {
      longDescription: longDescription.value,
      published: !isDraft,
      shortDescription: shortDescription.value,
      title: title.value
    };

    http
      .send({
        body: body,
        method: 'PUT',
        url: url
      })
      .then(() => {
        submitSet.success();
        dispatch(clearEditorAction());
        dispatch(
          push(`${ROUTES.SERVICE_PROVIDER}/${source.id}/products/${id}`)
        );
      })
      .catch(submitSet.error);
  };
}

const uploadProgressAction = (
  uploadProgress: number
): TAction<TProductEditorState, EProductEditorActions> => ({
  type: EProductEditorActions.BANNER_UPLOAD_PROGRESS,
  payload: { uploadProgress }
});

/**
 * Uploads a banner image. Associates it with an article-under-change.
 */
export function uploadProductBannerAction(file: File) {
  return (dispatch, getState) => {
    const {
      productEditor: { id, source }
    }: TState = getState();

    const url: string = `${API_URL}${ENDPOINTS.SERVICE_PROVIDER}/${source.id}/product/${id}/banner`;
    uploadSet = createAsyncActions<TProductEditorState>(
      dispatch,
      EProductEditorActions.BANNER_UPLOAD
    );

    const onError = (e: Error) => {
      dispatch(uploadProgressAction(null));
      uploadSet.error(e);
    };
    const onProgress = (progress: number): void =>
      dispatch(uploadProgressAction(progress));
    const onSuccess = ({ data }): void => {
      dispatch(uploadProgressAction(null));
      uploadSet.success(data);
    };

    uploadSet.request();
    new Uploader({
      onError: onError,
      onProgress: onProgress,
      onSuccess: onSuccess as any,
      token: getToken
    }).upload(file, url);
  };
}
