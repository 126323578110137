import { isProdHost } from './helpers';
import packageJson from '../../package.json';

export const APP_NAME = 'FishFacts';
export const VERSION = packageJson.version;

const BASE_URL = 'https://api-test2.fishfacts.fo/api/v3';
const GLOBALS_BASE_URL =
  '__FF_GLOBALS__' in window ? window.__FF_GLOBALS__.BASE_URL : '';
const globalBaseUrl = GLOBALS_BASE_URL.startsWith('http')
  ? `${GLOBALS_BASE_URL}/api/v3`
  : false;

export const API_URL = globalBaseUrl || BASE_URL;

const GLOBALS_MEDIA_URL =
  '__FF_GLOBALS__' in window ? window.__FF_GLOBALS__.MEDIA_URL : '';
export const MEDIA_BASE_URL = GLOBALS_MEDIA_URL.startsWith('http')
  ? GLOBALS_MEDIA_URL
  : isProdHost()
  ? 'https://storage.googleapis.com/media.fishfacts.fo'
  : 'https://storage.googleapis.com/media-test.fishfacts.fo';

export const IMAGE_URL = `${MEDIA_BASE_URL}/vessels/big`;
export const IMAGE_THUMB_URL = `${MEDIA_BASE_URL}/vessels/thumbnails`;
export const ARTICLE_IMAGE_URL = `${MEDIA_BASE_URL}/articles`;
export const PROVIDER_IMAGE_URL = `${MEDIA_BASE_URL}/serviceProviders`;
export const NO_IMAGE_PATH = '/assets/placeholders/vessel_placeholder.svg';

export const ROUTES = {
  _ABOUT: '/service/provider/26',
  ABOUT: '/about',
  ADVERTISE: '/advertise',
  ANALYTICS: '/analytics',
  COMPANIES: '/companies',
  COMPANY: '/company',
  COMPARE: '/compare',
  CONTACT: '/contact',
  DISCLAIMER: '/disclaimer',
  EVENT_EDITOR: '/events/editor',
  EVENTS: '/events',
  HOME: '/',
  LOGIN: '/login',
  MAP: '/map',
  MY_VESSELS: '/vessels/myvessels',
  PAGE404: '/404',
  PROMO_FORM: 'promotion-form',
  SERVICE_BRANCH: '/service/branch',
  SERVICE_CATEGORY: '/service/category',
  SERVICE_EDITOR: '/service/editor',
  NEWS: '/news',
  NEWS_EDITOR: '/news/editor',
  SERVICE_PLAN: '/service/plan',
  SERVICE_PROVIDER: '/service/provider',
  SERVICE_REGISTRATION: '/service/registration',
  TEST: '/test',
  USER: '/user',
  VESSEL: '/vessel',
  VESSELS_FLEET: '/vessels/:fleet',
  VESSELS: '/vessels'
};

export const ENDPOINTS = {
  COMPANIES: '/companies',
  DICTIONARIES: '/dictionaries',
  EVENT: '/event',
  FAVORITE_VESSELS: '/fleet/',
  FLEET: '/fleet',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NEWS: '/news',
  NOTES: '/notes',
  NOTES_VESSEL: '/notes/vessel',
  PERSONS: '/persons',
  PROMOTION: '/promotions',
  PROMOTION_COST: '/promotions/price',
  SCHEDULE_ITEM: '/events/item',
  SEARCH: '/search',
  SERVICE: '/serviceProvider',
  SERVICE_BRANCHES: '/serviceProvider/branches',
  SERVICE_PROVIDER: '/serviceProvider',
  SERVICE_SUBSCRIPTIONS: '/serviceProvider/subscriptionTypes',
  SESSION: '/user/active',
  TABLEAU_TOKEN: '/tableau/token',
  USER_COMPANY: '/user/company',
  USER_ROLES: '/user/roles',
  USER_USERS: '/user/users',
  VESSEL: '/vessel',
  VESSELS: '/vessels',
  VESSELS_LOCATION: '/vessels/location'
};

export const SUB_POINTS = {
  ALL: 'all',
  ANNUAL_REPORTS: '/annualreports',
  APPROVE_BOOKING: '/booking/accept',
  BOOK_MEETING: '/book',
  CANCEL_MEETING: '/booking/cancel',
  CATCHES: '/catches',
  FISHING_LICENCES: 'fishingLicences',
  PAYMENTS: '/payments',
  PHOTOS: 'photos',
  REJECT_BOOKING: '/booking/reject'
};

export const LOCALE = 'en_GB';
export const VESSELS_LOCATIONS_UPDATE_INTERVAL = 60 * 1000;
export const DATE_FORMAT = 'YYYY-MM-DD';

export const FB_APP_ID = '181656619777466'; // '288643752532019';
export const GOOGLE_MAP_KEY = {
  key: 'AIzaSyACaSoM_cjwvm8hWkaaB8T1aLRujRERXDU',
  language: 'en'
};

export const WYSIWYG_EMPTY_STRING = '<span />';

// export const CHUNK_LIMIT = 1048576; // 1MB
export const CHUNK_LIMIT = 20971520; // 20MB
export const ERR_MESSAGE_DURATION = 5; // seconds

export const SUB_PROJECTS = {
  ANALYTICS: 'https://analytics.fishfacts.com/',
  EXPO: 'https://expo.fishfacts.com',
  EXPO_FLOOR_PLAN: '/news/793',
  REGISTRATION: 'https://register.fishfacts.com/'
};

export const BANNER_LINK = '/news/1150';
