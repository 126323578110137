import { companyRegistrationInitialState } from './companyRegistration/companyRegistrationInitialState';
// import { contactListInitialState } from './vessel/contacts/contactListInitialState';
import { dictionariesInitialState } from './dictionaries/dictionariesInitialState';
import { eventsInitialState } from './events/events/eventsInitialState';
import { eventEditorInitialState } from './events/eventEditor/eventEditorInitialState';
import { fleetsInitialState } from './fleets/fleetsInintialState';
import { loginInitialState } from './login/loginInitialState';
import { mapEntitiesInitialState } from './map/mapEntities/mapEntitiesInitialState';
import { mapOptionsInitialState } from './map/mapOptions/mapOptionsInitialState';
import { newsInitialState } from './news/news/newsInitialState';
import { newsEditorInitialState } from './news/newsEditor/newsEditorInitialState';
import { newsRecentInitialState } from './news/newsRecent/newsRecentInitialState';
import { personInitialState } from './person/personInitialState';
import { productInitialState } from './product/product/productInitialState';
import { productEditorInitialState } from './product/productEditor/productEditorInitialState';
import { promoInitialState } from './promo/promoInitialState';
import { providerInitialState } from './provider/provider/providerInitialState';
import { providerEditorInitialState } from './provider/providerEditor/providerEditorInitialState';
import { providersInitialState } from './provider/providers/providersInitialState';
import { serviceBranchesInitialState } from './serviceBranches/serviceBranchesInitialState';
import { sessionInitialState } from './session/sessionInitialState';
import { searchInitialState } from './search/searchInitialState';
import { themeInitialState } from './theme/themeInitialState';
import { tracksInitialState } from './map/tracks/tracksInitialState';
import { vesselInitialState } from './vessel/vessel/vesselInitialState';
import { vesselsComparatorInitialState } from './vessel/vesselsComparator/vesselsComparatorInitialState';
import { vesselsFilterInitialState } from './vessel/vesselsFilter/vesselsFilterInitialState';
import { vesselsInitialState } from './vessel/vessels/vesselsInitialState';
import { vesselsLocationsInitialState } from './map/vesselsLocations/vesselsLocationsInitialState';
import { TState } from './appStateModel';

export const appInitialState: TState = {
  // General section
  dictionaries: dictionariesInitialState,
  login: loginInitialState,
  person: personInitialState,
  serviceBranches: serviceBranchesInitialState,
  session: sessionInitialState,
  search: searchInitialState,
  theme: themeInitialState,
  // Vessels section
  fleets: fleetsInitialState,
  vessel: vesselInitialState,
  vessels: vesselsInitialState,
  vesselsComparator: vesselsComparatorInitialState,
  vesselsFilter: vesselsFilterInitialState,
  // News
  news: newsInitialState,
  newsEditor: newsEditorInitialState,
  newsRecent: newsRecentInitialState,
  // Service
  companyRegistration: companyRegistrationInitialState,
  product: productInitialState,
  productEditor: productEditorInitialState,
  provider: providerInitialState,
  providerEditor: providerEditorInitialState,
  providers: providersInitialState,
  // Events
  events: eventsInitialState,
  eventEditor: eventEditorInitialState,
  // Map
  mapEntities: mapEntitiesInitialState,
  mapOptions: mapOptionsInitialState,
  tracks: tracksInitialState,
  vesselsLocations: vesselsLocationsInitialState,
  // Promotion & Campaign
  promo: promoInitialState
  // vessel (or client) related
  // contactList: contactListInitialState,
  // vessel: vesselInitialState,
};
