import { basicInitialState } from '../../_utils/basicInitialState';
import { TVesselsComparatorState } from './vesselsComparatorModel';

export const vesselsComparatorInitialState: TVesselsComparatorState = {
  ...basicInitialState,
  licenceA: null,
  licenceB: null,
  vesselA: null,
  vesselB: null,
  vesselAData: null,
  vesselBData: null
};
