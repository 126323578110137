import React from 'react';
import PropTypes from 'prop-types';

import './ServiceMap.scss';
import {
  buildLocationQueries,
  DEFAULT_PROVIDER_MAP_CENTER,
  getCenter
} from '../../../../services/location';

import { TPosition } from '../../../../types/position';
import {
  TProviderAddress,
  TProviderAddressShort
} from '../../../../types/providers';

type Props = {
  addresses: TProviderAddress[];
  onMarkerClick: Function;
};

class ServiceMap extends React.PureComponent<Props> {
  static propTypes;

  private readonly icon = window.L.icon({
    iconUrl: '/assets/icons/marker-icon.png',
    iconRetinaUrl: '/assets/icons/marker-icon-2x.png',
    shadowUrl: '/assets/icons/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
  });
  private map;

  componentDidMount() {
    const { addresses } = this.props;
    if (!addresses || !addresses.length) return;

    Promise.all(buildLocationQueries(addresses))
      .then(this.initMap as any)
      .catch(({ message }: Error) => {
        this.initMap();
        window.console.warn(`GOOGLE_GEOLOCATION: ${message}`);
      });
  }

  componentWillUnmount() {
    this.map && this.map.remove();
  }

  initMap = (positions?: TPosition): void => {
    this.map = window.L.map('leafletMap', {
      // @ts-ignore
      center: getCenter(positions) || DEFAULT_PROVIDER_MAP_CENTER,
      minZoom: 3,
      zoom: 4,
      zoomControl: false
    });

    window.L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      id: 'osm'
    }).addTo(this.map);
    this.drawMarkers(positions);
  };

  drawMarkers(positions: TPosition): void {
    Array.isArray(positions) && positions.forEach(this.drawMarker as any);
  }

  drawMarker = (pos: TPosition, index: number): void => {
    window.L.marker(pos as any, {
      icon: this.icon
    })
      .addTo(this.map)
      .on('click', () => this.handleMarkerClick(index));
  };

  handleMarkerClick = (index: number): void => {
    const { addresses, onMarkerClick } = this.props;
    const targetAddr = addresses[index];
    onMarkerClick({
      ...targetAddr,
      addressId: targetAddr.id,
      position: [targetAddr.latitude, targetAddr.longitude]
    } as Partial<TProviderAddressShort>);
  };

  render() {
    return (
      <section className="ServiceMap">
        <div id="leafletMap" className="ServiceMap__content" />
      </section>
    );
  }
}

ServiceMap.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.object,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      postcode: PropTypes.string.isRequired
    })
  ),
  onMarkerClick: PropTypes.func.isRequired
};

export default ServiceMap;
