import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TechFilter,
  TTechFilterValues,
  TTechFilterHandlers
} from 'react-fishfacts/dist';

import { setVesselFilterAction } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterActions';
import { vesselsFilterBlankState } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterInitialState';

import { EVesselsFilterActions } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterConstants';
import { TState } from '../../../../../../store/appStateModel';

type Props = {
  values: TTechFilterValues;
  handlers: TTechFilterHandlers;
};

const Sliders = ({ values, handlers }: Props) => {
  const {
    filterEnginePower: powerRange,
    filterBruttoTons: tonnageRange,
    filterBuildYear: yearRange,
    filterLength: lengthRange
  } = vesselsFilterBlankState;
  const ranges = { lengthRange, powerRange, tonnageRange, yearRange };

  return <TechFilter values={values} handlers={handlers} ranges={ranges} />;
};

const mapStateToProps = ({
  vesselsFilter: {
    filterLength: lengthValue,
    filterEnginePower: powerValue,
    filterBruttoTons: tonnageValue,
    filterBuildYear: yearValue
  }
}: TState) => ({
  values: {
    lengthValue,
    powerValue,
    tonnageValue,
    yearValue
  }
});

const mapDispatchToProps = (dispatch) => ({
  handlers: {
    selectLength: (value) =>
      dispatch(setVesselFilterAction(EVesselsFilterActions.SET_LENGTH, value)),
    selectPower: (value) =>
      dispatch(
        setVesselFilterAction(EVesselsFilterActions.SET_ENGINE_POWER, value)
      ),
    selectTonnage: (value) =>
      dispatch(setVesselFilterAction(EVesselsFilterActions.SET_TONNAGE, value)),
    selectYear: (value) =>
      dispatch(setVesselFilterAction(EVesselsFilterActions.SET_YEAR, value))
  }
});

Sliders.propTypes = {
  values: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Sliders);
