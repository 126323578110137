import React from 'react';
import PropTypes from 'prop-types';

import './VesselMap.scss';
import { commonMapPropTypes } from './utils/mapPropTypes';
import EEZMap from './EEZMap';
import MapModeSwitch from './controls/MapModeSwitch';
import SeabedMap from './SeabedMap';
import Search from 'components/common/inputs/Search/Search';
import { selectLegends } from './utils/colors';
import VesselsMapControl from './controls/VesselMapControl';
import WindyMap from './WindyMap';

import { EMapLayer } from '../../types/mapLayer';
import { ETrackPeriod } from '../../types/trackPeriod';
import { TCommonMapProps, TMapLegend } from './types/common';
import { TDictionaries } from '../../types/dictionaries';

type Props = TCommonMapProps & {
  clearEntities: () => void;
  currentFleets: number[];
  dictionaries: TDictionaries;
  fetchProviders: () => void;
  removeAllTracks: () => void;
  requestedFleets: number[];
  setFleetsFilter: (fleetsIds: number[]) => void;
  unwatchLocations: () => void;
  updateTrackPeriod: (trackPeriod: ETrackPeriod) => void;
};

class MapWrapper extends React.PureComponent<Props> {
  static propTypes;
  private vesselsMap = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const {
      currentFleets,
      fetchProviders,
      requestedFleets,
      setFleetsFilter
    } = this.props;
    fetchProviders();

    setFleetsFilter(
      requestedFleets.length > 0 ? requestedFleets : currentFleets
    );
  }
  componentWillUnmount() {
    this.props.clearEntities();
    this.props.removeAllTracks();
    this.props.unwatchLocations();
  }

  private static getMapComponentByLayer(type: EMapLayer) {
    switch (type) {
      case EMapLayer.EEZ:
        return EEZMap;
      case EMapLayer.SEABED:
        return SeabedMap;
      default:
        return WindyMap;
    }
  }

  render() {
    const {
      dictionaries,
      mapOptions,
      updateMapOptions,
      updateTrackPeriod,
      ...restOfProps
    } = this.props;

    const innerProps: TCommonMapProps = {
      ...restOfProps,
      mapOptions,
      updateMapOptions
    };

    const legends: TMapLegend[] = selectLegends({
      dictionaries: dictionaries,
      colorDescriptor: mapOptions.colorDescriptor
    });

    const Component = MapWrapper.getMapComponentByLayer(
      mapOptions.currentMapLayer
    );
    const getPopupContainer = () => this.vesselsMap.current;

    return (
      <div className="VesselsMap" ref={this.vesselsMap}>
        <Search
          className="VesselsMap__search"
          getPopupContainer={getPopupContainer}
        />
        <MapModeSwitch
          currentMapLayer={mapOptions.currentMapLayer}
          updateMapOptions={updateMapOptions}
        />
        <VesselsMapControl
          currentMapLayer={mapOptions.currentMapLayer}
          legends={legends}
          onColorChange={updateMapOptions}
          onPeriodChange={updateTrackPeriod}
        />

        <Component {...innerProps} />
      </div>
    );
  }
}

MapWrapper.propTypes = {
  ...commonMapPropTypes,
  currentFleets: PropTypes.arrayOf(PropTypes.number).isRequired,
  dictionaries: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.object),
    engines: PropTypes.arrayOf(PropTypes.object),
    fisheriesTypes: PropTypes.arrayOf(PropTypes.object),
    species: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  fetchProviders: PropTypes.func.isRequired,
  removeAllTracks: PropTypes.func.isRequired,
  requestedFleets: PropTypes.arrayOf(PropTypes.number).isRequired,
  setFleetsFilter: PropTypes.func.isRequired,
  unwatchLocations: PropTypes.func.isRequired,
  updateTrackPeriod: PropTypes.func.isRequired
};

export default MapWrapper;
