import { okAction, THttpResponse } from 'react-fishfacts/dist';

import { createAsyncActions } from '../../_utils/createAsyncActions';
import { ENDPOINTS } from '../../../other/config';
import { fetchLongMediaDescription } from '../../_utils/longMediaDescriptionUtils';
import http from '../../../services/http';

import { EProductActions } from './productConstants';
import { TProduct } from '../../../types/product';
import { TProductState } from './product';

export let fetchSet, removeSet;

/**
 * Retrieves a product by given ID.
 * @param providerId
 * @param productId
 */
export function fetchProductAction(providerId: number, productId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.SERVICE_PROVIDER}/${providerId}/product/${productId}`;
    fetchSet = createAsyncActions<TProductState>(
      dispatch,
      EProductActions.FETCH_PRODUCT
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TProduct>) => {
        fetchSet.success({
          isPending: true,
          product: data
        });
        dispatch(fetchProductBodyAction(data));
      })
      .catch(fetchSet.error);
  };
}

/**
 * Determines whether a product has longMediaDescription field. If so, fetches it and inserts.
 */
export function fetchProductBodyAction(product: TProduct) {
  return (dispatch) => {
    const { longDescriptionMedia } = product;
    if (!longDescriptionMedia) return;

    fetchLongMediaDescription(longDescriptionMedia.path).then(
      (body: string) => {
        const enrichedArticle: TProduct = {
          ...product,
          longDescription: body
        };
        dispatch(
          okAction(EProductActions.BODY_COMPOSED, {
            isPending: false,
            product: enrichedArticle
          })
        );
      }
    );
  };
}

/**
 * Remove product record by ID.
 * @param productId
 * @param providerId
 */
export function removeProduct(productId: number, providerId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.SERVICE_PROVIDER}/${providerId}/product/${productId}`;
    removeSet = createAsyncActions<TProductState>(
      dispatch,
      EProductActions.REMOVE_PRODUCT
    ).request();

    http
      .send({
        method: 'DELETE',
        url: url
      })
      .then(() => {
        removeSet.success();
      })
      .catch(removeSet.error);
  };
}
