import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './EventsList.scss';
import CardWrapper from '../other/CardWrapper';
import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import EventCard from '../../Service/Events/EventCard';
import Expander from '../../../../components/common/Expander/Expander';
import { TEvent } from '../../../../types/event';

type Props = {
  events: TEvent[];
  onEdit: (eventId?: number) => void;
  onRemove: (event: TEvent) => void;
};

class EventsList extends React.PureComponent<Props> {
  static propTypes;

  handleAdd = () => this.props.onEdit();
  handleEdit = (eventId: number) => this.props.onEdit(eventId);
  handleRemove = (eventId: number) => {
    const { events, onRemove } = this.props;
    const event = events.find(({ id }: TEvent) => id === eventId);
    onRemove(event);
  };

  render() {
    const { events } = this.props;

    const slides =
      events && events.length ? (
        events.map(
          (event: TEvent): ReactElement => {
            const handleEdit = () => this.handleEdit(event.id);
            return (
              <CardWrapper
                id={event.id}
                key={event.id}
                onEdit={handleEdit}
                onRemove={this.handleRemove}
              >
                <EventCard event={event} />
              </CardWrapper>
            );
          }
        )
      ) : (
        <EmptyList
          placeholder="Add your first event"
          placeholderImgUrl="/assets/placeholders/empty_events.svg"
        />
      );

    return (
      <section className="ServiceProvider__group">
        <Expander
          buttonText="Add event"
          itemWidth={0}
          onAdd={this.handleAdd}
          title="Events"
        >
          {slides}
        </Expander>
      </section>
    );
  }
}

EventsList.propTypes = {
  onAdd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  products: PropTypes.array
};

export default EventsList;
