import * as geojson from 'geojson';
import AbstractMap from './utils/AbstractMap';
import penguinsColonies from './utils/penguins_colonies.json';
import Russian_EEZ_Government_Order_No_414 from './utils/Russian_EEZ_Government_Order_No_414.json';
import { setUpEEZMap } from './utils/initialization';
import { IWindyApi } from './types/windy';

class EEZMap extends AbstractMap {
  rootId = 'eezMap';

  initMap() {
    this.mapApi = {
      map: setUpEEZMap(this.rootId)
    };
    this.setState({ hasInitPassed: true });
  }

  postInit(api: IWindyApi): void {
    window.L.geoJSON(penguinsColonies as geojson.GeoJsonObject).addTo(api.map);

    window.L.geoJSON(
      Russian_EEZ_Government_Order_No_414 as geojson.GeoJsonObject
    ).addTo(api.map);
  }
}

export default EEZMap;
