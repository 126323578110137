import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { FormInstance } from 'antd/lib/form';

import './ProductEditor.scss';
import ArticleEditorBanner from '../../../../components/common/articles/ArticleEditor/ArticleEditorBanner';
import ArticleEditorControls from '../../../../components/common/articles/ArticleEditor/ArticleEditorControls';
import ArticleEditorHeader from '../../../../components/common/articles/ArticleEditor/ArticleEditorHeader';
import { editorProps } from '../../../news/NewsEditor/helpers';
import { MEDIA_BASE_URL } from '../../../../other/config';
import ProductForm from './ProductForm';

import { TFormFields } from '../../../../types/formFields';
import { TProductEditorState } from '../../../../store/product/productEditor/productEditor';

type TProductEditorProps = Partial<TProductEditorState> & {
  isModified: boolean;
  onChange: (fields: TFormFields) => void;
  onCancel: () => void;
  onSubmit: (isDraft?: boolean) => void;
  onUpload: (file: File, productId: number) => void;
};

const ProductEditor = (props: TProductEditorProps): ReactElement => {
  const {
    banner,
    fields,
    id,
    isModified,
    onCancel,
    onChange,
    onSubmit,
    onUpload,
    source,
    uploadProgress
  } = props;
  const imgUrl: string = banner ? `${MEDIA_BASE_URL}/${banner.path}` : null;
  let form: FormInstance;

  const handleCancel = () => onCancel();
  const handleDraft = () => onSubmit(true);
  const handleSubmit = () => form.validateFields().then(() => onSubmit(false));
  const handleUpload = (file: File): void => onUpload(file, id);
  const saveFormRef = (formRef) => formRef && (form = formRef);

  return (
    <div className="ProductEditor">
      <ArticleEditorHeader onCancel={handleCancel} title="New product" />

      <ArticleEditorBanner
        imgUrl={imgUrl}
        onUpload={handleUpload}
        source={source}
        uploadProgress={uploadProgress}
        showUploadList={false}
      />

      <div className="ArticleEditor__holder">
        <ProductForm
          fields={fields}
          onChange={onChange}
          onFormRef={saveFormRef}
        />

        <ArticleEditorControls
          isDisabled={!isModified}
          onCancel={handleCancel}
          onDraft={handleDraft}
          onPublish={handleSubmit}
        />
      </div>
    </div>
  );
};

ProductEditor.propTypes = {
  ...editorProps,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};
export default ProductEditor;
