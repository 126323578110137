import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import CloseButton from '../buttons/CloseButton/CloseButton';
import { THandler } from '../../../types/handlers';


type Props = {
  children: any | any[];
  isOpen: boolean;
  onCancel: THandler;
  title: string
};

const modalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  maskClosable: true,
  title: null,
};


const ModalWrapper = ({ children, isOpen, onCancel, title }: Props): ReactElement => (
  <Modal visible={isOpen} {...modalProps}>
    <header className="Main__header app-justify-content-center">
      <h2 className="Main__headerTitle">{title}</h2>
      <CloseButton onClick={onCancel} title="Cancel"/>
    </header>

    {children}
  </Modal>
);


ModalWrapper.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default ModalWrapper;
