import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LOCALE } from '../../other/config';
import './companyData.scss';


class CompanyData extends React.PureComponent {
  locale = LOCALE;
  
  render() {
    const { company } = this.props;

    return (
      <table className="company-data">
        <tbody>
          <tr key="registrationNumber">
            <td>Reg. No.</td>
            <td><Link to={`/company/${company.id}`} className="link-underlined">{company.registrationNumber}</Link></td>
          </tr>
          <tr key="companyName">
            <td>Company Name</td>
            <td><Link to={`/company/${company.id}`} className="link-underlined">{company.name}</Link></td>
          </tr>
          <tr key="country">
            <td>Country</td>
            <td>{company.country.value[this.locale]}</td>
          </tr>

          <tr key="address">
            <td>Address</td>
            <td>{company.address}</td>
          </tr>
          <tr key="city">
            <td>City</td>
            <td>{company.city}</td>
          </tr>
          <tr key="postcode">
            <td>Postcode</td>
            <td>{company.postcode}</td>
          </tr>
          {company.directors && company.directors.map(row => (
            <tr key={`director_${row.id}`}>
              <td>Director</td>
              <td><Link to={`/person/${row.id}`} className="link-underlined">{row.fullName}</Link></td>
            </tr>
          ))}
          {company.chairman && (
            <tr key="chairman">
              <td>Chairman</td>
              <td><Link to={`/person/${company.chairman.id}`} className="link-underlined">{company.chairman.fullName}</Link></td>
            </tr>
          )}
          {company.boardMembers && company.boardMembers.map(row => (
            <tr key={`boardMember_${row.id}`}>
              <td>Board Members</td>
              <td><Link to={`/person/${row.id}`} className="link-underlined">{row.fullName}</Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}


CompanyData.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompanyData;
