import { MEDIA_BASE_URL } from '../../other/config';
import { TVesselPhotoSet } from '../../types/vessel';


export type TVesselPhotoItem = {
  description: string;
  fullscreen: string;
  original: string;
  thumbnail: string;
};

export function mapPhotos(photos: TVesselPhotoSet[]): TVesselPhotoItem[] {
  if (!photos) return null;

  return photos.map(({ big, original, thumbnail }: TVesselPhotoSet): TVesselPhotoItem => ({
    description: big.copyright || original.copyright || thumbnail.copyright,
    fullscreen: `${MEDIA_BASE_URL}/${big.path}`,
    original: `${MEDIA_BASE_URL}/${big.path}`,
    thumbnail: `${MEDIA_BASE_URL}/${thumbnail.path}`
  }));
}
