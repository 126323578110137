import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './VideoCard.scss';
import { getVideoMime } from '../../../../components/helpers/helpers';
import { PROVIDER_IMAGE_URL } from '../../../../other/config';
import { TVideo } from '../../../../types/media';

type Props = {
  video: TVideo;
};

const VideoCard = ({ video }: Props): ReactElement => {
  const { title, video: media, poster } = video;
  const url: string = media ? `${PROVIDER_IMAGE_URL}/${media.path}` : null;
  const posterUrl: string = poster
    ? `${PROVIDER_IMAGE_URL}/${poster.path}`
    : null;
  const fileType: string = getVideoMime(url);

  const content: ReactNode = url ? (
    <video className="VideoCard__video" controls poster={posterUrl}>
      <source src={url} type={fileType} />
    </video>
  ) : (
    <figure className="ProductCard__photo">
      <img src="/assets/placeholders/video_placeholder.svg" alt={title} />
    </figure>
  );

  return (
    <div className="VideoCard">
      {content}
      <h5 className="VideoCard__name">{title}</h5>
    </div>
  );
};

VideoCard.propTypes = {
  video: PropTypes.object.isRequired
};

export default VideoCard;
