import { TIdLocationDict, TLocaleSet } from './general';

export type TCountry = {
  label: string;
  value: string;
};
export type TCountryISO = {
  iso: string;
  isoAplha3: string;
  value: {
    en_GB: string;
  };
};

export type TEngine = {
  details?: string;
  manufacturerId?: number;
  manufacturerName?: string;
  power?: number;
};
export type TEngineShort = {
  id: number;
  name: string;
};

export type TFishery = TIdLocationDict;
export type TSpecies = TIdLocationDict;

export type TLanguage = {
  label: string;
  value: string;
};
export type TLanguageISO = {
  value: TLocaleSet;
  iso: string;
};

export enum ESupportedApps {
  AQUAFACTS = 'AQUAFACTS',
  FISHFACTS = 'FISHFACTS'
}

export type TVesselType = TIdLocationDict & {
  parentId: number;
  subTypes: TVesselType[];
  supportedApps: ESupportedApps[];
  value: TLocaleSet;
};

export type TManufacturer = {
  id: number;
  name: string;
};

export type TDictionaries = {
  countries: TCountry[];
  countriesISO: TCountryISO[];
  engines: TEngineShort[];
  fisheriesTypes: TFishery[];
  languages: TLanguage[];
  promotionCountries: TCountryISO[];
  species: TSpecies[];
  vesselTypes: TVesselType[];
  manufacturers: TManufacturer[];
  // companyRoles
  // vesselStatuses
};
