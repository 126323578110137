import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import { appInitialState } from './appInitialState';
import createRootReducer from './reducer';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();

const logger = createLogger({
  collapsed: (getState, action, logEntry) =>
    !(action && action.payload && action.payload.error) || logEntry.error
});

const middleware = [
  routerMiddleware(history),
  thunk,
  process.env.NODE_ENV !== 'test' && logger
].filter(Boolean);

const store = createStore(
  createRootReducer(history),
  appInitialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
