import {
  cityPattern,
  phonePattern
} from '../../../other/formAndValidation/validationRules';
import {
  formItemProps,
  itemProps2inputs
} from '../../../other/formAndValidation/formUtils';

const name = {
  label: 'Company name',
  name: 'name',
  rules: [{ required: true, message: 'Company name is required!' }],
  ...formItemProps
};
const address = {
  label: 'Address',
  name: 'address',
  rules: [{ required: true, message: 'Address is required!' }],
  ...formItemProps
};
const postcode = {
  label: 'Post code',
  name: 'postcode',
  rules: [{ required: true, message: 'Post code is required!' }],
  ...formItemProps
};
const city = {
  label: 'City',
  name: 'city',
  rules: [
    {
      pattern: cityPattern,
      message: 'Invalid city!'
    },
    {
      required: true,
      message: 'City is required!'
    }
  ],
  ...formItemProps
};
export const countryProps = {
  label: 'Country',
  name: 'country',
  rules: [{ required: true, message: 'Country is required!' }],
  ...formItemProps
};
const vatNumber = {
  label: 'VAT No.',
  name: 'vatNumber',
  rules: [{ required: true, message: 'VAT number is required!' }],
  ...formItemProps
};
const email = {
  label: 'Email',
  name: 'email',
  rules: [
    {
      type: 'email',
      message: 'Invalid email!'
    },
    {
      required: true,
      message: 'Email is required!'
    }
  ],
  ...formItemProps
};
const telephone = {
  label: 'Telephone',
  name: 'telephone',
  rules: [
    {
      pattern: phonePattern,
      message: 'Invalid telephone!'
    },
    {
      required: true,
      message: 'Telephone is required!'
    }
  ],
  ...formItemProps
};

export const formItems1 = [name, address, postcode, city].map(itemProps2inputs);

export const formItems2 = [vatNumber, email, telephone].map(itemProps2inputs);
