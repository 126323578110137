import React, { ReactElement } from 'react';

import './EventArticle.scss';
import ArticleBanner from '../../../components/common/articles/ArticleBanner/ArticleBanner';
import EventSchedule from './schedule/EventSchedule';
import EventTitle from './EventTitle';
import FBShareButton from '../../../components/common/buttons/FBShareButton/FBShareButton';
import GoBackButton from '../../../components/common/buttons/GoBackButton/GoBackButton';
import { MEDIA_BASE_URL, ROUTES } from '../../../other/config';
import { ModalPhoto } from '../../../components/common/modals/ModalPhoto';

import { TEvent } from '../../../types/event';
import { eventArticleProps, TEventScheduleBaseProps } from './helpers';
import { THandler } from '../../../types/handlers';

type Props = TEventScheduleBaseProps & {
  editEvent: THandler;
  event: TEvent;
  removeEvent: THandler;
};

const EventArticle = (props: Props): ReactElement | null => {
  const {
    isOwner,
    editEvent,
    event,
    onApproveReject,
    onBookCancel,
    removeEvent,
    userId
  } = props;
  if (!event) return <h4>Not found</h4>;

  const {
    banner,
    longDescription,
    published,
    schedule,
    shortDescription,
    source,
    utcOffset
  } = event;

  const handleBodyClick = (e) => {
    if (e.target.tagName !== 'IMG') return;
    new ModalPhoto(e.target.src);
  };

  const bannerUrl = banner
    ? `${MEDIA_BASE_URL}/${banner.path}`
    : '/assets/placeholders/event_placeholder.svg';

  const html = {
    __html:
      typeof longDescription === 'string'
        ? longDescription
        : `<pre><code class="app-error-msg">${JSON.stringify(
            longDescription,
            null,
            2
          )}</code></pre>`
  };

  return (
    <div className="EventArticle">
      <section className="EventArticle__content">
        <ArticleBanner
          canEdit={isOwner}
          imgUrl={bannerUrl}
          isDraft={!published}
          onEdit={editEvent}
          onRemove={removeEvent}
          source={source}
        />

        <FBShareButton />
        <EventTitle event={event} />

        <div className="EventArticle__description">{shortDescription}</div>

        <EventSchedule
          isOwner={isOwner}
          onApproveReject={onApproveReject}
          onBookCancel={onBookCancel}
          schedule={schedule}
          userId={userId}
          utcOffset={utcOffset}
        />

        <div
          className="EventArticle__body"
          dangerouslySetInnerHTML={html}
          onClick={handleBodyClick}
        />
      </section>

      <div className="app-d-flex app-justify-content-end app-pb-4">
        <GoBackButton pathBack={ROUTES.EVENTS} />
      </div>
    </div>
  );
};

EventArticle.propTypes = eventArticleProps;

export default EventArticle;
