import AbstractMap from './utils/AbstractMap';
import { setUpSeabedMap } from './utils/initialization';

class SeabedMap extends AbstractMap {
  rootId = 'seabedMap';

  initMap() {
    this.mapApi = {
      map: setUpSeabedMap(this.rootId)
    };
    this.setState({ hasInitPassed: true });
  }
}

export default SeabedMap;
