import React from 'react';
import { Carousel } from 'antd';
import './CarouselCustom.scss';

type Props = {
  autoplay?: boolean;
  autoplaySpeed?: number;
  effect?: 'scrollx' | 'fade';
  dots?: boolean;
  speed?: number;
};

class CarouselCustom extends React.PureComponent<Props> {
  public carousel: any;
  static defaultProps;

  next = () => this.carousel.next();
  previous = () => this.carousel.prev();
  assignCarousel = (node) => (this.carousel = node);

  render() {
    const { children, ...props } = this.props;

    return (
      <div className="CarouselCustom">
        <button
          type="button"
          className="CarouselCustom__prev"
          onClick={this.previous}
        >
          &lt;
        </button>

        <Carousel ref={this.assignCarousel} {...props}>
          {children}
        </Carousel>

        <button
          type="button"
          className="CarouselCustom__next"
          onClick={this.next}
        >
          &gt;
        </button>
      </div>
    );
  }
}

CarouselCustom.defaultProps = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  effect: 'scrollx',
  speed: 500
};

export default CarouselCustom;
