import { Tabs } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import Aquaculture from '../panes/Aquaculture/Aquaculture';
import BuildAndClassification from '../panes/BuildAndClassification';
import DeckEquipment from '../panes/DeckEquipment';
import Dimensions from '../panes/Dimensions';
import ElectricalPower from '../panes/ElectricalPower';
import { getItemFieldByIdFinder } from '../../helpers/helpers';
import Installations from '../panes/Installations/Installations';
import { isRoute, isIE } from 'components/helpers/helpers';
import PropulsionAndManeuvering from '../panes/PropulsionAndManeuvering';
import { ROUTES } from 'other/config';

import {
  TBuilt,
  TDimesions,
  TEngineShort,
  TManufacturer,
  TVesselClass,
  TVesselExtras
} from 'react-fishfacts/dist';

const { TabPane } = Tabs;
const getHeader = (text: string) => (
  <div className="ExtendedFields__tabHeader">{text}</div>
);

type Props = {
  built: TBuilt;
  dimensions: TDimesions;
  engines: TEngineShort[];
  filteredExtendedKeys: TVesselExtras;
  manufacturers: TManufacturer[];
  vesselClass: TVesselClass;
};

const ExtendedFieldsTabs = ({
  filteredExtendedKeys: {
    electricalPower,
    aquaculture,
    deckEquipment,
    installations,
    propulsionAndManeuvering
  },
  manufacturers,
  engines,
  built,
  dimensions,
  vesselClass
}: Props) => {
  const getManufacturerFieldById = getItemFieldByIdFinder(manufacturers);
  const getEngineFieldById = getItemFieldByIdFinder(engines);
  const tabPosition = isRoute(ROUTES.COMPARE) && isIE() ? 'left' : 'top';

  const aquacultureHeader = getHeader('Aquaculture');
  const buildAndClassificationHeader = getHeader('Build and classification');
  const deckEquipmentHeader = getHeader('Deck equipment');
  const dimensionsHeader = getHeader('Dimensions');
  const electricalPowerHeader = getHeader('Electrical power');
  const installationsHeader = getHeader('Ship installations');
  const propulsionAndManeuveringHeader = getHeader('Propulsion & maneuvering');

  return (
    <Tabs type="card" tabPosition={tabPosition}>
      {aquaculture && (
        <TabPane tab={aquacultureHeader} key="aquaculture">
          <Aquaculture
            aquaculture={aquaculture}
            getManufacturerFieldById={getManufacturerFieldById}
          />
        </TabPane>
      )}

      {(built || vesselClass) && (
        <TabPane
          tab={buildAndClassificationHeader}
          key="buildAndClassification"
        >
          <BuildAndClassification built={built} vesselClass={vesselClass} />
        </TabPane>
      )}

      {dimensions && (
        <TabPane tab={dimensionsHeader} key="dimensions">
          <Dimensions dimensions={dimensions} />
        </TabPane>
      )}

      {propulsionAndManeuvering && (
        <TabPane
          tab={propulsionAndManeuveringHeader}
          key="propulsionAndManeuvering"
        >
          <PropulsionAndManeuvering
            propulsionAndManeuvering={propulsionAndManeuvering}
            getManufacturerFieldById={getManufacturerFieldById}
            getEngineFieldById={getEngineFieldById}
          />
        </TabPane>
      )}

      {electricalPower && (
        <TabPane tab={electricalPowerHeader} key="electricalPower">
          <ElectricalPower
            electricalPower={electricalPower}
            getManufacturerFieldById={getManufacturerFieldById}
          />
        </TabPane>
      )}

      {deckEquipment && (
        <TabPane tab={deckEquipmentHeader} key="deckEquipment">
          <DeckEquipment
            deckEquipment={deckEquipment}
            getManufacturerFieldById={getManufacturerFieldById}
          />
        </TabPane>
      )}

      {installations && (
        <TabPane tab={installationsHeader} key="installations">
          <Installations
            installations={installations}
            getManufacturerFieldById={getManufacturerFieldById}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

ExtendedFieldsTabs.propTypes = {
  filteredExtendedKeys: PropTypes.object,
  manufacturers: PropTypes.array,
  engines: PropTypes.array,
  built: PropTypes.object,
  dimensions: PropTypes.object,
  vesselClass: PropTypes.object
};

export default ExtendedFieldsTabs;
