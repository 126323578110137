import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import './Login.scss';
import { canSaveToLocalStorage } from '../../services/storage';
import LoaderOverlay from '../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import { loginAction } from '../../store/login/loginActions';
import { LoginForm } from './LoginForm/LoginForm';
import { ROUTES } from '../../other/config';

import { TState } from '../../store/appStateModel';
import { TSubmitCredentials } from '../../types/credentials';

type Props = {
  displayError: string;
  isLoggedIn: boolean;
  isPending: boolean;
  isSessionError: boolean;
  onSubmit: (credentials: TSubmitCredentials) => void;
};

class LoginPage extends React.Component<Props> {
  static propTypes;
  private canStoreCredentials: boolean = canSaveToLocalStorage();

  render() {
    const {
      displayError,
      isLoggedIn,
      isPending,
      isSessionError,
      onSubmit
    } = this.props;
    const shouldShowSpinner = isSessionError && isPending;
    if (isLoggedIn) return <Redirect to={ROUTES.HOME} />;

    return (
      <LoaderOverlay className="LoginPage" isLoading={shouldShowSpinner}>
        <LoginForm
          canStoreCredentials={this.canStoreCredentials}
          displayError={displayError}
          isPending={isPending}
          onSubmit={onSubmit as any}
        />
      </LoaderOverlay>
    );
  }
}

LoginPage.propTypes = {
  displayError: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  isSessionError: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ login, session }: TState) => ({
  displayError: login.displayError,
  isLoggedIn: !!session.user,
  isPending: login.isPending,
  isSessionError: !!session.error
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSubmit: loginAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
