import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Progress } from 'antd';
import './ProgressBar2.scss';

type Props = {
  className?: string;
  progress?: number;
};

const ProgressBar2: React.FC<Props> = ({ className, progress }): ReactElement | null => {
  if (progress === void 0 || progress === null) return null;
  const cls = classnames('ProgressBar2', className);

  return (
    <div className={cls}>
      <Progress percent={progress} type="circle" />
    </div>
  );
};

ProgressBar2.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number
};

export default ProgressBar2;
