import React, { CSSProperties, ReactNode } from 'react';

import './MapLegend.scss';
import { DEFAULT_COLOR } from '../../utils/constants';
import { LOCALE } from '../../../../other/config';
import { providerBranchColors } from '../../utils/colors';
import { TServiceCategory } from '../../../../types/service';


/**
 * Returns a service category icon.
 * @param id
 * @param value
 */
function getServiceIcon({ id, value }: TServiceCategory): ReactNode {
  const label: string = value[LOCALE];
  const color: string = providerBranchColors[id - 4] || DEFAULT_COLOR;
  const style: CSSProperties = { backgroundColor: color };
  return (
    <div key={id} className="MapLegend__legend">
      <div className="MapLegend__provider">
        <span style={style} />
      </div>
      <span className="MapLegend__label">{label}</span>
    </div>
  );
}

/**
 * Returns a list of service categories' legends.
 * @param serviceCategories
 */
export function getServiceLegends(serviceCategories?: TServiceCategory[]): ReactNode | null {
  if (!serviceCategories || serviceCategories.length === 0) return null;
  return (
    <div className="MapLegend__list">
      {serviceCategories.map(getServiceIcon)}
    </div>
  );
}
