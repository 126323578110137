import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './AddressCard.scss';
import { LOCALE } from '../../../../other/config';
import { TProviderAddress } from '../../../../types/providers';

type Props = {
  location: TProviderAddress;
};

const AddressCard = ({ location }: Props): ReactElement | null => {
  if (!location) return null;

  const { address, city, country, postcode, title } = location;
  const countryString = country && country.value[LOCALE];
  const titleStub: ReactNode = (
    <img alt="" src="/assets/placeholders/location_card_placeholder.svg" />
  );

  return (
    <div className="AddressCard">
      <ul className="AddressCard__list">
        <li>
          <strong className="AddressCard__title">{title || titleStub}</strong>
        </li>
        <li>{address}</li>
        <li>{city}</li>
        <li>{postcode}</li>
        <li>{countryString}</li>
      </ul>
    </div>
  );
};

AddressCard.propTypes = {
  location: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.object,
    postcode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default AddressCard;
