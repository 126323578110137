import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './VesselOwner.scss';
import { LOCALE } from '../../../../other/config';
import { TOwner } from '../../../../types/owner';

type Props = {
  owner: TOwner;
};

const VesselOwner = ({ owner }: Props): ReactElement => {
  const ownerPath = `/company/${owner.id}`;

  return (
    <div className="VesselOwner">
      <div className="VesselOwner__pane">
        <h3 className="VesselOwner__title">Owner</h3>
        <span className="VesselOwner__subTitle">
          <Link to={ownerPath} className="link-underlined">
            {owner.name}
          </Link>
        </span>
        <div className="VesselOwner__data">{owner.address}</div>
        <div className="VesselOwner__data">{owner.city}</div>
        <div className="VesselOwner__data">{owner.postcode}, {owner.country.value[LOCALE]}</div>
        <div className="VesselOwner__data">Reg. No. {owner.registrationNumber}</div>
      </div>
    </div>
  );
};


VesselOwner.propTypes = {
  owner: PropTypes.object.isRequired,
};

export default VesselOwner;
