export class Track {
	
	constructor(options) {
		this.options = options;
		this._create();
	}
	
	_create() {
		const { color, map, path } = this.options;
		const options = {
			color: color,
			vesselId: path.vesselId
		};
		
		const points = Track._data2points(path.data);
		this.track = window.L.polyline(points, options)
			.addTo(map)
			.on('click', this._onClick);
	}
	
	_onClick = (e) => {
		const { map, onClick, path: { vesselId } } = this.options;
		const { latlng, target: { _latlngs } } = e;
		
		const distArr = _latlngs.map(ll => map.distance(ll, latlng));
		const minDist = Math.min(...distArr);
		const pointIndex = distArr.findIndex(d => d === minDist);
		onClick(vesselId, pointIndex, latlng);
	};
	
	static _data2points(data) {
	  return data.map(
	    ({ latitude, longitude }) => ([latitude, longitude])
    );
  }
  
  updateCoordinates(path) {
    const points = Track._data2points(path.data);
    this.track.setLatLngs(points);
  }
	
	remove() {
    this.track.off();
    this.track.removeFrom(this.options.map);
	}
	
}
