import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons/lib';

import './PromotionCost.scss';
import { TPromotionCost } from '../../../../types/promotion';

type Props = {
  cost?: TPromotionCost;
  isPending: boolean;
};

const loader = (
  <div className="PromotionCost__cost">
    ...is being calculated
    <br />
    <LoadingOutlined />
  </div>
);

class PromotionCost extends React.PureComponent<Props> {
  static propTypes;

  render() {
    const { cost, isPending } = this.props;
    if (!cost) return null;

    const budget = (
      <div className="PromotionCost__cost">
        <span className="PromotionCost__total">&euro;{cost.amount}</span>
        {cost.rate && (
          <small className="PromotionCost__rate">
            &euro;{cost.rate} per day
          </small>
        )}
      </div>
    );

    return (
      <div className="PromotionCost">
        <h2 className="PromotionCost__title">Total budget</h2>
        <div className="PromotionCost__budget">
          {isPending ? loader : budget}
        </div>

        <Button
          block
          className="app-btn-success"
          disabled={isPending}
          htmlType="submit"
          loading={isPending}
          size="large"
          type="primary"
        >
          Publish &amp; Pay
        </Button>
      </div>
    );
  }
}

PromotionCost.propTypes = {
  cost: PropTypes.object,
  isCostLoading: PropTypes.bool
};
export default PromotionCost;
