import React, { CSSProperties, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import classnames from 'classnames';

import './CloseButton.scss';
import IconCustom from '../../../../components/common/IconCustom/IconCustom';
import { THandler } from '../../../../types/handlers';

type Props = {
  className?: string;
  onClick: THandler;
  style?: CSSProperties;
  title?: string;
};


const CloseButton: React.FC<Props> = ({ className, onClick, style, title }): ReactElement => {
  const cls: string = classnames('CloseButton',className);

  return (
    <Button
      className={cls}
      onClick={onClick}
      size="large"
      title={title}
      type="link"
    >
      <IconCustom type="close" style={style} />
    </Button>
  );
};


CloseButton.defaultProps = {
  style: { fontSize: 18 }
};

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
};

export default React.memo(CloseButton);
