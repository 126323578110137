import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TVessel } from 'react-fishfacts/dist';

import VesselCard from '../VesselCard/VesselCard';
import { TVesselOfCompany } from 'types/vessel';

type Props = {
  favVesselsIds: number[];
  onFavClick: (vessels: TVessel[]) => void;
  vessel: TVesselOfCompany;
};

const ShortVessel = ({
  favVesselsIds,
  onFavClick,
  vessel
}: Props): ReactElement => {
  const handleFav = (vessel: TVessel) => onFavClick([vessel]);

  const { mediaPath, vesselId, vesselName, flag } = vessel;
  const photo = mediaPath
    ? { thumbnail: { path: `vessels/thumbnails/${mediaPath}` } }
    : null;

  const isInFavourites = favVesselsIds.includes(vesselId);

  const v = {
    id: vesselId,
    name: vesselName,
    flag: flag.iso,
    photo
  } as TVessel;

  return (
    <VesselCard
      isInFavourites={isInFavourites}
      onFavClick={handleFav}
      vessel={v}
    />
  );
};

ShortVessel.propTypes = {
  className: PropTypes.string,
  favVesselsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  vessels: PropTypes.array.isRequired
};

export default ShortVessel;
