import React from 'react';

import './AppsInfo.scss';

const AppsInfo = (
  <section className="AppsInfo">
    <div className="app-container">
      <div className="AppsInfo__holder">
        <img src="/assets/home/img-apps@2x.jpg" alt="Apps" />
        <div className="AppsInfo__text">
          <h4>
            AVAILABLE NOW{' '}
            <strong className="app-text-uppercase">The fishfacts App</strong>
          </h4>
          <p>View map and find service providers — all on the go</p>

          <div className="app-d-flex">
            <div className="Footer__brands">
              <a href="https://play.google.com/store/apps/details?id=fo.fishfacts.fishfactsapp">
                <img
                  src="/assets/googleplay.svg"
                  width="150"
                  alt="GooglePlay link"
                />
              </a>
              <a href="https://itunes.apple.com/us/app/fishfacts-fo/id1447608197?mt=8">
                <img
                  src="/assets/appstore.svg"
                  width="150"
                  alt="AppStore link"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AppsInfo;
