import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import './ProviderCategoryFilter.scss';
import {
  ALL_VALUE,
  filterOption,
  NONE_VALUE,
  num2StrArr,
  removeAllOption,
  removeNoneOption,
  strArr2Num,
  typeId2StrArr
} from '../../../../../components/helpers/convertors';
import { areEqual } from '../../../../../other/helpers';
import { setProvidersFilterAction } from '../../../../../store/provider/providers/providersActions';

import { EProvidersActions } from '../../../../../store/provider/providers/providersConstants';
import { TLabelValue } from '../../../../../other/formAndValidation/formUtils';
import { TProviderCategoryFilterSettings } from '../../../../../types/providerFilters';
import { TServiceCategory } from '../../../../../types/service';
import { TState } from '../../../../../store/appStateModel';

function categories2Labels(cats: TServiceCategory[]): TLabelValue[] {
  return cats.map(({ id, value: { en_GB } }: TServiceCategory) => ({
    label: en_GB,
    value: '' + id
  }));
}

type Props = {
  options: {
    serviceCategories: TServiceCategory[];
  };
  setProviderFilter: Function;
  values: TProviderCategoryFilterSettings;
};
const ALL: TLabelValue = {
  label: ALL_VALUE,
  value: ALL_VALUE
};
const NONE: TLabelValue = {
  label: NONE_VALUE,
  value: NONE_VALUE
};

const ProviderCategoryFilter = ({
  options: { serviceCategories },
  setProviderFilter,
  values: { categoryIds }
}: Props): ReactElement => {
  const categoryOptions = [ALL, NONE, ...categories2Labels(serviceCategories)];
  const categoryValue: string | string[] =
    categoryIds === null
      ? [NONE_VALUE]
      : categoryIds.length > 0
      ? num2StrArr(categoryIds)
      : [ALL_VALUE];

  const setFilter = (values: string[]): void =>
    setProviderFilter(
      EProvidersActions.PROVIDERS_FILTER_PARAM_CATEGORY,
      strArr2Num(values)
    );

  const handleCategory = (values: string[]): void => {
    // NONE choice
    if (
      !areEqual(categoryValue, [NONE_VALUE]) &&
      (values.includes(NONE_VALUE) || values.length === 0)
    ) {
      return setFilter(null);
    }
    // ALL case
    else if (
      (!areEqual(categoryValue, [ALL_VALUE]) && values.includes(ALL_VALUE)) ||
      areEqual(typeId2StrArr(serviceCategories), values)
    ) {
      return setFilter([]);
    }

    setFilter(removeAllOption(removeNoneOption(values)));
  };

  return (
    <div className="ProviderCategoryFilter__wrapper">
      <h2 className="ProviderCategoryFilter__label">Services</h2>

      <label className="ProviderCategoryFilter__label--sub">
        Service categories
      </label>
      <Select
        className="ProviderCategoryFilter__select"
        filterOption={filterOption}
        mode="tags"
        onChange={handleCategory}
        optionFilterProp="value"
        options={categoryOptions}
        value={categoryValue}
      />
    </div>
  );
};

ProviderCategoryFilter.propTypes = {
  options: PropTypes.shape({
    serviceCategories: PropTypes.array.isRequired
  }).isRequired,
  setProviderFilter: PropTypes.func.isRequired,
  values: PropTypes.shape({
    categoryIds: PropTypes.array
  })
};

const mapStateToProps = (state: TState) => {
  const {
    providers: { filterSettings },
    serviceBranches: { serviceCategories }
  } = state;
  return {
    options: { serviceCategories },
    values: filterSettings
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setProviderFilter: setProvidersFilterAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderCategoryFilter);
