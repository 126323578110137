import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import './AnnualReports.scss';

const CALCULATED = 'CALCULATED';

class AnnualReports extends React.PureComponent {
  render() {
    const {
      annualReport: { accounts = [], items }
    } = this.props;

    const names = items.map((col) => {
      const colSpan = Object.keys(col.reports).length;
      return (
        <th
          key={col.company.id}
          colSpan={colSpan}
          className="app-info-table-align-center"
        >
          {col.company.name}
        </th>
      );
    });

    const reports = items.map((col) =>
      Object.keys(col.reports)
        .reverse()
        .map((key) => {
          const value = `1,000 ${col.reports[key].currency}`;
          return (
            <th key={Math.random()} className="app-info-table-align-center">
              {col.reports[key].year} <br />
              <em className="app-info-table-text-sm">{value}</em>
            </th>
          );
        })
    );

    const getValues = (row2) =>
      items.map((col3) =>
        Object.keys(col3.reports)
          .reverse()
          .map((key) => (
            <td key={Math.random()} className="app-info-table-align-center">
              <NumberFormat
                value={col3.reports[key][row2.key]}
                displayType="text"
                thousandSeparator
              />
            </td>
          ))
      );

    const getFields = (group) => {
      if (!group || !group.accounts) return null;

      return group.accounts.map((row2) => {
        const key = `${row2.id}-account`;
        const cls = classnames('app-info-table-col-w50', {
          'account-name-calculated': row2.type === CALCULATED
        });
        return (
          <tr key={Math.random()} selected={row2.selected}>
            <td key={key} className={cls}>
              {row2.name}
            </td>
            {getValues(row2)}
          </tr>
        );
      });
    };

    return (
      <>
        <h3 className="AnnualReports__header">Annual Reports</h3>
        <table className="app-info-table AnnualReports">
          <thead>
            <tr className="app-info-table-head app-info-table-border-bottom">
              <th className="app-info-table-col-w50">Account</th>
              {names}
            </tr>
            <tr className="app-info-table-border-bottom">
              <th className="app-info-table-col-w50" />
              {reports}
            </tr>
          </thead>

          <tbody>
            {accounts.map((group) => (
              <React.Fragment key={Math.random()}>
                <tr>
                  <td className="account-group-name">{group.name}</td>
                </tr>
                {getFields(group)}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

AnnualReports.propTypes = {
  annualReport: PropTypes.object.isRequired
};

export default AnnualReports;
