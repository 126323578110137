import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './EventSchedule.scss';
import { groupEventsByDate } from '../../helpers';
import ScheduleItems from './ScheduleItems';

import { TEventScheduleGroup, TScheduleItem } from '../../../../types/event';
import { eventScheduleBaseProps, TEventScheduleBaseProps } from '../helpers';

type Props = TEventScheduleBaseProps & {
  schedule: TScheduleItem[];
  utcOffset: string;
};

const EventSchedule = ({
  isOwner,
  onApproveReject,
  onBookCancel,
  schedule,
  userId,
  utcOffset,
}: Props): ReactElement => {
  const groups: ReactElement[] = groupEventsByDate(schedule).map(
    ({ date, id, items }: TEventScheduleGroup) => {
      const [day, month] = date.split(' ');

      return (
        <div className="EventSchedule__group" key={id}>
          <div className="EventSchedule__date">
            <strong>{day}</strong> {month}
          </div>
          <ScheduleItems
            isOwner={isOwner}
            items={items}
            onApproveReject={onApproveReject}
            onBookCancel={onBookCancel}
            userId={userId}
            utcOffset={utcOffset}
          />
        </div>
      );
    }
  );

  return (
    <section className="EventSchedule">
      <h3 className="EventSchedule__title">Schedule</h3>
      {groups.length > 0
        ? groups
        : (
        <h4 className="EventSchedule__message">No schedule items yet...</h4>
      )}
    </section>
  );
};

EventSchedule.propTypes = {
  ...eventScheduleBaseProps,
  schedule: PropTypes.array.isRequired,
};

export default EventSchedule;
