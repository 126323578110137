import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { RouteProps } from 'react-router-dom';

import {
  addScheduleItemAction,
  cancelEditingAction,
  clearEditorAction,
  editEventAction,
  eventBannerUploadAction,
  removeScheduleItemAction,
  submitEventAction,
  updateFormFieldsAction,
  updateScheduleItemAction
} from '../../../store/events/eventEditor/eventEditorActions';
import EventEditorWrapper from './EventEditorWrapper';
import { removeEventAction } from '../../../store/events/events/eventsActions';
import { WYSIWYG_EMPTY_STRING } from '../../../other/config';

import { TEventEditorState } from '../../../store/events/eventEditor/eventEditor';
import { TFormFields } from '../../../types/formFields';
import { TState } from '../../../store/appStateModel';

/* eslint eqeqeq: 0 */
function isEventEmpty(model: TEventEditorState): boolean {
  const {
    banner,
    end,
    fields,
    longDescription,
    schedule,
    shortDescription,
    start,
    title
  } = model;
  if (!fields) return false;

  const {
    dates,
    longDescription: _longDescription,
    shortDescription: _shortDescription,
    title: _title,
    utcOffset: _utcOffset
  }: TFormFields = fields;

  if (
    !dates ||
    !_longDescription ||
    !_shortDescription ||
    !_title ||
    !_utcOffset
  )
    return false;
  const datesValue = dates.value || [];

  return (
    !banner &&
    !end &&
    !longDescription &&
    !schedule.length &&
    !shortDescription &&
    !start &&
    !title &&
    !datesValue[0] &&
    !datesValue[1] &&
    !_title.value &&
    !_shortDescription.value &&
    (!_longDescription.value || _longDescription.value === WYSIWYG_EMPTY_STRING)
  );
}

function format(dt?: Moment): string {
  if (!dt) return;
  return dt.clone().format('YYYY-MM-DD');
}

function isEventModified(model: TEventEditorState): boolean {
  const {
    end,
    fields,
    longDescription,
    shortDescription,
    start,
    title,
    utcOffset
  } = model;
  if (!fields) return false;

  const {
    dates,
    longDescription: _longDescription,
    shortDescription: _shortDescription,
    title: _title,
    utcOffset: _utcOffset
  }: TFormFields = fields;

  if (Object.keys(fields).length === 0) return false;
  const datesValue = dates.value || [];

  return (
    format(datesValue[0]) != (start && start.substring(0, 10)) ||
    format(datesValue[1]) != (end && end.substring(0, 10)) ||
    _longDescription.value != longDescription ||
    _shortDescription.value !== shortDescription ||
    _title.value !== title ||
    _utcOffset.value !== utcOffset
  );
}

const mapStateToProps = (state: TState, ownProps: { match: RouteProps }) => {
  const { eventEditor } = state;
  return {
    ...eventEditor,
    eventId: parseInt(ownProps.match.params.id),
    isEmpty: isEventEmpty(eventEditor),
    isModified: isEventModified(eventEditor)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEvent: editEventAction,
      onCancel: cancelEditingAction,
      onChange: updateFormFieldsAction,
      onItemAdd: addScheduleItemAction,
      onItemRemove: removeScheduleItemAction,
      onItemUpdate: updateScheduleItemAction,
      onLeave: clearEditorAction,
      onRemove: removeEventAction,
      onSubmit: submitEventAction,
      onUpload: eventBannerUploadAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventEditorWrapper);
