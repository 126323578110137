import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './VideosList.scss';
import { areEqual } from '../../../../other/helpers';
import CardWrapper from '../other/CardWrapper';
import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import Expander from '../../../../components/common/Expander/Expander';
import VideoCard from '../../Service/Videos/VideoCard';
import { TVideo } from '../../../../types/media';

type Props = {
  onAdd: () => void;
  onEdit: (video: TVideo) => void;
  onRemove: (videoId: number) => void;
  videos: TVideo[];
};
type State = {
  isExpanded: boolean;
};

class VideosList extends React.PureComponent<Props, State> {
  state: State = { isExpanded: false };
  static propTypes;

  /** We want the list to be expanded when we add\remove a video */
  componentDidUpdate(prevProps: Props) {
    const { videos } = this.props;

    if (videos && prevProps.videos && !areEqual(videos, prevProps.videos)) {
      this.setState({ isExpanded: true });
    }
  }

  render() {
    const { videos, onAdd, onEdit, onRemove } = this.props;

    const slides =
      videos && videos.length ? (
        videos.map(
          (video: TVideo): ReactElement => {
            const handleEdit = () => onEdit(video);

            return (
              <CardWrapper
                id={video.id}
                key={video.id}
                onEdit={handleEdit}
                onRemove={onRemove}
              >
                <VideoCard video={video} />
              </CardWrapper>
            );
          }
        )
      ) : (
        <EmptyList
          placeholder="Add your first video"
          placeholderImgUrl="/assets/placeholders/video_card_placeholder.svg"
        />
      );

    return (
      <section className="ServiceProvider__group">
        <Expander
          buttonText="Add video"
          isExpanded={this.state.isExpanded}
          itemWidth={220}
          onAdd={onAdd}
          title="Videos"
        >
          {slides}
        </Expander>
      </section>
    );
  }
}

VideosList.propTypes = {
  onAdd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  videos: PropTypes.array
};

export default VideosList;
