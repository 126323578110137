import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ALL_VALUE, Select, TLabelValue, TCountry } from 'react-fishfacts/dist';

import { setVesselFilterAction } from 'store/vessel/vesselsFilter/vesselsFilterActions';
import { EVesselsFilterActions } from 'store/vessel/vesselsFilter/vesselsFilterConstants';
import { TState } from 'store/appStateModel';

const ALL: TLabelValue = {
  label: 'All countries',
  value: ALL_VALUE
};

type Props = {
  countries: TCountry[];
  countryFilterValue: string[];
  selectCountry: (options: string[]) => void;
};

const CountriesFilter = ({
  countries,
  countryFilterValue,
  selectCountry
}: Props) => {
  const options = [ALL, ...(countries || [])];

  // When user types 'usa', one expects this value to be correct. But the
  // country options do not have such a value. So we need to find something
  // similar to 'usa' among the options and replace a wrong user input
  // with one of the options. (Fix for FIS-158).
  const handleCountry = (values: string[], options: TLabelValue[]) => {
    const wrongOptionIndex = options.findIndex(
      ({ label }: TLabelValue) => label === void 0
    );

    if (wrongOptionIndex < 0) {
      return selectCountry(values);
    }

    const targetOption = countries.find(({ label, value }: TLabelValue) => {
      const text = values[wrongOptionIndex].toLocaleLowerCase();
      return (
        value.toLowerCase().startsWith(text) ||
        label.toLowerCase().startsWith(text)
      );
    });

    if (!targetOption) {
      return selectCountry(values);
    }

    values.splice(wrongOptionIndex, 1, targetOption.value);
    selectCountry(values);
  };

  return (
    <Select
      value={countryFilterValue}
      options={options}
      onChange={handleCountry}
    />
  );
};

const mapStateToProps = ({
  dictionaries: { countries },
  vesselsFilter: { filterCountry: countryFilterValue }
}: TState) => ({
  countries,
  countryFilterValue
});

const mapDispatchToProps = (dispatch) => ({
  selectCountry: (value) =>
    dispatch(setVesselFilterAction(EVesselsFilterActions.SET_COUNTRY, value))
});

CountriesFilter.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  countryFilterValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectCountry: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CountriesFilter);
