import { TId, TLabelValue } from 'react-fishfacts/dist';

/** 'All' option for antd Select. */
export const ALL_VALUE = 'All';
export const NONE_VALUE = 'None';

export function typeId2StrArr(arr: TId[]): string[] {
  if (!arr) return arr as any;
  return arr.map(({id}: TId): string => '' + id);
}

export function typeLabelValue2StrArr(arr: TLabelValue[]): string[] {
  if (!arr) return arr as any;
  return arr.map(({value}: TLabelValue): string => value);
}

export function num2StrArr(arr: number[]): string[] {
  if (!arr) return arr as any;
  return arr.map((id: number): string => '' + id);
}

export function strArr2Num(arr: string[]): number[] {
  if (!arr) return arr as any;
  return arr.map((id: string): number => parseInt(id));
}

/** Filters out the ALL_VALUE from given array. */
export function removeAllOption(values: any[], allOption: string = ALL_VALUE): any[] {
  return values.filter(x => x !== allOption);
}
/** Filters out the NONE_VALUE from given array. */
export function removeNoneOption(values: any[]): any[] {
  return values.filter(x => x !== NONE_VALUE);
}

/**
 * A callback involved by antd Select to filter select options each time user hits keyboard.
 * @param inputValue
 * @param option
 */
export function filterOption(inputValue: string, option: any): boolean {
  return option.props.children.toLowerCase().includes(inputValue.toLowerCase());
}
