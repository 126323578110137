import { LatLng, Map, Point } from 'leaflet';
import { isTouchDevice } from '../../../components/helpers/helpers';
import { WIDTH } from './constants';

const multiplier = isTouchDevice() ? 1.5 : 1.1;

/**
 * Returns new coordinates of the point [x, y] rotated by angle (represented by sin-cos pair)
 * around the origin [x0, y0].
 */
export function getCoordinatesRotated(
  x0: number, y0: number, x: number, y: number, cos: number, sin: number
): [number, number] {
	return [
		x0 + x * cos - y * sin,
		y0 + x * sin + y * cos
	];
}

/**
 * Determines whether a point is inside the circle.
 * @param point
 * @param center
 * @param map
 */
export function isPointInside(point: Point, center: LatLng, map: Map): boolean {
	if (!center) return false;
	let xy;
	try {
    xy = map.latLngToContainerPoint(center);
  } catch (e) {}

  if (!xy) return false;
	const { x, y } = xy;
  const distance = Math.sqrt(Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2));
	return distance <= multiplier * WIDTH;
}
