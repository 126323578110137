import { TEvent } from '../../../types/event';

export enum EEventEditorActions {
  CREATE_EVENT_BLUEPRINT = 'eventEditor/CREATE_EVENT_BLUEPRINT',
  FETCH_EVENT = 'eventEditor/FETCH_EVENT',
  FETCH_EVENT_BODY = 'eventEditor/FETCH_EVENT_BODY',
  SUBMIT_EVENT = 'eventEditor/SUBMIT_EVENT',

  BANNER_UPLOAD = 'eventEditor/BANNER_UPLOAD',
  BANNER_UPLOAD_PROGRESS = 'eventEditor/BANNER_UPLOAD_PROGRESS',

  ASSIGN_FORM_FIELDS = 'eventEditor/ASSIGN_FORM_FIELDS',
  UPDATE_FIELD = 'eventEditor/UPDATE_FIELD',
  CLEAR_EDITOR = 'eventEditor/CLEAR_EDITOR',

  ADD_SCHEDULE_ITEM = 'eventEditor/ADD_SCHEDULE_ITEM',
  UPDATE_SCHEDULE_ITEM = 'eventEditor/UPDATE_SCHEDULE_ITEM',
  REMOVE_SCHEDULE_ITEM = 'eventEditor/REMOVE_SCHEDULE_ITEM'
}

export const eventBlueprint: Partial<TEvent> = {
  published: false,
  title: '',
  shortDescription: '',
  longDescription: ''
};
