import { basicInitialState } from '../../_utils/basicInitialState';
import { TNewsEditorState } from './newsEditorModel';

export const newsEditorInitialState: TNewsEditorState = {
  ...basicInitialState,
  published: false,
  time: null,
  source: null,
  title: null,
  shortDescription: null,
  id: null,
  language: null,
  languageIso: 'EN',
  longDescription: null,
  longDescriptionMedia: null,
  banner: null,
  logo: null,
  fields: {},
  uploadProgress: null
};
