import { ENDPOINTS } from '../../../other/config';
import { createAsyncActions } from '../../_utils/createAsyncActions';
import http from '../../../services/http';

import { ENewsRecentActions } from './newsRecentConstants';
import { newsRecentInitialState } from './newsRecentInitialState';
import { TNewsRecentState } from './newsRecentModel';
import { TArticle } from '../../../types/article';
import { TPagination } from '../news/newsModel';
import { TState } from '../../appStateModel';

type Response = TPagination & {
  data: TArticle[];
};

/**
 * Retrieves a list of recent news by provider ID.
 * The endpoint returns 4 news, and one of them might be the current article
 * @param {number} providerId
 * @param publishedOnly
 * @param fetchMore
 */
export function fetchRecentNewsAction(
  providerId: number,
  publishedOnly = true,
  fetchMore = false
) {
  return (dispatch, getState) => {
    const {
      newsRecent: { page: currentPage, news: currentNews, size }
    }: TState = getState();
    const page = fetchMore ? currentPage + 1 : currentPage;
    const actions = createAsyncActions<TNewsRecentState>(
      dispatch,
      ENewsRecentActions.NEWS_RECENT_FETCH
    );

    actions.request();

    const url: string = `${ENDPOINTS.NEWS}/provider/${providerId}?page=${page}&publishedOnly=${publishedOnly}&size=${size}`;

    http
      .send(url)
      .then(({ data, page, size, total }: Response) => {
        const result = fetchMore ? [...currentNews, ...data] : data;

        actions.success({
          news: result,
          page,
          size,
          total
        });
      })
      .catch(actions.error);
  };
}

/**
 * Clears recent news.
 */
export const newsRecentClear = {
  type: ENewsRecentActions.NEWS_RECENT_CLEAR,
  payload: newsRecentInitialState
};
