import React from 'react';
import PropTypes from 'prop-types';

import './ContactEditor.scss';
import AbstractEditor from '../other/AbstractEditor';
import ContactForm from './ContactForm';
import ContactList from './ContactList';
import ImageInput2 from '../../../../components/common/inputs/ImageInput2/ImageInput2';
import ModalWrapper from '../../../../components/common/modals/ModalWrapper';
import ProgressBar2 from '../../../../components/common/ProgressBar/ProgressBar2';
import { PROVIDER_IMAGE_URL } from '../../../../other/config';
import { TPerson } from '../../../../types/person';


type Props = {
  contacts: TPerson[];
};


class ContactEditor extends AbstractEditor<TPerson, Props> {
  static propTypes;

  render() {
    const { contacts, onAdd, onHandledObjectUpdate, onRemove, selectedItem, uploadProgress } = this.props;
    const { firstName, lastName, photo, position, telephone, mobile, email } = selectedItem || {};
    const values = {
      firstName,
      lastName,
      position,
      telephone: telephone || '',
      mobile: mobile || '',
      email
    };

    const isModalVisible = !!selectedItem;
    const imgUrl: string = photo
      ? `${PROVIDER_IMAGE_URL}/${selectedItem.photo.path}`
      : null;

    return (
      <div className="ContactEditor">
        <ContactList contacts={contacts} onAdd={onAdd} onEdit={this.handleEdit} onRemove={onRemove} />

        <ModalWrapper isOpen={isModalVisible} onCancel={this.handleCancel} title="Contact info">
          <div className="ContactEditor__main">
            <ImageInput2 imgUrl={imgUrl} onUpload={this.handleUpload}>
              <ProgressBar2 progress={uploadProgress} />
            </ImageInput2>
          </div>

          <ContactForm
            onCancel={this.handleCancel}
            onHandledObjectUpdate={onHandledObjectUpdate}
            onSubmit={this.handleSubmit}
            values={values}
          />
        </ModalWrapper>
      </div>
    );
  }

}


ContactEditor.propTypes = {
  ...AbstractEditor.propTypes,
  contacts: PropTypes.array,
};

export default ContactEditor;
