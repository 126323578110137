import './PointMark.scss';
import convertDDtoDMS, { addEventHandler, closest } from '../utils/helpers';
import { IMAGE_THUMB_URL, NO_IMAGE_PATH } from '../../../other/config';
import { isMobile } from '../../../components/helpers/helpers';
import { TVesselLocation } from '../../../types/vessel';

type Options = {
  isExtended: boolean;
  isInFavourites: boolean;
  onDescriptionClick: (vesselId: number) => void;
  onFavClick: (vessel: TVesselLocation) => void;
  onTitleClick: (e: Event) => void;
  showPicture: boolean;
  vessel: TVesselLocation;
};

export function getVesselPopup({
  isExtended,
  isInFavourites,
  onDescriptionClick,
  onFavClick,
  onTitleClick,
  showPicture,
  vessel
}: Options): HTMLElement {
  const {
    flag,
    id,
    speed,
    latitude: lat,
    longitude: lng,
    lastUpdate,
    name,
    photo,
    time
  } = vessel;
  const latitude = convertDDtoDMS(lat, false);
  const longitude = convertDDtoDMS(lng, true);
  const stamp = new Date(time || lastUpdate);

  const eventName = isMobile() ? 'touchend' : 'click';
  const handleClick = (e) => {
    e.preventDefault();
    onDescriptionClick(id);
  };
  const handleTitleClick = (e) => {
    e.preventDefault();
    closest(e.target, 'PointMark__photo') || onTitleClick(e);
  };
  const handleFav = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    onFavClick(vessel);
  };

  const popup = document.createElement('DIV');
  popup.classList.add('PointMark__info');
  addEventHandler(eventName, popup, handleClick);

  popup.innerHTML = `
    <p><span>Speed</span> ${speed}kn</p>
    <p><span>Latitude</span> ${latitude.deg}&#176; ${latitude.min}&#8242; ${
    latitude.dir
  }</p>
    <p><span>Longitude</span> ${longitude.deg}&#176; ${longitude.min}&#8242; ${
    longitude.dir
  }</p>
    <p>
    	<span>Time</span>
			${stamp.toLocaleDateString('en-GB')}
			${stamp.toLocaleTimeString()}
		</p>
  `;

  if (isExtended) {
    const header = document.createElement('DIV');
    header.classList.add('PointMark');
    addEventHandler(eventName, header, handleTitleClick);
    header.innerHTML = `
		<h3 class="ellipsis PointMark__name" title=${name}>
			<figure class="PointMark__flag">
				<img src="/assets/flags/${flag}.png" alt="Flag" />
			</figure>
			${name}
						  <i class="fas fa-camera" />

		</h3>`;

    if (showPicture) {
      const fig = document.createElement('FIGURE');
      fig.classList.add('PointMark__photo');
      const imgUrl = photo ? `${IMAGE_THUMB_URL}/${photo.path}` : NO_IMAGE_PATH;

      const favIcon = document.createElement('span');
      const className: string[] = [
        'PointMark__fav',
        'icon-custom',
        isInFavourites ? 'icon-star-filled' : 'icon-star'
      ];
      favIcon.classList.add(...className);
      header.append(favIcon);
      addEventHandler(eventName, favIcon, handleFav);

      fig.innerHTML = `<img class="PointMark__vessel" src="${imgUrl}" alt="${name}" />`;
      addEventHandler(eventName, fig, handleClick);
      header.append(fig);
    }
    popup.prepend(header);
  }

  return popup;
}
