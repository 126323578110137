export enum EFleetsActions {
  FETCH_FLEET = 'fleets/FETCH_FLEET',
  CREATE_FLEET = 'fleets/CREATE_FLEET',
  REMOVE_FLEET = 'fleets/REMOVE_FLEET',
  RENAME_FLEET = 'fleets/RENAME_FLEET',

  ADD_VESSEL = 'fleets/ADD_VESSEL',
  REMOVE_VESSEL = 'fleets/REMOVE_VESSEL',
  ASSIGN_VESSELS = 'fleets/ASSIGN_VESSELS'
}
