import { LatLng } from 'leaflet';

import { getPositionForAddress } from '../../../../services/location';
import { LOCALE } from '../../../../other/config';
import { ProviderUtil } from './ProviderUtil';

import {
  EProviderMediaObjectType,
  TProviderAddress,
  TServiceBranch
} from '../../../../types/providers';
import { TCountryISO } from '../../../../types/dictionaries';
import { TPerson } from '../../../../types/person';
import { TProduct } from '../../../../types/product';
import { TProviderEditorState } from '../providerEditor';
import { TVideo } from '../../../../types/media';

/**
 * Util to update an item of provider's addresses/branches/contacts/products/videos lists.
 */
export class ProviderUpdateUtil extends ProviderUtil {
  protected readonly branches: TServiceBranch[];
  protected readonly categories: TServiceBranch[];
  protected readonly countries: TCountryISO[];

  constructor(
    type: EProviderMediaObjectType,
    model: TProviderEditorState,
    refs: {
      countries: TCountryISO[];
      categories: TServiceBranch[];
    }
  ) {
    super(type, model);
    const { categories, countries } = refs;
    this.branches = model.branches;
    this.categories = categories;
    this.countries = countries;
  }

  /**
   * Updates given object within provider instance according to its type.
   */
  async getPartialUpdate(
    obj: Partial<TProviderAddress | TPerson | TProduct | TVideo> | number
  ): Promise<Partial<TProviderEditorState>> {
    switch (this.type) {
      case EProviderMediaObjectType.ADDRESS:
        return {
          addresses: await this.getAddressesUpdate(obj as TProviderAddress)
        };

      case EProviderMediaObjectType.BRANCH:
        return {
          branches: this.getBranchesUpdate(obj as number)
        };

      case EProviderMediaObjectType.CONTACT:
        return {
          contacts: this.getContactsUpdate(obj as TPerson)
        };

      case EProviderMediaObjectType.VIDEO:
        return {
          videos: this.getVideosUpdate(obj as TVideo)
        };
    }
  }

  private getAddressesUpdate(address: TProviderAddress): TProviderAddress[] {
    // The address editor returns country as string abbreviation (iso). So we ask TS not to bark here.
    const targetCountry: TCountryISO = this.countries.find(
      // @ts-ignore
      (c: TCountryISO): boolean => c.iso === address.country
    );

    const _address: Partial<TProviderAddress> = {
      ...address,
      // @ts-ignore
      country: targetCountry.value[LOCALE]
    };

    return getPositionForAddress(_address)
      .catch((err: string) => {
        window.console.error(`Location Service: ${err}`);
      })
      .then((position: LatLng | void) => {
        const updateAddress = (c: TProviderAddress): TProviderAddress => {
          if (c.id !== address.id) return c;
          return {
            ...address,
            ...(position || {}),
            country: targetCountry
          };
        };

        return this.addresses.map(updateAddress);
      });
  }

  private getBranchesUpdate(branchId: number): TServiceBranch[] {
    const isSelected: boolean = !!this.branches.find(
      ({ id }: TServiceBranch): boolean => id === branchId
    );

    if (isSelected) {
      return this.branches.filter(
        ({ id }: TServiceBranch): boolean => id !== branchId
      );
    } else {
      const branch: TServiceBranch = this.categories.find(
        ({ id }: TServiceBranch) => id === branchId
      );
      return [...this.branches, branch];
    }
  }

  private getContactsUpdate(contact: TPerson): TPerson[] {
    return this.contacts.map(
      (c: TPerson): TPerson => {
        if (c.id !== contact.id) return c;
        return contact;
      }
    );
  }

  private getVideosUpdate(video: TVideo): TVideo[] {
    return this.videos.map(
      (v: TVideo): TVideo => {
        if (v.id !== video.id) return v;
        return video;
      }
    );
  }
}
