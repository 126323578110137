import React from 'react';
import PropTypes from 'prop-types';

import './ScheduleEditor.scss';
import { areEqual } from '../../../../../other/helpers';
import ItemForm from './ItemForm';
import ModalWrapper from '../../../../../components/common/modals/ModalWrapper';
import SchedulePane from './SchedulePane';
import { scheduleItemBlueprint } from './helpers';
import { EScheduleItemStatus, TScheduleItem } from '../../../../../types/event';

type TScheduleEditorProps = {
  dates: any; // in fact [Moment, Moment];
  onItemAdd: (item: TScheduleItem) => void;
  onItemRemove: (id: number) => void;
  onItemUpdate: (item: TScheduleItem) => void;
  schedule: TScheduleItem[];
  utcOffset: string;
};
type State = {
  item: TScheduleItem;
};

class ScheduleEditor extends React.PureComponent<TScheduleEditorProps, State> {
  static propTypes;
  state: State = { item: null };

  handleAddItem = () => {
    const item: TScheduleItem = {
      ...scheduleItemBlueprint,
      // @ts-ignore
      id: '__' + Math.random()
    };
    this.props.onItemAdd(item);
    this.handleEdit(item as TScheduleItem);
  };

  handleCancel = () => {
    const { id, ..._item } = this.state.item;
    if (areEqual(scheduleItemBlueprint, _item)) {
      this.props.onItemRemove(id);
    }
    this.setState({ item: null });
  };

  handleEdit = (item: TScheduleItem) => this.setState({ item });

  handleStatusChange = (id: number, status: EScheduleItemStatus) => {
    const { onItemUpdate, schedule } = this.props;
    const target: TScheduleItem = schedule.find(
      (item: TScheduleItem) => item.id === id
    );

    onItemUpdate({
      ...target,
      status: status
    });
  };

  handleSubmit = (item: TScheduleItem) => {
    this.props.onItemUpdate(item);
    this.setState({ item: null });
  };

  render() {
    const { dates, onItemRemove, schedule, utcOffset } = this.props;
    const isModalVisible = !!this.state.item;

    return (
      <div className="ScheduleEditor">
        <SchedulePane
          onItemAdd={this.handleAddItem}
          onEdit={this.handleEdit}
          onItemRemove={onItemRemove}
          onStatus={this.handleStatusChange}
          schedule={schedule}
          utcOffset={utcOffset}
        />

        <ModalWrapper
          isOpen={isModalVisible}
          onCancel={this.handleCancel}
          title="Schedule item"
        >
          <ItemForm
            dates={dates}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            item={this.state.item}
          />
        </ModalWrapper>
      </div>
    );
  }
}

ScheduleEditor.propTypes = {
  dates: PropTypes.array,
  onItemAdd: PropTypes.func.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  onItemUpdate: PropTypes.func.isRequired,
  schedule: PropTypes.array.isRequired
};
export default ScheduleEditor;
