import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import './FleetsListItem.scss';

type Props = {
  isChecked: boolean;
  name: string;
  onToggle: Function;
};

const FleetsListItem = ({ isChecked, name, onToggle }: Props): ReactElement => (
  <li className="FleetsList__item">
    <Checkbox checked={isChecked} onChange={onToggle as any}>
      {name}
    </Checkbox>
  </li>
);

FleetsListItem.propTypes = {
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  onToggle: PropTypes.func.isRequired
};

export default FleetsListItem;
