import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import '../ServiceProvider.scss';
import Expander from '../../../../components/common/Expander/Expander';
import ProductCard from './ProductCard';
import { TProduct } from '../../../../types/product';

type Props = {
  products:  TProduct[];
};


const Products = ({ products }: Props): ReactElement | null => {
  if (!products || !products.length) return null;

  const slides: ReactElement[] = products.map((product: TProduct): ReactElement => (
    <ProductCard key={product.id} product={product} />
  ));

  return (
    <section className="ServiceProvider__group">
      <Expander itemWidth={260} title="Products">
        {slides}
      </Expander>
    </section>
  );
};

Products.propTypes = {
  products: PropTypes.array
};

export default React.memo(Products);
