import {
  ActionFactory,
  deepClone,
  errorAction,
  THttpResponse,
  okAction
} from 'react-fishfacts/dist';

import { createAsyncActions } from '../_utils/createAsyncActions';
import { defaultFilterSettings } from './serviceBranchesInitialState';
import {
  ENDPOINTS,
  LOCALE,
  PROVIDER_IMAGE_URL,
  ROUTES
} from '../../other/config';
import {
  PROVIDERS_FILTER_BY_COUNTRY,
  writeSettings
} from '../../services/settings';
import http from '../../services/http';
import store from '../store';

import {
  BASIC_HOME_CATEGORIES,
  EProvidersFilterActions,
  EServiceBranchesActions
} from './serviceBranchesConstants';
import { TNavigationCategory } from '../../types/navigation';
import {
  TProvider,
  TProviderAddress,
  TServiceBranch
} from '../../types/providers';
import { TProviderCountryFilterSettings } from '../../types/providerFilters';
import { TServiceBranchesState } from './serviceBranchesModel';
import { TServiceBranchGroup, TServiceCategory } from '../../types/service';
import { TState } from '../appStateModel';
import { EUserAuthorities, TUser } from '../session/sessionModel';

export let fetchSet;
const af = new ActionFactory<TServiceBranchesState, EServiceBranchesActions>(
  store
);
const fetchProvidersSet = af.createAsyncActions(
  EServiceBranchesActions.FETCH_PROVIDERS
);

/** Fetches all the branches at app start to display them in the aside navigation. */
export function fetchServiceBranchesAction() {
  return (dispatch, getState) => {
    const {
      session: { user }
    } = getState() as TState;

    fetchSet = createAsyncActions<TServiceBranchesState>(
      dispatch,
      EServiceBranchesActions.FETCH
    ).request();

    http
      .send(ENDPOINTS.SERVICE_BRANCHES)
      .then((resp: THttpResponse<TServiceBranchGroup[]>) => {
        const cats: TNavigationCategory[] = BASIC_HOME_CATEGORIES.concat(
          branches2nav(resp.data)
        );

        fetchSet.success({
          navigationCategories: filterCategories(cats, user),
          serviceBranches: resp.data,
          serviceCategories: serviceBranches2Categories(resp.data)
        });
      })
      .catch(fetchSet.error);
  };
}

/** Picks certain branch. */
export function pickServiceBranchAction(id: number) {
  return (dispatch, getState) => {
    const {
      serviceBranches: { serviceBranches }
    } = getState() as TState;

    if (!serviceBranches || serviceBranches.length === 0) {
      return setTimeout(() => dispatch(pickServiceBranchAction(id)), 150);
    }

    const branch = serviceBranches.find(
      ({ branch }: TServiceBranchGroup) => id === branch.id
    );
    dispatch({
      type: EServiceBranchesActions.PICK_BRANCH,
      payload: { branch }
    });
  };
}

/** Fetches providers and associates them with service category. */
export function getServiceCategoryAction(categoryId: number) {
  return (dispatch, getState) => {
    const {
      serviceBranches: {
        providerFilterSettings,
        serviceBranches,
        serviceProviders
      }
    } = getState() as TState;
    if (!serviceProviders)
      return dispatch(fetchServiceProvidersAction(categoryId));

    let subBranch = { branch: null };
    for (let serviceBranch of serviceBranches) {
      subBranch = serviceBranch.subBranches.find(
        ({ branch }: TServiceBranchGroup) => branch.id === categoryId
      );
      if (subBranch) break;
    }

    const category = {
      ...deepClone(subBranch.branch),
      providers: serviceProviders.filter(({ branches }: TProvider) =>
        branches.map(({ id }: TServiceBranch) => id).includes(categoryId)
      )
    };

    dispatch({
      type: EServiceBranchesActions.GET_CATEGORY,
      payload: { category }
    });

    dispatch(applyServiceCategoryFilter(providerFilterSettings));
  };
}

/** Fetches providers. */
export function fetchServiceProvidersAction(categoryId) {
  return async (dispatch) => {
    fetchProvidersSet.request();

    try {
      const url = ENDPOINTS.SERVICE_PROVIDER;
      const {
        data: serviceProviders
      }: THttpResponse<TProvider[]> = await http.send(url);

      fetchProvidersSet.success({ serviceProviders });
      dispatch(getServiceCategoryAction(categoryId));
    } catch (e) {
      fetchProvidersSet.error(e.message);

      // in case of error make another call
      setTimeout(() => dispatch(getServiceCategoryAction(categoryId), 750));
    }
  };
}

export function filterProviderAction(
  action: EProvidersFilterActions,
  values: any
) {
  return (dispatch, getState) => {
    const {
      serviceBranches: { providerFilterSettings }
    } = getState() as TState;
    const settings: TProviderCountryFilterSettings = {
      ...providerFilterSettings,
      ...getFilterSettingsUpdate(action, values)
    };

    writeSettings({ [PROVIDERS_FILTER_BY_COUNTRY]: settings });
    dispatch(
      okAction(EServiceBranchesActions.SET_FILTER, {
        providerFilterSettings: settings
      })
    );
    dispatch(applyServiceCategoryFilter(settings));
  };
}

/** Filters providers by country. */
function applyServiceCategoryFilter(settings: TProviderCountryFilterSettings) {
  return (dispatch, getState) => {
    const {
      serviceBranches: { category },
      session: { user }
    } = getState() as TState;

    if (!category) return;
    // ids of providers, created by user
    const providerIds: ReadonlyArray<number> =
      (user && user.userInfo.serviceProvidersId) || [];

    try {
      const categoryFiltered: TServiceCategory = deepClone(category);
      // filtered by provider filter
      const providers: TProvider[] = applyFilter(settings, category.providers);

      // filtered by published flag or user saved drafts
      categoryFiltered.providers = filterProvidersByPublishFlag(
        providers,
        providerIds
      ) as any;
      dispatch(
        okAction(EServiceBranchesActions.APPLY_FILTER, { categoryFiltered })
      );
    } catch (e) {
      dispatch(errorAction(EServiceBranchesActions.FILTER_FAILED, e));
    }
  };
}

export function resetServiceCategoryFilterAction() {
  return (dispatch) => {
    writeSettings({ [PROVIDERS_FILTER_BY_COUNTRY]: defaultFilterSettings });
    dispatch(
      okAction(EServiceBranchesActions.SET_FILTER, {
        providerFilterSettings: defaultFilterSettings
      })
    );
    dispatch(applyServiceCategoryFilter(defaultFilterSettings));
  };
}

// HELPERS SECTION
const countryFilter = (countries: string[]) => (item: TProvider): boolean => {
  if (!countries.length) return true;
  if (!item.addresses.length) return false;

  const selectedAddresses: TProviderAddress[] = item.addresses.filter(
    (addr: TProviderAddress) =>
      countries.includes(addr.country && addr.country.iso)
  );
  return !!selectedAddresses.length;
};
const filterMap = {
  providerFilterCountries: countryFilter
};
function applyFilter(
  settings: TProviderCountryFilterSettings,
  providers: TProvider[]
): TProvider[] {
  let arr: TProvider[] = deepClone(providers);

  Object.keys(settings).forEach((key: string) => {
    arr = arr.filter(filterMap[key](settings[key]));
  });
  return arr;
}

// Passes through only published providers, or user's provider drafts
function filterProvidersByPublishFlag(
  providers: TProvider[],
  providerIds: ReadonlyArray<number>
): TProvider[] {
  if (!providers) return providers;

  return providers.filter(
    ({ id, published }: TProvider) => published || providerIds.includes(id)
  );
}

/** Return a partial update of the providers filter. */
function getFilterSettingsUpdate(
  actionType: EProvidersFilterActions,
  value: any
): Partial<TProviderCountryFilterSettings> {
  switch (actionType) {
    case EProvidersFilterActions.FILTER_BY_COUNTRIES:
      return { providerFilterCountries: value };
    default:
      throw new Error('Unknown ProviderFilter action type: ' + actionType);
  }
}

const mapCategory = ({ branch }: TServiceBranchGroup): TNavigationCategory => {
  const { id, media, value } = branch;
  return {
    path: `${ROUTES.SERVICE_BRANCH}/${id}`,
    imageSrc: `${PROVIDER_IMAGE_URL}/${media.path}`,
    title: value[LOCALE]
  };
};

/**
 * Converts branches into a list of navigation categories.
 */
function branches2nav(branches: TServiceBranchGroup[]): TNavigationCategory[] {
  return branches ? branches.map(mapCategory) : [];
}

/**
 * Extracts subBranches into a list of categories.
 */
function serviceBranches2Categories(
  branches: TServiceBranchGroup[]
): TServiceCategory[] {
  const categories = [];
  if (!branches) return [];

  branches.forEach(({ subBranches }: TServiceBranchGroup) =>
    categories.push(...subBranches)
  );

  return categories.map(({ branch }: TServiceBranchGroup) => branch);
}

/* Filters the categories according to the user's permissions. */
function filterCategories(
  categories: TNavigationCategory[],
  user: TUser
): TNavigationCategory[] {
  const authorities = user ? user.userInfo.authorities : [];

  const callback = ({ requiredPermissions }: TNavigationCategory): boolean => {
    if (!requiredPermissions || requiredPermissions.length === 0) return true;

    return requiredPermissions.every((permission: EUserAuthorities): boolean =>
      authorities.includes(permission)
    );
  };

  return categories.filter(callback);
}
