import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './NewsArticle.scss';
import { ARTICLE_IMAGE_URL, ROUTES } from '../../../other/config';
import ArticleBanner from '../../../components/common/articles/ArticleBanner/ArticleBanner';
import ArticleTitle from '../../../components/common/articles/ArticleTitle/ArticleTitle';
import ContactUsButton from '../../../components/common/buttons/ContactUsButton/ContactUsButton';
import FBShareButton from '../../../components/common/buttons/FBShareButton/FBShareButton';
import GoBackButton from '../../../components/common/buttons/GoBackButton/GoBackButton';
import { ModalPhoto } from '../../../components/common/modals/ModalPhoto';
import PromoButton from '../../../components/common/buttons/PromoButton/PromoButton';
import RecentArticles from '../../../components/common/articles/RecentArticles/RecentArticles';

import { TArticle } from '../../../types/article';
import { EPromoStatus } from '../../../types/promotion';

type Props = {
  article: TArticle;
  canEdit: boolean;
  editArticle: () => void;
  removeArticle: () => void;
  status?: EPromoStatus;
};

const NewsArticle = (props: Props): ReactElement | null => {
  const { article, canEdit, editArticle, removeArticle, status } = props;
  const {
    banner,
    id,
    longDescription,
    published,
    shortDescription,
    source,
    time,
    title
  } = article;

  const handleBodyClick = (e) => {
    if (e.target.tagName !== 'IMG') return;
    new ModalPhoto(e.target.src);
  };

  const bannerUrl = banner
    ? `${ARTICLE_IMAGE_URL}/${banner.path}`
    : '/assets/placeholders/banner_placeholder.svg';

  const description: ReactNode = shortDescription && (
    <div className="NewsArticle__summary">
      <p>{shortDescription}</p>
    </div>
  );
  const html = {
    __html:
      typeof longDescription === 'string'
        ? longDescription
        : `<pre><code class="app-error-msg">${JSON.stringify(
            longDescription,
            null,
            2
          )}</code></pre>`
  };

  return (
    <section className="NewsArticle">
      <section className="NewsArticle__main">
        <ArticleTitle source={source} time={time} title={title} />
        <ArticleBanner
          canEdit={canEdit}
          imgUrl={bannerUrl}
          isDraft={!published}
          onEdit={editArticle}
          onRemove={removeArticle}
          source={source}
        />
        <FBShareButton />

        <div className="NewsArticle__actionButtons">
          {canEdit ? (
            <PromoButton articleId={id} status={status} />
          ) : (
            <ContactUsButton serviceProviderId={article.source.id} />
          )}
        </div>

        <div className="NewsArticle__description">
          {description}
          <div
            className="NewsArticle__body"
            dangerouslySetInnerHTML={html}
            onClick={handleBodyClick}
          />
        </div>
      </section>

      <div className="app-d-flex app-justify-content-end app-pb-4">
        <GoBackButton pathBack={ROUTES.NEWS} />
      </div>

      <RecentArticles
        articleId={article.id}
        name={article.source.name}
        providerId={article.source.id}
      />
    </section>
  );
};

NewsArticle.propTypes = {
  article: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    longDescription: PropTypes.string,
    shortDescription: PropTypes.string,
    source: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }),
    time: PropTypes.string,
    title: PropTypes.string
  }),
  canEdit: PropTypes.bool,
  editArticle: PropTypes.func.isRequired,
  removeArticle: PropTypes.func,
  news: PropTypes.array
};

export default NewsArticle;
