// eslint-disable-next-line
import * as __ from './services/polyfills'; // NEVER REMOVE!
import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import './index.scss';
import 'antd/dist/antd.css';
import App from './App';
import { runSessionService } from './services/session';
import { startTracking } from './services/tracker';
import store, { history } from './store/store';

startTracking();
runSessionService();

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

render(app, document.getElementById('app'));
