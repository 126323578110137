import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import {
  TFormField,
  TFormFieldError,
  TFormFields
} from '../../types/formFields';

/**
 * Returns true in case of valid fields. Otherwise shows a modal and returns undefined.
 * @param fields
 */
export function showValidationWarning(fields: TFormFields): boolean | void {
  const errArr = extractErrors(fields);
  if (errArr.length === 0) return true;

  Modal.error({
    title: 'Validation errors:',
    content: formatErrors(errArr)
  });
}

export function extractErrors(fields: TFormFields): TFormFieldError[] {
  // @ts-ignore
  return Object.values(fields)
    .map(({ errors, name }: TFormField): TFormFieldError | void => {
      if (errors.length === 0) return null;
      return {
        field: name[0],
        message: errors[0]
      };
    })
    .filter(Boolean);
}

function formatErrors(errors: TFormFieldError[]): ReactNode {
  return (
    <div className="ValidationErrors">
      <h4 className="ValidationErrors__header">
        Cannot submit due to following errors:
      </h4>

      <ul className="ValidationErrors__list">
        {errors.map(({ field, message }: TFormFieldError) => (
          <li className="ValidationErrors__item" key={field}>
            <b>{field}:</b> {message}
          </li>
        ))}
      </ul>

      <p>
        <i>You still can save your changes as a draft.</i>
      </p>
    </div>
  );
}
