import { TArticle } from './article';
import { TCountryISO, TVesselType } from './dictionaries';
import { TMedia } from './media';
import { TServiceCategory } from './service';

export type TPromoted = {
  bannerUrl?: string;
  countries: TCountryISO[];
  end: string;
  filtersBanner?: TMedia;
  id: number;
  menuBanner?: TMedia;
  mobileBanner?: TMedia;
  name: string;
  news?: TArticle;
  newsBanner?: TMedia;
  serviceCategories: TServiceCategory[];
  start: string;
  type: EPromotedType;
  userId: number;
  utcOffset: string;
  vesselTypes: TVesselType[];
};

export enum EPromotedType {
  BANNER = 'BANNER',
  NEWS = 'NEWS'
}

export type TPromotion = {
  countries: string[];
  end: string;
  newsId: number;
  serviceCategories: number[];
  start: string;
  type: EPromotedType;
  utcOffset: string;
  vesselTypes: number[];
};

export type TPromotionCost = {
  amount: number;
  currency: string;
  rate: number;
};

export type TPaymentReport = {
  checkoutLink: string;
  creationDate: string;
  paymentId: number;
  promotionId: number;
  status: EPaymentStatus;
};

export type TShortPaymentReport = {
  isOutdated: boolean;
  message: string;
  method: 'info' | 'warning' | 'error' | 'success';
  promotionId: number;
  status: EPaymentStatus;
};

export enum EPaymentStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED'
}

export enum EPromoStatus {
  POTENTIAL = 'POTENTIAL',
  PROMOTED = 'PROMOTED',
  UNABLE = 'UNABLE'
}
