import React from 'react';
import PropTypes from 'prop-types';
import './ShipIcon.scss';


const DEFAULT_COLOR = 'Grey';


const ShipIcon = ({ colors: [color1 = DEFAULT_COLOR, color2 = DEFAULT_COLOR], style, partStyle }) => {

  const styleLeft = { ...partStyle, backgroundColor: color1 };
  const styleRight = { ...partStyle, backgroundColor: color2 };

  return (
    <div className="ShipIcon" style={style}>
      <div className="ShipIcon__left" style={styleLeft} />
      <div className="ShipIcon__right" style={styleRight} />
    </div>
  );
};


ShipIcon.propTypes = {
  colors: PropTypes.array,
	partStyle: PropTypes.object,
  style: PropTypes.object,
};
ShipIcon.defaultProps = {
  colors: [],
	partStyle: {},
	style: {},
};

export default ShipIcon;
