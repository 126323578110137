import * as queryString from 'querystring';
import { readData, removeItem, writeData } from './storage';
import { TCredentials } from '../types/credentials';

const AUTH_KEY = 'FF_COM_AUTH';
enum EAuthKeys {
  CREDENTIALS = 'CREDENTIALS',
  KEEP_LOGGED = 'KEEP_LOGGED',
  TOKEN = 'TOKEN'
}

/**
 * Compulsory logging out on leaving the site.
 */
export function scheduleLogout() {
  window.addEventListener(
    'beforeunload',
    () => shouldKeepLogged() || clearAuthSettings()
  );
}

/**
 * Sets keep logged flag.
 * @param keepLogged
 */
export function setKeepLogged(keepLogged: boolean): void {
  writeSettings({ [EAuthKeys.KEEP_LOGGED]: keepLogged });
}
export function shouldKeepLogged(): boolean {
  return readAuthSettings()[EAuthKeys.KEEP_LOGGED] === true;
}

export function saveToken(token?: string): void {
  writeSettings({ [EAuthKeys.TOKEN]: token });
}
export function getToken(): string {
  const written = readAuthSettings()[EAuthKeys.TOKEN];
  if (written) return written;
}

export function getCredentialsFromURL() {
  const search = window.location.hash.split('?')[1];
  const { auth_token, username } = queryString.parse(search);

  if (auth_token && username) {
    username && saveCredentials({ username: username } as any);
    return { username: username, password: auth_token } as any;
  }
}

export function saveCredentials(credentials: TCredentials): void {
  writeSettings({ [EAuthKeys.CREDENTIALS]: credentials });
}
export function getCredentials(): TCredentials | void {
  const credentials = readAuthSettings()[EAuthKeys.CREDENTIALS];
  if (Object.keys(credentials).length) {
    return credentials;
  }
}

export function getCredentialsIfPreserved(): TCredentials | void {
  const credentialsFromURL = getCredentialsFromURL();

  if (credentialsFromURL) {
    return credentialsFromURL;
  } else {
    if (!shouldKeepLogged()) return;
    return getCredentials();
  }
}

/**
 * A couple of basic methods.
 */
export function readAuthSettings(): any {
  // todo remove old auth key read in v3.3
  const oldAuthSet = readData('auth');
  if (oldAuthSet) {
    writeData(AUTH_KEY, oldAuthSet);
    removeItem('auth');
    return oldAuthSet;
  }

  return readData(AUTH_KEY) || {};
}
export function writeSettings(data: { [key: string]: any }): any {
  const current = readAuthSettings();
  return writeData(AUTH_KEY, { ...current, ...data });
}
export function clearAuthSettings(): void {
  removeItem(AUTH_KEY);
}
