import { connect } from 'react-redux';

import './NewsList.scss';
import {
  clearNewsAction,
  fetchNewsAction,
  saveScrollAction
} from '../../../store/news/news/newsActions';
import { fetchPromotedAction } from '../../../store/promo/promoActions';
import NewsListWrapper from './NewsListWrapper';
import { selectArticleIds, shuffleArray } from './helpers';

import { TArticle } from '../../../types/article';
import { TState } from '../../../store/appStateModel';
import { TPromoted } from '../../../types/promotion';

const mapStateToProps = (state: TState) => {
  const {
    news: { isPending, news, scrollTop },
    promo: { articles },
    session: { user }
  } = state;

  const newsIds: ReadonlyArray<number> = user ? user.userInfo.newsId || [] : [];
  const serviceIds: ReadonlyArray<number> = user
    ? user.userInfo.serviceProvidersId || []
    : [];
  const promotedArticles = shuffleArray(
    articles.filter(({ news }: TPromoted): boolean => news.published)
  );

  return {
    hasServices: serviceIds.length > 0,
    isLoading: isPending,
    list:
      news &&
      news.filter(
        (article: TArticle) =>
          // user can see only published or their own drafts
          article.published || newsIds.includes(article.id)
      ),
    promotedArticles: promotedArticles,
    scrollTop: scrollTop || 0,
    // A list of news articles (that belong to the current user) that are not promoted yet,
    // so can potentially be promoted
    usersNews: selectArticleIds({
      articles: articles,
      newsIds: newsIds
    })
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearNews: () => dispatch(clearNewsAction),
  fetchPromoted: () => dispatch(fetchPromotedAction()),
  getNews: (...args) => dispatch(fetchNewsAction(...args)),
  saveScrollTop: (scrt) => dispatch(saveScrollAction(scrt))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsListWrapper);
