import React from 'react';
import PropTypes from 'prop-types';
import { FormInstance } from 'antd/lib/form';
import { LoaderOverlay } from 'react-fishfacts/dist';
import { Prompt } from 'react-router-dom';

import ArticleEditor from './ArticleEditor/ArticleEditor';
import { editorProps } from './helpers';
import Promotion from './Promotion/Promotion';
import { ROUTES } from '../../../other/config';
import { showValidationWarning } from '../../../other/formAndValidation/showValidationWarning';

import { TArticleEditorProps } from './helpers';
import { TNewsEditorState } from '../../../store/news/newsEditor/newsEditorModel';

const confirmation = () => `Discard changes and leave the page?`;
type Props = TNewsEditorState &
  TArticleEditorProps & {
    articleId: number;
    fetchPromoted: () => void;
    getArticle: (articleId: number) => void;
    isEmpty: boolean;
    isModified: boolean;
    location: Location;
    onLeave: () => void;
    onRemove: (articleId: number) => void;
  };

class NewsEditorWrapper extends React.Component<Props> {
  static propTypes;
  private form: FormInstance;

  componentDidMount() {
    const { articleId, fetchPromoted, getArticle } = this.props;
    fetchPromoted();
    getArticle(articleId);
  }

  componentWillUnmount(): void {
    const { id, isEmpty, onLeave, onRemove } = this.props;
    isEmpty && onRemove(id);
    onLeave();
  }

  assignForm = (el) => el && (this.form = el.form);

  assignPromotion = () => {
    this.props.location.hash &&
      setTimeout(() => {
        const form = document.getElementById(ROUTES.PROMO_FORM);
        form && form.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
  };

  handleSubmit = (isDraft?: boolean, isPromotion?: boolean): void => {
    const { fields, onSubmit } = this.props;
    if (isDraft) return onSubmit(true, false);
    showValidationWarning(fields) && onSubmit(false, isPromotion);
  };

  handlePay = () => {
    const cb = () => this.handleSubmit(false, true);
    this.form.validateFields().then(cb).catch(cb);
  };

  render() {
    const {
      isModified,
      isPending,
      getArticle,
      onSubmit,
      ...restOfProps
    } = this.props;
    const isLoading: boolean = isPending && !restOfProps.id;
    const editorProps = {
      ...restOfProps,
      isModified
    };

    return (
      <LoaderOverlay isLoading={isLoading}>
        <Prompt when={isModified} message={confirmation} />
        {restOfProps.id && (
          <>
            <ArticleEditor
              onSubmit={this.handleSubmit}
              ref={this.assignForm}
              {...editorProps}
            />
            <Promotion
              articleId={restOfProps.id}
              onPay={this.handlePay}
              ref={this.assignPromotion}
            />
          </>
        )}
      </LoaderOverlay>
    );
  }
}

NewsEditorWrapper.propTypes = {
  ...editorProps,
  articleId: PropTypes.number,
  fetchPromoted: PropTypes.func.isRequired,
  getArticle: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool,
  isModified: PropTypes.bool,
  onLeave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default NewsEditorWrapper;
