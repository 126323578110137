import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './AddressList.scss';
import AddressCard from '../../Service/Locations/AddressCard';
import { areEqual } from '../../../../other/helpers';
import CardWrapper from '../other/CardWrapper';
import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import Expander from '../../../../components/common/Expander/Expander';
import { TProviderAddress } from '../../../../types/providers';

type Props = {
  addresses: TProviderAddress[];
  onAdd: () => void;
  onEdit: (item: TProviderAddress) => void;
  onRemove: (id: number) => void;
};
type State = {
  isExpanded: boolean;
};

class AddressList extends React.PureComponent<Props, State> {
  state: State = { isExpanded: false };
  static propTypes;

  /** We want the list to be expanded when we add\remove a location */
  componentDidUpdate(prevProps: Props) {
    const { addresses } = this.props;

    if (
      addresses &&
      prevProps.addresses &&
      !areEqual(addresses, prevProps.addresses)
    ) {
      this.setState({ isExpanded: true });
    }
  }

  render() {
    const { addresses, onAdd, onEdit, onRemove } = this.props;

    const slides =
      addresses && addresses.length ? (
        addresses.map(
          (address: TProviderAddress): ReactElement => {
            const handleEdit = () => onEdit(address);

            return (
              <CardWrapper
                id={address.id}
                key={address.id}
                onEdit={handleEdit}
                onRemove={onRemove}
              >
                <>
                  <div className="AddressList__stub" />
                  <AddressCard location={address} />
                </>
              </CardWrapper>
            );
          }
        )
      ) : (
        <EmptyList
          placeholder="Add your first location"
          placeholderImgUrl="/assets/placeholders/location_card_placeholder.svg"
        />
      );

    return (
      <section className="ServiceProvider__group">
        <Expander
          buttonText="Add locations"
          isExpanded={this.state.isExpanded}
          itemWidth={220}
          onAdd={onAdd}
          title="Location"
        >
          {slides}
        </Expander>
      </section>
    );
  }
}

AddressList.propTypes = {
  addresses: PropTypes.array,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default AddressList;
