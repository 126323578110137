import { ENDPOINTS } from '../../other/config';
import {
  ESearchCategory,
  TSearchItemExtended,
  TSearchResult
} from '../../types/search';
import { EUserAuthorities } from '../session/sessionModel';
import { TSearchState } from './searchModel';
import { TState } from '../appStateModel';

export class SearchRequestUtil {
  private readonly getState: () => TState;
  private canViewCompanies: boolean;

  constructor(getState) {
    this.getState = getState;
  }

  getRequestParams(searchQuery: string): string {
    return `${ENDPOINTS.SEARCH}?text=${encodeURIComponent(searchQuery)}`;
  }

  getPayload(data: TSearchResult[]): Partial<TSearchState> {
    const {
      session: { user }
    }: TState = this.getState();
    const rights: ReadonlyArray<EUserAuthorities> = user
      ? user.userInfo.authorities
      : [];
    this.canViewCompanies = rights.includes(EUserAuthorities.VIEW_OWNER);

    return {
      searchResults: this.filterResults(data)
    };
  }

  // If user isn't allowed to view companies, remove them from the results.
  private filterResults(results: TSearchResult[]): TSearchResult[] {
    if (!results) return;
    if (this.canViewCompanies) return results;

    return results.filter(
      ({ type }: TSearchResult): boolean =>
        type.toUpperCase() !== ESearchCategory.COMPANY &&
        type.toUpperCase() !== ESearchCategory.PERSON
    );
  }
}

export function getItemRoute(item: TSearchItemExtended): string {
  const { category, id } = item;
  return `/${category.toLowerCase().replace('_', '/')}/${id}`;
}
