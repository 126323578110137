import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './Article.scss';
import ArticleTitle from './ArticleTitle/ArticleTitle';
import { ModalPhoto } from '../modals/ModalPhoto';

import { TArticle } from '../../../types/article';
import { TProduct } from '../../../types/product';

type Props = {
  article: TArticle | TProduct;
  children: ReactElement;
};

const Article = (props: Props): ReactElement => {
  const { article, children } = props;
  const {
    longDescription,
    shortDescription,
    source,
    time,
    title
  } = article as any;

  const handleBodyClick = (e) => {
    if (e.target.tagName !== 'IMG') return;
    new ModalPhoto(e.target.src);
  };

  const description: ReactNode = shortDescription && (
    <div className="Article__summary">
      <p>{shortDescription}</p>
    </div>
  );
  const html = {
    __html:
      typeof longDescription === 'string'
        ? longDescription
        : `<pre><code class="app-error-msg">${JSON.stringify(
            longDescription,
            null,
            2
          )}</code></pre>`
  };

  return (
    <section className="Article">
      <ArticleTitle source={source} time={time} title={title} />
      {children}

      <div className="Article__description">
        {description}
        <div
          className="Article__body"
          dangerouslySetInnerHTML={html}
          onClick={handleBodyClick}
        />
      </div>
    </section>
  );
};

Article.propTypes = {
  article: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    longDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    published: PropTypes.bool,
    shortDescription: PropTypes.string,
    source: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }),
    time: PropTypes.string,
    title: PropTypes.string
  }).isRequired
};

export default Article;
