import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import '../ServiceProvider.scss';
import ContactCard from './ContactCard';
import Expander from '../../../../components/common/Expander/Expander';
import { TPerson } from '../../../../types/person';

type Props = {
  contacts: TPerson[];
};


const Contacts: React.FC<Props> = ({ contacts }): ReactElement | null => {
  if (!contacts || !contacts.length) return null;

  const slides: ReactElement[] = contacts.map((contact: TPerson): ReactElement => (
    <ContactCard key={contact.id} contact={contact} />
  ));

  return (
    <section className="ServiceProvider__group">
      <Expander itemWidth={220} title="Contacts">
        {slides}
      </Expander>
    </section>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.array
};

export default React.memo(Contacts);
