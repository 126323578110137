import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm } from 'antd';

import './Confirmation.scss';
import { THandler } from '../../../types/handlers';

type Props = {
  children: ReactNode | ReactNode[];
  onConfirm: THandler;
  text: string | ReactNode;
  title: string;
  btnType?: 'link' | 'primary';
};

const Confirmation = ({
  children,
  onConfirm,
  text,
  title,
  btnType,
}: Props): ReactElement => {
  // const icon = <Icon className="Confirmation__icon" type="info-circle" />;

  const message = (
    <div className="Confirmation__content">
      <h3 className="Confirmation__confirmTitle">{title}</h3>
      {text}
    </div>
  );

  return (
    <Popconfirm
      // cancelText="Cancel"
      className="Confirmation"
      // icon={icon}
      // okText="Confirm"
      onConfirm={onConfirm}
      title={message}
    >
      <Button className="Confirmation__button" type={btnType}>
        {children}
      </Button>
    </Popconfirm>
  );
};

Confirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string.isRequired,
};

export default Confirmation;
