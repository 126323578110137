import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './ArticleBanner.scss';
import ArticleLogo from 'components/common/logos/ArticleLogo/ArticleLogo';
import BannerMenuOverlay from 'components/common/overlays/BannerMenuOverlay/BannerMenuOverlay';
import CommonBanner from 'components/common/banners/CommonBanner';
import { TArticleSource } from 'types/article';

type Props = {
  canEdit: boolean;
  imgUrl: string;
  isDraft: boolean;
  onEdit: () => void;
  onRemove: () => void;
  source: TArticleSource;
};

const ArticleBanner: React.FC<Props> = ({
  canEdit,
  imgUrl,
  isDraft,
  onEdit,
  onRemove,
  source
}): ReactElement => {
  const actions: ReactElement = (
    <div className="ArticleBanner__actions">
      <Button className="ArticleBanner__button" onClick={onEdit} type="link">
        Edit
      </Button>
      <div className="ArticleBanner__divider" />
      <Button className="ArticleBanner__button" onClick={onRemove} type="link">
        Delete
      </Button>
    </div>
  );

  return (
    <div className="ArticleBanner">
      <CommonBanner className="" imgUrl={imgUrl} isDraft={isDraft} />
      <ArticleLogo source={source} />

      {canEdit && <BannerMenuOverlay actions={actions} />}
    </div>
  );
};

ArticleBanner.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  imgUrl: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  // @ts-ignore
  source: PropTypes.object.isRequired
};

export default ArticleBanner;
