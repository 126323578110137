import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';

import { fetchPerson } from '../../store/person/personActions';
import LoaderOverlay from '../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import PersonCard from './PersonCard/PersonCard';

import { TBusinessPerson } from '../../types/person';
import { TState } from '../../store/appStateModel';

type Props = {
  fetchPerson: Function;
  isLoading: boolean;
  person: TBusinessPerson;
  personId: number;
};

class PersonPage extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { fetchPerson, personId } = this.props;
    fetchPerson(personId);
  }
  componentDidUpdate(prevProps: Props) {
    const { fetchPerson, personId } = this.props;
    if (prevProps.personId !== personId) {
      fetchPerson(personId);
    }
  }

  render() {
    const { isLoading, person } = this.props;

    return (
      <LoaderOverlay className="PersonPage" isLoading={isLoading}>
        {person ? <PersonCard person={person} /> : <h4>Not found</h4>}
      </LoaderOverlay>
    );
  }
}

PersonPage.propTypes = {
  fetchPerson: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  person: PropTypes.object,
  personId: PropTypes.number.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPerson: fetchPerson
    },
    dispatch
  );

const mapStateToProps = (state: TState, ownProps: { match: RouteProps }) => {
  const {
    person: { isPending, person }
  } = state;

  return {
    isLoading: isPending,
    person: person,
    personId: parseInt(ownProps.match.params.id)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonPage);
