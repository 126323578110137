import {
  EProviderMediaObjectType,
  TProviderAddress
} from '../../../../types/providers';
import { THandledObject, TProviderEditorState } from '../providerEditor';
import { TPerson } from '../../../../types/person';
import { TProduct } from '../../../../types/product';
import { TVideo } from '../../../../types/media';

/**
 * A base provider helper util class.
 */
export abstract class ProviderUtil {
  protected readonly addresses: TProviderAddress[];
  protected readonly contacts: TPerson[];
  protected readonly handledObject: THandledObject;
  protected readonly products: TProduct[];
  protected readonly type: EProviderMediaObjectType;
  protected readonly videos: TVideo[];

  constructor(type: EProviderMediaObjectType, model: TProviderEditorState) {
    if (!Object.keys(EProviderMediaObjectType).includes(type)) {
      throw new TypeError(`Bad media object type: ${type}`);
    }

    const { addresses, contacts, handledObject, products, videos } = model;

    this.addresses = addresses;
    this.contacts = contacts;
    this.handledObject = handledObject;
    this.products = products;
    this.type = type;
    this.videos = videos;
  }
}
