import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  IconCustom,
  isMobile,
  VesselCard as Card,
  TVessel
} from 'react-fishfacts/dist';

import './VesselCard.scss';
import { MEDIA_BASE_URL, NO_IMAGE_PATH } from 'other/config';

type Props = {
  isInFavourites: boolean;
  onFavClick: (vessel) => void;
  vesselsComparator?: {
    vesselA: Partial<TVessel>;
    vesselB: Partial<TVessel>;
  };
  vesselComparisonAdd: (p: { name: any; id: any }) => void;
  vesselComparisonRemove: (id: any) => void;
  vessel: TVessel;
};

const VesselCard = ({
  isInFavourites,
  onFavClick,
  vessel,
  // @ts-ignore
  vesselsComparator: { vesselA, vesselB } = {},
  vesselComparisonAdd,
  vesselComparisonRemove
}: Props): ReactElement | null => {
  if (!vessel) return null;

  const showCompare = !isMobile();
  const iconName = isInFavourites ? 'star-filled' : 'star';
  const isOneOf =
    (vesselA && vessel.id === vesselA.id) ||
    (vesselB && vessel.id === vesselB.id) ||
    false;

  const handleCheckbox = (event) => {
    const shouldAdd = event.target.checked;
    const { name, id } = vessel;
    event.stopPropagation();
    shouldAdd ? vesselComparisonAdd({ name, id }) : vesselComparisonRemove(id);
  };

  const handleFav = () => onFavClick(vessel);

  const checkInput = showCompare ? (
    <div className="VesselCardWrapper__title">
      <label>
        <input type="checkbox" checked={isOneOf} onChange={handleCheckbox} />
        <span className="VesselCardWrapper__compareMsg">
          {isOneOf ? 'Remove from compare' : 'Add to compare'}
        </span>
      </label>
    </div>
  ) : null;

  const compClass: string = classnames('VesselCardWrapper__compare', {
    'VesselCardWrapper__compare--chosen': isOneOf
  });

  const vesselPhotoUrl: string =
    vessel.photo && vessel.photo.thumbnail
      ? `${MEDIA_BASE_URL}/${vessel.photo.thumbnail.path}`
      : NO_IMAGE_PATH;

  const getPaths = () => ({
    flag: `/assets/flags/${vessel.flag}.png`,
    route: `/vessel/${vessel.id}`,
    photo: vesselPhotoUrl
  });

  const getLabel = (): ReactNode | null => {
    if (vesselA && vesselB) {
      return isOneOf ? checkInput : null;
    }
    return checkInput;
  };

  return (
    <div className="VesselCardWrapper">
      <Card getPaths={getPaths} vessel={vessel}>
        {showCompare && (
          <div className={compClass}>
            {getLabel()}

            <span className="VesselCardWrapper__fav" onClick={handleFav}>
              <IconCustom type={iconName} />
            </span>
          </div>
        )}
      </Card>
    </div>
  );
};

VesselCard.defaultProps = {
  onFavClick: Function.prototype,
  vesselComparisonAdd: Function.prototype,
  vesselComparisonRemove: Function.prototype
};

VesselCard.propTypes = {
  isInFavourites: PropTypes.bool,
  onFavClick: PropTypes.func,
  vesselsComparator: PropTypes.shape({
    vesselA: PropTypes.object,
    vesselB: PropTypes.object
  }),
  vesselComparisonAdd: PropTypes.func,
  vesselComparisonRemove: PropTypes.func,
  vessel: PropTypes.object.isRequired
};

export default VesselCard;
