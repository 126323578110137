import {
  PROVIDERS_FILTER_BY_CATEGORY,
  readSettings
} from '../../../services/settings';
import { TProviderCategoryFilterSettings } from '../../../types/providerFilters';
import { TProvidersState } from './providers';

export const providersFilterInitialSettings: TProviderCategoryFilterSettings = {
  categoryIds: [],
  isFilterSet: false
};
const settings = readSettings();
const filterSettings: TProviderCategoryFilterSettings =
  settings[PROVIDERS_FILTER_BY_CATEGORY] || providersFilterInitialSettings;

export const providersInitialState: TProvidersState = {
  addressArray: [],
  filterSettings,
  providers: null,
  error: null,
  isPending: false
};
