import { LatLng } from 'leaflet';
import { TPosition } from '../../../types/position';
import { TVesselLocation } from '../../../types/vessel';

export const mapDefaultPosition = {
  defaultCenter: { lat: 62, lng: 6.47 } as LatLng,
  defaultZoom: 4
};

/**
 * Returns the average center of the group of vessels.
 */
export function getGroupCenter(vessels: TVesselLocation[]): LatLng | void {
  if (!vessels || vessels.length === 1) return;

  const reducer = (sum: LatLng, current: TVesselLocation) => ({
    lat: (sum.lat + current.latitude) / vessels.length,
    lng: (sum.lng + current.longitude) / vessels.length
  });
  return vessels.reduce(
    reducer,
    mapDefaultPosition.defaultCenter as LatLng
  ) as LatLng;
}

/**
 * Returns a predefined center-zoom couple.
 */
export function getPredefinedView(
  center: LatLng,
  zoom: number,
  groupCenter: LatLng | void
): { center: LatLng; zoom: number } {
  const { defaultCenter, defaultZoom } = mapDefaultPosition;
  return {
    center: center || groupCenter || defaultCenter,
    zoom: zoom || defaultZoom
  };
}

/**
 * Determines whether a given position is valid.
 * @param position {[lat, lng]}
 * @returns {boolean}
 */
export function isPositionInvalid(position: TPosition): boolean {
  const [x, y] = position;
  return (
    x === null ||
    y === null ||
    x === void 0 ||
    y === void 0 ||
    (x === 0 && y === 0)
  );
}
