import React from 'react';
import Obfuscate from 'react-obfuscate';

function ContactPage() {
  return (
    <div>
      <h2>Contact</h2>
      <h3>Technical Support</h3>
      <p>
        For technical support please contact us via this e-mail:
        <Obfuscate email="admin@fishfacts.fo" />
      </p>
      <h3>Subscription and Other Inquiries </h3>
      <p>
        To get an account to our service or for other questions please contact
        us via this e-mail:
        <Obfuscate email="oli@fishfacts.fo" />
      </p>
    </div>
  );
}

export default ContactPage;
