import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './ServiceProvider.scss';
import BannerMenuOverlay from 'components/common/overlays/BannerMenuOverlay/BannerMenuOverlay';
import CommonBanner from 'components/common/banners/CommonBanner';
import Contacts from './Contacts/Contacts';
import ContactUsButton from 'components/common/buttons/ContactUsButton/ContactUsButton';
import EventButton from './EventButton/EventButton';
import Events from './Events/Events';
import Locations from './Locations/Locations';
import Products from './Products/Products';
import { PROVIDER_IMAGE_URL } from 'other/config';
import ServiceInfo from './ServiceInfo/ServiceInfo';
import ServiceLogo from 'components/common/logos/ServiceLogo/ServiceLogo';
import ServiceMap from './ServiceMap/ServiceMap';
import { ServiceProviderProps } from './helpers';
import Vessels from './Vessels/Vessels';
import Videos from './Videos/Videos';
import { TProvider, TProviderAddressShort } from 'types/providers';

const ServiceProvider = (props: ServiceProviderProps): ReactElement | null => {
  const {
    editProvider,
    events,
    isLoggedIn,
    isOwner,
    nearestEventId,
    onMarkerClick,
    provider
  } = props;
  if (!provider) return null;

  const {
    addresses,
    banner,
    contacts,
    id,
    logo,
    longDescription,
    name,
    shortDescription,
    products,
    published,
    vesselsByCompany,
    videos,
    website
  }: TProvider = provider;

  const handleEdit = () => editProvider(id);
  const handleMarkerClick = (address: Partial<TProviderAddressShort>) =>
    onMarkerClick({
      ...address,
      name,
      providerId: id
    });

  const bannerUrl = banner ? `${PROVIDER_IMAGE_URL}/${banner.path}` : null;

  const actions: ReactElement = (
    <div className="ServiceProvider__actions">
      <Button
        className="ServiceProvider__actionButton"
        onClick={handleEdit}
        type="link"
      >
        Edit
      </Button>
    </div>
  );

  const actionButtons =
    isLoggedIn &&
    published &&
    (isOwner ? (
      <EventButton isOwner={isOwner} nearestEventId={nearestEventId} />
    ) : (
      <ContactUsButton serviceProviderId={id} />
    ));

  return (
    <div className="ServiceProvider">
      <section className="ServiceProvider__banner">
        <CommonBanner imgUrl={bannerUrl} isDraft={!published} />
        <ServiceLogo id={id} logo={logo} name={name} />

        {isOwner && <BannerMenuOverlay actions={actions} />}
      </section>

      <section className="ServiceProvider__section">
        <ServiceInfo
          longDescription={longDescription}
          name={name}
          nearestEventId={nearestEventId}
          shortDescription={shortDescription}
          website={website}
        >
          {actionButtons}
        </ServiceInfo>

        <ServiceMap addresses={addresses} onMarkerClick={handleMarkerClick} />
      </section>

      <Vessels vessels={vesselsByCompany} />
      <Locations addresses={addresses} />
      <Contacts contacts={contacts} />
      <Products products={products} />
      <Videos videos={videos} />
      <Events events={events} />
    </div>
  );
};

ServiceProvider.propTypes = {
  editProvider: PropTypes.func.isRequired,
  isOwner: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  nearestEventId: PropTypes.number,
  onMarkerClick: PropTypes.func.isRequired,
  provider: PropTypes.shape({
    addresses: PropTypes.array,
    banner: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string
    }),
    contacts: PropTypes.array,
    logo: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string
    }).isRequired,
    longDescription: PropTypes.string,
    name: PropTypes.string,
    products: PropTypes.array,
    shortDescription: PropTypes.string,
    vesselsByCompany: PropTypes.array,
    videos: PropTypes.array,
    website: PropTypes.string
  })
};

export default ServiceProvider;
