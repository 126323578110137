import {
  ActionFactory,
  TAction,
  TFishingLicence,
  THttpResponse,
  TVessel
} from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import { ENDPOINTS, ROUTES, SUB_POINTS } from '../../../other/config';
import http from '../../../services/http';
import store from '../../store';
import { vesselsComparatorInitialState } from './vesselsComparatorInitialState';

import { EVesselsComparatorActions } from './vesselsComparatorConstants';
import { TState } from '../../appStateModel';
import { TVesselsComparatorState } from './vesselsComparatorModel';

const af = new ActionFactory<
  TVesselsComparatorState,
  EVesselsComparatorActions
>(store);
const fetchVesselsSet = af.createAsyncActions(
  EVesselsComparatorActions.FETCH_VESSELS
);
const fetchLicencesSet = af.createAsyncActions(
  EVesselsComparatorActions.FETCH_LICENCES
);

/**
 * Adds a vessel to the comparator.
 */
export function addToComparatorAction(vessel: Partial<TVessel>) {
  return (dispatch, getState) => {
    const {
      vesselsComparator: { vesselA, vesselB }
    } = getState() as TState;
    if (vesselA !== null && vesselB !== null) return;

    if (vesselA === null) {
      dispatch(addVessel('vesselA', vessel));
    } else if (vesselB === null) {
      dispatch(addVessel('vesselB', vessel));
    }
  };
}

/**
 * Removes the vessel from the comparator.
 */
export function removeFromComparatorAction(id: number) {
  return (dispatch, getState) => {
    const {
      vesselsComparator: { vesselA, vesselB }
    } = getState() as TState;
    if (vesselA === null && vesselB === null) return;

    if (vesselA && vesselA.id === id) {
      dispatch(removeVessel('vesselA'));
    } else if (vesselB.id === id) {
      dispatch(removeVessel('vesselB'));
    }
  };
}

/**
 * Fetches the vessels related data and redirects to the comparator page.
 */
export function runComparatorAction() {
  return (dispatch, getState) => {
    const {
      vesselsComparator: { vesselA, vesselB }
    } = getState() as TState;

    dispatch(resetComparator());
    dispatch(push(ROUTES.COMPARE));
    fetchVesselsSet.request();
    fetchLicencesSet.request();

    Promise.all([
      http.send(`${ENDPOINTS.VESSELS}/${vesselA.id}`),
      http.send(`${ENDPOINTS.VESSELS}/${vesselB.id}`)
    ])
      .then(
        ([respA, respB]: [THttpResponse<TVessel>, THttpResponse<TVessel>]) => {
          const { vesselsComparator } = getState() as TState;

          fetchVesselsSet.success({
            vesselAData: respA.data,
            vesselBData: respB.data,
            isPending: !vesselsComparator.licenceA
          });
        }
      )
      .catch(fetchVesselsSet.error);

    Promise.all([
      http.send(
        `${ENDPOINTS.VESSELS}/${vesselA.id}/${SUB_POINTS.FISHING_LICENCES}`
      ),
      http.send(
        `${ENDPOINTS.VESSELS}/${vesselB.id}/${SUB_POINTS.FISHING_LICENCES}`
      )
    ])
      .then(
        ([respA, respB]: [
          THttpResponse<TFishingLicence>,
          THttpResponse<TFishingLicence>
        ]) => {
          const { vesselsComparator } = getState() as TState;

          fetchLicencesSet.success({
            licenceA: respA.data,
            licenceB: respB.data,
            isPending: !vesselsComparator.vesselAData
          });
        }
      )
      .catch(fetchLicencesSet.error);
  };
}

const addVessel = (
  key,
  value
): TAction<Partial<TVesselsComparatorState>, EVesselsComparatorActions> => ({
  type: EVesselsComparatorActions.ADD_VESSEL,
  payload: { [key]: value }
});

const removeVessel = (
  key
): TAction<Partial<TVesselsComparatorState>, EVesselsComparatorActions> => ({
  type: EVesselsComparatorActions.REMOVE_VESSEL,
  payload: { [key]: null }
});

const resetComparator = (): TAction<
  Partial<TVesselsComparatorState>,
  EVesselsComparatorActions
> => ({
  type: EVesselsComparatorActions.RESET_COMPARATOR,
  payload: vesselsComparatorInitialState
});
