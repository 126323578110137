import { createAsyncActions, THttpResponse } from 'react-fishfacts/dist';

import { ENDPOINTS } from '../../other/config';
import http from '../../services/http';

import { EPersonActions } from './personConstants';
import { TBusinessPerson } from '../../types/person';
import { TPersonState } from './person';

export let fetchSet;

/**
 * Fetches a person by ID.
 */
export function fetchPerson(personId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.PERSONS}/${personId}`;
    fetchSet = createAsyncActions<TPersonState>(
      dispatch,
      EPersonActions.FETCH_PERSON
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TBusinessPerson>) =>
        fetchSet.success({
          person: data
        })
      )
      .catch(fetchSet.error);
  };
}
