import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  addToComparatorAction,
  runComparatorAction,
  removeFromComparatorAction
} from '../../../store/vessel/vesselsComparator/vesselsComparatorActions';
import { applyVesselsFilterAction } from '../../../store/vessel/vessels/vesselsActions';
import VesselListPage from './VesselsListPage';
import { TState } from '../../../store/appStateModel';

const mapStateToProps = (state: TState) => {
  const {
    fleets: { fleets },
    vesselsComparator,
    vesselsFilter: { isFilterSet },
    vessels: { isPending, selectedVessels }
  } = state;

  return {
    fleets,
    isFilterSet,
    isPending,
    vesselsComparator,
    vessels: selectedVessels
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      applyVesselsFilter: applyVesselsFilterAction,
      compareVessels: runComparatorAction,
      vesselComparisonAdd: addToComparatorAction,
      vesselComparisonRemove: removeFromComparatorAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VesselListPage);
