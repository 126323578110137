import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import './CategoryList.scss';

const CategoryItem = ({ category, className }) => {
  const cssClass = className
    ? 'CategoryList__item ' + className
    : 'CategoryList__item';

  const figureStyle = {
    backgroundImage: 'url(' + encodeURI(category.imageSrc) + ')'
  };

  return (
    <Link to={category.path} className="CategoryList__wrapper">
      <div className={cssClass}>
        <figure className="CategoryList__item--figure" style={figureStyle} />
      </div>
      <h3 className="CategoryList__name">{category.title}</h3>
    </Link>
  );
};

CategoryItem.propTypes = {
  category: PropTypes.shape({
    imageSrc: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string
};

const CategoryList = ({
  categories,
  className,
  item,
  itemClassName,
  title
}) => {
  if (!categories) return null;

  const cssClass = className ? 'CategoryList ' + className : 'CategoryList';
  const Component = item || CategoryItem;

  return (
    <section className={cssClass}>
      {title && <h2 className="CategoryList__header">{title}</h2>}

      <Row>
        {className ? (
          <Col span={24}>
            <Row gutter={24}>
              {categories.map((category) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  className="app-d-flex app-mb-3"
                  key={category.title}
                >
                  <Component category={category} className={itemClassName} />
                </Col>
              ))}
            </Row>
          </Col>
        ) : (
          <Col span={24} lg={{ span: 14, offset: 5 }}>
            <Row gutter={24}>
              {categories.map((category) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  className="app-d-flex app-mb-3"
                  key={category.title}
                >
                  <Component category={category} className={itemClassName} />
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </section>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.array,
  className: PropTypes.string,
  item: PropTypes.any,
  itemClassName: PropTypes.string,
  title: PropTypes.string
};

export default CategoryList;
