import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons/lib';
import './CheckButton.scss';

type Props = {
  isChecked: boolean;
  onClick: Function;
  text: string;
  value: number | string
};


const CheckButton = ({ isChecked, onClick, text, value }: Props): ReactElement => {
  const handleClick = () => onClick(value);

  return isChecked ? (
    <Button
      className="CheckButton CheckButton--checked"
      onClick={handleClick}
      type="primary"
    >
      {text}
      {/*@ts-ignore*/}
      <CheckOutlined />
    </Button>
  ) : (
    <Button
      className="CheckButton"
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};


CheckButton.propTypes = {
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default CheckButton;
