import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import classnames from 'classnames';

import './CreateFleet.scss';
import AddButton from 'components/common/buttons/AddButton/AddButton';

type Props = {
  onCreate: (fleetName: string) => void;
};

type State = {
  isActive: boolean;
  isExceeded: boolean;
  value: string;
};

class CreateFleet extends React.PureComponent<Props, State> {
  static propTypes;

  state: State = {
    isActive: false,
    isExceeded: false,
    value: ''
  };

  handleAdd = () => this.setState({ isActive: true });

  handleChange = ({ target: { value } }) =>
    this.setState({
      isExceeded: value.length > 30,
      value
    });

  handleCancel = () =>
    this.setState({
      isActive: false,
      isExceeded: false,
      value: ''
    });

  handleSave = () => {
    this.props.onCreate(this.state.value);
    this.handleCancel();
  };

  render() {
    const { isActive, isExceeded, value } = this.state;
    const isDisabled = value.trim() === '' || isExceeded;
    const cls = classnames('CreateFleet', {
      'CreateFleet--active': isActive,
      'CreateFleet--error': isExceeded
    });

    const controls: ReactNode = (
      <span className="CreateFleet__btns">
        <Button onClick={this.handleCancel}>Cancel</Button>

        <Button
          type="primary"
          disabled={isDisabled}
          onClick={this.handleSave}
          className="app-ml-1"
        >
          Save
        </Button>
      </span>
    );

    const input: ReactNode = (
      <>
        <h3>Add new fleet</h3>
        <Input autoFocus={true} onChange={this.handleChange} value={value} />

        {isExceeded && (
          <div className="ant-form-explain app-error-msg">
            Fleet name shouldn&apos;t be longer than 30 characters!
          </div>
        )}
        {controls}
      </>
    );

    return (
      <section className={cls}>
        {isActive ? (
          input
        ) : (
          <AddButton label="New fleet" onAdd={this.handleAdd} />
        )}
      </section>
    );
  }
}

CreateFleet.propTypes = {
  onCreate: PropTypes.func.isRequired
};

export default CreateFleet;
