import React from 'react';
import PropTypes from 'prop-types';
import {
  ALL_VALUE,
  areEqual,
  removeAllOption,
  typeLabelValue2StrArr,
  TCountry,
  TLabelValue
} from 'react-fishfacts/dist';
import classnames from 'classnames';
import { Select } from 'antd';

import './CountriesSelect.scss';
import { filterOption } from '../../helpers/convertors';

type Props = {
  className?: string;
  onChange?: (countries: string[]) => void;
  options: TCountry[];
  value?: string[];
};
const ALL: TLabelValue = {
  label: 'All countries',
  value: ALL_VALUE
};

class CountriesSelect extends React.PureComponent<Props> {
  static propTypes;

  getValue = (): string | string[] => {
    const { value } = this.props;
    if (!value) return;
    return value.length > 0 ? value : [ALL_VALUE];
  };

  handleChange = (values: string[]): void => {
    const { onChange, options } = this.props;
    const _value: string | string[] = this.getValue();

    // Selected ALL or removed each or selected each
    if (
      !areEqual(_value, [ALL_VALUE]) &&
      (values.includes(ALL_VALUE) ||
        values.length === 0 ||
        areEqual(typeLabelValue2StrArr(options), values))
    ) {
      onChange([]);
    } else {
      onChange(removeAllOption(values));
    }
  };

  render() {
    const { className, options } = this.props;
    const cls: string = classnames('CountriesSelect', className);
    const _options = [ALL, ...options];
    const _value: string | string[] = this.getValue();

    return (
      <Select
        className={cls}
        filterOption={filterOption}
        mode="tags"
        onChange={this.handleChange}
        optionFilterProp="value"
        options={_options}
        value={_value}
      />
    );
  }
}

CountriesSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.string)
};

export default CountriesSelect;
