import * as queryString from 'querystring';
import { APP_VERSION, writeSettings, readSettings } from './settings';
import {
  checkSessionAction,
  storeRedirectPathAction
} from '../store/session/sessionActions';
import { initializeThemeAction } from '../store/theme/themeActions';
import { refreshFilterAction } from '../store/vessel/vesselsFilter/vesselsFilterActions';
import { removeItem } from './storage';
import { scheduleLogout } from './auth';
import store from '../store/store';
import { VERSION } from '../other/config';

const styles = [
  'color: white',
  'background-color: RosyBrown',
  'font-size: 14px'
].join(';');

export function runSessionService() {
  redirectOnHashUrl();
  fixIncompatibilities();
  doCheck();
  scheduleLogout();
  window.console.log(`%c FishFacts v${VERSION} `, styles);
  writeSettings({ [APP_VERSION]: VERSION });
}

function redirectOnHashUrl() {
  if (window.location.hash.startsWith('#/')) {
    window.location.replace(window.location.href.replace('#/', ''));
  }
}

function doCheck() {
  store.dispatch(storeRedirectPathAction());
  store.dispatch(checkSessionAction());
  store.dispatch(refreshFilterAction());
  store.dispatch(initializeThemeAction());

  // Remove token and username from the URL.
  // Need to wait till the session response comes back. Otherwise routerFollowRedirect action will restore the hash.
  setTimeout(() => removeTokenFromURL(), 3000);
}

function removeTokenFromURL() {
  const search = window.location.search.split('?')[1];
  const { auth_token, username, ...rest } = queryString.parse(search);
  if (!auth_token && !username) return;

  const _search = decodeURIComponent(queryString.stringify(rest));
  const refresh = `${window.location.origin}${window.location.pathname}?${_search}`;
  window.history.pushState({ path: refresh }, '', refresh);
}

function fixIncompatibilities() {
  const { APP_VERSION } = readSettings();

  if (APP_VERSION !== VERSION) {
    // todo uncomment in v3.1
    // removeItem('account');
    // removeItem('auth');
    // removeItem('autologin');
    // removeItem('settings');
    // removeItem('token');
    removeItem('isDarkTheme');
  }
}
