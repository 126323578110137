import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { TCountry, TLabelValue } from 'react-fishfacts/dist';

import PromotionControl from './PromotionControl';
import { ROUTES } from '../../../../other/config';

import { TFormFields } from '../../../../types/formFields';
import {
  EPaymentStatus,
  TPromoted,
  TPromotionCost,
  TShortPaymentReport
} from '../../../../types/promotion';
import { TTreeSelectOption } from '../../../../other/formAndValidation/formUtils';

export type TPromotionProps = {
  article: TPromoted;
  articleId: number;
  cost: TPromotionCost;
  countries: TCountry[];
  fetchPaymentReport: (promoId: number) => void;
  fields: TFormFields;
  goToNewsPage: () => void;
  isCostLoading: boolean;
  isLoading: boolean;
  onCalculate: (promotion: TFormFields, articleId: number) => void;
  onPay: () => void;
  report: TShortPaymentReport;
  serviceOptions: TTreeSelectOption[];
  vesselTypesOptions: TLabelValue[];
};

class PromoWrapper extends React.Component<TPromotionProps> {
  static propTypes;

  componentDidUpdate(prevProps: Readonly<TPromotionProps>): void {
    const { article, fetchPaymentReport, report } = this.props;

    if (!prevProps.report && report && !report.isOutdated) {
      Modal[report.method]({
        content: report.message,
        title: 'Promotion payment status',
        onOk: this.handleModal
      });
    }

    if (!prevProps.article && article) {
      fetchPaymentReport(article.id);
    }
  }

  handleChange = (fields: TFormFields) => {
    const { articleId, onCalculate } = this.props;
    onCalculate(fields, articleId);
  };

  handleModal = () => {
    const {
      goToNewsPage,
      report: { status }
    } = this.props;

    if (status === EPaymentStatus.SUCCESS) {
      return goToNewsPage();
    }

    setTimeout(() => {
      const form = document.getElementById(ROUTES.PROMO_FORM);
      form && form.scrollIntoView({ behavior: 'smooth' });
    });
  };

  render() {
    const { countries, serviceOptions, vesselTypesOptions } = this.props;
    if (
      countries.length > 0 &&
      serviceOptions.length > 1 &&
      vesselTypesOptions.length > 1
    ) {
      return (
        <PromotionControl {...this.props} onCalculate={this.handleChange} />
      );
    }

    return null;
  }
}

export const promotionProps = {
  article: PropTypes.object,
  articleId: PropTypes.number.isRequired,
  cost: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.object),
  fetchPaymentReport: PropTypes.func.isRequired,
  fields: PropTypes.object,
  goToNewsPage: PropTypes.func.isRequired,
  isCostLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCalculate: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
  report: PropTypes.object,
  serviceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  vesselTypesOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

PromoWrapper.propTypes = promotionProps;
export default PromoWrapper;
