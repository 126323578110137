import * as Sentry from '@sentry/browser';
import { ActionFactory, TAction, THttpResponse } from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import { autoLoginAction } from '../login/loginActions';
import { ENDPOINTS, ROUTES } from '../../other/config';
import { getCredentialsFromURL, getToken } from '../../services/auth';
import http from '../../services/http';
import store from '../store';

import { ESessionActions } from './sessionConstants';
import { TSessionState, TUser } from './sessionModel';
import { TState } from '../appStateModel';

const af = new ActionFactory<TSessionState, ESessionActions>(store);
const checkSessionSet = af.createAsyncActions(ESessionActions.CHECK_SESSION);
const updateSessionSet = af.createAsyncActions(ESessionActions.UPDATE_SESSION);

/**
 * Checks whether a user session is still valid.
 */
export function checkSessionAction() {
  return () => {
    if (getCredentialsFromURL()) {
      return store.dispatch(autoLoginAction());
    }
    if (!getToken()) return;
    checkSessionSet.request();

    http
      .send(ENDPOINTS.SESSION)
      .then(handleSessionResponse)
      .catch((e) => {
        checkSessionSet.error(e);
        store.dispatch({
          type: ESessionActions.INVALIDATE_SESSION,
          payload: { user: null }
        });
      });
  };
}

/**
 * Shadow session update to keep in sync the user's fleets, providers and events.
 */
export function updateSessionAction() {
  return (dispatch) => {
    // we do not set `isLoading = true` here, because it will rerender the whole application!
    dispatch({ type: ESessionActions.UPDATE_SESSION_REQUEST });

    http
      .send(ENDPOINTS.SESSION)
      .then((resp: THttpResponse<TUser>) =>
        updateSessionSet.success({ user: resp.data })
      )
      .catch(updateSessionSet.error);
  };
}

/**
 * Handles the session response.
 */
const handleSessionResponse = (resp: THttpResponse<TUser>): void => {
  Sentry.configureScope((scope) =>
    scope.setUser({
      id: String(resp.data.userInfo.id)
    })
  );

  checkSessionSet.success({ user: resp.data });
  store.dispatch(followRedirectPathAction());
};

/**
 * Assigns session data.
 * @param user
 */
export function storeSessionAction(user: TUser) {
  return {
    type: ESessionActions.STORE_SESSION,
    payload: {
      error: null,
      user: user
    }
  };
}

/** Stores given routing path. */
export const storeRedirectPathAction = (): TAction<
  TSessionState,
  ESessionActions
> => ({
  type: ESessionActions.STORE_REDIRECT_PATH,
  payload: {
    redirectPath: window.location.pathname + window.location.search
  }
});

export function followRedirectPathAction() {
  return (dispatch, getState) => {
    const { session } = getState() as TState;

    if (session.redirectPath !== window.location.pathname) {
      dispatch(push(session.redirectPath || ROUTES.HOME));
    }

    dispatch({
      type: ESessionActions.CLEAR_REDIRECT_PATH,
      payload: { redirectPath: null }
    });
  };
}
