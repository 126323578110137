import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import IconCustom from '../../../../components/common/IconCustom/IconCustom';

import './FBShareButton.scss';

/**
 * Calls Facebook UI lib to share the resource.
 */
function shareOnFacebook(): void {
  const url = window.location.href;

  window['FB'].ui({
    display: 'popup',
    method: 'share',
    href: url,
  }, window.console.log);
}

type Props = {
  className?: string;
};

const FBShareButton = ({ className }: Props) => {
  const cls: string = classnames('FBShareButton', className);

  return (
    <div className={cls}>
      <Button
        onClick={shareOnFacebook}
        title="Share on Facebook"
        type="primary"
      >
        <IconCustom type="facebook"/> Share
      </Button>
    </div>
  );
};

export default FBShareButton;
