import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import './ServiceInfo.scss';

type Props = {
  longDescription: string;
  name: string;
  nearestEventId?: number;
  shortDescription: string;
  website: string;
};

const ServiceInfo: React.FC<Props> = ({
  children,
  longDescription,
  name,
  nearestEventId,
  shortDescription,
  website
}): ReactElement => {
  // const where = {
  //   pathname: `${ROUTES.EVENTS}/${nearestEventId}`,
  //   state: {
  //     prevPath: ROUTES.SERVICE_PROVIDER
  //   }
  // };

  return (
    <div className="ServiceInfo">
      {children}

      <div className="ServiceInfo__site">
        <a
          href={website}
          target="_blank"
          className="app-pointer"
          rel="noopener noreferrer"
        >
          {website}
        </a>
      </div>

      <h2 className="ServiceInfo__name">{name}</h2>
      <p>{shortDescription}</p>
      <p>{longDescription}</p>
    </div>
  );
};

ServiceInfo.propTypes = {
  longDescription: PropTypes.string,
  name: PropTypes.string,
  nearestEventId: PropTypes.number,
  shortDescription: PropTypes.string,
  website: PropTypes.string
};

export default ServiceInfo;
