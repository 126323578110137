import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './SchedulePane.scss';
import { groupEventsByDate } from '../../../helpers';
import IconCustom from '../../../../../components/common/IconCustom/IconCustom';
import ScheduleItem from './ScheduleItem';
import {
  EScheduleItemStatus,
  TEventScheduleGroup,
  TScheduleItem,
} from '../../../../../types/event';

type Props = {
  onEdit: (item: TScheduleItem) => void;
  onItemAdd: () => void;
  onItemRemove: (id: number) => void;
  onStatus: (id: number, status: EScheduleItemStatus) => void;
  schedule: TScheduleItem[];
  utcOffset: string;
};

const SchedulePane = ({
  onItemAdd,
  onEdit,
  onItemRemove,
  onStatus,
  schedule,
  utcOffset,
}: Props): ReactElement => {
  const handleAdd = () => onItemAdd();

  const groups: ReactElement[] = groupEventsByDate(schedule).map(
    ({ date, id, items }: TEventScheduleGroup) => {
      const [day, month] = date.split(' ');

      return (
        <div className="SchedulePane__group" key={id}>
          <div className="SchedulePane__date">
            <strong>{day}</strong> {month}
          </div>

          <ul className="SchedulePane__items">
            {items.map((item) => (
              <ScheduleItem
                item={item}
                key={item.id}
                onEdit={onEdit}
                onItemRemove={onItemRemove}
                onStatus={onStatus}
                utcOffset={utcOffset}
              />
            ))}
          </ul>
        </div>
      );
    }
  );

  return (
    <section className="SchedulePane">
      <div className="app-d-flex app-justify-content-between app-align-items-center">
        <h3 className="SchedulePane__title">Schedule</h3>

        <Button onClick={handleAdd} title="Add item" type="link">
          <IconCustom type="plus" />
          Add item
        </Button>
      </div>

      {groups}
    </section>
  );
};

SchedulePane.propTypes = {
  onItemAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  onStatus: PropTypes.func.isRequired,
  schedule: PropTypes.array.isRequired,
};

export default SchedulePane;
