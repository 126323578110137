import { okAction, TAction, THttpResponse } from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import { createAsyncActions } from '../../_utils/createAsyncActions';
import { ENDPOINTS, ROUTES } from '../../../other/config';
import { fetchLongMediaDescription } from '../../_utils/longMediaDescriptionUtils';
import http from '../../../services/http';
import { newsInitialState } from './newsInitialState';

import { ENewsActions } from './newsConstants';
import { TArticle } from '../../../types/article';
import { TNewsState, TPagination } from './newsModel';
import { TState } from '../../appStateModel';

export let fetchListSet, fetchArticleSet, fetchRecentSet, removeSet;

/**
 * Fetches news list, supports server-side pagination.
 */
export function fetchNewsAction(fetchMore?: boolean) {
  return (dispatch, getState) => {
    const {
      news: { news, pagination }
    }: TState = getState();
    if (news && news.length > 0 && !fetchMore) return;

    const requestedPage: number = fetchMore ? pagination.page + 1 : 0;
    const url = `${ENDPOINTS.NEWS}?page=${requestedPage}&size=${pagination.size}`;

    fetchListSet = createAsyncActions<TNewsState>(
      dispatch,
      ENewsActions.FETCH_NEWS
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TArticle[]>) => {
        const _news: TArticle[] = fetchMore ? [...(news || []), ...data] : data;
        const _pagination: TPagination = {
          ...pagination,
          page: requestedPage
        };
        fetchListSet.success({
          news: _news,
          pagination: _pagination
        });
      })
      .catch(fetchListSet.error);
  };
}

/**
 * Retrieves news article by ID.
 */
export function fetchArticleAction(newsId: number) {
  return (dispatch, getState) => {
    if (!newsId) return;
    fetchArticleSet = createAsyncActions<TNewsState>(
      dispatch,
      ENewsActions.FETCH_ARTICLE
    ).request();

    http
      .send(`${ENDPOINTS.NEWS}/${newsId}`)
      .then(({ data }: THttpResponse<TArticle>) => {
        fetchArticleSet.success({ isPending: true });
        dispatch(fetchNewsBodyAction(data));
      })
      .catch(fetchArticleSet.error);
  };
}

/**
 * Helper action; fetches news article body (longDescription part).
 */
export function fetchNewsBodyAction(article: TArticle) {
  return (dispatch) => {
    const {
      longDescriptionMedia: { path }
    } = article;

    fetchLongMediaDescription(path).then((articleBody: string) => {
      const update = {
        ...article,
        longDescription: articleBody
      };
      dispatch(
        okAction(ENewsActions.NEWS_BODY_COMPOSED, {
          article: update,
          isPending: false
        })
      );
    });
  };
}

/**
 * Action; purges article model.
 */
export const clearNewsAction: TAction<TNewsState, ENewsActions> = {
  type: ENewsActions.CLEAR_NEWS,
  payload: newsInitialState
};

/**
 * Clears article.
 */
export const clearArticleAction = (): TAction<TNewsState, ENewsActions> => ({
  type: ENewsActions.CLEAR_NEWS_ARTICLE,
  payload: { article: null }
});

export const saveScrollAction = (
  scrollTop: number
): TAction<TNewsState, ENewsActions> => ({
  type: ENewsActions.SAVE_SCROLL_TOP,
  payload: { scrollTop }
});

/**
 * Removes an article from both the store and server.
 */
export function newsArticleRemove(articleId: number) {
  return (dispatch, getState) => {
    removeSet = createAsyncActions<TNewsState>(
      dispatch,
      ENewsActions.REMOVE_ARTICLE
    ).request();

    http
      .send({
        method: 'DELETE',
        url: `${ENDPOINTS.NEWS}/${articleId}`
      })
      .then(() => {
        const {
          news: { news }
        }: TState = getState();

        removeSet.success({
          news: news
            ? news.filter(({ id }: TArticle) => id !== articleId)
            : null
        });
      })
      .catch(removeSet.error);
  };
}

export const gotoNewsListAction = () => push(ROUTES.NEWS);
