import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import '../ServiceProvider.scss';
import EventCard from './EventCard';
import Expander2 from '../../../../components/common/Expander/Expander2';
import { TEvent } from '../../../../types/event';

type Props = {
  events: TEvent[];
};

class Events extends React.PureComponent<Props> {
  static propTypes;

  render() {
    const { events } = this.props;
    if (!events || !events.length) return null;

    const slides: ReactElement[] = events.map(
      (event: TEvent): ReactElement => (
        <EventCard event={event} key={event.id} />
      )
    );

    return (
      <section className="ServiceProvider__group">
        <Expander2 itemWidth={600} title="Events">
          {slides}
        </Expander2>
      </section>
    );
  }
}

Events.propTypes = {
  events: PropTypes.array
};

export default Events;
