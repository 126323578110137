import { THttpRequestOptions } from 'react-fishfacts/dist';

import { API_URL, ENDPOINTS } from '../../../../other/config';
import { ProviderMediaHandlingUtil } from './ProviderMediaHandlingUtil';

import { EProviderMediaObjectType } from '../../../../types/providers';
import { TPerson } from '../../../../types/person';
import { TProviderEditorState } from '../providerEditor';
import { TVideo } from '../../../../types/media';

/**
 * Handles provider-associated files uploading.
 */
export class ProviderMediaUploadUtil extends ProviderMediaHandlingUtil {
  getRequestParams(): THttpRequestOptions {
    const prefix: string = `${API_URL}${ENDPOINTS.SERVICE_PROVIDER}/${this.id}/`;
    return {
      body: void 0,
      method: 'POST',
      url: prefix + this.getSuffix()
    };
  }

  protected getSuffix(): string {
    switch (this.type) {
      case EProviderMediaObjectType.BANNER:
        return 'media/banner';
      case EProviderMediaObjectType.LOGO:
        return 'media/logo';
      case EProviderMediaObjectType.CONTACT:
        return `media/contact/${this.objectId}`;
      case EProviderMediaObjectType.POSTER:
        return `video/${this.objectId}/poster`;
      case EProviderMediaObjectType.VIDEO:
        return `video/${this.objectId}/chunks`;
      default:
        throw new Error('ProviderMediaUploadUtil: Bad object type');
    }
  }

  getPayload(data: any): Partial<TProviderEditorState> {
    switch (this.type) {
      case EProviderMediaObjectType.BANNER:
        return {
          banner: data
        };
      case EProviderMediaObjectType.LOGO:
        return {
          logo: data
        };

      case EProviderMediaObjectType.CONTACT:
        const mapContact = (cnt: TPerson): TPerson => {
          if (cnt.id !== this.objectId) return cnt;
          return {
            ...cnt,
            photo: data
          };
        };
        return {
          contacts: this.contacts.map(mapContact),
          handledObject: {
            ...this.handledObject,
            photo: data
          }
        };

      case EProviderMediaObjectType.POSTER:
        const mapVideoForPoster = (video: TVideo): TVideo => {
          if (video.id !== this.objectId) return video;
          return {
            ...video,
            poster: data
          };
        };
        return {
          videos: this.videos.map(mapVideoForPoster),
          handledObject: {
            ...this.handledObject,
            poster: data
          }
        };

      case EProviderMediaObjectType.VIDEO:
        const mapVideo = (video: TVideo): TVideo => {
          if (video.id !== this.objectId) return video;
          return {
            ...video,
            video: data
          };
        };
        return {
          videos: this.videos.map(mapVideo),
          handledObject: {
            ...this.handledObject,
            video: data
          }
        };
    }
  }
}
