import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Collapse, message } from 'antd';

import './CategorySelector.scss';
import CheckButton from './CheckButton';
import { ERR_MESSAGE_DURATION, LOCALE } from '../../../../other/config';
import { TServiceBranch } from '../../../../types/providers';
import { IconCustom } from 'react-fishfacts/dist';

type Props = {
  categories: TServiceBranch[];
  currentBranches: TServiceBranch[];
  branchesLimit?: number;
  onChange: (branchId: number) => void;
};

const CategorySelector = ({
  branchesLimit,
  categories,
  currentBranches,
  onChange
}: Props): ReactElement => {
  const header: ReactNode = (
    <header className="ExpanderHeader__header">
      <h3 className="ExpanderHeader__title">Service branches</h3>
    </header>
  );

  /** Returns IDs of the provider's branches */
  const getBranchesIds = (): number[] => {
    if (!currentBranches) return [];
    return currentBranches.map(({ id }: TServiceBranch): number => id);
  };

  const handleChange = (value: number) => {
    if (!currentBranches) return;
    const isIn: boolean = getBranchesIds().includes(value);

    if (!isIn && currentBranches.length === branchesLimit) {
      return message.error(
        `Maximum number of branches is ${branchesLimit}!`,
        ERR_MESSAGE_DURATION
      );
    }

    if (isIn && currentBranches.length === 1) {
      return message.error(
        `At least one service branch must be chosen!`,
        ERR_MESSAGE_DURATION
      );
    }
    onChange(value);
  };

  const cats: ReactNode[] = categories.map(
    ({ id, value }: TServiceBranch): ReactElement => {
      const isChecked: boolean = getBranchesIds().includes(id);
      const text: string = value[LOCALE];
      return (
        <CheckButton
          isChecked={isChecked}
          key={id}
          onClick={handleChange}
          text={text}
          value={id}
        />
      );
    }
  );

  const expandIcon = () => <IconCustom type="chevron-up" />;

  return (
    <section className="CategorySelector Expander">
      <Collapse
        className="CategorySelector__collapse"
        bordered={false}
        expandIcon={expandIcon}
      >
        <Collapse.Panel children={cats} header={header} key="1" />
      </Collapse>
    </section>
  );
};

CategorySelector.defaultProps = {
  branchesLimit: 3
};

CategorySelector.propTypes = {
  categories: PropTypes.array,
  currentBranches: PropTypes.array,
  branchesLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default React.memo(CategorySelector);
