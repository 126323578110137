import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form, Row, Col, Input, TimePicker } from 'antd';
import moment, { Moment } from 'moment';

import './ItemForm.scss';
import {
  dateRules,
  endRules,
  notesRules,
  startRules,
  titleRules
} from './helpers';
import { pretendUTC } from '../../../../../other/formAndValidation/formUtils';
import SaveCancelGroup from '../../../../../components/common/buttons/SaveCancelGroup/SaveCancelGroup';
import { TScheduleItem } from '../../../../../types/event';

type Props = {
  dates: string[];
  item: TScheduleItem;
  onCancel: () => void;
  onSubmit: (item: TScheduleItem) => void;
};

class ItemForm extends React.PureComponent<Props> {
  static propTypes;

  handleSubmit = (values) => {
    const { item, onSubmit } = this.props;
    const { date, end, notes, start, title } = values;
    const _date = pretendUTC(date);
    onSubmit({
      ...item,
      end: `${_date}T${end.format('HH:mm')}`,
      start: `${_date}T${start.format('HH:mm')}`,
      notes: notes,
      title: title
    });
  };

  getDisabledRange = (date: Moment): boolean => {
    if (!date) return false;
    const { dates } = this.props;
    return !date.isBetween(dates[0], dates[1], 'day', '[]');
  };

  getInitialValues(): {} {
    const { end, notes, start, title } = this.props.item;
    return {
      date: start ? moment(start.substring(0, 10)) : void 0,
      end: end ? moment(end) : void 0,
      notes: notes,
      start: start ? moment(start) : void 0,
      title: title
    };
  }

  render() {
    const { dates } = this.props;
    const defaultDateValue = moment(dates[0]);

    return (
      <Form
        className="ItemForm"
        initialValues={this.getInitialValues()}
        onFinish={this.handleSubmit}
      >
        <Form.Item name="title" rules={titleRules}>
          <Input className="EventMainForm__input" placeholder="Title" />
        </Form.Item>

        <Row gutter={24}>
          <Col md={12} sm={24}>
            <Form.Item
              className="ItemForm__date"
              label="Day"
              name="date"
              rules={dateRules}
            >
              <DatePicker
                className="EventMainForm__input"
                defaultPickerValue={defaultDateValue}
                disabledDate={this.getDisabledRange}
                showToday={false}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="ItemForm__range">
          <Row gutter={24}>
            <Col md={12} sm={24}>
              <Form.Item label="Start time" name="start" rules={startRules}>
                <TimePicker
                  className="EventMainForm__input"
                  format="HH:mm"
                  minuteStep={5}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item label="End time" name="end" rules={endRules}>
                <TimePicker
                  className="EventMainForm__input"
                  format="HH:mm"
                  minuteStep={5}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item name="notes" rules={notesRules}>
          <Input.TextArea
            className="EventMainForm__area"
            placeholder="Notes. Only visible to you"
          />
        </Form.Item>

        <SaveCancelGroup onCancel={this.props.onCancel} />
      </Form>
    );
  }
}

ItemForm.propTypes = {
  dates: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default ItemForm;
