import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button, Tag } from 'antd';
import { TVessel } from 'react-fishfacts/dist';

import './VesselCompare.scss';
import { THandler } from '../../../types/handlers';

type Props = {
  compareVessels: THandler;
  vesselsComparator: {
    vesselA: Partial<TVessel>;
    vesselB: Partial<TVessel>;
  };
  vesselComparisonRemove: THandler;
};

const VesselCompare = ({
  compareVessels,
  vesselsComparator: { vesselA, vesselB },
  vesselComparisonRemove
}: Props): ReactElement | null => {
  if (!vesselA && !vesselB) return null;

  return (
    <div className="flex-row VesselCompare">
      <b className="VesselCompare__title">Compare list</b>

      {vesselA && (
        <Tag
          className="VesselCompare__tag"
          closable={true}
          color="blue"
          onClose={() => vesselComparisonRemove(vesselA.id)}
        >
          {vesselA.name}
        </Tag>
      )}

      {vesselB && (
        <Tag
          className="VesselCompare__tag"
          closable={true}
          color="blue"
          onClose={() => vesselComparisonRemove(vesselB.id)}
        >
          {vesselB.name}
        </Tag>
      )}

      {vesselA && vesselB && (
        <Button className="VesselCompare__button" onClick={compareVessels}>
          Compare
        </Button>
      )}
    </div>
  );
};

VesselCompare.propTypes = {
  compareVessels: PropTypes.func.isRequired,
  vesselsComparator: PropTypes.shape({
    vesselA: PropTypes.object,
    vesselB: PropTypes.object
  }),
  vesselComparisonRemove: PropTypes.func.isRequired
};

export default React.memo(VesselCompare);
