import { isTouchDevice } from '../../../components/helpers/helpers';

const scaler = isTouchDevice() ? 1.8 : 1;

export const ROTOR = Math.PI / 180;
export const HEIGHT = scaler * 14;
export const WIDTH = scaler * 6;
export const RADIUS = scaler * 5;
export const DEFAULT_COLOR = 'Grey';
export const DEFAULT_COLORS = [DEFAULT_COLOR, DEFAULT_COLOR];
