import React from 'react';
import PropTypes from 'prop-types';
import {
  ALL_VALUE,
  num2StrArr,
  removeAllOption,
  Select,
  strArr2Num,
  TIdNameObj,
  TLabelValue
} from 'react-fishfacts/dist';
import { connect } from 'react-redux';

import { optionsSelector } from '../../helpers/optionsSelector';
import { setVesselFilterAction } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterActions';

import { EVesselsFilterActions } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterConstants';
import { TFleetShort } from '../../../../../../types/fleet';
import { TState } from '../../../../../../store/appStateModel';

const ALL: TLabelValue = {
  label: 'All fleets',
  value: ALL_VALUE
};

type Props = {
  filterFleetValue: number[];
  fleets: TIdNameObj[];
  selectFleets: (options: number[]) => void;
};

const FleetsFilter = ({ fleets, filterFleetValue, selectFleets }: Props) => {
  const options = [ALL, ...optionsSelector(fleets)];
  const value = num2StrArr(filterFleetValue);
  const onChange = (values) => {
    const arg = strArr2Num(removeAllOption(values)).filter(Boolean);
    selectFleets(arg);
  };

  return <Select value={value} options={options} onChange={onChange} />;
};

const mapStateToProps = ({
  session,
  vesselsFilter: { filterFleet: filterFleetValue }
}: TState) => {
  const fleets: TFleetShort[] = session.user
    ? session.user.userInfo.fleets
    : ([] as any);

  return {
    fleets,
    filterFleetValue
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectFleets: (value) =>
      dispatch(setVesselFilterAction(EVesselsFilterActions.SET_FLEET, value))
  };
};

FleetsFilter.propTypes = {
  filterFleetValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  fleets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectFleets: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetsFilter);
