import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { IconCustom } from 'react-fishfacts/dist';

import { ROUTES } from 'other/config';
import withAuth from 'components/withAuth';
import { TUserInfo } from 'types/userInfo';

type Props = {
  isNewsIssuer: boolean;
  userInfo: Partial<TUserInfo>;
};

const CreateNewsButton: FC<Props> = ({
  isNewsIssuer,
  userInfo: { serviceProvidersId }
}) => {
  if (!isNewsIssuer) return null;

  const hasProvider = serviceProvidersId && serviceProvidersId.length > 0;
  const btnHref = hasProvider ? ROUTES.NEWS_EDITOR : void 0;

  const handleClick = (): void => {
    if (hasProvider) return;
    const modal = Modal.info({
      content:
        "Seems that you still haven't created your service. News articles are service related stuff. So please, create your service first.",
      onOk: () => modal.destroy(),
      title: 'Please, create your service first!'
    });
  };

  return (
    <Button href={btnHref} onClick={handleClick} shape="round" size="large">
      <IconCustom type="plus" />
      Article
    </Button>
  );
};

CreateNewsButton.propTypes = {
  isNewsIssuer: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    serviceProvidersId: PropTypes.arrayOf(PropTypes.number)
  }).isRequired
};

export default withAuth(CreateNewsButton);
