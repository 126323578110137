import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

import './VesselPage2.scss';
import ExtendedFields from './ExtendedFields/ExtendedFields';
import FishingQuotes from './FishingQuotes/FishingQuotes';
import FishingRights from './FishingRights/FishingRights';
import GoBackButton from '../../../components/common/buttons/GoBackButton/GoBackButton';
import VesselInfo from './VesselInfo/VesselInfo';
import VesselOwner from './VesselOwner/VesselOwner';
import VesselPhotos from './VesselPhotos/VesselPhotos';
import VesselTitle from './VesselTitle/VesselTitle';
import withFleets from '../../../components/common/vessel/withFleets/withFleets';

import { TFishingRights } from '../../../types/fishingLicence';
import { THandler } from '../../../types/handlers';
import { TVesselPhotoItem } from '../helpers';
import { EUserAuthorities } from '../../../store/session/sessionModel';
import {
  TVessel,
  TEngineShort,
  TManufacturer,
  IconCustom
} from 'react-fishfacts/dist';

const { Panel } = Collapse;

type Props = {
  canViewOwner: boolean;
  engines: TEngineShort[];
  favVesselsIds: number[];
  fishingRights: TFishingRights;
  onFavClick: Function;
  manufacturers: TManufacturer[];
  photos: TVesselPhotoItem[];
  showOnMap: THandler;
  userPermissions: EUserAuthorities[];
  vessel: TVessel;
};

const VesselPage2 = (props: Props) => {
  const {
    canViewOwner,
    engines,
    favVesselsIds,
    fishingRights,
    onFavClick,
    manufacturers,
    photos,
    showOnMap,
    vessel,
    userPermissions
  } = props;

  const { fishingLicences, quotes } = fishingRights || {};
  const flagSrc = `/assets/flags/${vessel.flag}.png`;
  const defaultActiveKey = ['1'];
  const extendedFieldsHeader = (
    <div className="ExtendedFields__collapseHeader">Extended Details</div>
  );
  const fishingRightsHeader = (
    <div className="ExtendedFields__collapseHeader">Fishing rights</div>
  );

  const pointer = { cursor: 'pointer' };
  const yellowAndPointer = {
    color: '#FFDC24',
    cursor: 'pointer'
  };

  const isInFavourites = favVesselsIds.includes(vessel.id);
  const iconName = isInFavourites ? 'star-filled' : 'star';
  const style = isInFavourites ? yellowAndPointer : pointer;

  const handleClick = (e) => {
    e.preventDefault();
    showOnMap(vessel.id);
  };

  const handleFav = () => onFavClick(vessel);

  const controls = (
    <div className="VesselPage__controls">
      <div onClick={handleFav} role="button" tabIndex={0}>
        <IconCustom style={style} title="Add vessel to fleet" type={iconName} />
      </div>

      {userPermissions.includes(EUserAuthorities.VIEW_MAP) && (
        <div onClick={handleClick} onTouchEnd={handleClick}>
          <IconCustom
            style={pointer}
            title="Show vessel on map"
            type="map-point"
          />
        </div>
      )}
    </div>
  );

  const expandIcon = () => <IconCustom type="chevron-up" />;

  return (
    <div className="VesselPage">
      <div className="VesselPage__top">
        <div className="VesselPage__info">
          <GoBackButton type="text" size="small" />
          <VesselTitle flagSrc={flagSrc} name={vessel.name} />
          {controls}

          <div className="VesselPage__gallery app-d-sm-block app-d-none">
            <VesselPhotos photos={photos} />
          </div>
          <VesselInfo vessel={vessel} />
        </div>

        <div className="VesselPage__gallery app-d-sm-none">
          <VesselPhotos photos={photos} />
        </div>
      </div>

      {vessel.owner && canViewOwner && <VesselOwner owner={vessel.owner} />}

      <Collapse
        bordered={false}
        defaultActiveKey={defaultActiveKey}
        expandIcon={expandIcon}
        className="VesselPage__collapse"
      >
        <Panel
          header={extendedFieldsHeader}
          key="1"
          className="VesselPage__collapsePanel"
        >
          <ExtendedFields
            engines={engines}
            manufacturers={manufacturers}
            userPermissions={userPermissions}
            vessel={vessel}
          />
        </Panel>

        {fishingLicences && fishingLicences.length && (
          <Panel
            header={fishingRightsHeader}
            key="2"
            className="VesselPage__collapsePanel"
          >
            <FishingRights licences={fishingLicences} />
          </Panel>
        )}
      </Collapse>

      <FishingQuotes quotes={quotes} />
    </div>
  );
};

VesselPage2.propTypes = {
  canViewOwner: PropTypes.bool,
  engines: PropTypes.array,
  favVesselsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  fishingRights: PropTypes.object,
  manufacturers: PropTypes.array,
  onFavClick: PropTypes.func.isRequired,
  showOnMap: PropTypes.func.isRequired,
  photos: PropTypes.array,
  userPermissions: PropTypes.array.isRequired,
  vessel: PropTypes.shape({
    flag: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    photo: PropTypes.object
  }).isRequired
};

export default withFleets(VesselPage2);
