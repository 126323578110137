import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Col, DatePicker, Row, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { minMaxLenValidator, RTEBasic } from 'react-fishfacts/dist';
import moment, { Moment } from 'moment';

import './EventMainForm.scss';
import { DATE_FORMAT } from '../../../../other/config';
import { formFields2Dict } from '../../../../other/formAndValidation/formUtils';
import { timezones } from './timezones';
import { TEventMainFormProps } from './helpers';

const datesRules = [
  { required: true, message: 'Please select start~end dates!' }
];
const shortRules = minMaxLenValidator('Short description', 1000, 0);
const titleRules = minMaxLenValidator('Title', 160, 0);

class EventMainForm extends React.PureComponent<TEventMainFormProps> {
  static propTypes;
  private form: FormInstance;
  private SHORT_MAX = 1000;
  private TITLE_MAX = 160;

  // Possible to only set date starting from tomorrow and a year ahead.
  getDisabledDates = (date: Moment): boolean =>
    !date.isBetween(Date.now(), moment().add(1, 'years'), 'days', '()');

  handleChange = (_, fields /*: TFormField[]*/) =>
    this.props.onChange({
      ...this.props.fields,
      ...formFields2Dict(fields)
    });

  handleRef = (form) => {
    if (form) {
      this.props.onFormRef(form);
      this.form = form;
    }
  };

  handleSubmit = () => {
    const dates = this.form.getFieldValue('dates').filter(Boolean);
    if (dates.length === 0) {
      return setTimeout(() => {
        this.form.setFields([
          {
            name: ['dates'],
            errors: ['Start~end dates are required!']
          }
        ]);
      });
    }
    this.props.onSubmit();
  };

  render() {
    const { children, fields } = this.props;
    const { shortDescription, title } = fields;
    const fieldsArr = Object.values(fields) as any;

    const titleCount = title && title.value ? title.value.length : 0;
    const titleCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': titleCount > this.TITLE_MAX
    });

    const shortCount =
      shortDescription && shortDescription.value
        ? shortDescription.value.length
        : 0;
    const shortCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': shortCount > this.SHORT_MAX
    });

    return (
      <div className="EventMainForm EditModeForm">
        <Form
          className="EventMainForm__content"
          fields={fieldsArr}
          onFieldsChange={this.handleChange}
          onFinish={this.handleSubmit}
          ref={this.handleRef}
        >
          <div className="EditModeForm__title">
            <Form.Item name="title" rules={titleRules}>
              <Input className="EventMainForm__input" placeholder="Title" />
            </Form.Item>
            <span className={titleCls}>
              {titleCount}/{this.TITLE_MAX}
            </span>
          </div>

          <div className="EditModeForm__title">
            <Form.Item name="shortDescription" rules={shortRules}>
              <Input.TextArea
                className="EventMainForm__area"
                placeholder="Brief summary"
              />
            </Form.Item>
            <span className={shortCls}>
              {shortCount}/{this.SHORT_MAX}
            </span>
          </div>

          <Row gutter={24}>
            <Col xl={16}>
              <div className="EventMainForm__period">
                <Form.Item
                  label="Start~end dates"
                  name="dates"
                  rules={datesRules}
                >
                  {/*<label className="EventMainForm__label">Start~end dates</label>*/}
                  <DatePicker.RangePicker
                    disabledDate={this.getDisabledDates}
                    format={DATE_FORMAT}
                    size="large"
                  />
                </Form.Item>
              </div>
            </Col>

            <Col xl={8}>
              <div className="EventMainForm__zone">
                <Form.Item label="GMT shift" name="utcOffset">
                  {/*<label className="EventMainForm__input">GMT shift</label>*/}
                  <Select options={timezones} size="large" />
                </Form.Item>
              </div>
            </Col>
          </Row>

          {children}
          <Form.Item name="longDescription">
            {/*// @ts-ignore*/}
            <RTEBasic />
          </Form.Item>
        </Form>
      </div>
    );
  }
}

EventMainForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
export default EventMainForm;
