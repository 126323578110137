import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { getRefrigerationBlock } from '../../../helpers/getRefrigerationBlock';
import ProcessingFactory from './components/ProcessingFactory';

import {
  EElectronics,
  EFarmAutomation,
  EOtherInstallationsType,
  ELoadingUnloading,
  TInstallations
} from 'react-fishfacts/dist';

type Props = {
  installations: TInstallations;
  getManufacturerFieldById: (number) => string | JSX.Element;
};

const Installations = ({
  installations,
  getManufacturerFieldById
}: Props): ReactElement => {
  const {
    processingFactory,
    loadingUploading,
    refrigeration,
    boilers,
    automation,
    electronics,
    otherInstallations
  } = installations;

  return (
    <table className="ExtendedFields__table">
      <tbody>
        <ProcessingFactory
          processingFactory={processingFactory}
          getManufacturerFieldById={getManufacturerFieldById}
        />

        {loadingUploading && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Loading/Unloading</th>
            </tr>
            {loadingUploading.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(loadingUploading.manufacturerId)}
                </td>
              </tr>
            )}
            {loadingUploading.type && (
              <tr>
                <th>Type</th>
                <td>{ELoadingUnloading[loadingUploading.type]}</td>
              </tr>
            )}
          </>
        )}

        {getRefrigerationBlock(refrigeration, getManufacturerFieldById)}

        {boilers && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Boilers</th>
            </tr>
            <tr>
              <th>Provider</th>
              <td>{getManufacturerFieldById(boilers.manufacturerId)}</td>
            </tr>
          </>
        )}

        {automation && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Automation</th>
            </tr>
            {automation.map(({ type, manufacturerId }, index) => (
              <React.Fragment key={`automation-${manufacturerId}-${type}`}>
                <tr>
                  <th>Provider</th>
                  <td>{getManufacturerFieldById(manufacturerId)}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>{EFarmAutomation[type]}</td>
                </tr>

                {index + 1 < automation.length && (
                  <tr>
                    <td colSpan={2} height={25} />
                  </tr>
                )}
              </React.Fragment>
            ))}
          </>
        )}

        {electronics && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Electronics</th>
            </tr>
            {electronics.map(({ type, manufacturerId }, index) => (
              <React.Fragment key={`electronics-${manufacturerId}-${type}`}>
                <tr>
                  <th>Provider</th>
                  <td>{getManufacturerFieldById(manufacturerId)}</td>
                </tr>
                <tr>
                  <th>Equipment</th>
                  <td>{EElectronics[type]}</td>
                </tr>

                {index + 1 < electronics.length && (
                  <tr key={`tab-${manufacturerId}-${type}`}>
                    <td colSpan={2} height={25} />
                  </tr>
                )}
              </React.Fragment>
            ))}
          </>
        )}

        {otherInstallations && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Other installations</th>
            </tr>
            {otherInstallations.map(({ type, manufacturerId }, index) => (
              <React.Fragment
                key={`otherInstallations-${manufacturerId}-${type}`}
              >
                <tr>
                  <th>Provider</th>
                  <td>{getManufacturerFieldById(manufacturerId)}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>{EOtherInstallationsType[type]}</td>
                </tr>

                {index + 1 < otherInstallations.length && (
                  <tr key={`tab-${manufacturerId}-${type}`}>
                    <td colSpan={2} height={25} />
                  </tr>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};

Installations.propTypes = {
  installations: PropTypes.shape({
    boilers: PropTypes.object,
    refrigeration: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loadingUploading: PropTypes.object,
    processingFactory: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    automation: PropTypes.array
  }).isRequired,
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default Installations;
