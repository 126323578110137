import React from 'react';
import PropTypes from 'prop-types';
import { THandledObject } from '../../../../store/provider/providerEditor/providerEditor';

type TAbstractEditorProps<T> = {
  onAdd: () => void;
  onEdit: (obj?: T) => void;
  onHandledObjectUpdate: (update: Partial<THandledObject>) => void;
  onRemove: (objectId: number) => void;
  onSubmit: (object: T) => void;
  onUpload?: (file: File, objectId: number) => void;
  selectedItem: T;
  uploadProgress?: number;
};

abstract class AbstractEditor<T, Props> extends React.PureComponent<
  TAbstractEditorProps<T> & Props
> {
  static propTypes;

  handleCancel = () => this.props.onEdit();

  handleEdit = (item: T) => this.props.onEdit(item);

  handleSubmit = (values: Partial<T>) => {
    const { onSubmit, selectedItem } = this.props;
    onSubmit({
      ...selectedItem,
      ...values
    });
  };

  handleUpload = (file: File) => {
    const { onUpload, selectedItem } = this.props;
    typeof onUpload === 'function' && onUpload(file, selectedItem['id']);
  };

  abstract render(): React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
}

AbstractEditor.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onHandledObjectUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  selectedItem: PropTypes.object,
  uploadProgress: PropTypes.number
};

export default AbstractEditor;
