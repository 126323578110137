import React from 'react';
import PropTypes from 'prop-types';

import { TDimesions } from 'react-fishfacts/dist';

type Props = {
  dimensions: TDimesions;
};

const Dimensions = ({
  dimensions: {
    bruttotons,
    nettotons,
    deadWeight,
    depth,
    length,
    lengthOverall,
    width,
    draft,
    displacement
  }
}: Props) => (
  <table className="ExtendedFields__table">
    <tbody>
      {!!bruttotons && (
        <tr>
          <th>Gross tonnage, tons</th>
          <td>{bruttotons}</td>
        </tr>
      )}
      {!!nettotons && (
        <tr>
          <th>Net Tonnage, tons</th>
          <td>{nettotons}</td>
        </tr>
      )}
      {!!deadWeight && (
        <tr>
          <th>Dead weight, tons</th>
          <td>{deadWeight}</td>
        </tr>
      )}
      {!!depth && (
        <tr>
          <th>Depth, m</th>
          <td>{depth}</td>
        </tr>
      )}
      {!!length && (
        <tr>
          <th>Length, m</th>
          <td>{length}</td>
        </tr>
      )}
      {!!lengthOverall && (
        <tr>
          <th>Length Overall, m</th>
          <td>{lengthOverall}</td>
        </tr>
      )}
      {!!width && (
        <tr>
          <th>Beam, m</th>
          <td>{width}</td>
        </tr>
      )}
      {!!draft && (
        <tr>
          <th>Draft, m</th>
          <td>{draft}</td>
        </tr>
      )}
      {!!displacement && (
        <tr>
          <th>Displacement, tons</th>
          <td>{displacement}</td>
        </tr>
      )}
    </tbody>
  </table>
);

Dimensions.propTypes = {
  vesselSizes: PropTypes.shape({
    bruttotons: PropTypes.number,
    nettotons: PropTypes.number,
    deadWeight: PropTypes.number,
    depth: PropTypes.number,
    length: PropTypes.number,
    lengthOverall: PropTypes.number,
    width: PropTypes.number,
    draft: PropTypes.number,
    displacement: PropTypes.number
  })
};

export default Dimensions;
