import React from 'react';
import PropTypes from 'prop-types';
import { TLabelValue } from 'react-fishfacts/dist';

import { getSelectorUpdate, getSelectorValue } from './helpers';
import VesselTypesSelect from '../../../../../components/selects/VesselTypesSelect/VesselTypesSelect';

type Props = {
  className?: string;
  onChange?: (types: string[]) => void;
  options: TLabelValue[];
  optionsIds: string[];
  value?: string[];
};

class VesselTypesSelector extends React.PureComponent<Props> {
  static propTypes;

  handleChange = (values: string[]): void => {
    const { onChange, optionsIds, value } = this.props;
    onChange(getSelectorUpdate(values, value, optionsIds));
  };

  render() {
    const { className, options, optionsIds, value } = this.props;
    const _value = getSelectorValue(optionsIds, value) as any;

    return (
      <VesselTypesSelect
        className={className}
        onChange={this.handleChange as any}
        options={options}
        value={_value}
      />
    );
  }
}

VesselTypesSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

export default VesselTypesSelector;
