import { TUserInfo } from '../../types/userInfo';
import { TBasicState } from '../_utils/basicState';

export type TSessionState = TBasicState & {
  redirectPath: string;
  user?: TUser;
};

export type TUser = Readonly<{
  name: string;
  token: string;
  userInfo: TUserInfo;
}>;

export enum EUserAuthorities {
  ADMIN = 'ADMIN',
  CREATE_NEWS = 'CREATE_NEWS',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  USER = 'USER',
  VESSEL_EXTENDED_FIELDS = 'VESSEL_EXTENDED_FIELDS',
  VIEW_ANALYTICS = 'VIEW_ANALYTICS',
  VIEW_OWNER = 'VIEW_OWNER',
  VIEW_MAP = 'VIEW_MAP'
}
