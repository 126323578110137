import * as Sentry from '@sentry/browser';
import { THttpResponse } from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import {
  clearAuthSettings,
  getCredentialsIfPreserved,
  saveCredentials,
  saveToken,
  setKeepLogged
} from 'services/auth';
import { createAsyncActions } from 'store/_utils/createAsyncActions';
import { ENDPOINTS, ROUTES } from 'other/config';
import http from 'services/http';
import { prefetchDataAction } from 'store/dictionaries/dictionariesActions';
import {
  followRedirectPathAction,
  storeSessionAction
} from 'store/session/sessionActions';
import store from 'store/store';

import { ELoginActions } from './loginConstants';
import { TCredentials } from 'types/credentials';
import { TLoginState } from './loginModel';
import { TState } from 'store/appStateModel';
import { TUser } from 'store/session/sessionModel';

export let loginSet;

/**
 * Fired by a login form.
 * @param username
 * @param password
 * @param shouldKeepLogged
 */
export function loginAction(
  username: string,
  password: string,
  shouldKeepLogged: boolean
) {
  return (dispatch) => {
    setKeepLogged(shouldKeepLogged);
    shouldKeepLogged && saveCredentials({ username, password } as TCredentials);

    loginSet = createAsyncActions<TLoginState>(
      dispatch,
      ELoginActions.LOGIN
    ).request();

    sendLoginRequest(username, password, true)
      .then(handleLoginResponse)
      .catch((e: Error) => {
        const message = e.message.includes('401')
          ? 'Wrong user name and/or password'
          : e.message;

        loginSet.error(e, {
          displayError: message
        });
      });
  };
}

export function autoLoginAction() {
  return (dispatch, getState) => {
    const { login } = getState() as TState;
    if (login.isPending) return;

    const credentials = getCredentialsIfPreserved() as TCredentials;
    if (!credentials) {
      saveToken();
      return dispatch(push(ROUTES.LOGIN));
    }

    loginSet = createAsyncActions<TLoginState>(
      dispatch,
      ELoginActions.LOGIN
    ).request();

    sendLoginRequest(credentials.username, credentials.password)
      .then(handleLoginResponse)
      .catch(loginSet.error);
  };
}

/**
 * Fired on logOut. Clears the auth token and user data.
 */
export function logoutAction() {
  return (dispatch) => {
    clearAuthSettings();
    dispatch(storeSessionAction(null));
    sendLogoutRequest();
  };
}

/**
 * Forms login request body and hits the login endpoint.
 */
export function sendLoginRequest(
  username: string,
  password: string,
  skipToken?: boolean
): Promise<any> {
  const params = new FormData();
  params.append('username', username);
  params.append('password', password);

  return http.send(
    {
      body: params,
      credentials: 'include',
      method: 'POST',
      url: ENDPOINTS.LOGIN
    },
    {
      skipToken: skipToken
    }
  );
}

/**
 * Login response handler. Handles 401, general errors and success.
 */
export const handleLoginResponse = (
  res: THttpResponse<TUser>
): void | never => {
  if (res.status === 401) {
    clearAuthSettings();
    store.dispatch(push(ROUTES.LOGIN));
    throw new Error('Bad credentials or token expired!');
  }

  if (!res || !res.ok) {
    const msg = 'Network problem. Please, retry later.';
    throw new Error(res.statusText || msg);
  }

  saveToken(res.data.token);
  Sentry.configureScope((scope) =>
    scope.setUser({
      id: String(res.data.userInfo.id)
    })
  );

  loginSet.success({ displayError: null });
  store.dispatch(storeSessionAction(res.data));
  store.dispatch(prefetchDataAction(true));
  store.dispatch(followRedirectPathAction());
};

/**
 * Hits logout endpoint, reloads the page.
 */
export function sendLogoutRequest(): void {
  http
    .send({
      credentials: 'include',
      method: 'DELETE',
      url: ENDPOINTS.LOGOUT
    })
    .then(() => window.location.reload())
    .catch(() => window.location.reload());
}
