import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import '../ServiceProvider.scss';
import Expander from '../../../../components/common/Expander/Expander';
import VideoCard from './VideoCard';
import { TVideo } from '../../../../types/media';

type Props = {
  videos: TVideo[];
};


const Videos = ({ videos }: Props): ReactElement | null => {
  if (!videos || !videos.length) return null;

  const slides: ReactElement[] = videos.map((video: TVideo): ReactElement => (
    <VideoCard key={video.id} video={video} />
  ));

  return (
    <section className="ServiceProvider__group">
      <Expander itemWidth={260} title="Videos">
        {slides}
      </Expander>
    </section>
  );
};

Videos.propTypes = {
  videos: PropTypes.array
};

export default React.memo(Videos);
