import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { LoaderOverlay } from 'react-fishfacts/dist';

import './FleetsManager.scss';
import {
  createFleetAction,
  removeFleetAction,
  renameFleetAction
} from '../../../store/fleets/fleetsActions';
import FleetsEditor from './FleetsEditor';

import { TFleetExt } from '../../../types/fleet';
import { TState } from '../../../store/appStateModel';

type Props = {
  fleets: TFleetExt[];
  isLoading: boolean;
  onCreate: (fleetName: string) => void;
  onRemove: (fleetId: number) => void;
  onRename: (fleet: TFleetExt, newName: string) => void;
};
type State = {
  isVisible: boolean;
};
const modalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  maskClosable: true,
  title: null
};

class FleetsManager extends React.Component<Props, State> {
  static propTypes;
  state: State = { isVisible: false };

  handleCancel = () => this.setState({ isVisible: false });

  showMenu = () => this.setState({ isVisible: true });

  render() {
    const { isLoading, ...restOfProps } = this.props;
    const { isVisible } = this.state;

    return (
      <div className="FleetsManager">
        <Button
          className="FleetsManager__link"
          onClick={this.showMenu}
          type="link"
        >
          Manage fleets
        </Button>

        <Modal
          visible={isVisible}
          {...modalProps}
          onCancel={this.handleCancel}
          className="modal-small"
        >
          <LoaderOverlay
            className="FleetsManager__loader"
            isLoading={isLoading}
            isTransparent={true}
          >
            <FleetsEditor onCancel={this.handleCancel} {...restOfProps} />
          </LoaderOverlay>
        </Modal>
      </div>
    );
  }
}

FleetsManager.propTypes = {
  fleets: PropTypes.array,
  isLoading: PropTypes.bool,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  onRename: PropTypes.func
};

const mapStateToProps = ({ fleets }: TState) => ({
  fleets: fleets.fleets,
  isLoading: fleets.isPending
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: createFleetAction,
      onRemove: removeFleetAction,
      onRename: renameFleetAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FleetsManager);
