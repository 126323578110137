import { message } from 'antd';

import AbstractMap from './utils/AbstractMap';
import { mapOptions } from './utils/helpers';
import { setUpWindyMap } from './utils/initialization';

import { EMapLayer } from '../../types/mapLayer';
import { IWindyApi } from './types/windy';
import { TCommonMapProps } from './types/common';

class WindyMap extends AbstractMap {
  rootId = 'windy';

  componentDidMount() {
    const { currentMapLayer } = this.props.mapOptions;
    this.initMap();
    this.setLayer(currentMapLayer);
  }

  componentDidUpdate(prevProps: Readonly<TCommonMapProps>): void {
    const { currentMapLayer } = this.props.mapOptions;
    currentMapLayer !== prevProps.mapOptions.currentMapLayer &&
      this.setLayer(currentMapLayer);
  }

  initMap() {
    const {
      mapOptions: { currentMapLayer },
      updateMapOptions
    } = this.props;

    if (!window['windyInit']) {
      message.error(
        "This layer isn't available at the moment. Please retry soon."
      );
      return updateMapOptions({ layer: EMapLayer.EEZ });
    }

    const initialize = (api: IWindyApi): void => {
      this.mapApi = api;
      setUpWindyMap(api, currentMapLayer);
      api.store.set('disableWebGL', true);
      api.map.whenReady(() => this.setState({ hasInitPassed: true }));
      this.setState({ hasInitPassed: true });
    };

    // A hack for windy's DI warning.
    if (!window['copy_of_W']) window['copy_of_W'] = { ...window['W'] };
    if (window['W'] && window['W'].windyBoot)
      window['W'] = { ...window['copy_of_W'] };
    (window as any).windyInit(mapOptions, initialize);
  }

  setLayer(layer: EMapLayer): void {
    if (!this.mapApi) {
      setTimeout(() => this.setLayer(layer), 300);
    } else {
      this.mapApi.store.set('overlay', layer);
    }
  }
}

export default WindyMap;
