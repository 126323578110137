import { createSelector } from 'reselect';

import { LOCALE } from '../../../other/config';
import { EColorDescriptor } from '../types/colorDescriptor';
import {
  ESupportedApps,
  TCountry,
  TDictionaries,
  TVesselType
} from '../../../types/dictionaries';
import { TMapLegend } from '../types/common';

// Utilized CSS colors:
//
// black
// cyan
// MediumBlue
// green
// lime
// magenta
// orange
// red
// SaddleBrown
// violet
// white
// yellow

export const countryColorMap = {
  AN: ['SaddleBrown', 'SaddleBrown'],
  CL: ['black', 'black'],
  CN: ['white', 'white'],
  DE: ['Lime', 'Lime'],
  DK: ['yellow', 'yellow'],
  EE: ['black', 'cyan'],
  ES: ['black', 'MediumBlue'],
  FO: ['green', 'green'],
  GB: ['magenta', 'magenta'],
  GL: ['black', 'green'],
  IE: ['orange', 'orange'],
  IS: ['MediumBlue', 'MediumBlue'],
  KR: ['black', 'lime'],
  LT: ['black', 'magenta'],
  LV: ['black', 'orange'],
  NL: ['black', 'red'],
  NO: ['red', 'red'],
  PL: ['black', 'SaddleBrown'],
  PT: ['black', 'violet'],
  RU: ['black', 'white'],
  SW: ['black', 'yellow'],
  FR: ['cyan', 'cyan'],
  UA: ['cyan', 'MediumBlue'],
  VU: ['cyan', 'green'],
  AU: ['cyan', 'lime'],
  BZ: ['cyan', 'magenta'],
  CA: ['cyan', 'orange'],
  JP: ['cyan', 'red'],
  NZ: ['cyan', 'SaddleBrown'],
  ZA: ['cyan', 'violet'],
  US: ['cyan', 'white'],
  UY: ['cyan', 'yellow'],
  BS: ['MediumBlue', 'green'],
  MT: ['MediumBlue', 'lime']
};

export const vesselTypesColorMap = {
  1: ['black', 'cyan'],
  2: ['green', 'green'],
  3: ['red', 'red'],
  4: ['MediumBlue', 'MediumBlue'],
  5: ['yellow', 'yellow'],
  6: ['black', 'black'],
  7: ['white', 'white'],
  8: ['cyan', 'cyan'],
  9: ['magenta', 'magenta'],
  10: ['orange', 'orange'],
  11: ['Lime', 'Lime'],
  12: ['SaddleBrown', 'SaddleBrown'],
  13: ['violet', 'violet'],
  14: ['black', 'MediumBlue'],
  15: ['black', 'green'],
  16: ['black', 'lime'],
  17: ['black', 'magenta'],
  18: ['black', 'orange'],
  19: ['black', 'red'],
  20: ['black', 'SaddleBrown'],
  21: ['black', 'violet'],
  22: ['black', 'white'],
  23: ['black', 'yellow'],
  24: ['cyan', 'MediumBlue'],
  25: ['cyan', 'green'],
  26: ['cyan', 'lime'],
  27: ['cyan', 'magenta'],
  28: ['cyan', 'orange'],
  29: ['cyan', 'red'],
  30: ['cyan', 'SaddleBrown'],
  31: ['cyan', 'violet'],
  32: ['cyan', 'white'],
  33: ['cyan', 'yellow']
};

export const providerBranchColors = [
  '#B3F95B',
  '#20FFAF',
  '#9FD700',
  '#02BCC8',
  '#068504',
  '#00C2FF',
  '#4E74FC',
  '#2044FF',
  '#0216C8',
  '#15127B',
  '#BA9052',
  '#932323',
  '#EDFF20',
  '#FFD525',
  '#FF8717',
  '#FF2020',
  '#FFDADA',
  '#FF86B9',
  '#FF2098',
  '#CC12C5',
  '#8D0088',
  '#C095FA',
  '#9D20FF',
  '#771DBE'

  // 'cyan',
  // 'MediumBlue',
  // 'green',
  // 'lime',
  // 'magenta',
  // 'orange',
  // 'red',
  // 'SaddleBrown',
  // 'violet',
  // 'yellow'
];

// Tracks coloring
export const colorArray: string[] = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF'
];

export function getTrackColour(
  colorMap: Record<number, string>,
  vesselId: number
): string {
  if (colorMap[vesselId]) return colorMap[vesselId];

  let i = 0;
  const colorsInUse: string[] = Object.values(colorMap);

  while (true) {
    if (colorsInUse.includes(colorArray[i])) {
      i += 1;
    } else {
      break;
    }
  }
  return colorArray[i];
}
// A couple of atomic selectors.
const colorDescriptorSelector = (args: {
  colorDescriptor: EColorDescriptor;
}): EColorDescriptor => args.colorDescriptor;

const countriesSelector = (args: {
  dictionaries: Partial<TDictionaries>;
}): TCountry[] => args['dictionaries'].countries;

// Returns flattered array of vessel types. And also filtered by Fishing type.
const typesSelector = createSelector(
  (args: { dictionaries: Partial<TDictionaries> }): TVesselType[] =>
    args.dictionaries.vesselTypes,
  (vesselTypes: TVesselType[]): TVesselType[] =>
    vesselTypes
      .map((type: TVesselType) => {
        if (!type.subTypes || type.subTypes.length < 1) return type;
        return [type, ...type.subTypes];
      })
      .flat()
      .filter(({ supportedApps }: TVesselType) =>
        supportedApps.includes(ESupportedApps.FISHFACTS)
      )
);
/**
 * Returns a legend color set for the given color descriptor.
 */
export const selectLegends = createSelector(
  [colorDescriptorSelector, countriesSelector, typesSelector],
  (
    colorDescriptor: EColorDescriptor,
    countries: TCountry[],
    vesselTypes: TVesselType[]
  ): TMapLegend[] => {
    if (colorDescriptor === EColorDescriptor.COUNTRIES) {
      return countries.map(
        (country: TCountry): TMapLegend => ({
          colors: countryColorMap[country.value],
          label: country.value
        })
      );
    }
    return vesselTypes.map(
      (type: TVesselType): TMapLegend => ({
        colors: vesselTypesColorMap[type.id],
        label: type.value[LOCALE]
      })
    );
  }
);
