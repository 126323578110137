import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ScheduleItems.scss';
import OwnersItem from './items/OwnersItem';
import UsersItem from './items/UsersItem';

import { eventScheduleBaseProps, TEventScheduleBaseProps } from '../helpers';
import { TScheduleItem } from '../../../../types/event';

type Props = TEventScheduleBaseProps & {
  items: TScheduleItem[];
  utcOffset: string;
};


const ScheduleItems = ({ isOwner, items, onApproveReject, onBookCancel, userId, utcOffset }: Props): ReactElement => {
  const list = items.map((_item: TScheduleItem) =>
    /*true*/ isOwner ? (
      <OwnersItem
        item={_item}
        key={_item.id}
        onApproveReject={onApproveReject}
        utcOffset={utcOffset}
      />
    ) : (
      <UsersItem
        item={_item}
        key={_item.id}
        onBookCancel={onBookCancel}
        userId={userId}
        utcOffset={utcOffset}
      />
  ));

  return (
    <div className="ScheduleItems">
      {list}
    </div>
  );
};


ScheduleItems.propTypes = {
  ...eventScheduleBaseProps,
  items: PropTypes.array.isRequired,
};

export default ScheduleItems;
