import { push } from 'connected-react-router';

import deleteConfirm from '../../components/common/modals/deleteConfirm';
import { newsArticleRemove } from '../../store/news/news/newsActions';
import { ROUTES } from '../../other/config';
import store from '../../store/store';

import { EPromoStatus, TPromoted } from '../../types/promotion';

export function getStatus(
  articleId: number,
  promotedArticles: ReadonlyArray<TPromoted>,
  usersNews: ReadonlyArray<number>
): EPromoStatus {
  const promotedIds = promotedArticles
    .map(({ news }: TPromoted): number => news && news.id)
    .filter(Number);
  const isPromoted = promotedIds.includes(articleId);
  if (isPromoted) return EPromoStatus.PROMOTED;

  const isOwn = usersNews.includes(articleId);
  return isOwn ? EPromoStatus.POTENTIAL : EPromoStatus.UNABLE;
}

export function removeConfirm(articleId: number): void {
  deleteConfirm('Remove this article permanently?', () => {
    store.dispatch(newsArticleRemove(articleId));
    store.dispatch(push(ROUTES.NEWS));
  });
}
