import React from 'react';
import PropTypes from 'prop-types';

import { TMouseHandler } from '../../../types/handlers';

type Props = {
  onClose: TMouseHandler;
};

const MapLegendHeader = ({ onClose }: Props) => (
  <header className="RightSideDrawer__header">
    <div className="app-d-flex app-align-items-center">
      <button
        className="Burger Burger--arrow Burger__burger RightSideDrawer__burger Burger--arrowRight--isActive"
        onClick={onClose}
        type="button"
        data-testid="Burger"
      >
        <span className="Burger__box">
          <span className="Burger__inner" />
        </span>
      </button>

      <h3>TIP</h3>
    </div>
  </header>
);

MapLegendHeader.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default MapLegendHeader;
