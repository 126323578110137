import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ContactList.scss';
import { areEqual } from '../../../../other/helpers';
import CardWrapper from '../other/CardWrapper';
import ContactCard from '../../Service/Contacts/ContactCard';
import EmptyList from '../../../../components/common/EmptyList/EmptyList';
import Expander from '../../../../components/common/Expander/Expander';
import { TPerson } from '../../../../types/person';

type Props = {
  contacts: TPerson[];
  onAdd: () => void;
  onEdit: (contact: TPerson) => void;
  onRemove: (contactId: number) => void;
};
type State = {
  isExpanded: boolean;
};

class ContactList extends React.PureComponent<Props, State> {
  state: State = { isExpanded: false };
  static propTypes;

  /** We want the list to be expanded when we add\remove a contact */
  componentDidUpdate(prevProps: Props) {
    const { contacts } = this.props;

    if (
      contacts &&
      prevProps.contacts &&
      !areEqual(contacts, prevProps.contacts)
    ) {
      this.setState({ isExpanded: true });
    }
  }

  render() {
    const { contacts, onAdd, onEdit, onRemove } = this.props;

    const slides =
      contacts && contacts.length ? (
        contacts.map(
          (contact: TPerson): ReactElement => {
            const handleEdit = () => onEdit(contact);
            return (
              <CardWrapper
                id={contact.id}
                key={contact.id}
                onEdit={handleEdit}
                onRemove={onRemove}
              >
                <ContactCard contact={contact} />
              </CardWrapper>
            );
          }
        )
      ) : (
        <EmptyList
          placeholder="Add your first contact"
          placeholderImgUrl="/assets/placeholders/contact_card_placeholder.svg"
        />
      );

    return (
      <section className="ServiceProvider__group">
        <Expander
          buttonText="Add contact"
          isExpanded={this.state.isExpanded}
          itemWidth={220}
          onAdd={onAdd}
          title="Contacts"
        >
          {slides}
        </Expander>
      </section>
    );
  }
}

ContactList.propTypes = {
  contacts: PropTypes.array,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ContactList;
