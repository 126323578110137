import React from 'react';
import PropTypes from 'prop-types';

import './NewsList.scss';
import CarouselCustom from 'components/common/CarouselCustom/CarouselCustom';
import { getChunkSize, WIDTH_THRESHOLD_SMALL } from './helpers';
import LatestArticle from './articles/LatestArticle';
import NewsListInner from './NewsListInner';
import PromotedArticle from './articles/PromotedArticle';

import { TArticle } from 'types/article';
import { TPromoted } from 'types/promotion';

type Props = {
  list?: ReadonlyArray<TArticle>;
  promotedArticles?: ReadonlyArray<TPromoted>;
  usersNews: number[];
  width?: number;
};

class NewsList extends React.PureComponent<Props> {
  static propTypes: Record<string, any>;

  render() {
    const { list, promotedArticles, usersNews, width } = this.props;
    if (!list) return null;

    const chunkSize: number = getChunkSize(width);
    const carousel = promotedArticles && promotedArticles.length > 0 && (
      <CarouselCustom>
        {promotedArticles.map((prom: TPromoted) => (
          <PromotedArticle article={prom} key={prom.id} />
        ))}
      </CarouselCustom>
    );

    if (width <= WIDTH_THRESHOLD_SMALL || carousel) {
      return (
        <>
          {carousel}

          <NewsListInner
            chunkSize={chunkSize}
            key="0"
            list={list}
            promotedArticles={promotedArticles}
            usersNews={usersNews}
          />
        </>
      );
    }

    const [latest, ...rest] = list;
    return (
      <>
        <LatestArticle article={latest} />

        <NewsListInner
          chunkSize={chunkSize}
          list={rest}
          promotedArticles={promotedArticles}
          usersNews={usersNews}
        />
      </>
    );
  }
}

NewsList.propTypes = {
  list: PropTypes.array,
  promotedArticles: PropTypes.arrayOf(PropTypes.object),
  usersNews: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number
};

export default NewsList;
