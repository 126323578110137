import {
  Http,
  THttpResponse,
  THttpRequestOptions,
  THttpConfig
} from 'react-fishfacts/dist';

import { API_URL } from '../other/config';
import { autoLoginAction } from '../store/login/loginActions';
import { getToken, saveToken } from './auth';
import store from '../store/store';

export type THTTPConfig = THttpConfig & {
  skipToken?: boolean;
};

class HTTP extends Http {
  constructor() {
    super(API_URL, HTTP.handleResponse as any);
  }

  private static handleError<T>(res: THttpResponse<T>): never {
    const { message, status, statusText, url } = res;
    const msg = `HttpError: ${url} -- ${status} ${statusText || message}`;
    throw new Error(msg);
  }

  private static handleResponse<T>(
    res: THttpResponse<T>
  ): THttpResponse<T> | never {
    if (res.status === 401) {
      store.dispatch(autoLoginAction());
      saveToken();
      HTTP.handleError(res);
    }

    res.ok || HTTP.handleError(res);
    return res;
  }

  send(
    options: string | Partial<THttpRequestOptions>,
    config: THTTPConfig = {}
  ): Promise<any> {
    const token: string = getToken();

    if (!token && (!config || !config.skipToken)) {
      window.console.warn(`Invalid authentication token: ${token}`);
      return super.send(options, config);
    }

    const headers = {
      ...(config.headers || {}),
      'X-Auth-Token': token
    };

    return super.send(options, {
      ...config,
      headers: headers
    });
  }
}

export default new HTTP();
