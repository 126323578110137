import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppsInfo from './AppsInfo/AppsInfo';
import CategoryList from '../../components/common/CategoryList/CategoryList';
import Footer from './Footer/Footer';
import Intro from './Intro/Intro';
import Video from './Video/Video';

const Home = ({ categories }) => {
  return (
    <div className="Main__home">
      <Intro />
      <CategoryList categories={categories} />
      <Video />
      {AppsInfo}
      <Footer />
    </div>
  );
};

Home.propTypes = {
  categories: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  categories: state.serviceBranches.navigationCategories
});

export default connect(mapStateToProps)(Home);
