import {
  createAsyncActions,
  TAction,
  THttpResponse
} from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import { getItemRoute, SearchRequestUtil } from './SearchUtil';
import { highlightVesselAction } from '../map/mapEntities/mapEntitiesActions';
import http from '../../services/http';
import { isMap } from '../../components/helpers/helpers';
import { watchLocationsAction } from '../map/vesselsLocations/vesselsLocationsActions';

import { ESearchActions } from './searchConstants';
import {
  ESearchCategory,
  TSearchItemExtended,
  TSearchResult
} from '../../types/search';
import { TSearchState } from './searchModel';

export let searchSet;

/**
 * Fired on search key stroke.
 * @param searchQuery
 */
export function searchRequestAction(searchQuery: string) {
  return (dispatch, getState) => {
    const util = new SearchRequestUtil(getState);
    dispatch(searchTypedAction(searchQuery));
    if (!searchQuery) return;

    searchSet = createAsyncActions<TSearchState>(
      dispatch,
      ESearchActions.SEARCH
    ).request();

    return http
      .send(util.getRequestParams(searchQuery))
      .then(({ data }: THttpResponse<TSearchResult[]>) =>
        searchSet.success(util.getPayload(data))
      )
      .catch(searchSet.error);
  };
}

/**
 * Fired when user selects an item from obtained search result.
 * @param item
 */
export function selectSearchResultAction(item: TSearchItemExtended) {
  return (dispatch) => {
    const { category, id } = item as TSearchItemExtended;

    // If current page is the map and we're looking for a vessel, we want the vessel to be highlighted.
    if (isMap() && category === ESearchCategory.VESSEL) {
      dispatch(highlightVesselAction(Number(id)));
      return dispatch(watchLocationsAction());
    }
    // Otherwise just go to an item's page.
    dispatch(push(getItemRoute(item)));
    dispatch(searchTypedAction());
  };
}

/**
 * Reports what text has been typed. Also used to clear search results.
 * @param searchQuery
 */
export const searchTypedAction = (
  searchQuery = ''
): TAction<TSearchState, ESearchActions> => ({
  type: ESearchActions.SEARCH_TYPED,
  payload: {
    searchResults: null,
    searchQuery
  }
});
