import { readSettings, VESSELS_FILTER } from '../../../services/settings';
import { TVesselsFilterState } from './vesselsFilterModel';

export const vesselsFilterBlankState: TVesselsFilterState = {
  filterBruttoTons: { min: 0, max: 16000 },
  filterBuildYear: { min: 1950, max: new Date().getFullYear() },
  filterCountry: [],
  filterEngineModel: [],
  filterEnginePower: { min: 150, max: 16000 },
  filterVesselType: [],
  filterFleet: [],
  filterLength: { min: 0, max: 220 },
  filterSpecies: [],
  isFilterSet: false
};

const settings: Partial<TVesselsFilterState> = readSettings()[VESSELS_FILTER];
export const vesselsFilterInitialState: TVesselsFilterState = {
  ...vesselsFilterBlankState,
  ...settings
};
