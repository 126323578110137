import { minMaxLenValidator } from '../../../../../other/formAndValidation/validators';
import { EScheduleItemStatus, TScheduleItem } from '../../../../../types/event';

export const LABEL_MAP = {
  [EScheduleItemStatus.AVAILABLE]: 'Vacant',
  [EScheduleItemStatus.BOOKED]: 'Booked',
  [EScheduleItemStatus.PENDING]: 'Requested'
};

export const scheduleItemBlueprint: Partial<TScheduleItem> = {
  appointee: null,
  end: null,
  notes: null,
  start: null,
  status: EScheduleItemStatus.AVAILABLE,
  title: null
};

export const dateRules = [{ required: true, message: 'Please select date!' }];
export const titleRules = minMaxLenValidator('Title', 160, 0);
export const startRules = [
  { required: true, message: 'Please select start time!' }
];
export const endRules = [
  { required: true, message: 'Please select end time!' }
];
export const notesRules = minMaxLenValidator('Notes', 100);
