import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';

import './MapModeSwitch.scss';
import IconCustom from '../../../components/common/IconCustom/IconCustom';

import { EMapLayer } from '../../../types/mapLayer';
import { TMapOptionsState } from '../../../store/map/mapOptions/mapOptionsModel';

type Props = {
  currentMapLayer: EMapLayer;
  updateMapOptions: (options: Partial<TMapOptionsState>) => void;
};

const MapModeSwitch = ({
  currentMapLayer,
  updateMapOptions
}: Props): ReactElement => {
  const handleChange = ({ target: { value } }: RadioChangeEvent) =>
    updateMapOptions({ layer: value });

  return (
    <div className="MapModeSwitch">
      <Radio.Group onChange={handleChange} value={currentMapLayer}>
        <Radio.Button value={EMapLayer.EEZ}>
          <span title="Exclusive economic zones">{EMapLayer.EEZ}</span>
        </Radio.Button>

        <Radio.Button value={EMapLayer.SEABED}>
          <IconCustom title="Seabed" type="seabed-2" />
        </Radio.Button>

        {/*<Radio.Button value={TMapLayer.ICES}>*/}
        {/*  <span title="Exclusive economic zones">{TMapLayer.ICES}</span>*/}
        {/*</Radio.Button>*/}

        <Radio.Button value={EMapLayer.TEMP}>
          <IconCustom title="Temperature" type="thermometer" />
        </Radio.Button>

        <Radio.Button value={EMapLayer.WIND}>
          <IconCustom title="Wind" type="wind-sign" />
        </Radio.Button>

        <Radio.Button value={EMapLayer.WAVES}>
          <IconCustom title="Waves" type="water-waves" />
        </Radio.Button>

        <Radio.Button value={EMapLayer.CURR}>
          <IconCustom title="Currents" type="currents" />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

MapModeSwitch.propTypes = {
  currentMapLayer: PropTypes.string.isRequired,
  updateMapOptions: PropTypes.func.isRequired
};

export default MapModeSwitch;
