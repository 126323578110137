import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'antd';

import './ThemeToggle.scss';
import { setTheme } from '../../../../store/theme/themeActions';
import { TState } from '../../../../store/appStateModel';
import { IconCustom } from 'react-fishfacts/dist';

type Props = {
  isDark: boolean;
  setTheme: (isDark: boolean) => void;
};

class ThemeToggle extends React.PureComponent<Props> {
  static propTypes;

  toggleTheme = () => {
    const { isDark, setTheme } = this.props;
    setTheme(!isDark);
  };

  render() {
    return (
      <div className="ThemeToggle">
        <IconCustom type="day-night" />
        <Switch checked={this.props.isDark} onChange={this.toggleTheme} />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setTheme: (isDark: boolean) => dispatch(setTheme(isDark))
});

const mapStateToProps = ({ theme: { isDark } }: TState) => ({
  isDark
});

ThemeToggle.propTypes = {
  isDark: PropTypes.bool.isRequired,
  setTheme: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggle);
