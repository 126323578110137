import { errorAction, THttpResponse, okAction } from 'react-fishfacts/dist';

import { areEqual, deepClone } from '../../../other/helpers';
import { clearSelectionAction } from '../../map/mapEntities/mapEntitiesActions';
import { createAsyncActions } from '../../_utils/createAsyncActions';
import { ENDPOINTS } from '../../../other/config';
import {
  PROVIDERS_FILTER_BY_CATEGORY,
  writeSettings
} from '../../../services/settings';
import http from '../../../services/http';
import { providersFilterInitialSettings } from './providersInitialState';
import { purgeTracksAction } from '../../map/tracks/tracksActions';
import {
  unwatchLocationsAction,
  watchLocationsAction
} from '../../map/vesselsLocations/vesselsLocationsActions';

import { EProvidersActions } from './providersConstants';
import {
  TProvider,
  TProviderAddressShort,
  TServiceBranch
} from '../../../types/providers';
import { TProviderCategoryFilterSettings } from '../../../types/providerFilters';
import { TProvidersState } from './providers';
import { TState } from '../../appStateModel';

export let fetchSet;

/**
 * Simply retrieves providers. Calls for filtering on response.
 */
export function fetchProvidersAction() {
  return (dispatch, getState) => {
    const {
      providers: { providers }
    } = getState() as TState;
    if (providers && providers.length) return;

    fetchSet = createAsyncActions<TProvidersState>(
      dispatch,
      EProvidersActions.FETCH_PROVIDERS
    ).request();

    http
      .send(ENDPOINTS.SERVICE_PROVIDER)
      .then(({ data }: THttpResponse<TProvider[]>) => {
        fetchSet.success({ providers: data });
        dispatch(applyProvidersFilter());
      })
      .catch(fetchSet.error);
  };
}

/**
 * Defines whether current settings are different from the initial ones disregarding `isFilterSet` field value.
 * @param settings
 */
export function isProvidersFilterSet(
  settings: TProviderCategoryFilterSettings
): boolean {
  const sets = deepClone<TProviderCategoryFilterSettings>(settings);
  const inits = deepClone<TProviderCategoryFilterSettings>(
    providersFilterInitialSettings
  );
  // @ts-ignore
  delete sets['isFilterSet'];
  // @ts-ignore
  delete inits['isFilterSet'];
  return !areEqual(sets, inits);
}

/**
 * Sets filter for `providersModel.addressArray`, which are displayed on the map.
 * @param action
 * @param values
 * @returns {Function}
 */
export function setProvidersFilterAction(
  action: EProvidersActions,
  values: any
) {
  return (dispatch, getState) => {
    const {
      providers: { filterSettings }
    } = getState() as TState;

    // It's called 'partial' because we still need to update 'isFilterSet'.
    const partialSettingsUpdate: TProviderCategoryFilterSettings = {
      ...filterSettings,
      ...getFilterSettingsPartialUpdate(action, values)
    };
    const isFilterSet = isProvidersFilterSet(partialSettingsUpdate);
    const settingsUpdate: TProviderCategoryFilterSettings = {
      ...filterSettings,
      ...partialSettingsUpdate,
      isFilterSet: isFilterSet
    };

    dispatch(
      okAction(EProvidersActions.PROVIDERS_FILTER_SET, {
        filterSettings: settingsUpdate
      })
    );
    dispatch(applyProvidersFilter());
    writeSettings({ [PROVIDERS_FILTER_BY_CATEGORY]: settingsUpdate });

    // If no service categories are selected, show vessels. Otherwise remove vessels and tracks.
    if (!isFilterSet || partialSettingsUpdate.categoryIds === null) {
      dispatch(watchLocationsAction());
    } else {
      dispatch(unwatchLocationsAction());
      dispatch(clearSelectionAction());
      dispatch(purgeTracksAction());
    }
  };
}

/**
 * Resets the filter state and lets all the provider addresses.
 */
export function clearProvidersFilterAction() {
  return (dispatch) => {
    writeSettings({
      [PROVIDERS_FILTER_BY_CATEGORY]: providersFilterInitialSettings
    });
    dispatch(
      okAction(EProvidersActions.PROVIDERS_FILTER_SET, {
        filterSettings: providersFilterInitialSettings
      })
    );
    dispatch(applyProvidersFilter());
  };
}

/**
 * Maps given array of providers into array of their addresses arrays.
 * @param providers
 */
export function mapProviders(
  providers: TProvider[]
): TProviderAddressShort[][] {
  return providers.map(({ addresses, branches, id: providerId, name }) => {
    return addresses.map(
      ({ id: addressId, country, latitude, longitude, title }) => ({
        addressId,
        branches,
        country,
        name,
        position: [latitude, longitude],
        providerId,
        title
      })
    );
  });
}

export function getFilterSettingsPartialUpdate(
  actionType: EProvidersActions,
  values: any
): Partial<TProviderCategoryFilterSettings> {
  switch (actionType) {
    case EProvidersActions.PROVIDERS_FILTER_PARAM_CATEGORY:
      return { categoryIds: values };
    default:
      throw new Error('Unknown ProvidersFilter action type: ' + actionType);
  }
}

/**
 * Applies filter settings for `providersModel.addressArray`.
 * @returns {Function}
 */
export function applyProvidersFilter() {
  return (dispatch, getState) => {
    const {
      providers: { filterSettings, providers }
    } = getState() as TState;
    // @ts-ignore
    if (!providers || !providers.length) return;

    const settings = deepClone<TProviderCategoryFilterSettings>(filterSettings);
    // @ts-ignore
    delete settings['isFilterSet'];

    try {
      const addressArray: TProviderAddressShort[] = applyFilter(
        settings,
        providers
      );
      dispatch(
        okAction(EProvidersActions.PROVIDERS_FILTER_APPLY, { addressArray })
      );
    } catch (e) {
      window.console.error(`Action 'providersApplyFilter': ${e.message}`);
      dispatch(errorAction(EProvidersActions.PROVIDERS_FILTER_FAIL, e));
    }
  };
}

/**
 * Filters providers by given service categories.
 * @param categoryIds
 */
export const categoryFilter = (categoryIds: number[]): Function => (
  item: TProvider
): boolean => {
  if (!categoryIds) return false; // selected: 'None'
  if (!categoryIds.length) return true; // selected: 'All'
  if (!item.branches.length) return false;

  const branchIds: number[] = item.branches.map(({ id }: TServiceBranch) => id);
  return branchIds.some((branchId: number) => categoryIds.includes(branchId));
};

const filterMap: Record<string, Function> = {
  categoryIds: categoryFilter
};

/**
 * Performs providers filtering according to the filter settings.
 * @param settings
 * @param providers
 */
export function applyFilter(
  settings: TProviderCategoryFilterSettings,
  providers: TProvider[]
): TProviderAddressShort[] {
  let arr: TProvider[] = deepClone<TProvider[]>(providers);

  Object.keys(settings).forEach((key: string) => {
    arr = arr.filter(filterMap[key](settings[key])) as TProvider[];
  });

  const addrArr = mapProviders(arr) as TProviderAddressShort[][];
  // Concatenation of the addressArrays of each provider.
  const callback = (
    previousValue: TProviderAddressShort[],
    addrArr: TProviderAddressShort[]
  ) => {
    addrArr.forEach((addr: TProviderAddressShort) => previousValue.push(addr));
    return previousValue;
  };
  return addrArr.reduce(callback, []);
}
