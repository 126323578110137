import React from 'react';
import classnames from 'classnames';
import { LoaderOverlay, THttpResponse } from 'react-fishfacts/dist';
import { message } from 'antd';

import './AnalyticsPage.scss';
import {
  ENDPOINTS,
  ERR_MESSAGE_DURATION,
  SUB_PROJECTS
} from '../../other/config';
import http from '../../services/http';

type State = {
  isLoading: boolean;
  token: string;
};

class AnalyticsPage extends React.PureComponent<null, State> {
  state: State = {
    isLoading: true,
    token: null
  };

  componentDidMount() {
    http
      .send({
        method: 'POST',
        url: ENDPOINTS.TABLEAU_TOKEN
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  handleError = (e: Error) => {
    this.setState({ isLoading: false });
    window.console.error(e);
    message.error(
      `Failed to get Tableau token: ${e.message}`,
      ERR_MESSAGE_DURATION
    );
  };

  handleResponse = ({ data }: THttpResponse<{ token: string }>): void =>
    this.setState({
      isLoading: false,
      token: data.token
    });

  getUrl(): string {
    return `${SUB_PROJECTS.ANALYTICS}?token=${this.state.token}`;
  }

  render() {
    const { isLoading, token } = this.state;
    const cls = classnames('Analytics', {
      'Analytics--bordered': token
    });

    return (
      <LoaderOverlay className={cls} isLoading={isLoading}>
        {token ? (
          <iframe
            className="Analytics__frame"
            src={this.getUrl()}
            title="analytics"
          />
        ) : (
          <h3 className="Analytics__message">
            Failed to load Analytics. Please retry in a while.
          </h3>
        )}
      </LoaderOverlay>
    );
  }
}

export default AnalyticsPage;
