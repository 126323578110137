import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './FleetsEditor.scss';
import ArticleEditorHeader from '../articles/ArticleEditor/ArticleEditorHeader';
import CreateFleet from '../vessel/withFleets/CreateFleet';
import FleetsEditorItem from './FleetsEditorItem';
import { TFleetExt } from '../../../types/fleet';

type Props = {
  fleets: TFleetExt[];
  onCancel: () => void;
  onCreate: (fleetName: string) => void;
  onRemove: (fleetId: number) => void;
  onRename: (fleet: TFleetExt, newName: string) => void;
};

const FleetsEditor = ({
  fleets,
  onCancel,
  onCreate,
  onRemove,
  onRename
}: Props): ReactElement => {
  const isEmpty: boolean = !fleets || fleets.length === 0;

  const list = fleets.map(
    (fleet: TFleetExt): ReactNode => (
      <FleetsEditorItem
        fleet={fleet}
        key={fleet.id}
        onRemove={onRemove}
        onRename={onRename}
      />
    )
  );

  const placeholder: ReactNode = (
    <div className="FleetsEditor__placeholder">
      <div className="FleetsEditor__invocation">Create your first fleet</div>
      <img
        src="/assets/placeholders/fleets_placeholder.svg"
        alt=""
        className="FleetsEditor__image"
      />
    </div>
  );

  return (
    <div className="FleetsEditor">
      <ArticleEditorHeader onCancel={onCancel} title="Manage fleets" />

      <ul className="FleetsList">
        {isEmpty ? placeholder : list}

        <li className="FleetsList__btn">
          <CreateFleet onCreate={onCreate} />
        </li>
      </ul>
    </div>
  );
};

FleetsEditor.propTypes = {
  fleets: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired
};

export default FleetsEditor;
