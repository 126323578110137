import React from 'react';
import PropTypes from 'prop-types';

import './ArticleLogo.scss';
import BrandLogo from '../BrandLogo/BrandLogo';
import { ROUTES } from '../../../../other/config';
import { TArticleSource } from '../../../../types/article';

type Props = {
  source: TArticleSource;
};

const ArticleLogo = ({ source }: Props) => {
  if (!source || !source.logo) {
    return (
      <strong className="ArticleLogo__fallback">{source.name}</strong>
    );
  }

  const url: string = `${ROUTES.SERVICE_PROVIDER}/${source.id}`;
  return <BrandLogo className="ArticleLogo" logo={source.logo} url={url}/>
};


ArticleLogo.propTypes = {
// @ts-ignore
  source: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
  })
};

export default ArticleLogo;
