import { THttpRequestOptions } from 'react-fishfacts/dist';
import { ENDPOINTS } from '../../../../other/config';
import { ProviderMediaHandlingUtil } from './ProviderMediaHandlingUtil';

import { EProviderMediaObjectType } from '../../../../types/providers';
import { TProviderEditorState, THandledObject } from '../providerEditor';

/**
 * Handles provider-associated media object removing.
 */
export class ProviderMediaRemoveUtil extends ProviderMediaHandlingUtil {
  getRequestParams(): THttpRequestOptions {
    const prefix = `${ENDPOINTS.SERVICE_PROVIDER}/${this.id}`;

    return {
      body: void 0,
      method: 'DELETE',
      url: `${prefix}/${this.getSuffix()}/${this.objectId}`
    };
  }

  getPayload(): Partial<TProviderEditorState> {
    return {
      ...this.getArrayUpdate(),
      handledObject: null,
      handledObjectType: null
    };
  }

  private getArrayUpdate(): Partial<TProviderEditorState> {
    switch (this.type) {
      case EProviderMediaObjectType.ADDRESS:
        return {
          addresses: this.addresses.filter(this.filterCallback)
        };
      case EProviderMediaObjectType.CONTACT:
        return {
          contacts: this.contacts.filter(this.filterCallback)
        };
      case EProviderMediaObjectType.PRODUCT:
        return {
          products: this.products.filter(this.filterCallback)
        };
      case EProviderMediaObjectType.VIDEO:
        return {
          videos: this.videos.filter(this.filterCallback)
        };
    }
  }

  private filterCallback = ({ id }: THandledObject): boolean =>
    id !== this.objectId;
}
