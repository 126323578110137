import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { namespaces2reducers } from 'react-fishfacts/dist';

const namespaces = [
  // General section
  'dictionaries',
  'login',
  'person',
  'serviceBranches',
  'session',
  'search',
  'theme',
  // Vessels section
  'fleets',
  'vessel',
  'vessels',
  'vesselsComparator',
  'vesselsFilter',
  // News
  'news',
  'newsEditor',
  'newsRecent',
  // Provider
  'companyRegistration',
  'product',
  'productEditor',
  'provider',
  'providerEditor',
  'providers',
  // Events
  'events',
  'eventEditor',
  // Map
  'mapEntities',
  'mapOptions',
  'tracks',
  'vesselsLocations',
  // Promotion & Campaign
  'promo'

  // vessel (or client) related
  // 'contactList',
  // 'vessel',
];

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...namespaces2reducers(namespaces)
  });

export default createRootReducer;
