import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RTEBasic } from 'react-fishfacts/dist';

import './ProductForm.scss';
import { formFields2Dict } from '../../../../other/formAndValidation/formUtils';
import { minMaxLenValidator } from '../../../../other/formAndValidation/validators';
import { TFormFields } from '../../../../types/formFields';

type Props = {
  fields: TFormFields;
  onChange: (fields: TFormFields) => void;
  onFormRef: (form: FormInstance) => void;
};

const titleRules = minMaxLenValidator('Title', 160, 3);
const shortDescRules = minMaxLenValidator('Brief summary', 1000);

class ProductForm extends React.PureComponent<Props> {
  static propTypes;
  private SHORT_MAX = 1000;
  private TITLE_MAX = 160;

  assignForm = (form: FormInstance) => {
    form && this.props.onFormRef(form);
  };

  handleChange = (_, fields /*: TFormField[]*/) =>
    this.props.onChange({
      ...this.props.fields,
      ...formFields2Dict(fields)
    });

  render() {
    const { shortDescription, title } = this.props.fields;
    const fieldsArr = Object.values(this.props.fields) as any;

    const titleCount = title && title.value ? title.value.length : 0;
    const titleCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': titleCount > this.TITLE_MAX
    });

    const shortCount =
      shortDescription && shortDescription.value
        ? shortDescription.value.length
        : 0;
    const shortCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': shortCount > this.SHORT_MAX
    });

    return (
      <div className="ProductForm EditModeForm">
        <Form
          className="ProductForm__content"
          fields={fieldsArr}
          onFieldsChange={this.handleChange}
          ref={this.assignForm}
        >
          <div className="EditModeForm__title">
            <Form.Item name="title" rules={titleRules}>
              <Input className="ProductForm__input" placeholder="Title" />
            </Form.Item>
            <span className={titleCls}>
              {titleCount}/{this.TITLE_MAX}
            </span>
          </div>

          <div className="EditModeForm__title">
            <Form.Item name="shortDescription" rules={shortDescRules}>
              <Input.TextArea
                className="ProductForm__input"
                placeholder="Brief summary"
              />
            </Form.Item>
            <span className={shortCls}>
              {shortCount}/{this.SHORT_MAX}
            </span>
          </div>

          <Form.Item name="longDescription">
            {/*// @ts-ignore*/}
            <RTEBasic />
          </Form.Item>
        </Form>
      </div>
    );
  }
}

ProductForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFormRef: PropTypes.func.isRequired
};
export default ProductForm;
