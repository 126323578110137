import React from 'react';

import { transformToArray } from './helpers';

import {
  ECapacityUnits,
  ERefrigeration,
  TRefrigeration
} from 'react-fishfacts/dist';

export const getRefrigerationBlock = (
  refrigeration: TRefrigeration | TRefrigeration[],
  getManufacturerFieldById: (number) => string | JSX.Element
): JSX.Element => {
  if (!refrigeration) return null;

  const array = transformToArray(refrigeration);

  const refrigerators = array.map(
    ({ manufacturerId, type, volume, units }, index) => (
      <React.Fragment key={`refrigeration-${manufacturerId}-${type}`}>
        <tr className="ExtendedFields__listItem">
          <th colSpan={2}>Refrigerator {index + 1}</th>
        </tr>
        {manufacturerId && (
          <tr>
            <th>Provider</th>
            <td>{getManufacturerFieldById(manufacturerId)}</td>
          </tr>
        )}
        {type && (
          <tr>
            <th>Type</th>
            <td>{ERefrigeration[type]}</td>
          </tr>
        )}
        {volume && (
          <tr>
            <th>Volume</th>
            <td>
              {volume} {ECapacityUnits[units]}
            </td>
          </tr>
        )}
        {index + 1 < array.length && (
          <tr>
            <th>&nbsp;</th>
            <td> </td>
          </tr>
        )}
      </React.Fragment>
    )
  );

  return (
    <>
      <tr className="ExtendedFields__paragraph">
        <th colSpan={2}>Refrigeration</th>
      </tr>
      {refrigerators}
    </>
  );
};
