import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './BrandLogo.scss';
import { PROVIDER_IMAGE_URL } from '../../../../other/config';
import { TMedia } from '../../../../types/media';

type Props = {
  className?: string;
  logo: TMedia;
  url: string;
};


const BrandLogo: React.FC<Props> = ({ className, logo, url }): ReactElement | null => {
  if (!logo) return null;

  const cls: string = classnames('BrandLogo', className);
  const imgUrl = `${PROVIDER_IMAGE_URL}/${logo.path}`;

  return (
		<Link className={cls} to={url}>
			<img src={imgUrl} alt="" className="BrandLogo__image"/>
		</Link>
  );
};


BrandLogo.propTypes = {
  className: PropTypes.string,
  // @ts-ignore
  logo: PropTypes.shape({
    path: PropTypes.string.isRequired
  }),
  url: PropTypes.string.isRequired
};

export default React.memo(BrandLogo);
