import React from 'react';
import PropTypes from 'prop-types';
import { areEqual, TLabelValue } from 'react-fishfacts/dist';
import CountriesSelect from '../../../../../components/selects/CountriesSelect/CountriesSelect';

type Props = {
  className?: string;
  onChange?: (countries: string[]) => void;
  options: TLabelValue[];
  value?: string[];
};

class CountriesSelector extends React.PureComponent<Props> {
  static propTypes;

  getValue = (): string[] => {
    const { options, value } = this.props;
    if (!value) return;
    const _countries = options.map(({ value }: TLabelValue) => value).sort();
    return areEqual(value.sort(), _countries) ? [] : value;
  };

  handleChange = (values: string[]): void => {
    const { onChange, options } = this.props;
    const countries =
      values.length > 0
        ? values
        : options.map(({ value }: TLabelValue) => value);
    onChange(countries.sort());
  };

  render() {
    const { className, options } = this.props;
    return (
      <CountriesSelect
        className={className}
        onChange={this.handleChange}
        options={options}
        value={this.getValue()}
      />
    );
  }
}

CountriesSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.string)
};

export default CountriesSelector;
