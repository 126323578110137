import React, { ReactElement } from 'react';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';

import './VesselFilter.scss';
import { BANNER_LINK } from '../../../../other/config';
import CountriesFilter from './components/CountriesFilter/CountriesFilter';
import EnginesFilter from './components/EngineFilter/EnginesFilter';
import { IconCustom } from 'react-fishfacts/dist';
import FleetsFilter from './components/FleetsFilter/FleetsFilter';
import FleetsManager from '../../FleetsManager/FleetsManager';
import Sliders from './components/Sliders/Sliders';
import VesselTypesFilter from './components/VesselTypesFilter/VesselTypesFilter';

const VesselFilter = (): ReactElement => {
  const expandIcon = () => <IconCustom type="chevron-right" />;

  return (
    <div className="VesselFilter">
      <div className="VesselFilter__row">
        <label className="VesselFilter__label--bold">My Fleets</label>
        <FleetsManager />
        <FleetsFilter />
      </div>

      <div className="VesselFilter__row">
        <label className="VesselFilter__label--bold">COUNTRY</label>
        <CountriesFilter />
      </div>

      <div className="VesselFilter__row">
        <label className="VesselFilter__label--bold">VESSEL TYPE</label>
        <VesselTypesFilter />
      </div>
      <div className="VesselFilter__row">
        <div className="VesselFilter__banner">
          <Link to={BANNER_LINK}>
            <img
              src="/assets/banner/fishfacts/analytics/222x42.jpg"
              alt="Banner"
              width="100%"
            />
          </Link>
        </div>
      </div>

      <Collapse
        accordion={true}
        className="FilterCollapse"
        expandIcon={expandIcon}
      >
        <Collapse.Panel header="Technical Details" key="1">
          <div className="VesselFilter__row">
            <label className="VesselFilter__label">ENGINE MODEL</label>
            <EnginesFilter />
          </div>
          <Sliders />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default VesselFilter;
