import React, { ReactElement, ReactNode } from 'react';
import './TimeLine.scss';


const HOURS_24 = 24 * 60 * 60 * 1000;
const WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function getTimeLine(days: number): TDate[] {
  const now = Date.now();
  const getDay = (_, i: number): TDate => {
    const date = new Date(now + i * HOURS_24);
    return {
      day: date.getDate(),
      weekDay: WEEK[date.getDay()]
    };
  };

  return Array(days).fill(null).map(getDay);
}

function calcWidth(): string {
  const fullWidth: number = document.documentElement.clientWidth - 66;
  return `${fullWidth}px`;
}

type TDate = {
  day: number;
  weekDay: string;
};
type Props = {
  days: number;
};


class TimeLine extends React.PureComponent<Props> {

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleClick = (e) => e.stopPropagation();

  handleResize = () => this.forceUpdate();

  render() {
    const dates: TDate[] = getTimeLine(this.props.days);
    const style = {
      width: calcWidth()
    };

    const line: ReactNode[] = dates.map(({ day, weekDay }: TDate): ReactElement => (
      <div className="TimeLine__day" key={day}>{weekDay} {day}</div>
    ));

    return (
      <div className="TimeLine app-d-flex flex-row" onClick={this.handleClick} style={style}>
        {line}
      </div>
    );
  }
}


export default TimeLine;
