import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ContactCard.scss';
import { PROVIDER_IMAGE_URL } from '../../../../other/config';
import { TPerson } from '../../../../types/person';

type Props = {
  contact: TPerson;
};

const ContactCard = ({ contact }: Props): ReactElement => {
  const {
    email,
    firstName,
    lastName,
    mobile,
    photo,
    position,
    telephone
  } = contact;
  const name = `${firstName} ${lastName}`;
  const imgUrl: string = photo
    ? `${PROVIDER_IMAGE_URL}/${photo.path}`
    : '/assets/placeholders/contact_placeholder.svg';

  if (!photo && !firstName)
    return (
      <div className="ContactCard">
        <img src="/assets/placeholders/contact_card_placeholder.svg" alt="" />
      </div>
    );

  return (
    <div className="ContactCard">
      <figure className="ContactCard__photo">
        <img src={imgUrl} alt={name} />
      </figure>

      <ul className="ContactCard__info">
        <li className="ContactCard__name">{name}</li>
        <li className="ContactCard__position">{position}</li>
        <li>
          <a href={`tel:${{ telephone }}`}>{telephone}</a>
        </li>
        <li>
          <a href={`tel:${{ telephone }}`}>{mobile}</a>
        </li>
        <li>
          <a href={`mailto:${email}`}>{email}</a>
        </li>
      </ul>
    </div>
  );
};

ContactCard.propTypes = {
  contact: PropTypes.object.isRequired
};

export default ContactCard;
