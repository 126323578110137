import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import ImageGallery from 'react-image-gallery';

import './VesselPhotos.scss';
import { NO_IMAGE_PATH } from '../../../../other/config';
import { TVesselPhotoItem } from '../../helpers';

type Props = {
  photos: TVesselPhotoItem[];
};

const VesselPhotos = ({ photos }: Props): ReactElement => {
  if (!photos || !photos.length) {
    return (
      <div className="VesselPhotos">
        <img className="VesselPhotos__placeholder" src={NO_IMAGE_PATH} alt="" />
      </div>
    );
  }

  const props = {
    additionalClass: 'VesselPhotos__gallery',
    infinite: false,
    items: photos,
    showPlayButton: false,
    showThumbnails: photos.length > 1,
    renderFullscreenButton: (onClick, isFullscreen) => {
      const [IconComponent, ariaLabel] = isFullscreen
        ? [FullscreenExitOutlined, 'Close Fullscreen']
        : [FullscreenOutlined, 'Open Fullscreen'];
      const buttonClassName = classnames(
        'image-gallery-icon image-gallery-fullscreen-button VesselPhotos__fullscreenButton',
        {
          'VesselPhotos__fullscreenButton--active': isFullscreen
        }
      );

      return (
        <button
          onClick={onClick}
          className={buttonClassName}
          aria-label={ariaLabel}
        >
          <IconComponent className="VesselPhotos__fullscreenIcon" />
        </button>
      );
    }
  };

  return (
    <div className="VesselPhotos">
      <ImageGallery {...props} />
    </div>
  );
};

VesselPhotos.propTypes = {
  photos: PropTypes.array
};

export default VesselPhotos;
