import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

import './ProductForm.scss';
import { productTitleRules } from '../other/validationRules';
import SaveCancelGroup from '../../../../components/common/buttons/SaveCancelGroup/SaveCancelGroup';
import { TVideo } from '../../../../types/media';

type TProductFormProps = {
  onCancel: () => void;
  onSubmit: (obj: Partial<TVideo>) => void;
  values: Partial<TVideo>;
}


class ProductForm extends React.PureComponent<TProductFormProps> {
  static propTypes;
  private form: FormInstance;

  handleSubmit = () => this.form.validateFields()
    .then(this.props.onSubmit);

  render() {
    return (
      <Form className="ProductForm"
            initialValues={this.props.values}
            onFinish={this.handleSubmit}
            ref={(el) => this.form = el} >

        <Form.Item name="title" rules={productTitleRules}>
          <Input placeholder="Title" />
        </Form.Item>

        <SaveCancelGroup onCancel={this.props.onCancel} />
      </Form>
    );
  }
}


ProductForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ProductForm;
