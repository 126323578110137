import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ArticleEditorHeader.scss';
import CloseButton from '../../buttons/CloseButton/CloseButton';

type Props = {
  onCancel: () => void;
  title: string;
};


const ArticleEditorHeader = ({ onCancel, title }: Props): ReactElement => (
  <header className="Main__header app-justify-content-center">
    <h2 className="Main__headerTitle">{title}</h2>
    <CloseButton
      className="ArticleEditorHeader__cancel"
      onClick={onCancel}
      title="Cancel"
    />
  </header>
);

ArticleEditorHeader.propTypes = {
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default ArticleEditorHeader;
