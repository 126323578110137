import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './ScheduleItem.scss';
import { COLOR_MAP } from '../../../helpers';
import Confirmation from '../../../Confirmation/Confirmation';
import { LABEL_MAP } from './helpers';
import IconCustom from '../../../../../components/common/IconCustom/IconCustom';
import RejectApprove from '../../../Confirmation/RejectApprove';
import { ROUTES } from '../../../../../other/config';
import { EScheduleItemStatus, TScheduleItem } from '../../../../../types/event';

type Props = {
  item: TScheduleItem;
  onEdit: (item: TScheduleItem) => void;
  onItemRemove: (id: number) => void;
  onStatus: (id: number, status: EScheduleItemStatus) => void;
  utcOffset: string;
};


const ScheduleItem = ({ item, onEdit, onItemRemove, onStatus, utcOffset }: Props): ReactElement => {
  const { appointee, end, id, notes, start, status, title } = item;

  const _end = moment(end).format('HH:mm');
  const _start = moment(start).format('HH:mm');
  const path = appointee ? `${ROUTES.COMPANY}/${appointee.companyId}` : null;
  const shouldShowControls = item.status !== EScheduleItemStatus.BOOKED;

  const handleEdit = () => onEdit(item);
  const handleRemove = () => onItemRemove(id);
  const handleApprove = () => onStatus(id, EScheduleItemStatus.BOOKED);
  const handleReject = () => onStatus(id, EScheduleItemStatus.AVAILABLE);

  const person = appointee && (
    <div className="ScheduleItem__person">
      <table>
        <tbody>
          {(appointee.firstName || appointee.lastName) && (
            <tr>
              <th>Name:</th>
              <td>
                {appointee.firstName} {appointee.lastName}
              </td>
            </tr>
          )}

          {appointee.companyName && (
            <tr>
              <th>Company: </th>
              <td>
                <Link to={path}>
                  {appointee.companyName}
                </Link>
              </td>
            </tr>
          )}

          {appointee.email && (
            <tr>
              <th>Email:</th>
              <td>
                <a href={`mailto:${appointee.email}`}>
                  {appointee.email}
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  const footer = notes && (
    <footer className="ScheduleItem__footer">
      <h5>
        Notes{' '}
        <small>
          <sup>*</sup>visible only to you
        </small>
      </h5>
      <div className="ScheduleItem__notes">{notes}</div>
    </footer>
  );

  const controls = status === EScheduleItemStatus.PENDING && (
    <RejectApprove onApprove={handleApprove} onReject={handleReject} />
  );

  const text =
    status === EScheduleItemStatus.AVAILABLE ? null : (
      <p>We will notify user by sending them an email.</p>
    );

  return (
    <article className="ScheduleItem">
      <header className="ScheduleItem__header">
        <div className="ScheduleItem__range">
          {_start} - {_end} <small>{utcOffset} GMT</small>
        </div>

        <div className="app-d-flex">
          <Tag className="app-custom-tag" color={COLOR_MAP[status]}>
            {LABEL_MAP[status]}
          </Tag>

          {shouldShowControls && (
            <>
              <Button onClick={handleEdit} title="Edit item" type="link">
                <IconCustom type="pencil-create" />
              </Button>

              <Confirmation
                onConfirm={handleRemove}
                text={text}
                title="Do you confirm item removing?"
              >
                <IconCustom title="Remove item" type="delete" />
              </Confirmation>
            </>
          )}
        </div>
      </header>

      <div className="ScheduleItem__title">{title}</div>

      {person}
      {controls}
      {footer}
    </article>
  );
};

ScheduleItem.propTypes = {
  item: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  onStatus: PropTypes.func.isRequired,
};

export default ScheduleItem;
