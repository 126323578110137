import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './LatestArticle.scss';
import { ARTICLE_IMAGE_URL, ROUTES } from '../../../../other/config';
import CommonBanner from '../../../../components/common/banners/CommonBanner';
import PostInfo from '../PostInfo/PostInfo';
import { TArticle } from '../../../../types/article';

type Props = {
  article?: Readonly<TArticle>;
};

const LatestArticle: React.FC<Props> = ({ article }): ReactElement => {
  if (!article) return null;
  const {
    banner,
    id,
    published,
    shortDescription,
    source,
    time,
    title
  } = article;

  const bannerUrl = banner ? `${ARTICLE_IMAGE_URL}/${banner.path}` : null;
  const path = `${ROUTES.NEWS}/${id}`;

  const titleCls: string = classnames('LatestArticle__title', {
    'LatestArticle__title--draft': !published
  });
  const descCls: string = classnames('LatestArticle__description', {
    'LatestArticle__description--draft': !published
  });

  return (
    <article className="LatestArticle">
      <div className="LatestArticle__content">
        <CommonBanner
          className="LatestArticle__banner"
          imgUrl={bannerUrl}
          isDraft={!published}
          path={path}
        />

        <div className="LatestArticle__info">
          {title && (
            <Link to={path} className="LatestArticle__text">
              <h5 className={titleCls}>{title}</h5>
            </Link>
          )}

          <PostInfo date={time} source={source} />

          {shortDescription && (
            <Link to={path} className="LatestArticle__text">
              <p className={descCls}>{shortDescription}</p>
            </Link>
          )}
        </div>
      </div>
    </article>
  );
};

LatestArticle.propTypes = {
  // @ts-ignore
  article: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    published: PropTypes.bool.isRequired,
    shortDescription: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};

export default React.memo(LatestArticle);
