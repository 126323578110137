import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDisplayName } from './helpers/helpers';
import { storeRedirectPathAction } from 'store/session/sessionActions';
import { EUserAuthorities } from 'store/session/sessionModel';

function withAuth(WrappedComponent) {
  function HOC(props) {
    return <WrappedComponent {...props} />;
  }

  HOC.displayName = `withAuth(${getDisplayName(WrappedComponent)})`;
  HOC.propTypes = {
    isAdmin: PropTypes.bool,
    isProvider: PropTypes.bool,
    isUser: PropTypes.bool,
    redirectTo: PropTypes.func.isRequired,
    rights: PropTypes.array.isRequired
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
}

const mapStateToProps = (state) => {
  const {
    session: { user }
  } = state;
  const userInfo = user ? user.userInfo : {};
  const { authorities } = userInfo;
  const rights = authorities || [];

  return {
    canViewCompany: rights.includes(EUserAuthorities.VIEW_OWNER),
    isAdmin: rights.includes(EUserAuthorities.ADMIN),
    isLoggedIn: !!(user && user.token),
    isNewsIssuer: rights.includes(EUserAuthorities.CREATE_NEWS),
    isProvider: rights.includes(EUserAuthorities.SERVICE_PROVIDER),
    isUser: rights.includes(EUserAuthorities.USER),
    rights,
    userInfo
  };
};

const mapDispatchToProps = (dispatch) => ({
  redirectTo: () => dispatch(storeRedirectPathAction())
});

export default withAuth;
