import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';

import './FleetsList.scss';
import CreateFleet from './CreateFleet';
import FleetsListItem from './FleetsListItem';
import { TFleetExt } from '../../../../types/fleet';

type Props = {
  favVesselIds?: number[];
  fleets: TFleetExt[];
  onCreate: (fleetName: string) => void;
  onToggle: (vesselIds: number[], fleet: TFleetExt) => void;
};


/**
 * Decides whether all the vessels of current fleet `fleetVesselIds`
 * are included into overall list of the favourite vessels `favVesselIds`.
 */
function isInFavs(favVesselIds: number[], fleetVesselIds: number[]): boolean {
  if (favVesselIds.length === 0 || fleetVesselIds.length === 0) {
    return false;
  } else if (favVesselIds.length >= fleetVesselIds.length) {
    return fleetVesselIds.every((id: number) => favVesselIds.includes(id))
  } else {
    return favVesselIds.every((id: number) => fleetVesselIds.includes(id))
  }
}

const FleetsList = ({
                      favVesselIds,
                      fleets,
                      onCreate,
                      onToggle,
}: Props): ReactElement => {

  const mapList = (fleet: TFleetExt): ReactNode => {
    const { id, name, vesselIds } = fleet;
    const handleToggle = () => onToggle(favVesselIds, fleet);

    const isChecked: boolean = isInFavs(favVesselIds, vesselIds);

    return (
      <FleetsListItem
        isChecked={isChecked}
        key={id}
        name={name}
        onToggle={handleToggle}
      />
    );
  };

  return (
    <ul className="FleetsList">
      {fleets.map(mapList)}

      <li className="FleetsList__btn">
        <CreateFleet onCreate={onCreate} />
      </li>
    </ul>
  );
};

FleetsList.propTypes = {
  favVesselIds: PropTypes.arrayOf(
    PropTypes.number
  ),
  fleets: PropTypes.array,
  onCreate: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
};

export default FleetsList;
