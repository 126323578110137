export enum EEventsActions {
  FETCH_EVENTS = 'events/FETCH_EVENTS',
  FETCH_EVENT = 'events/FETCH_EVENT',
  REMOVE_EVENT = 'events/REMOVE_EVENT',

  APPROVE_REJECT_MEETING = 'events/APPROVE_REJECT_MEETING',
  BOOK_CANCEL_MEETING = 'events/BOOK_CANCEL_MEETING',

  CLEAR_EVENT = 'events/CLEAR_EVENT',
  CLEAR_EVENTS = 'events/CLEAR_EVENTS',

  EVENT_BODY_COMPOSED = 'events/EVENT_BODY_COMPOSED',
  SAVE_SCROLL_TOP = 'events/SAVE_SCROLL_TOP'
}
