import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import { Button } from 'antd';
import { IconCustom, LoaderOverlay } from 'react-fishfacts/dist';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RouteComponentProps } from 'react-router-dom';
import Swipe from 'react-easy-swipe';

import './NewsList.scss';
import CreateNewsButton from './CreateNewsButton/CreateNewsButton';
import MapLink from './MapLink/MapLink';
import NewsList from './NewsList';
import { ROUTES } from 'other/config';

import { TArticle } from 'types/article';
import { TPromoted } from 'types/promotion';

type Props = {
  clearNews: () => void;
  fetchPromoted: () => void;
  getNews: (fetchMore: boolean) => void;
  hasServices: boolean;
  history: RouteComponentProps;
  isLoading: boolean;
  isNewsIssuer: boolean;
  list?: TArticle[];
  promotedArticles?: TPromoted[];
  promotionId: number;
  saveScrollTop: (scrollTop: number) => void;
  scrollTop: number;
  usersNews: number[];
};

class NewsListWrapper extends React.Component<Props, null> {
  static propTypes: Record<string, any>;
  private scrollTop: number;
  private unsubscribe: () => void;

  componentDidMount() {
    const { fetchPromoted, getNews, history } = this.props;

    fetchPromoted();
    getNews(true);
    this.unsubscribe = history.listen((location) => {
      if (!location.pathname.startsWith(ROUTES.NEWS)) {
        this.unsubscribe();
        this.scrollTop = 0;
      }
    });
  }

  componentWillUnmount() {
    this.props.saveScrollTop(this.scrollTop);
  }

  handleScroll = ({ target }) => {
    this.scrollTop = target ? target.scrollTop : 0;
  };

  handleSwipe = () => {
    const { clearNews, getNews } = this.props;
    if (this.scrollTop > 0.1 * document.documentElement.offsetHeight) return;
    clearNews();
    getNews(true);
  };

  render() {
    const {
      getNews,
      hasServices,
      isLoading,
      list,
      promotedArticles,
      scrollTop,
      usersNews
    } = this.props;

    const data: TArticle[] = list || [];
    const getMoreNews = () => getNews(true);
    const isListLoaded: boolean = !isLoading || (list && !!list.length);
    const title: string = isListLoaded ? 'News' : 'Loading...';

    const header: ReactElement = (
      <header className="Main__header NewsList__header">
        <h2 className="Main__headerTitle">{title}</h2>
        <div className="NewsList__controls">
          {hasServices && (
            <Button
              href={ROUTES.NEWS_EDITOR}
              className="NewsList__campaign"
              type="primary"
              shape="round"
              size="large"
            >
              <IconCustom type="plus" />
              Campaign
            </Button>
          )}

          <CreateNewsButton />
        </div>
      </header>
    );

    if (!isListLoaded)
      return (
        <LoaderOverlay
          className="NewsListWrapper"
          isLoading={true}
          isTransparent={true}
        >
          {header}
        </LoaderOverlay>
      );

    const Content: FC = () => (
      <InfiniteScroll
        className="NewsList__scroller"
        dataLength={data.length}
        hasMore={!isLoading}
        initialScrollY={scrollTop}
        loader={null}
        next={getMoreNews as any}
        onScroll={this.handleScroll}
        scrollableTarget="Main__container"
      >
        {header}
        <div className="NewsList__row">
          <NewsList
            list={list}
            promotedArticles={promotedArticles}
            usersNews={usersNews}
          />
        </div>
      </InfiniteScroll>
    );

    return (
      <div className="NewsListWrapper">
        {/*// @ts-ignore*/}
        <Swipe onSwipeDown={this.handleSwipe}>
          <MapLink />
          <AutoSizer className="NewsList">{Content}</AutoSizer>
        </Swipe>
      </div>
    );
  }
}

NewsListWrapper.propTypes = {
  clearNews: PropTypes.func.isRequired,
  fetchPromoted: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired,
  hasServices: PropTypes.bool,
  isLoading: PropTypes.bool,
  list: PropTypes.array,
  promotionId: PropTypes.number,
  saveScrollTop: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
  usersNews: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default NewsListWrapper;
