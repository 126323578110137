import L from 'leaflet';
import {
  countryColorMap,
  vesselTypesColorMap,
  providerBranchColors
} from './colors';
import {
  DEFAULT_COLOR,
  DEFAULT_COLORS,
  HEIGHT,
  RADIUS,
  ROTOR,
  WIDTH
} from './constants';
import { getCoordinatesRotated } from './coordinates';
import { isPositionInvalid } from './positioning';

import { EColorDescriptor } from '../types/colorDescriptor';
import { TProviderAddressShort } from '../../../types/providers';
import { TVesselLocation } from '../../../types/vessel';

require('../plugins/L.CanvasLayer');
const cnv = (L as any).canvasLayer();

function getColors(
  colorDescriptor = EColorDescriptor.COUNTRIES,
  fisheriesType: number,
  flag: string
): [string, string] {
  const colors =
    colorDescriptor === EColorDescriptor.COUNTRIES
      ? (countryColorMap as any)[flag]
      : (vesselTypesColorMap as any)[fisheriesType];
  return colors || DEFAULT_COLORS;
}

export function markersRenderer(
  providers: TProviderAddressShort[],
  vessels: TVesselLocation[],
  map: {},
  colorDescriptor: EColorDescriptor
): Object {
  const obj = {
    onDrawLayer: (info: any): void => {
      const ctx = info.canvas.getContext('2d');
      ctx && ctx.clearRect(0, 0, info.canvas.width, info.canvas.height);
      drawVessels(vessels, info, colorDescriptor);
      drawProviders(providers, info);
    }
  };

  cnv.delegate(obj).addTo(map);
  cnv.needRedraw();
  return cnv;
}

export function drawVessels(
  vessels: TVesselLocation[],
  info: any,
  colorDescriptor: EColorDescriptor
) {
  // console.log('drawVessels()', vessels && vessels.length);
  if (!vessels || !vessels.length) return;
  const ctx = info.canvas.getContext('2d');

  vessels.forEach((vessel) => {
    const { fisheriesType, flag, heading, latitude, longitude } = vessel;
    const colors = getColors(colorDescriptor, fisheriesType, flag);
    const position = [latitude, longitude];
    const rad = heading * ROTOR;
    const cos = Math.cos(rad);
    const sin = Math.sin(rad);

    if (!info.bounds.contains(position)) return;
    const { x, y } = info.layer._map.latLngToContainerPoint(position);

    ctx.fillStyle = colors[0];
    ctx.beginPath();
    ctx.moveTo(...getCoordinatesRotated(x, y, 0, -HEIGHT / 2, cos, sin));
    ctx.lineTo(
      ...getCoordinatesRotated(x, y, -WIDTH / 2, -HEIGHT / 14, cos, sin)
    );
    ctx.lineTo(
      ...getCoordinatesRotated(x, y, -WIDTH / 2, HEIGHT / 2, cos, sin)
    );
    ctx.lineTo(...getCoordinatesRotated(x, y, 0, (2 * HEIGHT) / 7, cos, sin));
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = colors[1];
    ctx.beginPath();
    ctx.moveTo(...getCoordinatesRotated(x, y, 0, -HEIGHT / 2, cos, sin));
    ctx.lineTo(
      ...getCoordinatesRotated(x, y, WIDTH / 2, -HEIGHT / 14, cos, sin)
    );
    ctx.lineTo(...getCoordinatesRotated(x, y, WIDTH / 2, HEIGHT / 2, cos, sin));
    ctx.lineTo(...getCoordinatesRotated(x, y, 0, (2 * HEIGHT) / 7, cos, sin));
    ctx.closePath();
    ctx.fill();

    // Vessel title
    if (!vessel.name) return;
    const name = vessel.name.toUpperCase();
    ctx.fillStyle = 'black';
    ctx.font = '11px Inter, Arial, sans-serif';
    ctx.fillText(name, x - WIDTH, y + HEIGHT);
  });
}

function drawProviders(providers: TProviderAddressShort[], info: any): void {
  if (!providers || !providers.length) return;
  // console.log('drawProviders()', providers.length);
  const ctx = info.canvas.getContext('2d');
  providers.forEach(drawAddresses);

  function drawAddresses({
    branches,
    country,
    name,
    position
  }: TProviderAddressShort): void {
    if (isPositionInvalid(position)) return;
    if (!info.bounds.contains(position)) return;
    const color =
      branches && branches.length
        ? providerBranchColors[branches[0].id - 4] || DEFAULT_COLOR
        : DEFAULT_COLOR;
    const { x, y } = info.layer._map.latLngToContainerPoint(position);

    ctx.fillStyle = DEFAULT_COLOR;
    ctx.beginPath();
    ctx.arc(x, y, RADIUS + 3, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.beginPath();
    ctx.arc(x, y, RADIUS + 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(x, y, RADIUS, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();

    // Provider title
    if (!name) return;
    const text = name.toUpperCase();
    ctx.fillStyle = 'black';
    ctx.font = '11px Inter, Arial, sans-serif';
    ctx.fillText(text, x - 1.3 * RADIUS, y + 3.2 * RADIUS);
  }
}
