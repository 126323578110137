import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import { isProdHost } from '../other/helpers';
import { VERSION } from '../other/config';

export function startTracking(): void {
  if (!isProdHost()) return;
  initSentry();
  initGA();
}

/** Initializes Sentry error logger */
function initSentry(): void {
  Sentry.init({
    dsn: 'https://8d3024788efb46a1b182c2d14720d816@sentry.io/1393586',
    release: `fishfacts-fe@${VERSION}`,
    blacklistUrls: [
      'https://api4.windy.com',
      'https://www.windy.com'
    ],
    ignoreErrors: [
      'Cannot read property \'_leaflet_pos\' of undefined',
      'Cannot read property \'bindFramebuffer\' of null',
      /failed to load tile/i,
      /out of memory/i,
      /_leaflet_pos/
    ]
  });
}

/** Initializes Google Analytics */
function initGA(): void {
  ReactGA.initialize('UA-100556059-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
}
