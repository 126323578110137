import { createSelector } from 'reselect';
import { TServiceBranchGroup } from '../../../types/service';
import { TTreeSelectOption } from '../../../other/formAndValidation/formUtils';

// Converts TServiceBranch into TTreeOption
const branchMapper = ({
  branch,
  subBranches
}: TServiceBranchGroup): TTreeSelectOption => {
  const key = branch.id.toString();
  const option = {
    id: key,
    title: branch.value.en_GB,
    key: key,
    value: key
  };

  return !subBranches || subBranches.length === 0
    ? option
    : {
        ...option,
        children: subBranches.map(branchMapper)
      };
};

// Builds an options tree out of serviceCategories.
export const selectServiceOptions = createSelector(
  (serviceBranches: TServiceBranchGroup[]): TServiceBranchGroup[] =>
    serviceBranches,
  (branches: TServiceBranchGroup[]): TTreeSelectOption[] =>
    (branches || []).map(branchMapper)
);
