import React from 'react';
import PropTypes from 'prop-types';
import {
  ALL_VALUE,
  areEqual,
  num2StrArr,
  removeAllOption,
  strArr2Num,
  TLabelValue
} from 'react-fishfacts/dist';
import classnames from 'classnames';
import { Select } from 'antd';

import './VesselTypesSelect.scss';
import { ALL } from './selectVesselTypesOptions';
import { filterOption } from '../../helpers/convertors';

type Props = {
  className?: string;
  onChange?: (types: number[]) => void;
  options: TLabelValue[];
  value?: number[];
};

class VesselTypesSelect extends React.PureComponent<Props> {
  static propTypes;

  getValue = (): string | string[] => {
    const { value } = this.props;
    if (!value) return;
    return value.length > 0 ? num2StrArr(value) : [ALL_VALUE];
  };

  handleChange = (values: string[]): void => {
    const { onChange } = this.props;
    const _value: string | string[] = this.getValue();

    // Selected ALL or removed each or selected each
    if (
      !areEqual(_value, [ALL_VALUE]) &&
      (values.includes(ALL_VALUE) ||
        values.length === 0 ||
        areEqual(_value, values))
    ) {
      onChange([]);
    } else {
      const arg = strArr2Num(removeAllOption(values)).filter(Boolean);
      onChange(arg);
    }
  };

  render() {
    const { className, options } = this.props;
    const cls: string = classnames('VesselTypesSelect', className);
    const _value: string | string[] = this.getValue();
    const _options = [ALL, ...options];

    return (
      <Select
        className={cls}
        filterOption={filterOption}
        mode="tags"
        onChange={this.handleChange}
        optionFilterProp="value"
        options={_options}
        value={_value}
      />
    );
  }
}

VesselTypesSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  )
};

export default VesselTypesSelect;
