import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ProductArticle.scss';
import Article from '../../../components/common/articles/Article';
import ArticleBanner from '../../../components/common/articles/ArticleBanner/ArticleBanner';
import { MEDIA_BASE_URL } from '../../../other/config';
import { TProduct } from '../../../types/product';

type Props = {
  article: TProduct;
  canEdit: boolean;
  children: ReactElement;
  editArticle: () => void;
  removeArticle: () => void;
};

const ProductArticle = (props: Props): ReactElement => {
  const { article, canEdit, children, editArticle, removeArticle } = props;
  const { banner, published, source } = article;
  const bannerUrl = banner
    ? `${MEDIA_BASE_URL}/${banner.path}`
    : '/assets/placeholders/product_placeholder.svg';

  return (
    <section className="NewsArticle">
      <Article article={article}>
        <ArticleBanner
          canEdit={canEdit}
          imgUrl={bannerUrl}
          isDraft={!published}
          onEdit={editArticle}
          onRemove={removeArticle}
          source={source}
        />
      </Article>

      <div className="app-d-flex app-justify-content-end app-pb-4">
        {children}
      </div>
    </section>
  );
};

ProductArticle.propTypes = {
  article: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    longDescription: PropTypes.string,
    shortDescription: PropTypes.string,
    source: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }),
    time: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  canEdit: PropTypes.bool,
  editArticle: PropTypes.func.isRequired,
  removeArticle: PropTypes.func
};

export default ProductArticle;
