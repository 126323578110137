import { ActionFactory, THttpResponse } from 'react-fishfacts/dist';
import { push } from 'connected-react-router';
import { TVessel, TVesselPhotoSet } from 'react-fishfacts/dist';

import { ENDPOINTS, ROUTES, SUB_POINTS } from '../../../other/config';
import http from '../../../services/http';
import store from '../../store';

import { EVesselActions } from './vesselConstants';
import { TFishingRights } from '../../../types/fishingLicence';
import { TVesselState } from './vessel';

const af = new ActionFactory<TVesselState, EVesselActions>(store);
const fetchVesselSet = af.createAsyncActions(EVesselActions.FETCH_VESSEL);
const fetchLicenceSet = af.createAsyncActions(EVesselActions.FETCH_LICENCES);
const fetchPhotoSet = af.createAsyncActions(EVesselActions.FETCH_PHOTOS);

/**
 * Triggers a bunch of actions fetching the vessel related resources.
 * @param vesselId
 */
export function fetchVesselDataAction(vesselId: number) {
  return (dispatch) => {
    dispatch(fetchVessel(vesselId));
    dispatch(fetchLicences(vesselId));
    dispatch(fetchVesselPhotos(vesselId));
  };
}

/**
 * Retrieves vessel's pictures by given vessel ID.
 */
function fetchVesselPhotos(vesselId: number) {
  return () => {
    const url = `${ENDPOINTS.VESSELS}/${vesselId}/${SUB_POINTS.PHOTOS}`;
    fetchPhotoSet.request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TVesselPhotoSet[]>) => {
        data.sort(
          (a: TVesselPhotoSet, b: TVesselPhotoSet): number =>
            a.position - b.position
        );
        fetchPhotoSet.success({
          photos: data
        });
      })
      .catch(fetchPhotoSet.error);
  };
}

/**
 * Retrieves vessel details by given ID.
 */
function fetchVessel(id: number) {
  return () => {
    fetchVesselSet.request();

    http
      .send(`${ENDPOINTS.VESSELS}/${id}`)
      .then(({ data }: THttpResponse<TVessel>) =>
        fetchVesselSet.success({
          vessel: data
        })
      )
      .catch(fetchVesselSet.error);
  };
}

/**
 * Retrieves the licences by given vessel ID.
 */
function fetchLicences(vesselId: number) {
  return () => {
    const url = `${ENDPOINTS.VESSELS}/${vesselId}/${SUB_POINTS.FISHING_LICENCES}`;
    fetchLicenceSet.request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TFishingRights>) =>
        fetchLicenceSet.success({
          fishingRights: data
        })
      )
      .catch(fetchLicenceSet.error);
  };
}

export function showVesselPageAction(vesselId: number) {
  return (dispatch) => {
    const path = `${ROUTES.VESSEL}/${vesselId}`;
    dispatch(push(path));
  };
}
