import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { FilterDrawer, IconCustom } from 'react-fishfacts/dist';

import './VesselsPageFilter.scss';
import { resetVesselFilterAction } from '../../store/vessel/vesselsFilter/vesselsFilterActions';
import VesselFilter from '../common/vessel/VesselFilter/VesselFilter';
import { TState } from '../../store/appStateModel';

type Props = {
  isFilterSet: boolean;
  resetFilter: () => void;
};
const icon = (
  <IconCustom type="filters" title="Filter" style={{ fontSize: 22 }} />
);

const VesselsPageFilter: React.FC<Props> = ({ isFilterSet, resetFilter }) => {
  const [isVisible, setVisibility] = useState<boolean>(false);

  const handleClose = () => setVisibility(false);
  const handleOpen = () => setVisibility(true);

  return (
    <>
      <Button className="VesselFilter__button" onClick={handleOpen}>
        <div className="VesselFilter__button-content">
          {icon}
          {isFilterSet && <div className="VesselFilter__indicator" />}
        </div>
      </Button>

      <FilterDrawer
        isVisible={isVisible}
        onClose={handleClose}
        onReset={resetFilter}
      >
        <VesselFilter />
      </FilterDrawer>
    </>
  );
};

VesselsPageFilter.propTypes = {
  isFilterSet: PropTypes.bool,
  resetFilter: PropTypes.func.isRequired
};

const mapStateToProps = ({ vesselsFilter }: TState) => ({
  isFilterSet: vesselsFilter.isFilterSet
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetFilter: resetVesselFilterAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VesselsPageFilter);
