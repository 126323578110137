import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import './UsersItem.scss';
import { COLOR_MAP } from '../../../helpers';
import Confirmation from '../../../Confirmation/Confirmation';
import TimeSlot from './TimeSlot';
import { EScheduleItemStatus, TScheduleItem } from '../../../../../types/event';

type Props = {
  item: TScheduleItem;
  onBookCancel: (item: TScheduleItem, shouldCancel?: boolean) => void;
  userId: number;
  utcOffset: string;
};


const UsersItem = ({ item, onBookCancel, userId, utcOffset }: Props): ReactElement => {
  const { appointee, end, status, start, title } = item;
  const isAppointee = appointee ? userId === appointee.id : false;

  const handleBook = () => onBookCancel(item);
  const handleCancel = () => onBookCancel(item, true);

  const getStatusLabel = (): string => {
    if (status === EScheduleItemStatus.PENDING) {
      return isAppointee ? 'Pending' : 'Booked';
    }
    if (status === EScheduleItemStatus.BOOKED) {
      return isAppointee ? 'Booked by You!' : 'Booked';
    }
    return 'Vacant';
  };

  const getStatusColorKey = (): string => {
    if (status === EScheduleItemStatus.PENDING) {
      return isAppointee ? EScheduleItemStatus.PENDING : EScheduleItemStatus.BOOKED;
    }
    if (status === EScheduleItemStatus.BOOKED) {
      return isAppointee ? EScheduleItemStatus.SELF_BOOKED : EScheduleItemStatus.BOOKED;
    }
    return EScheduleItemStatus.AVAILABLE;
  };

  const tag = (
    <Tag
      className="app-custom-tag"
      color={COLOR_MAP[getStatusColorKey()]}
    >{getStatusLabel()}</Tag>
  );

  const getControl = (): ReactNode => {
    if (userId && (status === EScheduleItemStatus.AVAILABLE)) {
      const text = (
        <p>
          We will send your <i>name</i> and <i>@fishfacts.com</i> email to the Event owner so that they confirm your submission.
        </p>
      );
      return (
        <div className="UsersItem__controls">
          <Confirmation
            onConfirm={handleBook}
            text={text}
            title="Do you confirm meeting reservation?"
          >
            Book now!
          </Confirmation>
        </div>
      );
    }

    if (isAppointee) {
      const text = (
        <p>
          We will send your <i>name</i> and <i>@fishfacts.com</i> email to the Event owner to notify them of cancelling.
        </p>
      );
      return (
        <div className="UsersItem__controls">
          <Confirmation
            onConfirm={handleCancel}
            text={text}
            title="Do you confirm meeting cancelling?"
          >
            Cancel
          </Confirmation>
        </div>
      );
    }
  };

  return (
    <article className="UsersItem">
      <header className="UsersItem__header">
        <div className="UsersItem__info">
          <TimeSlot endTime={end} startTime={start} utcOffset={utcOffset} />
          <div className="UsersItem__title">{title}</div>
        </div>
        {tag}
      </header>

      {getControl()}
    </article>
  );
};


UsersItem.propTypes = {
  item: PropTypes.object.isRequired,
  onBookCancel: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
};

export default UsersItem;
