import React from 'react';
import PropTypes from 'prop-types';

import { transformToArray } from '../../../../helpers/helpers';

import { EDelousing, TDelousing } from 'react-fishfacts/dist';

type Props = {
  delousing: TDelousing | TDelousing[];
  getManufacturerFieldById: (number) => string | JSX.Element;
};

const Delousing = ({ delousing, getManufacturerFieldById }: Props) => {
  if (!delousing) return null;

  const array = transformToArray(delousing);

  return (
    <>
      <tr className="ExtendedFields__paragraph">
        <th colSpan={2}>Delousing</th>
      </tr>

      {array.map(({ manufacturerId, method }, index) => (
        <React.Fragment key={`processingFactory-${manufacturerId}-${index}`}>
          <tr className="ExtendedFields__listItem">
            <th colSpan={2}>Delousing {index + 1}</th>
          </tr>
          {manufacturerId && (
            <tr>
              <th>Provider</th>
              <td>{getManufacturerFieldById(manufacturerId)}</td>
            </tr>
          )}
          {method && (
            <tr>
              <th>Details</th>
              <td>{EDelousing[method]}</td>
            </tr>
          )}
          {index + 1 < array.length && (
            <tr>
              <th>&nbsp;</th>
              <td> </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

Delousing.propTypes = {
  delousing: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default Delousing;
