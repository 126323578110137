import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './ExtendedFields.scss';
import ExtendedFieldsTabs from './components/ExtendedFieldsTabs/ExtendedFieldsTabs';
import { filterExtendedFields } from './helpers/helpers';

import { EUserAuthorities } from 'store/session/sessionModel';
import { TEngineShort, TManufacturer, TVessel } from 'react-fishfacts/dist';

type Props = {
  engines: TEngineShort[];
  manufacturers: TManufacturer[];
  vessel: TVessel;
  userPermissions: EUserAuthorities[];
};

const ExtendedFields = ({
  vessel: { built, vesselSizes: dimensions, extendedFields, vesselClass },
  engines,
  manufacturers,
  userPermissions
}: Props) => {
  const filteredExtendedKeys = filterExtendedFields(extendedFields || {});

  let content = <>no data</>;

  if (!userPermissions.includes(EUserAuthorities.VESSEL_EXTENDED_FIELDS)) {
    content = (
      <>
        Please contact Hanus Samró, sales and communication director,{' '}
        <a href="mailto:hs@fishfacts.fo">hs@fishfacts.fo</a>
      </>
    );
  } else if (Object.keys(filteredExtendedKeys).length || built || vesselClass) {
    content = (
      <ExtendedFieldsTabs
        built={built}
        dimensions={dimensions}
        engines={engines}
        filteredExtendedKeys={filteredExtendedKeys}
        manufacturers={manufacturers}
        vesselClass={vesselClass}
      />
    );
  }

  return content;
};

ExtendedFields.propTypes = {
  engines: PropTypes.array,
  manufacturers: PropTypes.array,
  userPermissions: PropTypes.array,
  vessel: PropTypes.object
};

export default memo(ExtendedFields);
