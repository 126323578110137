import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { IconCustom } from 'react-fishfacts/dist';

type Props = {
  label?: string;
  onAdd: () => void;
};

const AddButton: FC<Props> = ({ label, onAdd }) => (
  <Button className="AddButton" onClick={onAdd} size="large" type="link">
    <IconCustom type="plus" />
    {label}
  </Button>
);

AddButton.propTypes = {
  label: PropTypes.string,
  onAdd: PropTypes.func.isRequired
};

export default AddButton;
