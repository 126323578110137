import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './EventDates.scss';


type Props = {
  endDate: string;
  startDate: string;
};

const EventDates = ({ endDate, startDate }: Props): ReactElement => {
  const [eDay, eMonth] = moment(endDate).format('DD MMM').split(' ');
  const [sDay, sMonth] = moment(startDate).format('DD MMM').split(' ');

  if (endDate === startDate) {
    return (
      <div className="EventDates">
        <div className="EventDates__date">
          <div><strong>{sDay}</strong></div>{sMonth}
        </div>
      </div>
    )
  }

  if (eMonth !== sMonth) {
    return (
      <div className="EventDates">
        <div className="EventDates__date">
          <div><strong>{sDay}</strong></div>{sMonth}
        </div>
        <div className="EventDates__divider">&nbsp;&#8211;</div>
        <div className="EventDates__date">
          <div><strong>{eDay}</strong></div>{eMonth}
        </div>
      </div>
    )
  }

  // start month == end month
  return (
    <div className="EventDates">
      <div className="EventDates__date">
        <div className="EventDates__date">
          <div><strong>{sDay}</strong></div>
        </div>
        <div className="EventDates__divider">&nbsp;&#8211;</div>
        <div className="EventDates__date">
          <div><strong>{eDay}</strong></div>{eMonth}
        </div>
      </div>
    </div>
  );
};


EventDates.propTypes = {
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired
};

export default EventDates;
