import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './SaveCancelGroup.scss';

type Props = {
  disabled?: boolean;
  onCancel: () => void;
  onSave?: () => void;
};

const SaveCancelGroup: FC<Props> = ({ disabled, onCancel, onSave }) => (
  <div className="SaveCancelGroup">
    <Button className="app-btn-min-w-170" onClick={onCancel} size="large">
      Cancel
    </Button>
    <Button
      type="primary"
      className="app-btn-min-w-170"
      disabled={disabled}
      htmlType="submit"
      onClick={onSave}
      size="large"
    >
      Save
    </Button>
  </div>
);

SaveCancelGroup.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func
};

export default SaveCancelGroup;
