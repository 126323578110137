import { isDictionary } from '../../other/helpers';

/**
 * Creates a GET request string out of given dictionary of parameters.
 */
export function buildQueryString(params: {}): string {
  const paramArr = Object.entries(params).map(([key, value]) => {
    if (Array.isArray(value)) {
      return `${key}=${value.join(',')}`;
    } else if (isDictionary(value)) {
      return buildQueryString(params);
    }
    return `${key}=${value}`;
  });
  return paramArr.join('&');
}
