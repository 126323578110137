import React from 'react';
import PropTypes from 'prop-types';

import { getServiceProviderLink } from '../../helpers/helpers';
import { TBuilt, TVesselClass } from 'react-fishfacts/dist';

type Props = {
  built: TBuilt;
  vesselClass: TVesselClass;
};

const BuildAndClassification = ({
  built: { buildYear, vesselYard, designer, design },
  vesselClass: { classification, classNotation, notableMentions }
}: Props) => {
  const isBuildDetailsDisplayed = buildYear || vesselYard || designer || design;
  const isVesselClassDisplayed =
    classification || classNotation || notableMentions;

  return (
    <table className="ExtendedFields__table">
      <tbody>
        {isBuildDetailsDisplayed && (
          <tr className="ExtendedFields__paragraph">
            <th colSpan={2}>Build details</th>
          </tr>
        )}
        {buildYear && (
          <tr>
            <th>Build Year</th>
            <td>{buildYear}</td>
          </tr>
        )}
        {vesselYard && (
          <tr>
            <th>Yard</th>
            <td>
              {getServiceProviderLink(
                vesselYard.serviceProviderId,
                vesselYard.name
              )}
            </td>
          </tr>
        )}
        {designer && (
          <tr>
            <th>Designer</th>
            <td>
              {getServiceProviderLink(
                designer.serviceProviderId,
                designer.name
              )}
            </td>
          </tr>
        )}
        {design && (
          <tr>
            <th>Design</th>
            <td>{design}</td>
          </tr>
        )}
        {isVesselClassDisplayed && (
          <tr className="ExtendedFields__paragraph">
            <th colSpan={2}>Classification</th>
          </tr>
        )}
        {classification && (
          <tr>
            <th>Classification</th>
            <td>{classification.name}</td>
          </tr>
        )}
        {classNotation && (
          <tr>
            <th>Class notation</th>
            <td>{classNotation}</td>
          </tr>
        )}
        {notableMentions && (
          <tr>
            <th>Notable mentions</th>
            <td>{notableMentions}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

BuildAndClassification.propTypes = {
  built: PropTypes.shape({
    buildYear: PropTypes.number,
    vesselYard: PropTypes.shape({ name: PropTypes.string }),
    designer: PropTypes.shape({ name: PropTypes.string }),
    design: PropTypes.string
  }),
  vesselClass: PropTypes.shape({
    classification: PropTypes.shape({ name: PropTypes.string }),
    classNotation: PropTypes.string,
    notableMentions: PropTypes.string
  })
};

export default BuildAndClassification;
