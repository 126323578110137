import { isFalsy } from '../../../../other/helpers';
import { ProviderUtil } from './ProviderUtil';

import {
  EProviderMediaObjectType,
  TProviderAddress
} from '../../../../types/providers';
import { THandledObject, TProviderEditorState } from '../providerEditor';
import { TPerson } from '../../../../types/person';
import { TVideo } from '../../../../types/media';

/**
 * Its only goal is to determine whether the given object has been modified by user.
 */
export class ObjectChecker extends ProviderUtil {
  private readonly object: THandledObject;

  static isEmptyOrFalsy(x): boolean {
    if (typeof x === 'string' && x.trim() === '') return true;
    return isFalsy(x);
  }

  constructor(type: EProviderMediaObjectType, model: TProviderEditorState) {
    super(type, model);
    this.object = this.findOriginalObject();
  }

  // Is it untouched, pristine, like a blueprint?
  isBlueprintLike(): boolean {
    switch (this.type) {
      case EProviderMediaObjectType.ADDRESS:
        return this.isAddressEmpty();
      case EProviderMediaObjectType.CONTACT:
        return this.isContactEmpty();
      case EProviderMediaObjectType.VIDEO:
        return this.isVideoEmpty();
    }
  }

  // Is it untouched, pristine, like a blueprint?
  private findOriginalObject(): THandledObject {
    switch (this.type) {
      case EProviderMediaObjectType.ADDRESS:
        return this.addresses.find(
          ({ id }: TProviderAddress) => id === this.handledObject.id
        );
      case EProviderMediaObjectType.CONTACT:
        return this.contacts.find(
          ({ id }: TPerson) => id === this.handledObject.id
        );
      case EProviderMediaObjectType.VIDEO:
        return this.videos.find(
          ({ id }: TVideo) => id === this.handledObject.id
        );
    }
  }

  private isAddressEmpty(): boolean {
    const {
      address,
      city,
      country,
      latitude,
      longitude,
      postcode,
      title
    } = this.object as TProviderAddress;
    const isEmpty: Function = ObjectChecker.isEmptyOrFalsy;

    return (
      isEmpty(address) &&
      isEmpty(city) &&
      isEmpty(country) &&
      isEmpty(latitude) &&
      isEmpty(longitude) &&
      isEmpty(postcode) &&
      isEmpty(title)
    );
  }

  private isContactEmpty(): boolean {
    const { firstName, lastName, position, telephone, mobile, email } = this
      .object as TPerson;
    const isEmpty: Function = ObjectChecker.isEmptyOrFalsy;

    return (
      isEmpty(firstName) &&
      isEmpty(lastName) &&
      isEmpty(position) &&
      isEmpty(telephone) &&
      isEmpty(mobile) &&
      isEmpty(email)
    );
  }

  private isVideoEmpty(): boolean {
    const { poster, title, video } = this.object as TVideo;
    const isEmpty: Function = ObjectChecker.isEmptyOrFalsy;
    return isEmpty(poster) && isEmpty(title) && isEmpty(video);
  }
}
