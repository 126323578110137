import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  LoaderOverlay,
  TEngineShort,
  TManufacturer,
  TVessel
} from 'react-fishfacts/dist';
import { push } from 'connected-react-router';

import { fetchVesselDataAction } from '../../../store/vessel/vessel/vesselActions';
import { highlightVesselAction } from '../../../store/map/mapEntities/mapEntitiesActions';
import { mapPhotos, TVesselPhotoItem } from '../helpers';
import { ROUTES } from '../../../other/config';
import VesselPage2 from './VesselPage2';
import withAuth from '../../../components/withAuth';

import { EUserAuthorities } from '../../../store/session/sessionModel';
import { TFishingRights } from '../../../types/fishingLicence';
import { THandler } from '../../../types/handlers';
import { TState } from '../../../store/appStateModel';

type Props = {
  canViewOwner: boolean;
  engines: TEngineShort[];
  fetchVesselData: (vesselId: number) => void;
  fishingRights: TFishingRights;
  isLoading: boolean;
  manufacturers: TManufacturer[];
  photos: TVesselPhotoItem[];
  showOnMap: THandler;
  userPermissions: EUserAuthorities[];
  vessel: TVessel;
  vesselId: number;
};

class VesselPageWrapper extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { fetchVesselData, vesselId } = this.props;
    fetchVesselData(vesselId);
  }
  componentDidUpdate(prevProps: Props) {
    const { fetchVesselData, vesselId } = this.props;
    if (prevProps.vesselId !== vesselId) {
      fetchVesselData(vesselId);
    }
  }

  render() {
    const {
      canViewOwner,
      engines,
      fishingRights,
      isLoading,
      manufacturers,
      photos,
      showOnMap,
      userPermissions,
      vessel
    } = this.props;

    return (
      <LoaderOverlay className="vessel" isLoading={isLoading}>
        {vessel ? (
          <VesselPage2
            canViewOwner={canViewOwner}
            engines={engines}
            fishingRights={fishingRights}
            manufacturers={manufacturers}
            photos={photos}
            showOnMap={showOnMap}
            vessel={vessel}
            userPermissions={userPermissions}
          />
        ) : (
          <h3>Vessel is not found.</h3>
        )}
      </LoaderOverlay>
    );
  }
}

VesselPageWrapper.propTypes = {
  canViewOwner: PropTypes.bool,
  fetchVesselData: PropTypes.func.isRequired,
  fishingRights: PropTypes.object,
  isLoading: PropTypes.bool,
  photos: PropTypes.array,
  showOnMap: PropTypes.func.isRequired,
  userPermissions: PropTypes.array,
  vessel: PropTypes.object,
  vesselId: PropTypes.number.isRequired
};

const mapStateToProps = (state: TState, ownProps) => {
  const {
    canViewCompany,
    match: {
      params: { vesselId: id }
    },
    rights
  } = ownProps;
  const {
    vessel: { fishingRights, isPending, photos, vessel },
    dictionaries: { manufacturers, engines }
  } = state;

  return {
    canViewOwner: canViewCompany,
    fishingRights: fishingRights,
    isLoading: isPending,
    photos: mapPhotos(photos),
    vessel: vessel,
    manufacturers: manufacturers,
    userPermissions: rights,
    engines: engines,
    vesselId: parseInt(id, 10)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVesselData: (vesselId: number) =>
      dispatch(fetchVesselDataAction(vesselId)),
    showOnMap: (vesselId: number) => {
      dispatch(push(ROUTES.MAP));
      dispatch(highlightVesselAction(vesselId));
    }
  };
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(VesselPageWrapper)
);
