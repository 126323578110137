import React, { ReactElement } from 'react';
import { AutoSizer } from 'react-virtualized';

import './VesselListPage.scss';
import VesselsList from './VesselsList';
import withFleets from '../../../components/common/vessel/withFleets/withFleets';
import { TVesselsListBaseProps, vesselsListBasePropTypes } from './helpers';
import EmptyList from '../../../components/common/EmptyList/EmptyList';

const VesselListWrapper = (props: TVesselsListBaseProps): ReactElement => {
  const { vessels } = props;
  if (!vessels || !vessels.length)
    return (
      <EmptyList
        placeholder="No vessels match current filter settings."
        placeholderImgUrl="/assets/placeholders/empty_search.svg"
      />
    );

  const listRenderer = ({ height, width }) => (
    <VesselsList height={height} width={width} {...props} />
  );

  return (
    <div className="VesselListPage__list-wrapper">
      <div className="VesselListPage__list-container">
        <AutoSizer>{listRenderer}</AutoSizer>
      </div>
    </div>
  );
};

VesselListWrapper.propTypes = vesselsListBasePropTypes;
export default withFleets(VesselListWrapper);
