import React from 'react';
import { Link } from 'react-router-dom';
import { LOCALE } from '../other/config';


class Species extends React.PureComponent {

  state = { licences: null };

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies() {
    fetch('https://api-test.fishfacts.fo/api/fishinglicences?', { credentials: 'include' })
      .then(response => response.json())
      .then((json) => {
        this.setState({
          licences: json.data,
        });
      });
  }

  render() {
    const { licences } = this.state;
    if (!licences) return <h4>Loading...</h4>;

    return (
      <div>
        <h2>Fishing Licences</h2>
        <table className="stripped">
          <thead>
            <tr>
              <th>Country</th>
              <th>Vessel</th>
              <th>Start</th>
              <th>End</th>
              <th>Name</th>
              <th>Specie</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {licences.map(licence => licence.licences.map(row => row.species.map(specie => (
              // <tr key={`${licence.vessel.id}-${row.fishingLicence.id}`}>
              <tr key={Math.random()}>
                <td>
                  {licence.vessel.flag.iso}
                </td>
                <td>
                  <Link to={`/vessel/${licence.vessel.id}`}>{licence.vessel.name}</Link>
                </td>
                <td>
                  {row.fishingLicence.start}
                </td>
                <td>
                  {row.fishingLicence.end}
                </td>
                <td>
                  {row.fishingLicence.name}
                </td>
                <td>
                  {specie.specie.value[LOCALE]}
                </td>
                <td>
                  {specie.amount}
                </td>
              </tr>
            ))))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Species;
