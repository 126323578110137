import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './EventCard.scss';
import DraftOverlay from '../../../../components/common/overlays/DraftOverlay/DraftOverlay';
import EventDates from '../../../events/EventPage/EventDates';
import { MEDIA_BASE_URL, ROUTES } from '../../../../other/config';
import { TEvent } from '../../../../types/event';

type Props = {
  event: TEvent;
};

const EventCard = ({ event }: Props): ReactElement => {
  const {
    banner,
    end,
    id,
    published,
    shortDescription,
    start,
    thumbnail,
    title
  } = event;
  const _banner = thumbnail || banner;

  const path = `${ROUTES.EVENTS}/${id}`;
  const imgUrl: string = _banner
    ? `${MEDIA_BASE_URL}/${_banner.path}`
    : '/assets/placeholders/event_placeholder.svg';

  const img: ReactNode = (
    <figure className="EventCardHorizontal__photo">
      {imgUrl && <img src={imgUrl} alt={title} />}
      <DraftOverlay isShown={!published} />
    </figure>
  );

  return (
    <Link className="EventCardHorizontal" to={path}>
      {img}

      <div className="EventCardHorizontal__holder">
        <EventDates endDate={end} startDate={start} />

        <div className="EventCardHorizontal__main">
          <h5 className="EventCardHorizontal__name">{title}</h5>
          <p className="EventCardHorizontal__description">{shortDescription}</p>
        </div>
      </div>
    </Link>
  );
};

EventCard.propTypes = {
  event: PropTypes.shape({
    banner: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    published: PropTypes.bool,
    source: PropTypes.shape({
      id: PropTypes.number.isRequired
    }),
    thumbnail: PropTypes.shape({
      path: PropTypes.string.isRequired
    }),
    title: PropTypes.string
  }).isRequired
};

export default EventCard;
