import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';

import {
  cancelEditingAction,
  clearEditorAction,
  editArticleAction,
  submitArticleAction,
  newsBannerUpload,
  updateFormFieldsAction
} from '../../../store/news/newsEditor/newsEditorActions';
import {
  clearPromotionAction,
  fetchPromotedAction
} from '../../../store/promo/promoActions';
import { newsArticleRemove } from '../../../store/news/news/newsActions';
import NewsEditorWrapper from './NewsEditorWrapper';
import { WYSIWYG_EMPTY_STRING } from '../../../other/config';

import { TFormFields } from '../../../types/formFields';
import { TNewsEditorState } from '../../../store/news/newsEditor/newsEditorModel';
import { TState } from '../../../store/appStateModel';

function isArticleEmpty(newsEditorModel: TNewsEditorState): boolean {
  const {
    banner,
    fields,
    languageIso,
    logo,
    longDescription,
    shortDescription,
    title
  } = newsEditorModel;
  if (!fields) return false;

  const {
    languageIso: _languageIso,
    longDescription: _longDescription,
    shortDescription: _shortDescription,
    title: _title
  }: TFormFields = fields;

  if (!_languageIso || !_longDescription || !_shortDescription || !_title)
    return false;

  return (
    !banner &&
    !languageIso &&
    !logo &&
    !longDescription &&
    !shortDescription &&
    !title &&
    !_languageIso.value &&
    !_title.value &&
    !_shortDescription.value &&
    (!_longDescription.value || _longDescription.value === WYSIWYG_EMPTY_STRING)
  );
}

function isArticleModified(newsEditorModel: TNewsEditorState): boolean {
  const {
    fields,
    languageIso,
    longDescription,
    shortDescription,
    title
  } = newsEditorModel;
  if (!fields) return false;

  const {
    languageIso: _languageIso,
    longDescription: _longDescription,
    shortDescription: _shortDescription,
    title: _title
  }: TFormFields = fields;

  if (!_languageIso || !_longDescription || !_shortDescription || !_title)
    return false;

  return (
    !!longDescription !== !!_longDescription.value ||
    !!languageIso !== !!_languageIso.value ||
    !!shortDescription !== !!_shortDescription.value ||
    !!title !== !!_title.value
  );
}

const mapStateToProps = (state: TState, ownProps: { match: RouteProps }) => {
  const {
    dictionaries: { languages },
    newsEditor
  } = state;
  return {
    ...newsEditor,
    articleId: parseInt(ownProps.match.params.id),
    isEmpty: isArticleEmpty(newsEditor),
    isModified: isArticleModified(newsEditor),
    languages
  };
};

const mapDispatchToProps = (dispatch) => {
  const bound = bindActionCreators(
    {
      fetchPromoted: fetchPromotedAction,
      getArticle: editArticleAction,
      onCancel: cancelEditingAction,
      onChange: updateFormFieldsAction,
      onLeave: clearEditorAction,
      onRemove: newsArticleRemove,
      onSubmit: submitArticleAction,
      onUpload: newsBannerUpload
    },
    dispatch
  );

  return {
    ...bound,
    onLeave: () => {
      dispatch(clearEditorAction);
      dispatch(clearPromotionAction);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsEditorWrapper);
