import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoaderOverlay, TVessel } from 'react-fishfacts/dist';
import { Modal } from 'antd';

import './WithFleets.scss';
import {
  createFleetAction,
  toggleVesselAction
} from '../../../../store/fleets/fleetsActions';
import FleetsMenu from './FleetsMenu';
import { getDisplayName } from '../../../helpers/helpers';

import { TFleetExt } from '../../../../types/fleet';
import { TState } from '../../../../store/appStateModel';

type Props = {
  fleets: TFleetExt[];
  isLoading: boolean;
  onCreate: (fleetName: string) => void;
  onRemove: (fleetId: number) => void;
  onVesselToggle: (vesselIds: number[], fleet: TFleetExt) => void;
};
type State = {
  vessels?: TVessel[];
};
const modalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  icon: null,
  maskClosable: true,
  title: null
};

/**
 * The fleets management modal menu. Manages fleets, associated to the given vessels.
 * Also exposes create fleet functionality.
 * Exposes two extra properties to a wrapped component:
 * `favVesselsIds: number[]` - a list of vessels IDs that are associated with any user's fleets;
 * `onFavClick(vessels: TVessel | TVessel[]): void` - a callback to show the fleets management menu.
 *  Expects a list of vessels to be passed.
 * @param WrappedComponent
 */
function withFleets(WrappedComponent) {
  class HOC extends React.Component<Props, State> {
    static displayName: string;
    static propTypes;
    state: State = { vessels: null };

    handleCancel = () => this.setState({ vessels: null });

    showMenu = (vessels: TVessel[]) => {
      if (!vessels) {
        throw TypeError('withFleets: vessels are not defined');
      }
      this.setState({
        vessels: Array.isArray(vessels) ? vessels : [vessels]
      });
    };

    // Returns ids of all vessels associated with any user's fleets.
    getFavVesselsIds(): number[] {
      const arrOfFavs: number[][] = this.props.fleets.map(
        ({ vesselIds }: TFleetExt): number[] => vesselIds
      );
      return arrOfFavs
        .join()
        .split(',')
        .map((id: string): number => +id);
    }

    render() {
      const { fleets, isLoading, onCreate, onVesselToggle } = this.props;
      const { vessels } = this.state;
      const vesselIds = vessels ? vessels.map(({ id }: TVessel) => id) : null;

      const componentProps = {
        ...this.props,
        favVesselsIds: this.getFavVesselsIds(),
        onFavClick: this.showMenu
      };

      return (
        <>
          <WrappedComponent {...componentProps} />

          <Modal
            visible={!!vessels}
            {...modalProps}
            onCancel={this.handleCancel}
            className="modal-small"
          >
            <LoaderOverlay
              className="WithFleets__loader"
              isLoading={isLoading}
              isTransparent={true}
            >
              <FleetsMenu
                fleets={fleets}
                onCancel={this.handleCancel}
                onCreate={onCreate}
                onVesselToggle={onVesselToggle}
                vesselIds={vesselIds}
              />
            </LoaderOverlay>
          </Modal>
        </>
      );
    }
  }

  HOC.displayName = `withFleets(${getDisplayName(WrappedComponent)})`;
  HOC.propTypes = withFleets.propTypes;
  return connect(mapStateToProps, mapDispatchToProps)(HOC);
}

withFleets.propTypes = {
  fleets: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onVesselToggle: PropTypes.func.isRequired
};

const mapStateToProps = ({ fleets }: TState) => ({
  fleets: fleets.fleets,
  isLoading: fleets.isPending
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: createFleetAction,
      onVesselToggle: toggleVesselAction
    },
    dispatch
  );

export default withFleets;
