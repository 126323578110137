import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import './User.scss';
import { clearSettings } from '../../services/settings';
import { logoutAction } from '../../store/login/loginActions';
import { ROUTES } from '../../other/config';


class UserPage extends React.PureComponent {
	
  handleLogout = () => {
    const { logout, history } = this.props;
    logout();
    history.push(ROUTES.HOME);
  };

  handleClear = () => {
    clearSettings();
    window.location.reload();
  };
	
  render() {
    const { isLoggedIn, userInfo } = this.props;

    if (!isLoggedIn) return <Redirect to={ROUTES.LOGIN} />;

    return (
      <div className="UserPage">
        <div className="UserPage__form">
          <h2 className="UserPage__title">Welcome, {userInfo.username}!</h2>
  
          <Button
            block
            className="app-mb-5 UserPage__button"
            onClick={this.handleLogout}
            type="primary"
            size="large"
          >Logout</Button>
  
          <Button
            block
            className="UserPage__button"
            onClick={this.handleClear}
            size="large"
          >Clear settings</Button>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { session: { user } } = state;

  return {
    isLoggedIn: !!(user && user.token),
    userInfo: user && user.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout: logoutAction
}, dispatch);

UserPage.propTypes = {
  userInfo: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
