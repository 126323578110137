import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './PersonCard.scss';
import { MEDIA_BASE_URL } from '../../../other/config';
import PersonContacts from './PersonContacts';
import PersonPositions from './PersonPositions';
import { TBusinessPerson } from '../../../types/person';

type Props = {
  person: TBusinessPerson;
};

const PersonCard = ({ person }: Props): ReactElement => {
  const { contact, firstName, lastName, photo, positions, source } = person;
  const name = `${firstName} ${lastName}`;
  const position = contact ? contact.position : 'Unknown';

  const imgUrl: string = photo
    ? `${MEDIA_BASE_URL}${photo.path}`
    : '/assets/placeholders/contact_placeholder.svg';

  return (
    <div className="PersonCard">
      <div className="PersonCard__img-holder">
        <figure className="PersonCard__img">
          <img src={imgUrl} alt={name} />
        </figure>
      </div>

      <div className="PersonCard__info">
        <strong className="PersonCard__name">{name}</strong>
        <PersonPositions
          position={position}
          positions={positions}
          source={source}
        />
        <PersonContacts contact={contact} />
      </div>
    </div>
  );
};

PersonCard.propTypes = {
  person: PropTypes.shape({
    contact: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    photo: PropTypes.object,
    positions: PropTypes.array,
    source: PropTypes.object
  }).isRequired
};
export default PersonCard;
