import { EMapLayer } from '../../../types/mapLayer';

/** Hides tools at the bottom that may clash with Windy forecast player */
export function hideBottomTools(shouldHide: boolean): void {
  const logo = document.getElementsByClassName('VesselsMap__logo')[0] as any;
  const trackPeriod = document.getElementsByClassName(
    'slideout slideout-bottom'
  )[0] as any;
  const fullScreen = document.getElementsByClassName(
    'leaflet-bottom leaflet-left'
  )[0] as any;

  if (logo) logo.style.display = shouldHide ? 'none' : 'block';
  if (trackPeriod) trackPeriod.style.display = shouldHide ? 'none' : 'block';
  if (fullScreen) fullScreen.style.display = shouldHide ? 'none' : 'block';
}

/** Hides Windy's weather forecast player elements */
export function hideWeatherForecast(shouldHide: boolean): void {
  const play = document.getElementById('playpause');
  const line = document.getElementsByClassName('progress-line')[0] as any;
  const code = document.getElementsByClassName('timecode')[1] as any;

  if (play) play.style.display = shouldHide ? 'none' : 'block';
  if (line) line.style.display = shouldHide ? 'none' : 'block';
  if (code) code.style.display = shouldHide ? 'none' : 'block';
}

export function shouldShowForecast(currentMapLayer: EMapLayer): boolean {
  return (
    currentMapLayer === EMapLayer.TEMP ||
    currentMapLayer === EMapLayer.WIND ||
    currentMapLayer === EMapLayer.WAVES
  );
}
