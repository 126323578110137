import { TAction } from 'react-fishfacts/dist';

import { clearProvidersFilterAction } from '../../provider/providers/providersActions';
import { fetchTrackAction } from '../tracks/tracksActions';
import { mapEntitiesInitialState } from './mapEntitiesInitialState';
import { resetVesselFilterAction } from '../../vessel/vesselsFilter/vesselsFilterActions';
import { updateMapOptionsAction } from '../mapOptions/mapOptionsActions';

import { EMapEntitiesActions } from './mapEntitiesConstants';
import { EMapEntitiesMode } from '../../../types/trafficMode';
import { EMapLayer } from '../../../types/mapLayer';
import { LatLng } from 'leaflet';
import { TMapEntitiesState } from './mapEntitiesModel';
import { TProviderAddressShort } from '../../../types/providers';
import { TState } from '../../appStateModel';
import { TVesselLocation } from '../../../types/vessel';

/**
 * Sets the mode of the map entities, such as vessels and services.
 */
export const setEntriesModeAction = (
  mode = EMapEntitiesMode.MULTI
): TAction<TMapEntitiesState, EMapEntitiesActions> => ({
  type: EMapEntitiesActions.SET_MODE,
  payload: { mapEntriesMode: mode }
});

export const purgeEntitiesAction = (): TAction<
  TMapEntitiesState,
  EMapEntitiesActions
> => ({
  type: EMapEntitiesActions.PURGE_ENTITIES,
  payload: mapEntitiesInitialState
});

/**
 * Clears a highlighted entity (vessel or service) and sets the multi vessel mode, which is default one.
 */
export const clearSelectionAction = (): TAction<
  TMapEntitiesState,
  EMapEntitiesActions
> => ({
  type: EMapEntitiesActions.CLEAR_SELECTION,
  payload: {
    mapEntriesMode: EMapEntitiesMode.MULTI,
    highlightedEntity: null
  }
});

/**
 * Toggles single\multiple-vessel mode.
 */
export function toggleVesselSelectAction(vesselId: number) {
  return (dispatch, getState) => {
    const { vesselsLocations } = getState() as TState;

    if (!vesselsLocations.locations) {
      return setTimeout(
        () => dispatch(toggleVesselSelectAction(vesselId)),
        300
      );
    }
    const highlightedEntity: TVesselLocation = vesselsLocations.locations.find(
      ({ id }: TVesselLocation) => id === vesselId
    );
    if (!highlightedEntity) return;

    const action: TAction<TMapEntitiesState, EMapEntitiesActions> = {
      type: EMapEntitiesActions.SELECT_VESSEL,
      payload: {
        mapEntriesMode: vesselId
          ? EMapEntitiesMode.HIGHLIGHTED_VESSEL
          : EMapEntitiesMode.MULTI,
        highlightedEntity: highlightedEntity
      }
    };

    dispatch(action);
    vesselId && dispatch(fetchTrackAction(vesselId));
  };
}

/**
 * Fired when we want a certain vessel to be shown its card, track and centered. Called on vessel search on the map page
 * or when redirected from a vessel page to the map.
 * @param vesselId
 * @returns {Function}
 */
export function highlightVesselAction(vesselId: number) {
  return (dispatch, getState) => {
    const { vesselsLocations } = getState() as TState;
    const highlightedEntity = vesselsLocations.locations.find(
      ({ id }: TVesselLocation): boolean => id === vesselId
    );
    if (!highlightedEntity) return;
    const center = new LatLng(
      highlightedEntity.latitude,
      highlightedEntity.longitude
    );

    // The vessel might be out of the filter settings, so we first need to reset the filter.
    dispatch(resetVesselFilterAction());
    // to focus vessel it should be displayed in the map
    dispatch(updateMapOptionsAction({ center }));
    dispatch(toggleVesselSelectAction(vesselId));
  };
}

/**
 * Toggles HIGHLIGHTED_PROVIDER mode.
 * @param service
 * @returns {Function}
 */
export function toggleServiceSelectAction(
  service: TProviderAddressShort = null
) {
  return (dispatch) => {
    const action: TAction<TMapEntitiesState, EMapEntitiesActions> = {
      type: EMapEntitiesActions.SELECT_SERVICE,
      payload: {
        mapEntriesMode: service
          ? EMapEntitiesMode.HIGHLIGHTED_PROVIDER
          : EMapEntitiesMode.MULTI,
        highlightedEntity: service || null
      }
    };
    dispatch(action);

    if (service) {
      dispatch(updateMapOptionsAction({ layer: EMapLayer.EEZ }));
      dispatch(clearProvidersFilterAction());
    }
  };
}

export const updateVesselCardsRefsAction = (
  locations: TVesselLocation[]
): TAction<TMapEntitiesState, EMapEntitiesActions> => ({
  type: EMapEntitiesActions.UPDATE_VESSEL_CARDS_REFS,
  payload: {
    vesselCardsRefs: locations
  }
});

export const updateServiceCardRefAction = (
  address: TProviderAddressShort
): TAction<TMapEntitiesState, EMapEntitiesActions> => ({
  type: EMapEntitiesActions.UPDATE_SERVICE_CARD_REF,
  payload: {
    serviceCardRef: address
  }
});
