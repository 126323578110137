import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import {
  closest,
  orderProvidersByDistance,
  orderVesselsByDistance
} from './utils/helpers';
import { commonMapPropTypes } from './utils/mapPropTypes';
import { getGroupCenter, getPredefinedView } from './utils/positioning';
import { getTrackColour } from './utils/colors';
import { isIOS13 } from '../../components/helpers/helpers';
import { isPointInside } from './utils/coordinates';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { markersRenderer } from './utils/markersRenderer';
import { ServicePopup } from './elements/ServicePopup';
import { Track } from './elements/Track';
import { VesselPopup } from './elements/VesselPopup';
import withFleets from '../../components/common/vessel/withFleets/withFleets';

import { EMapEntitiesMode } from '../../types/trafficMode';
import { IWindyApi } from './types/windy';
import { TCommonMapProps, TTrackPoint } from './types/common';
import { THandler } from '../../types/handlers';
import { TProviderAddressShort } from '../../types/providers';
import { TVesselLocation, TVesselTrack } from '../../types/vessel';

type Props = TCommonMapProps & {
  api: IWindyApi;
  favVesselsIds: number[];
  onFavClick: THandler;
  postInitCallback?: Function;
};

class MapInner extends React.PureComponent<Props, null> {
  static propTypes: Record<string, any>;
  private markerLayer: Record<string, any>;
  private hoveredProvider: TProviderAddressShort = null;
  private hoveredVessel: TVesselLocation = null;
  private tracks: Track[] = [];
  // A vesselCard appears on vessel marker click or when user gets redirected from vessel's page.
  // (Full vessel info including its picture).
  private vesselCards: VesselPopup[] = [];
  // A vesselPopup appears on vessel track click.
  private vesselPopups: VesselPopup[] = [];
  // Appears on vessel hover.
  private vesselTooltip: VesselPopup = null;

  componentDidMount() {
    this.init();
    this.restoreElements();
  }
  componentDidUpdate(prevProps: Props) {
    const {
      mapOptions: { trackMode },
      selectedEntry,
      tracks
    } = this.props;
    this.drawMarkers();

    if (trackMode === EMapEntitiesMode.HIGHLIGHTED_VESSEL) {
      this.showVesselCard(selectedEntry as TVesselLocation, true);
    } else if (trackMode === EMapEntitiesMode.HIGHLIGHTED_PROVIDER) {
      this.createServicePopup(selectedEntry as TProviderAddressShort);
    } else {
      this.setMapView();
    }

    if (tracks !== prevProps.tracks) this.buildTracks();
  }
  componentWillUnmount() {
    const {
      api: { map, store }
    } = this.props;
    try {
      store && store.set('disableWebGL', true);
      // @ts-ignore
      map.gestureHandling.disable();
      map.remove();
    } catch (e) {}
  }

  init() {
    const { api, postInitCallback } = this.props;
    const { map } = api;

    this.setMapView();
    map.on('moveend', this.handleMoveEnd);
    map.on('mousemove', this.handleMouseMove);
    map.on('click', this.handleMapClick);
    // a hack: markers used to disappear when entering full screen mode (macOS Chrome)
    const update = () => {
      setTimeout(() => this.drawMarkers(), 200);

      const mapContainer = document.getElementsByClassName(
        'VesselsMap__layout'
      )[0];
      if (!mapContainer) return;

      const activeClass = 'map-fullscreen-active';
      const hasClass = document.getElementsByClassName(activeClass)[0];

      if (hasClass) {
        mapContainer.parentElement.classList.remove(activeClass);
      } else {
        mapContainer.parentElement.classList.add(activeClass);
      }
    };
    map.on('enterFullscreen', update);
    map.on('exitFullscreen', update);

    this.drawMarkers();
    typeof postInitCallback === 'function' && postInitCallback(api);
  }

  drawMarkers() {
    const {
      api: { map },
      mapOptions: { colorDescriptor },
      providers,
      vessels
    } = this.props;
    this.markerLayer = markersRenderer(
      providers,
      vessels,
      map,
      colorDescriptor
    );
  }

  restoreElements(): void {
    const { trackPoints, vesselCardsRefs } = this.props;
    this.buildTracks();
    vesselCardsRefs.forEach((vessel: TVesselLocation) =>
      this.showVesselCard(vessel)
    );
    trackPoints.forEach(({ vesselId, pointIndex, latlng }: TTrackPoint) =>
      this.handlePolylineClick(vesselId, pointIndex, latlng)
    );
  }

  /* Mouse move handling */
  onMouse = ({ containerPoint, latlng }: LeafletMouseEvent): void => {
    const {
      api: { map },
      providers,
      vessels
    } = this.props;
    const nearestProvider =
      providers && orderProvidersByDistance(providers, latlng)[0];
    const nearestVessel = vessels && orderVesselsByDistance(vessels, latlng)[0];

    if (nearestVessel) {
      const pos = new LatLng(nearestVessel.latitude, nearestVessel.longitude);
      const isHovered = isPointInside(containerPoint, pos, map);
      isHovered
        ? this.handleMarkerMouseOver(nearestVessel)
        : this.handleMarkerMouseLeave();
    } else {
      this.hoveredVessel = null;
    }
    if (nearestProvider) {
      const isHovered = isPointInside(
        containerPoint,
        nearestProvider.position,
        map
      );
      isHovered
        ? this.handleProviderMouseOver(nearestProvider)
        : this.handleProviderMouseLeave();
    } else {
      this.hoveredProvider = null;
    }
  };
  mouseHandler = throttle(this.onMouse, 100);
  handleMouseMove = (e: LeafletMouseEvent) => {
    if (closest(e.originalEvent.target, 'PointMark__info')) return;
    this.mouseHandler(e);
  };

  /* Mouse over and leave handling */
  handleMarkerMouseOver = (nearestVessel: TVesselLocation) => {
    if (this.hoveredVessel && this.hoveredVessel.id === nearestVessel.id)
      return;
    this.hoveredVessel = nearestVessel;
    this.cursorPointer();
    if (!isIOS13()) this.showVesselTooltip(nearestVessel);
  };
  handleProviderMouseOver = (nearestProvider: TProviderAddressShort): void => {
    if (
      this.hoveredProvider &&
      this.hoveredProvider.providerId === nearestProvider.providerId
    )
      return;
    this.hoveredProvider = nearestProvider;
    this.cursorPointer();
  };
  handleMarkerMouseLeave = () => {
    if (!this.hoveredVessel) return;
    this.hoveredProvider || this.cursorGrab();
    this.closeVesselTooltip();
    this.hoveredVessel = null;
  };
  handleProviderMouseLeave = (): void => {
    if (!this.hoveredProvider) return;
    this.hoveredProvider = null;
    this.hoveredVessel || this.cursorGrab();
  };

  /* Methods to make cards/popups/tooltips appear  */
  isVesselCardShown(vesselId: number): boolean {
    return !!this.vesselCards.find(
      (c: VesselPopup) => c.options.vessel.id === vesselId
    );
  }
  // A vessel card creator: vessel click case, vessel's page redirect case.
  showVesselCard(vessel: TVesselLocation, shouldSetView?: boolean): void {
    const {
      mapOptions: { showCard },
      updateVesselCardsRefs
    } = this.props;
    if (!showCard || this.isVesselCardShown(vessel.id)) return;
    shouldSetView && this.setMapView();

    const popupOptions = {
      autoClose: false,
      closeOnClick: false,
      closeOnEscapeKey: false
    };
    this.vesselCards.push(this.getVesselInfo(vessel, true, popupOptions));
    updateVesselCardsRefs(
      this.vesselCards.map((p: VesselPopup) => p.options.vessel)
    );
  }
  // Shouldn't appear if a marker has its vessel card already shown.
  showVesselTooltip(vessel: TVesselLocation): void {
    this.vesselTooltip = this.isVesselCardShown(vessel.id)
      ? null
      : this.getVesselInfo(vessel, false);
  }
  showVesselPopup(vessel: TVesselLocation): void {
    const popupOptions = {
      autoClose: false,
      closeOnClick: false,
      closeOnEscapeKey: false
    };
    this.vesselPopups.push(this.getVesselInfo(vessel, false, popupOptions));
  }
  // General case vessel card/popup/tooltip creator.
  getVesselInfo(
    vessel: TVesselLocation,
    isExtended: boolean,
    popupOptions?: {}
  ): VesselPopup {
    const {
      api: { map },
      favVesselsIds,
      goToVesselPage,
      mapOptions: { showPicture },
      onFavClick
    } = this.props;

    const isInFavourites: boolean = favVesselsIds.includes(vessel.id);
    const markerOptions = {
      isExtended,
      isInFavourites,
      onFavClick,
      showPicture
    };
    return new VesselPopup({
      map: map,
      markerOptions,
      onClick: goToVesselPage,
      onClose: this.handleMarkerClose,
      popupOptions,
      vessel
    });
  }
  createServicePopup = (service: TProviderAddressShort): void => {
    const { api, updateServiceCardRef } = this.props;
    updateServiceCardRef(service);

    new ServicePopup({
      map: api.map,
      onClose: this.handleServiceCardClose,
      service
    });
  };

  /** Service card oClose handler */
  handleServiceCardClose = (): void => {
    const { onServiceCardClose, updateServiceCardRef } = this.props;
    onServiceCardClose();
    updateServiceCardRef(null);
  };

  /* Methods to close vessel cards/popups/tooltips */
  handleMarkerClose = (
    vesselId: number,
    isExtended: boolean,
    latlng: LatLng
  ): void => {
    isExtended
      ? this.handleVesselCardClose(vesselId)
      : this.handlePopupClose(vesselId, latlng);
  };
  handlePopupClose = (vesselId: number, latlng: LatLng): void =>
    this.props.updateTrackPoints(null, vesselId, latlng);
  handleVesselCardClose = (vesselId: number): void => {
    this.closeVesselCard(vesselId);
    this.closeVesselPopups(vesselId);
    this.toggleVesselTrack(vesselId, true);
  };
  getRemoveVesselPopupCallback = (vesselId: number) => (
    p: VesselPopup
  ): boolean => {
    const {
      options: {
        vessel: { id }
      }
    } = p;
    id === vesselId && p.remove();
    return id !== vesselId;
  };
  closeVesselCard(vesselId: number): void {
    this.vesselCards = this.vesselCards.filter(
      this.getRemoveVesselPopupCallback(vesselId)
    );
    this.props.updateVesselCardsRefs(
      this.vesselCards.map((p: VesselPopup) => p.options.vessel)
    );
  }
  closeVesselPopups(vesselId: number): void {
    this.vesselPopups = this.vesselPopups.filter(
      this.getRemoveVesselPopupCallback(vesselId)
    );
  }
  closeVesselTooltip(): void {
    if (!this.vesselTooltip) return;
    this.vesselTooltip.remove();
    this.vesselTooltip = null;
  }
  toggleVesselTrack(vesselId: number, isCardShown: boolean): void {
    const trackToRemove = this.tracks.find(({ options }) => {
      if (!options) return false;
      return options.path.vesselId === vesselId;
    });

    if (trackToRemove) {
      this.props.onTrackRemove(vesselId);
    } else if (!isCardShown) {
      this.props.onTrackAdd(vesselId);
    }
  }

  /* Mouse click handlers */
  handleMapClick = ({ containerPoint, latlng }: LeafletMouseEvent): void => {
    const {
      api: { map, picker },
      providers,
      vessels
    } = this.props;
    const nearestProvider =
      providers && orderProvidersByDistance(providers, latlng)[0];
    const nearestVessel = vessels && orderVesselsByDistance(vessels, latlng)[0];
    let isHoveredVessel, isHoveredService;

    if (nearestVessel) {
      const pos = new LatLng(nearestVessel.latitude, nearestVessel.longitude);
      isHoveredVessel = isPointInside(containerPoint, pos, map);
      isHoveredVessel && this.handleMarkerClick(nearestVessel);
    }
    if (nearestProvider) {
      const isHoveredService = isPointInside(
        containerPoint,
        nearestProvider.position,
        map
      );
      isHoveredService && this.handleProviderClick(nearestProvider);
    }
    !isHoveredService &&
      !isHoveredVessel &&
      picker &&
      this.showWeatherPicker(latlng);
  };
  handleMarkerClick = (vessel: TVesselLocation) => {
    const isCardShown: boolean = this.isVesselCardShown(vessel.id);
    this.toggleVesselTrack(vessel.id, isCardShown);

    if (isCardShown) {
      this.handleVesselCardClose(vessel.id);
    } else {
      this.showVesselCard(vessel);
    }
  };
  handleProviderClick = (provider: TProviderAddressShort): void =>
    this.props.goToProviderPage(provider.providerId);
  handlePolylineClick = (
    vesselId: number,
    pointIndex: number,
    latlng: LatLng
  ): void => {
    const track = this.props.tracks.find(({ vesselId: id }) => vesselId === id);
    const point = track && track.data[pointIndex];
    if (!point) return;

    const { speed, lastUpdate } = point;
    const { lat, lng } = latlng;
    const vessel = {
      speed,
      latitude: lat,
      longitude: lng,
      lastUpdate,
      id: vesselId
    };
    this.showVesselPopup(vessel as TVesselLocation);
    this.props.updateTrackPoints({ latlng, pointIndex, vesselId });
  };
  showWeatherPicker({ lat, lng }: LatLng): void {
    const {
      api: { picker }
    } = this.props;
    picker.open({ lat, lon: lng });
  }

  buildTracks(): void {
    const colorMap = {};

    // remove all tracks, remembering their colors
    this.tracks.forEach((track: Track) => {
      colorMap[track.options.path.vesselId] = track.options.color;
      track.remove();
    });

    this.tracks = this.props.tracks.map(
      (path: TVesselTrack): Track => {
        const options = {
          color: getTrackColour(colorMap, path.vesselId),
          map: this.props.api.map,
          onClick: this.handlePolylineClick,
          path: path
        };
        return new Track(options);
      }
    );
  }

  /* Utility methods */
  // Preserves the map center and zoom in the local storage.
  handleMoveEnd = () => {
    const {
      api: { map },
      updateMapOptions
    } = this.props;
    map &&
      updateMapOptions({
        center: map.getCenter(),
        zoom: map.getZoom()
      });
  };

  /** Sets center and zoom of the map regarding to the mode */
  setMapView(): void {
    const {
      api: { map, store },
      mapOptions,
      selectedEntry,
      vessels
    } = this.props;

    if (
      mapOptions.trackMode === EMapEntitiesMode.HIGHLIGHTED_VESSEL &&
      !store
    ) {
      // store as windy detector
      const {
        latitude: lat,
        longitude: lng
      } = selectedEntry as TVesselLocation;
      map.setView({ lat, lng }, 7);
      map.panBy([0, -130]);
    } else if (mapOptions.trackMode === EMapEntitiesMode.HIGHLIGHTED_PROVIDER) {
      map.setView((selectedEntry as TProviderAddressShort).position, 7);
    } else {
      // todo now groupCenter is never set. Introduce group traffic mode to set the map center on filtered vessels.
      const { center, zoom } = getPredefinedView(
        mapOptions.center,
        mapOptions.zoom,
        getGroupCenter(vessels)
      );
      map.setView(center, zoom);
    }
  }
  cursorGrab(): void {
    // @ts-ignore
    this.props.api.map._mapPane &&
      // @ts-ignore
      this.props.api.map._mapPane.style.setProperty(
        'cursor',
        'grab',
        'important'
      );
  }
  cursorPointer(): void {
    // @ts-ignore
    this.props.api.map._mapPane.style.setProperty(
      'cursor',
      'pointer',
      'important'
    );
  }

  render() {
    const {
      mapOptions: { currentMapLayer }
    } = this.props;
    const imgLogo =
      currentMapLayer === 'currents' ? 'Logo_Windy.svg' : 'Logo_EEZ.svg';

    return (
      <div className="MapInner">
        <img
          src={`assets/logo/${imgLogo}`}
          alt=""
          className="VesselsMap__logo"
        />
      </div>
    );
  }
}

MapInner.propTypes = {
  ...commonMapPropTypes,
  api: PropTypes.object.isRequired,
  favVesselsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  postInitCallback: PropTypes.func
} as any;

export default withFleets(MapInner);
