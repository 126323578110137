import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons/lib';

import './PromoButton.scss';
import { ROUTES } from 'other/config';
import { EPromoStatus } from 'types/promotion';

type Props = {
  articleId: number;
  size?: 'default' | 'small';
  status?: EPromoStatus;
};

const Icon = <PlusOutlined />;
const PromoButton = ({ articleId, size, status }: Props) => {
  const to = {
    pathname: `${ROUTES.NEWS_EDITOR}/${articleId}`,
    hash: `#${ROUTES.PROMO_FORM}`
  };
  const style = {
    fontSize: size === 'small' ? '14px' : '18px'
  };

  if (!status || status === EPromoStatus.UNABLE) {
    return null;
  } else if (status === EPromoStatus.PROMOTED) {
    return <div className="PromoButton__label">Promoted</div>;
  }

  return (
    <Link to={to}>
      <Button
        icon={Icon}
        shape="round"
        size={size as any}
        style={style}
        type="primary"
      >
        Promote
      </Button>
    </Link>
  );
};

PromoButton.propTypes = {
  articleId: PropTypes.number.isRequired,
  size: PropTypes.string,
  status: PropTypes.string
};
export default PromoButton;
