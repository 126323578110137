import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { TLabelValue } from 'react-fishfacts/dist';

import {
  calculateCostAction,
  fetchPaymentReportAction
} from '../../../../store/promo/promoActions';
import { checkPromoPaymentReport, NONE_VALUE } from './helpers';
import { gotoNewsListAction } from '../../../../store/news/news/newsActions';
import PromoWrapper from './PromoWrapper';
import { selectServiceOptions } from '../../../../components/selects/ServiceCategoriesSelect/selectServiceOptions';
import { selectVesselTypesOptions } from '../../../../components/common/vessel/VesselFilter/components/VesselTypesFilter/vesselTypesFilterOptions';

import { TPromoted } from '../../../../types/promotion';
import { TState } from '../../../../store/appStateModel';
import { TTreeSelectOption } from '../../../../other/formAndValidation/formUtils';

const NONE_LABEL = 'None';
const noServices: TTreeSelectOption = {
  id: NONE_VALUE,
  title: NONE_LABEL,
  key: NONE_VALUE,
  value: NONE_VALUE
};
const noTypes: TLabelValue = {
  label: NONE_LABEL,
  value: NONE_VALUE
};

const selectArticle = createSelector(
  (articles: TPromoted[], articleId: number) => ({
    articleId: articleId,
    articles: articles
  }),
  ({ articles, articleId }): TPromoted =>
    articles.find(({ news }: TPromoted) => news && news.id === articleId)
);

type OwnProps = {
  articleId: number;
  onPay: () => void;
};

const mapStateToProps = (state: TState, ownProps: OwnProps) => {
  const {
    promo: { articles, cost, isCalculating, isPending, fields, report },
    dictionaries: { promotionCountries, vesselTypes },
    serviceBranches: { serviceBranches }
  } = state;

  return {
    article: selectArticle(articles, ownProps.articleId),
    articleId: ownProps.articleId,
    cost: cost,
    countries: promotionCountries,
    fields: fields,
    isCostLoading: isCalculating,
    isLoading: isPending,
    onPay: ownProps.onPay,
    report: checkPromoPaymentReport(report),
    serviceOptions: [noServices, ...selectServiceOptions(serviceBranches)],
    vesselTypesOptions: [noTypes, ...selectVesselTypesOptions(vesselTypes)]
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchPaymentReport: fetchPaymentReportAction,
      goToNewsPage: gotoNewsListAction,
      onCalculate: calculateCostAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoWrapper);
