import {
  cityPattern,
  phonePattern,
  websitePattern
} from '../../../../other/formAndValidation/validationRules';
import { minMaxLenValidator } from '../../../../other/formAndValidation/validators';

// INFO
export const longDescriptionRules = minMaxLenValidator(
  'About service provider',
  1200,
  30
);
export const serviceNameRules = minMaxLenValidator('Service name', 50, 5);
export const shortDescriptionRules = minMaxLenValidator(
  'Short description',
  300,
  15
);
export const websiteRules = [
  ...minMaxLenValidator('Website', 50, 8),
  {
    pattern: websitePattern,
    message: "Value doesn't match pattern!"
  }
];

// ADDRESS
export const addressRules = minMaxLenValidator('Address', 50, 5);
export const cityRules = [
  ...minMaxLenValidator('City', 50, 3),
  {
    pattern: cityPattern,
    message: "City value doesn't match pattern!"
  }
];
export const countryRules = [
  {
    required: true,
    message: 'Country is required!'
  }
];
export const postcodeRules = minMaxLenValidator('Post code', 50, 3);
export const titleRules = minMaxLenValidator('Location title', 50, 3);

// CONTACT
export const emailRules = [
  {
    required: true,
    message: 'Email is required!'
  },
  {
    type: 'email',
    message: "Email value doesn't match pattern!"
  }
] as any;
export const firstNameRules = minMaxLenValidator('First name', 50, 2);
export const lastNameRules = minMaxLenValidator('Last name', 50, 2);
export const mobileRules = [
  {
    pattern: phonePattern,
    message: "Mobile value doesn't match pattern!"
  }
];
export const positionRules = minMaxLenValidator('Last name', 100, 5);
export const telephoneRules = [
  {
    required: true,
    message: 'Telephone is required!'
  },
  {
    pattern: phonePattern,
    message: "Phone value doesn't match pattern!"
  }
];

// PRODUCT
export const productTitleRules = minMaxLenValidator('Title', 100, 5);
