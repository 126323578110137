import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';
import Logo from '../../../components/common/logos/Logo/Logo';
import { ROUTES } from '../../../other/config';

const year = new Date().getFullYear();

const Footer = () => (
  <footer className="Footer">
    <div className="Footer__main">
      <div className="Footer__nav">
        <Logo />

        <ul className="Footer__links">
          <li>
            <Link className="Footer__link" to={ROUTES._ABOUT}>
              About us
            </Link>
          </li>
          {/*<li><Link to={ROUTES.CONTACT}>Contact us</Link></li>*/}
          {/*<li><Link to={routes.ADVERTISE}>Advertise with us</Link></li>*/}
          <li>
            <Link className="Footer__link" to={ROUTES.DISCLAIMER}>
              Disclaimer
            </Link>
          </li>
        </ul>
      </div>

      <div className="Footer__copyright">&copy; {year} FISHFACTS</div>
    </div>

    <div className="app-d-flex app-justify-content-end app-d-sm-none">
      <div className="Footer__brands">
        <a href="https://play.google.com/store/apps/details?id=fo.fishfacts.fishfactsapp">
          <img src="/assets/googleplay.svg" width="150" alt="GooglePlay link" />
        </a>
        <a href="https://itunes.apple.com/us/app/fishfacts-fo/id1447608197?mt=8">
          <img src="/assets/appstore.svg" width="150" alt="AppStore link" />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
