import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import './DraftOverlay.scss';

type Props = {
  className?: string;
  isShown?: boolean;
};

const DraftOverlay = ({ isShown }: Props): ReactElement | null => {
  if (!isShown) return null;

  return (
    <div className="DraftOverlay">
      <div className="DraftOverlay__label">Draft</div>
    </div>
  );
};

DraftOverlay.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string,
  isDraft: PropTypes.bool,
  path: PropTypes.string
};

export default DraftOverlay;
