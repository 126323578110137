import { push } from 'connected-react-router';
import { TAction, THttpResponse } from 'react-fishfacts/dist';

import { createAsyncActions } from '../../_utils/createAsyncActions';
import { ENDPOINTS, ROUTES } from '../../../other/config';
import http from '../../../services/http';
import { providerInitialState } from './providerInitialState';
import { sortArraysForProvider } from '../providerEditor/helpers/helpers';

import { EProviderActions } from './providerConstants';
import { TProvider } from '../../../types/providers';
import { TProviderState } from './providerModel';

export let fetchSet;

/** Fetch provider by ID. */
export function fetchProviderAction(providerId: number) {
  return (dispatch) => {
    fetchSet = createAsyncActions<TProviderState>(
      dispatch,
      EProviderActions.FETCH
    ).request();
    const url = `${ENDPOINTS.SERVICE_PROVIDER}/${providerId}`;

    http
      .send(url)
      .then(({ data }: THttpResponse<TProvider>) =>
        fetchSet.success({
          provider: sortArraysForProvider(data)
        })
      )
      .catch(fetchSet.error);
  };
}

/** Resets the state. */
export const clearProviderAction = (): TAction<
  TProviderState,
  EProviderActions
> => ({
  type: EProviderActions.CLEAR_PROVIDER,
  payload: providerInitialState
});

/** Redirects to the editor page of the current provider. */
export const editProviderAction = (providerId: number) =>
  push(`${ROUTES.SERVICE_EDITOR}/${providerId}`);

export const showProviderPageAction = (providerId: number) =>
  push(`${ROUTES.SERVICE_PROVIDER}/${providerId}`);
