import React from 'react';
import PropTypes from 'prop-types';
import { areEqual, removeAllOption, strArr2Num } from 'react-fishfacts/dist';
import classnames from 'classnames';
import { TreeSelect } from 'antd';

import './ServiceCategoriesSelect.scss';
import {
  filterTreeNode,
  TTreeSelectOption
} from '../../../other/formAndValidation/formUtils';

type Props = {
  className?: string;
  onChange?: (branches: number[]) => void;
  options: TTreeSelectOption[];
  value?: string[];
};
const ALL_VALUE = 'All branches';

class ServiceCategoriesSelect extends React.PureComponent<Props> {
  static propTypes;

  getValue = (): string | string[] => {
    const { value } = this.props;
    if (!value) return;
    return value.length > 0 ? value : [ALL_VALUE];
  };

  handleChange = (values: string[]): void => {
    const { onChange } = this.props;
    const _value: string | string[] = this.getValue();

    // Selected ALL or removed each or selected each
    if (
      !areEqual(_value, [ALL_VALUE]) &&
      (values.includes(ALL_VALUE) ||
        values.length === 0 ||
        areEqual(_value, values))
    ) {
      onChange([]);
    } else {
      const arg = strArr2Num(removeAllOption(values)).filter(Boolean);
      onChange(arg);
    }
  };

  render() {
    const { className, options } = this.props;
    const cls: string = classnames('ServiceCategoriesSelect', className);
    const _value: string | string[] = this.getValue();

    return (
      <TreeSelect
        autoClearSearchValue
        showSearch
        className={cls}
        value={_value}
        allowClear
        treeCheckable
        onChange={this.handleChange}
        treeData={options}
        filterTreeNode={filterTreeNode}
      />
    );
  }
}

ServiceCategoriesSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  )
};

export default ServiceCategoriesSelect;
