import { TArticle } from '../../../types/article';

export enum ENewsEditorActions {
  CREATE_ARTICLE_BLUEPRINT = 'newsEditor/CREATE_ARTICLE_BLUEPRINT',
  FETCH_ARTICLE = 'newsEditor/FETCH_ARTICLE',
  FETCH_ARTICLE_BODY = 'newsEditor/FETCH_ARTICLE_BODY',
  SUBMIT_ARTICLE = 'newsEditor/SUBMIT_ARTICLE',

  BANNER_UPLOAD = 'newsEditor/BANNER_UPLOAD',
  BANNER_UPLOAD_PROGRESS = 'newsEditor/BANNER_UPLOAD_PROGRESS',

  ASSIGN_FORM_FIELDS = 'newsEditor/ASSIGN_FORM_FIELDS',
  UPDATE_FIELD = 'newsEditor/UPDATE_FIELD',
  CLEAR_EDITOR = 'newsEditor/CLEAR_EDITOR'
}

export const articleBlueprint: Partial<TArticle> = {
  published: false,
  title: '',
  shortDescription: '',
  longDescription: ''
};
