import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './RejectApprove.scss';
import Confirmation from './Confirmation';
import { THandler } from '../../../types/handlers';

type Props = {
  onApprove: THandler;
  onReject: THandler;
};

const RejectApprove = ({ onReject, onApprove }: Props): ReactElement => {
  const text = <p>We will notify user by sending them an email.</p>;

  return (
    <div className="RejectApprove">
      <Confirmation
        onConfirm={onReject}
        text={text}
        btnType="link"
        title="Do you confirm rejection?"
      >
        Reject
      </Confirmation>

      <Confirmation
        onConfirm={onApprove}
        text={text}
        btnType="primary"
        title="Do you confirm approval?"
      >
        Approve
      </Confirmation>
    </div>
  );
};

RejectApprove.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default RejectApprove;
