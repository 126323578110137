import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import classnames from 'classnames';
import { withEllipsisMenu } from 'react-fishfacts/dist';
import './CardWrapper.scss';

type Props = {
  children: ReactElement;
  className?: string;
  id: number;
  onEdit: (id: number) => void;
  onRemove: (id: number) => void;
};


const CardWrapper: React.FC<Props> = ({ children, className, id, onEdit, onRemove }): ReactElement => {
  const cls = classnames('CardWrapper', className);
  const handleEdit = () => onEdit(id);
  const handleRemove = () => onRemove(id);

  const actions = (
    <div className="ArticleBanner__actions">
      <Button className="ArticleBanner__button" onClick={handleEdit} type="link">Edit</Button>
      <div className="ArticleBanner__divider"/>
      <Button className="ArticleBanner__button" onClick={handleRemove} type="link">Delete</Button>
    </div>
  );

  const Menu = withEllipsisMenu(actions);

  return (
    <div className={cls}>
      <div className="CardWrapper__menu">
        <Menu />
      </div>
      {children}
    </div>
  )
};


CardWrapper.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default CardWrapper;
