import React from 'react';

function DisclaimerPage() {
  return (
    <div>
      <h1>
        Disclaimer
      </h1>
      <ul>
        <li>
          Fishfacts is aiming to provide as correct and complete information as possible but
          cannot guarantee for any misleading or wrong information
        </li>
        <li>Data is provided for informational reasons only. The data is not legal or financial
          advice.
        </li>
        <li>
          Users of data are responsible for using data
        </li>
        <li>
          Fishfacts will only be collecting data like technical specifications, fishing
          rights, company data and annual reports from official sources. Fishfacts does our
          foremost to provide reliable and up-to-date data. But fishfacts does not warrant the
          completeness or accuracy of the collected data. Fishfacts cannot ensure the data is
          up-to-date. Fishfacts will state date and time when the data is collected
        </li>
        <li>
          Fishfacts cannot be responsible of costs or decisions built on data from the
          database.
        </li>
        <li>
          Fishfacts will disclaim any responsibility for mistakes or omissions of any kind on
          this website.
        </li>
        <li>
          Fishfacts reserves the right to change these terms of use without warning.
        </li>
        <li>
          Fishfacts makes reservations for errors due to human or technical failure. In case
          of error or mistakes, please contact Fishfacts
        </li>
      </ul>
    </div>
  );
}

export default DisclaimerPage;
