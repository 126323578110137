import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

import './Aside.scss';
import Navigation from './components/Navigation/Navigation';
import ThemeToggle from './components/ThemeToggle/ThemeToggle';
import { isIE } from '../helpers/helpers';
import { BANNER_LINK } from '../../other/config';

type Props = {
  isAsideOpen: boolean;
  toggleAside: () => void;
};

const Aside: FunctionComponent<Props> = ({ isAsideOpen, toggleAside }) => {
  const handleClose = () => toggleAside();

  return (
    <aside className="Aside__container">
      <Drawer
        className="Aside"
        placement="left"
        closable={false}
        onClose={handleClose}
        visible={isAsideOpen}
        getContainer={false}
      >
        <Link to={BANNER_LINK}>
          <img
            className="app-d-block"
            src="/assets/banner/fishfacts/analytics/150x150.jpg"
            alt="Banner"
            width="150"
          />
        </Link>
        <Navigation closeAside={handleClose} />
        {isIE() || <ThemeToggle />}
      </Drawer>
    </aside>
  );
};

Aside.propTypes = {
  isAsideOpen: PropTypes.bool.isRequired,
  toggleAside: PropTypes.func.isRequired
};

export default Aside;
