import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './VesselTitle.scss';

type Props = {
  flagSrc: string;
  name: string;
};

const VesselTitle = ({ flagSrc, name }: Props): ReactElement => (
  <h2 className="VesselTitle">
    <figure className="VesselTitle__flag">
      <img src={flagSrc} alt={name} />
    </figure>
    {name}
  </h2>
);


VesselTitle.propTypes = {
  flagSrc: PropTypes.string,
  name: PropTypes.string,
};

export default VesselTitle;
