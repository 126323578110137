import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-virtualized';

import {
  getGridParams,
  TVesselsListBaseProps,
  vesselsListBasePropTypes
} from './helpers';
import VesselCard from '../../../components/common/vessel/VesselCard/VesselCard';

type Props = TVesselsListBaseProps & {
  height: number;
  width: number;
};

const VesselsList = (props: Props): ReactElement => {
  const { favVesselsIds, height, vessels, width, ...restOfProps } = props;
  const _height = Math.ceil(height);
  const _width = Math.ceil(width);

  const { columnCount, columnWidth, rowCount, rowHeight } = getGridParams(
    width,
    vessels.length
  );

  const cellRenderer = (cellProps): ReactElement | null => {
    const { columnIndex, key, rowIndex, style } = cellProps;
    const index: number = rowIndex * columnCount + columnIndex;
    const vessel = vessels[index];
    if (!vessel) return null;

    const isInFavourites: boolean = favVesselsIds.includes(vessel.id);

    return (
      <div key={key} style={style}>
        <VesselCard
          isInFavourites={isInFavourites}
          // @ts-ignore
          vessel={vessel}
          {...restOfProps}
        />
      </div>
    );
  };

  return (
    <Grid
      cellRenderer={cellRenderer}
      className="VesselListPage__list"
      columnCount={columnCount}
      columnWidth={columnWidth}
      height={_height}
      rowHeight={rowHeight}
      rowCount={rowCount}
      width={_width}
    />
  );
};

VesselsList.propTypes = {
  ...vesselsListBasePropTypes,
  height: PropTypes.number,
  width: PropTypes.number
};

export default VesselsList;
