import { ROUTES } from '../../other/config';
import { EUserAuthorities } from '../session/sessionModel';
import { TNavigationCategory } from '../../types/navigation';

export enum EServiceBranchesActions {
  FETCH = 'serviceBranches/FETCH',
  FETCH_PROVIDERS = 'serviceBranches/FETCH_PROVIDERS',

  PICK_BRANCH = 'serviceBranches/PICK_BRANCH',
  GET_CATEGORY = 'serviceBranches/GET_CATEGORY',

  APPLY_FILTER = 'serviceBranches/APPLY_FILTER',
  SET_FILTER = 'serviceBranches/SET_FILTER',
  FILTER_FAILED = 'serviceBranches/FILTER_FAILED',

  FILTER_PROVIDERS = 'serviceBranches/FILTER_PROVIDERS'
}

export enum EProvidersFilterActions {
  FILTER_BY_COUNTRIES = 'FILTER_BY_COUNTRIES'
}

export const BASIC_HOME_CATEGORIES: TNavigationCategory[] = [
  {
    path: ROUTES.VESSELS,
    imageSrc: '/assets/home/Vessels.png',
    title: 'Vessels'
  },
  {
    path: ROUTES.NEWS,
    imageSrc: '/assets/home/News.png',
    title: 'News'
  },
  {
    path: ROUTES.MAP,
    imageSrc: '/assets/home/Map.png',
    title: 'Map',
    requiredPermissions: [EUserAuthorities.VIEW_MAP]
  },
  {
    path: ROUTES.ANALYTICS,
    imageSrc: '/assets/home/company.jpg',
    title: 'Analytics',
    requiredPermissions: [EUserAuthorities.VIEW_ANALYTICS],
    isHiddenForMobile: true
  }
];
