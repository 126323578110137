import React, { ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { FilterDrawer, IconCustom } from 'react-fishfacts/dist';

import './MapFilter.scss';
import MapOptions from './MapOptions/MapOptions';
import ProviderCategoryFilter from './ProviderCategoryFilter/ProviderCategoryFilter';
import { clearProvidersFilterAction } from '../../../../store/provider/providers/providersActions';
import { resetVesselFilterAction } from '../../../../store/vessel/vesselsFilter/vesselsFilterActions';
import VesselFilter from '../../../../components/common/vessel/VesselFilter/VesselFilter';
import { watchLocationsAction } from '../../../../store/map/vesselsLocations/vesselsLocationsActions';
import { TState } from '../../../../store/appStateModel';

type Props = {
  isFilterSet: boolean;
  resetFilters: () => void;
};

const MapFilter: React.FC<Props> = ({
  isFilterSet,
  resetFilters
}: Props): ReactElement => {
  const [isVisible, setVisibility] = useState<boolean>(false);

  const handleClose = () => setVisibility(false);
  const handleOpen = () => setVisibility(true);

  return (
    <div className="MapFilter">
      <Button className="MapFilter__button" onClick={handleOpen}>
        <div className="MapFilter__button-content">
          <IconCustom type="filters" title="Legend" style={{ fontSize: 22 }} />
          {isFilterSet && <div className="MapFilter__indicator" />}
        </div>
      </Button>

      <FilterDrawer
        isVisible={isVisible}
        onClose={handleClose}
        onReset={resetFilters}
      >
        <VesselFilter />
        <ProviderCategoryFilter />
        <MapOptions />
      </FilterDrawer>
    </div>
  );
};

MapFilter.propTypes = {
  isFilterSet: PropTypes.bool,
  resetFilters: PropTypes.func.isRequired
};

const mapStateToProps = (state: TState) => {
  const {
    providers: { filterSettings },
    vesselsFilter
  } = state;
  return {
    isFilterSet: vesselsFilter.isFilterSet || filterSettings.isFilterSet
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetFilters: () => {
    dispatch(clearProvidersFilterAction());
    dispatch(resetVesselFilterAction());
    dispatch(watchLocationsAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MapFilter);
