import { connect } from 'react-redux';
import { PrivateRoute } from 'react-fishfacts/dist';

import { ROUTES } from '../../other/config';
import { TState } from '../../store/appStateModel';


const mapStateToProps = (state: TState) => {
  const { session: { user } } = state;

  return {
    isLoggedIn: !!(user && user.token),
    unAuthRedirect: ROUTES.LOGIN
  };
};

export default connect(mapStateToProps)(PrivateRoute);
