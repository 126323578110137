import { basicInitialState } from '../_utils/basicInitialState';
import { TPromoState } from './promoModel';

export const promoInitialState: TPromoState = {
  ...basicInitialState,
  articles: [],
  cost: null,
  isCalculating: false,
  fields: null,
  report: null
};
