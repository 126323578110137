import moment from 'moment';
import {
  EScheduleItemStatus,
  TEventScheduleGroup,
  TScheduleItem,
} from '../../types/event';

/**
 * Type converter. Groups schedule items by date.
 */
export function groupEventsByDate(
  schedule: TScheduleItem[]
): TEventScheduleGroup[] {
  const dates = new Set();
  const groups = [];

  schedule.forEach((item: TScheduleItem) => {
    const dayMonth = moment(item.start).format('DD MMM');
    dates.add(dayMonth);
  });

  dates.forEach((_dateMonth: string) => {
    const list = schedule.filter((item: TScheduleItem) => {
      const dayMonth = moment(item.start).format('DD MMM');
      return _dateMonth === dayMonth;
    });
    groups.push({
      date: _dateMonth,
      id: Math.random(),
      items: list,
    });
  });

  return groups;
}

export const COLOR_MAP = {
  [EScheduleItemStatus.AVAILABLE]: '',
  [EScheduleItemStatus.BOOKED]: 'blue',
  [EScheduleItemStatus.PENDING]: 'green',
  [EScheduleItemStatus.SELF_BOOKED]: 'orange',
};
