import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import Delousing from './components/Delousing';
import { getRefrigerationBlock } from '../../../helpers/getRefrigerationBlock';

import {
  EAGD,
  EAquaOperations,
  ECageMaintenance,
  ECapacityUnits,
  EElectronics,
  EFarmAutomation,
  EHandlingSystem,
  ELoadingUnloading,
  ESlaughter,
  EWellArrangement,
  TAquaculture
} from 'react-fishfacts/dist';

type Props = {
  aquaculture: TAquaculture;
  getManufacturerFieldById: (number) => string | JSX.Element;
};

const Aquaculture = ({
  aquaculture,
  getManufacturerFieldById
}: Props): ReactElement => {
  const {
    capacity,
    electronics,
    loadingUnloading,
    well,
    refrigeration,
    handling,
    slaughter,
    delousing,
    AGDMethod,
    automation,
    cageMaintenance,
    aquaOperations
  } = aquaculture;

  return (
    <table className="ExtendedFields__table">
      <tbody>
        {capacity && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Capacity</th>
            </tr>
            {capacity.units && (
              <tr>
                <th>Units</th>
                <td>{ECapacityUnits[capacity.units]}</td>
              </tr>
            )}
            {capacity.value && (
              <tr>
                <th>Value</th>
                <td>{capacity.value}</td>
              </tr>
            )}
          </>
        )}

        {loadingUnloading && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Loading/Unloading</th>
            </tr>
            {loadingUnloading.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(loadingUnloading.manufacturerId)}
                </td>
              </tr>
            )}
            {loadingUnloading.type && (
              <tr>
                <th>Type</th>
                <td>{ELoadingUnloading[loadingUnloading.type]}</td>
              </tr>
            )}
          </>
        )}

        {well && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Well</th>
            </tr>
            {well.arrangement && (
              <tr>
                <th>Arrangement</th>
                <td>{EWellArrangement[well.arrangement]}</td>
              </tr>
            )}
            {well.arrangement && (
              <tr>
                <th>Departments amount</th>
                <td>{well.departmentsAmount}</td>
              </tr>
            )}
          </>
        )}

        {getRefrigerationBlock(refrigeration, getManufacturerFieldById)}

        {handling && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Handling</th>
            </tr>
            {handling.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(handling.manufacturerId)}</td>
              </tr>
            )}
            {handling.type && (
              <tr>
                <th>Type</th>
                <td>{EHandlingSystem[handling.type]}</td>
              </tr>
            )}
          </>
        )}

        {slaughter && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Slaughter and bleeding</th>
            </tr>
            {slaughter.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(slaughter.manufacturerId)}</td>
              </tr>
            )}
            {slaughter.type && (
              <tr>
                <th>Type</th>
                <td>{ESlaughter[slaughter.type]}</td>
              </tr>
            )}
          </>
        )}

        <Delousing
          delousing={delousing}
          getManufacturerFieldById={getManufacturerFieldById}
        />

        {AGDMethod && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>AGD method</th>
            </tr>
            <tr>
              <th>Method</th>
              <td>{EAGD[AGDMethod]}</td>
            </tr>
          </>
        )}

        {automation && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Automation</th>
            </tr>
            {automation.map(({ type }) => (
              <tr key={type}>
                <th>Type</th>
                <td>{EFarmAutomation[type]}</td>
              </tr>
            ))}
          </>
        )}

        {cageMaintenance && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Cage maintenance</th>
            </tr>
            {cageMaintenance.map(({ type }) => (
              <tr key={type}>
                <th>Type</th>
                <td>{ECageMaintenance[type]}</td>
              </tr>
            ))}
          </>
        )}

        {aquaOperations && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Aqua operations</th>
            </tr>
            {aquaOperations.map(({ type }) => (
              <tr key={type}>
                <th>Type</th>
                <td>{EAquaOperations[type]}</td>
              </tr>
            ))}
          </>
        )}

        {electronics && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Electronics</th>
            </tr>
            {electronics.map(({ type, manufacturerId }, index) => (
              <React.Fragment key={`electronics-${manufacturerId}-${type}`}>
                <tr>
                  <th>Provider</th>
                  <td>{getManufacturerFieldById(manufacturerId)}</td>
                </tr>
                <tr>
                  <th>Equipment</th>
                  <td>{EElectronics[type]}</td>
                </tr>

                {index + 1 < electronics.length && (
                  <tr key={`tab-${manufacturerId}-${type}`}>
                    <td colSpan={2} height={25} />
                  </tr>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};

Aquaculture.propTypes = {
  aquaculture: PropTypes.shape({
    capacity: PropTypes.object,
    loadingUnloading: PropTypes.object,
    well: PropTypes.object,
    refrigeration: PropTypes.object,
    handling: PropTypes.object,
    slaughter: PropTypes.object,
    delousing: PropTypes.object,
    AGDMethod: PropTypes.string,
    automation: PropTypes.arrayOf(PropTypes.object),
    aquaOperations: PropTypes.arrayOf(PropTypes.object),
    cageMaintenance: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default Aquaculture;
