import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ALL_VALUE,
  num2StrArr,
  removeAllOption,
  Select,
  strArr2Num,
  TLabelValue,
  TVesselType
} from 'react-fishfacts/dist';

import { setVesselFilterAction } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterActions';
import { selectVesselTypesOptions } from './vesselTypesFilterOptions';

import { EVesselsFilterActions } from '../../../../../../store/vessel/vesselsFilter/vesselsFilterConstants';
import { TState } from '../../../../../../store/appStateModel';

const ALL: TLabelValue = {
  label: 'All types',
  value: ALL_VALUE
};

type Props = {
  vesselTypes: TVesselType[];
  vesselTypeFilterValue: number[];
  selectVesselType: (options: number[]) => void;
};

function VesselTypesFilter({
  vesselTypes,
  vesselTypeFilterValue,
  selectVesselType
}: Props) {
  const options = [ALL, ...selectVesselTypesOptions(vesselTypes)];
  const value = num2StrArr(vesselTypeFilterValue);
  const onChange = (values) => {
    const arg = strArr2Num(removeAllOption(values)).filter(Boolean);
    selectVesselType(arg);
  };

  return <Select options={options} value={value} onChange={onChange} />;
}

const mapStateToProps = ({
  dictionaries: { vesselTypes },
  vesselsFilter: { filterVesselType: vesselTypeFilterValue }
}: TState) => ({
  vesselTypes,
  vesselTypeFilterValue
});

const mapDispatchToProps = (dispatch) => ({
  selectVesselType: (value) =>
    dispatch(
      setVesselFilterAction(EVesselsFilterActions.SET_VESSEL_TYPE, value)
    )
});

VesselTypesFilter.propTypes = {
  vesselTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  vesselTypeFilterValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectVesselType: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(VesselTypesFilter);
