import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { push } from 'connected-react-router';
import { RouteProps } from 'react-router-dom';

import {
  clearEditorOnLeave,
  createMediaObjectAction,
  editProviderAction,
  handleObjectAction,
  removeMediaObjectAction,
  submitProviderAction,
  updateHandledObjectAction,
  updateProviderObjectAction,
  updateProviderValuesAction,
  uploadFileAction
} from '../../../store/provider/providerEditor/providerEditorActions';
import deleteConfirm from '../../../components/common/modals/deleteConfirm';
import { editProductAction } from '../../../store/product/productEditor/productEditorActions';
import ProviderEditorWrapper from './ProviderEditorWrapper';
import { removeEventAction } from '../../../store/events/events/eventsActions';
import { ROUTES } from '../../../other/config';
import store from '../../../store/store';
import withAuth from '../../../components/withAuth';

import { EProviderMediaObjectType } from '../../../types/providers';
import {
  EScheduleItemStatus,
  TEvent,
  TScheduleItem
} from '../../../types/event';
import { TState } from '../../../store/appStateModel';

function getMediaType(type) {
  switch (type) {
    case EProviderMediaObjectType.ADDRESS:
      return 'address';
    case EProviderMediaObjectType.CONTACT:
      return 'contact';
    case EProviderMediaObjectType.PRODUCT:
      return 'product';
    case EProviderMediaObjectType.VIDEO:
      return 'video';
    default:
      throw new Error(`Unknown media object type: ${type}`);
  }
}

const confirmEventRemove = (event: TEvent) => {
  const bookedSlot: TScheduleItem = event.schedule.find(
    ({ status }: TScheduleItem) => status === EScheduleItemStatus.BOOKED
  );
  if (bookedSlot) {
    return notification.warning({
      message: 'Event cannot be removed',
      description:
        'This event cannot be removed since it has one or more booked time slots. If you want to remove it anyway, please contact us directly.',
      duration: 7
    });
  }
  deleteConfirm(`Remove event permanently?`, () =>
    store.dispatch(removeEventAction(event.id, true))
  );
};

const mediaRemoveConfirm = (type: EProviderMediaObjectType, objectId: number) =>
  deleteConfirm(`Remove ${getMediaType(type)} permanently?`, () =>
    store.dispatch(removeMediaObjectAction(type, objectId))
  );

const mapStateToProps = (
  state: TState,
  ownProps: {
    match: RouteProps;
  }
) => {
  const {
    dictionaries: { countries },
    events: { events },
    providerEditor: editorState,
    serviceBranches: { serviceCategories }
  } = state;
  const providerId: number = parseInt(ownProps.match.params.id);

  return {
    categories: serviceCategories,
    countries,
    editorState,
    // we want only our own events
    events: (events || []).filter(
      ({ source }: TEvent) => source.id === providerId
    ),
    providerId
  };
};

const boundActions = bindActionCreators(
  {
    getProvider: editProviderAction,
    onAddMediaObject: createMediaObjectAction,
    onFieldChange: updateProviderValuesAction,
    onHandledObjectUpdate: updateHandledObjectAction,
    onLeave: clearEditorOnLeave,
    onObjectChange: updateProviderObjectAction,
    onObjectHandle: handleObjectAction,
    onProductEdit: editProductAction,
    onSubmit: submitProviderAction,
    onUpload: uploadFileAction
  },
  store.dispatch
);

const mapDispatchToProps = (dispatch) => ({
  ...boundActions,
  onEventEdit: (eventId?: number) =>
    dispatch(push(`${ROUTES.EVENT_EDITOR}/${eventId || ''}`)),
  onEventRemove: confirmEventRemove,
  onRemove: mediaRemoveConfirm
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ProviderEditorWrapper)
);
