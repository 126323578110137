import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { IconCustom } from 'react-fishfacts/dist';

import './FleetsEditorItem.scss';
import EditableContent from '../inputs/EditableContent/EditableContent';
import { TFleetExt } from '../../../types/fleet';

type Props = {
  fleet: TFleetExt;
  onRemove: (fleetId: number) => void;
  onRename: (fleet: TFleetExt, newName: string) => void;
};
type State = {
  errorMessage: string;
  isEdited: boolean;
  isPromptVisible: boolean;
};

class FleetsEditorItem extends React.PureComponent<Props, State> {
  static propTypes;
  state: State = {
    errorMessage: null,
    isEdited: false,
    isPromptVisible: false
  };

  clearErrorMessage = () => this.setState({ errorMessage: null });

  handleChange = (name: string) => {
    const errorMessage =
      name.length > 30
        ? "Fleet name shouldn't be longer than 30 characters!"
        : null;
    this.setState({ errorMessage });
  };

  handleSave = (_name: string) => {
    if (this.state.errorMessage) return;

    const { fleet, onRename } = this.props;
    const name = _name.trim();

    if (name && name !== fleet.name) {
      onRename(fleet, name);
    }
  };

  handleRemove = () => this.props.onRemove(this.props.fleet.id);

  handleVisibleChange = (isPromptVisible) => this.setState({ isPromptVisible });

  showPrompt = () => this.setState({ isPromptVisible: true });

  hidePrompt = () => this.setState({ isPromptVisible: false });

  prompt: ReactNode = (
    <div className="FleetsEditorItem__popover" data-testid="fleet-prompt">
      <Button onClick={this.hidePrompt} size="small">
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={this.handleRemove}
        className="app-ml-1"
        size="small"
      >
        Remove
      </Button>
    </div>
  );

  render() {
    const { fleet } = this.props;
    const { errorMessage } = this.state;
    const title = `Sure removing '${fleet.name}' fleet?`;

    return (
      <li className="FleetsEditorItem">
        <EditableContent
          errorMessage={errorMessage}
          onCancel={this.clearErrorMessage}
          onChange={this.handleChange}
          onSave={this.handleSave}
          text={fleet.name}
          title="Edit fleet name"
        />

        <Popover
          content={this.prompt}
          title={title}
          trigger="click"
          visible={this.state.isPromptVisible}
          onVisibleChange={this.handleVisibleChange}
          placement="topRight"
        >
          <Button onClick={this.showPrompt} title="Remove fleet" type="link">
            <IconCustom type="delete" />
          </Button>
        </Popover>
      </li>
    );
  }
}

FleetsEditorItem.propTypes = {
  fleet: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired
};

export default FleetsEditorItem;
