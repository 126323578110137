import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoaderOverlay } from 'react-fishfacts/dist';
import { push } from 'connected-react-router';
import { RouteProps } from 'react-router-dom';

import {
  clearProviderAction,
  editProviderAction,
  fetchProviderAction
} from 'store/provider/provider/providerActions';
import { fetchEventsAction } from 'store/events/events/eventsActions';
import RecentArticles from 'components/common/articles/RecentArticles/RecentArticles';
import { ROUTES } from 'other/config';
import ServiceProvider from './ServiceProvider';
import { ServiceProviderProps } from './helpers';
import { toggleServiceSelectAction } from 'store/map/mapEntities/mapEntitiesActions';
import withAuth from 'components/withAuth';

import { TEvent } from 'types/event';
import { TProduct } from 'types/product';
import { TProviderAddressShort } from 'types/providers';
import { TState } from 'store/appStateModel';

type Props = ServiceProviderProps & {
  clearProvider: () => void;
  editProvider: (providerId: number) => void;
  fetchProvider: (providerId: number) => void;
  getEvents: () => void;
  getNews: (providerId: number) => void;
  isLoading: boolean;
  isLoggedIn: boolean;
  match: RouteProps;
  providerId?: number;
};

class ServicePage extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { fetchProvider, getEvents, providerId } = this.props;
    fetchProvider(providerId);
    getEvents();
  }

  componentDidUpdate(prevProps: Props) {
    const { fetchProvider, providerId } = this.props;
    if (prevProps.providerId !== providerId) {
      fetchProvider(providerId);
    }
  }
  componentWillUnmount() {
    this.props.clearProvider();
  }

  render() {
    const {
      isLoading,
      isLoggedIn,
      provider,
      providerId,
      ...restOfProps
    } = this.props;
    const props = { provider, isLoggedIn, ...restOfProps };
    const name = provider ? provider.name : '';

    return (
      <LoaderOverlay isLoading={isLoading}>
        <ServiceProvider {...props} />
        <RecentArticles providerId={providerId} name={name} />
      </LoaderOverlay>
    );
  }
}

ServicePage.propTypes = {
  clearProvider: PropTypes.func.isRequired,
  fetchProvider: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  provider: PropTypes.object,
  providerId: PropTypes.number
};

/**
 * Filters events by ID and restricts non-owner from seeing unpublished.
 */
function filterEvents(
  events: TEvent[],
  providerId: number,
  isOwner: boolean
): TEvent[] {
  if (!events) return [];

  return events.filter(
    ({ published, source }: TEvent) =>
      source.id === providerId && (isOwner || published)
  );
}

const mapStateToProps = (state: TState, ownProps: any) => {
  const {
    events,
    provider: { isPending, provider }
  } = state;

  const providerId: number = parseInt(ownProps.match.params.id);
  const providerIds = ownProps.userInfo.serviceProvidersId || [];
  const isOwner: boolean = provider && providerIds.includes(provider.id);

  // Non-owner shouldn't see product-drafts:
  if (provider && !isOwner) {
    provider.products = (provider.products || []).filter(
      ({ published }: TProduct) => published
    );
  }

  return {
    canCreate: ownProps.isProvider,
    events: filterEvents(events.events, providerId, isOwner),
    isLoading: isPending,
    isOwner,
    nearestEventId: provider && provider.eventIds && provider.eventIds[0],
    provider,
    providerId
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      clearProvider: clearProviderAction,
      editProvider: editProviderAction,
      fetchProvider: fetchProviderAction,
      getEvents: fetchEventsAction
    },
    dispatch
  ),
  onMarkerClick: (service: TProviderAddressShort) => {
    dispatch(toggleServiceSelectAction(service));
    dispatch(push(ROUTES.MAP));
  }
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ServicePage)
);
