import { basicInitialState } from '../../_utils/basicInitialState';
import { TNewsState } from './newsModel';

export const newsInitialState: TNewsState = {
  ...basicInitialState,
  article: null,
  news: null,
  pagination: {
    page: 0,
    size: 12,
    total: 0
  },
  scrollTop: 0
};
