import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './AccountLink.scss';
import { ROUTES } from '../../../other/config';
import { TState } from '../../../store/appStateModel';

type Props = {
  isLoggedIn: boolean;
  className?: string;
  activeClassName?: string;
  onClick?: () => void;
};

export const AccountLinkInner: FunctionComponent<Props> = ({
  className,
  isLoggedIn,
  activeClassName,
  onClick
}) => {
  const text = isLoggedIn ? 'My account' : 'Log in';
  const route: string = isLoggedIn ? ROUTES.USER : ROUTES.LOGIN;
  const linkClassName = classNames('AccountLink', className);

  return (
    <NavLink
      onClick={onClick}
      activeClassName={activeClassName}
      className={linkClassName}
      to={route}
      title={text}
    >
      {text}
    </NavLink>
  );
};

AccountLinkInner.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string
};

const mapStateToProps = ({ session: { user } }: TState) => ({
  isLoggedIn: !!(user && user.token)
});

export default connect(mapStateToProps, null)(AccountLinkInner);
