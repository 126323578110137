import { basicInitialState } from '../../_utils/basicInitialState';
import { ETrackPeriod } from '../../../types/trackPeriod';
import { TTracksState } from './tracksModel';

export const tracksInitialState: TTracksState = {
  ...basicInitialState,
  trackPeriod: ETrackPeriod.DAYS_3,
  trackPoints: [],
  tracks: []
};
