import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './UserDetails.scss';
import { ROUTES } from '../../../../../other/config';
import { TAppointee } from '../../../../../types/event';

type Props = {
  appointee?: TAppointee;
};

const UserDetails = ({ appointee }: Props): ReactElement => {
  if (!appointee) return null;
  const path = `${ROUTES.COMPANY}/${appointee.companyId}`;

  return (
    <div className="UserDetailsOwner__person">
      <table>
        <tbody>
          {(appointee.firstName || appointee.lastName) && (
            <tr>
              <th>Name:</th>
              <td>
                {appointee.firstName} {appointee.lastName}
              </td>
            </tr>
          )}

          {appointee.companyName && (
            <tr>
              <th>Company: </th>
              <td>
                <Link to={path}>
                  {appointee.companyName}
                </Link>
              </td>
            </tr>
          )}

          {appointee.email && (
            <tr>
              <th>Email:</th>
              <td>
                <a href={`mailto:${appointee.email}`}>
                  {appointee.email}
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

UserDetails.propTypes = {
  appointee: PropTypes.object,
};

export default UserDetails;
