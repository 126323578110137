import { okAction, THttpResponse, TVessel } from 'react-fishfacts/dist';

import { createAsyncActions } from '../_utils/createAsyncActions';
import { ENDPOINTS } from '../../other/config';
import { FleetUpdateUtil } from './helpers';
import http from '../../services/http';
import { setVesselFilterAction } from '../vessel/vesselsFilter/vesselsFilterActions';
import { updateSessionAction } from '../session/sessionActions';

import { EFleetsActions } from './fleetsConstants';
import { EVesselsFilterActions } from '../vessel/vesselsFilter/vesselsFilterConstants';
import { TFleet, TFleetExt, TFleetShort } from '../../types/fleet';
import { TFleetsState } from './fleetsModel';
import { TState } from '../appStateModel';

export let fetchSet,
  createSet,
  removeSet,
  addVesselSet,
  removeVesselSet,
  renameSet;

/**
 * Retrieves fleets.
 */
export function fetchFleetsAction() {
  return (dispatch, getState) => {
    const { fleets } = getState() as TState;
    if (fleets.fleets.length > 0) return;

    fetchSet = createAsyncActions<TFleetsState>(
      dispatch,
      EFleetsActions.FETCH_FLEET
    ).request();

    http
      .send(ENDPOINTS.FLEET)
      .then(({ data }: THttpResponse<TFleet[]>) => {
        fetchSet.success({ fleets: data });
        dispatch(assignVesselsToFleets());
      })
      .catch(fetchSet.error);
  };
}

/**
 * Assigns associated vessels to the fleets.
 */
export function assignVesselsToFleets() {
  return (dispatch, getState) => {
    pickVessels();

    function pickVessels() {
      const {
        fleets: { fleets },
        vessels: { vessels }
      } = getState() as TState;

      // make sure vessels have already been fetched, otherwise wait
      if (!vessels || !vessels.length) {
        return setTimeout(() => pickVessels(), 300);
      }

      const fleetMapper = (fleet: TFleet): TFleetExt => {
        const relatedVessels = vessels.filter(({ id }: TVessel) =>
          fleet.vesselIds.includes(id)
        );
        return {
          ...fleet,
          vessels: relatedVessels
        };
      };

      dispatch(
        okAction(EFleetsActions.ASSIGN_VESSELS, {
          // @ts-ignore
          fleets: fleets.map(fleetMapper)
        })
      );
    }
  };
}

/**
 * Creates a new fleet with given name.
 */
export function createFleetAction(name: string) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;
    createSet = createAsyncActions<TFleetsState>(
      dispatch,
      EFleetsActions.CREATE_FLEET
    ).request();

    http
      .send({
        body: { name: name },
        method: 'POST',
        url: ENDPOINTS.FLEET
      })
      .then(({ data: fleet }: THttpResponse<TFleetShort>) => {
        const newFleet: TFleetExt = {
          ...fleet,
          vesselIds: [],
          vessels: []
        };
        createSet.success({
          fleets: fleets.concat([newFleet])
        });
        // add the new fleet to the user's profile.
        dispatch(updateSessionAction());
      })
      .catch(createSet.error);
  };
}

/**
 * Removes a fleet by given ID.
 */
export function removeFleetAction(fleetId: number) {
  return (dispatch, getState) => {
    removeSet = createAsyncActions<TFleetsState>(
      dispatch,
      EFleetsActions.REMOVE_FLEET
    ).request();

    http
      .send({
        credentials: 'include',
        method: 'DELETE',
        url: `${ENDPOINTS.FLEET}/${fleetId}`
      })
      .then(() => {
        const {
          fleets: { fleets },
          vesselsFilter: { filterFleet }
        } = getState() as TState;

        removeSet.success({
          fleets: fleets.filter((f: TFleetExt) => f.id !== fleetId)
        });

        // delete the fleet from the user's profile.
        dispatch(updateSessionAction());

        // delete the fleet from the vesselFilter if it was there
        if (filterFleet.includes(fleetId)) {
          const fleetsIds: number[] = filterFleet.filter(
            (id: number) => id !== fleetId
          );
          dispatch(
            setVesselFilterAction(EVesselsFilterActions.SET_FLEET, fleetsIds)
          );
        }
      })
      .catch(removeSet.error);
  };
}

/**
 * Add/remove vessel to/from the fleet.
 */
export function toggleVesselAction(ids: number[], fleet: TFleetExt) {
  return (dispatch) => {
    const { vesselIds } = fleet;
    const action: Function = ids.every((id: number) => vesselIds.includes(id))
      ? removeVesselAction
      : addVesselAction;

    dispatch(action(ids, fleet));
  };
}

/**
 * Add a vessel to the fleet.
 */
export function addVesselAction(vesselIds: number[], fleet: TFleetExt) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets },
      vessels: { vessels }
    } = getState() as TState;

    const util = new FleetUpdateUtil(fleet, fleets as any, vesselIds, vessels);
    const params = util.getAddOperationRequestParams();

    addVesselSet = createAsyncActions<TFleetsState>(
      dispatch,
      EFleetsActions.ADD_VESSEL
    ).request();

    http
      .send(params)
      .then(() => addVesselSet.success(util.getAddOperationPayload()))
      .catch(addVesselSet.error);
  };
}

/**
 * Remove a vessel from the fleet.
 */
export function removeVesselAction(vesselIds: number[], fleet: TFleetExt) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;

    const util = new FleetUpdateUtil(fleet, fleets as any, vesselIds);
    const params = util.getRemoveOperationRequestParams();

    removeVesselSet = createAsyncActions<TFleetsState>(
      dispatch,
      EFleetsActions.REMOVE_VESSEL
    ).request();

    http
      .send(params)
      .then(() => removeVesselSet.success(util.getRemoveOperationPayload()))
      .catch(removeVesselSet.error);
  };
}

/**
 * Change a fleet name.
 */
export function renameFleetAction(fleet: TFleetExt, name: string) {
  return (dispatch, getState) => {
    const {
      fleets: { fleets }
    } = getState() as TState;

    const util = new FleetUpdateUtil(fleet, fleets as any);
    const params = util.getRenameOperationRequestParams(name);

    renameSet = createAsyncActions<TFleetsState>(
      dispatch,
      EFleetsActions.RENAME_FLEET
    ).request();

    http
      .send(params)
      .then(() => renameSet.success(util.getRenameOperationPayload(name)))
      .catch(renameSet.error);
  };
}
