import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  ALL_VALUE,
  areEqual,
  removeAllOption,
  typeLabelValue2StrArr
} from 'react-fishfacts/dist';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import './ProviderCountryFilter.scss';
import { filterProviderAction } from '../../store/serviceBranches/serviceBranchesActions';

import { EProvidersFilterActions } from '../../store/serviceBranches/serviceBranchesConstants';
import { TCountry } from '../../types/dictionaries';
import {
  TLabelValue,
  typeSearchFilter
} from '../../other/formAndValidation/formUtils';
import { TState } from '../../store/appStateModel';

type Props = {
  options: {
    countries: TCountry[];
  };
  setProviderFilter: (
    action: EProvidersFilterActions,
    values: string[]
  ) => void;
  values: {
    countryValues: string[];
  };
};
const ALL: TLabelValue = {
  label: 'All countries',
  value: ALL_VALUE
};

const ProviderCountryFilter = ({
  options: { countries },
  setProviderFilter,
  values: { countryValues }
}: Props): ReactElement => {
  const _countryValue: string | string[] =
    countryValues.length > 0 ? countryValues : [ALL_VALUE];

  const countryOptions = [ALL, ...countries] as TLabelValue[];

  const handleCountries = (values: string[]): void => {
    // Selected ALL or removed each or selected each
    if (
      !areEqual(_countryValue, [ALL_VALUE]) &&
      (values.includes(ALL_VALUE) ||
        values.length === 0 ||
        areEqual(typeLabelValue2StrArr(countries), values))
    ) {
      setProviderFilter(EProvidersFilterActions.FILTER_BY_COUNTRIES, []);
    } else {
      setProviderFilter(
        EProvidersFilterActions.FILTER_BY_COUNTRIES,
        removeAllOption(values)
      );
    }
  };

  return (
    <div className="ProviderCountryFilter">
      <Select
        className="ProviderCountryFilter__select"
        filterOption={typeSearchFilter}
        mode="tags"
        onChange={handleCountries}
        optionFilterProp="value"
        options={countryOptions}
        value={_countryValue}
      />
    </div>
  );
};

ProviderCountryFilter.propTypes = {
  options: PropTypes.shape({
    countries: PropTypes.array.isRequired
  }).isRequired,
  setProviderFilter: PropTypes.func.isRequired,
  values: PropTypes.shape({
    countryValues: PropTypes.array.isRequired
  }).isRequired
};

const mapStateToProps = (state: TState) => {
  const {
    serviceBranches: {
      providerFilterSettings: { providerFilterCountries }
    },
    dictionaries: { countries }
  } = state;

  return {
    options: { countries: countries },
    values: { countryValues: providerFilterCountries }
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setProviderFilter: filterProviderAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderCountryFilter);
