import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
// import { Button } from 'antd';

import './EventCard.scss';
import BrandLogo from '../../../components/common/logos/BrandLogo/BrandLogo';
import CommonBanner from '../../../components/common/banners/CommonBanner';
import EventDates from '../EventPage/EventDates';
import { MEDIA_BASE_URL, ROUTES } from '../../../other/config';
import { TEvent } from '../../../types/event';

type Props = {
  event: TEvent;
};

const EventCard = ({ event }: Props): ReactElement => {
  const {
    banner,
    end,
    id,
    published,
    shortDescription,
    source,
    start,
    title
  } = event;

  const bannerUrl = banner
    ? `${MEDIA_BASE_URL}/${banner.path}`
    : '/assets/placeholders/event_placeholder.svg';
  // const locationLabel = isVirtual
  //   ? 'Virtual location'
  //   : `Location: ${location}`;
  const path = `${ROUTES.EVENTS}/${id}`;
  const sourceUrl = source ? `${ROUTES.SERVICE_PROVIDER}/${source.id}` : null;

  const descCls: string = classnames('EventCard__description', {
    'EventCard__description--draft': !published
  });
  const titleCls: string = classnames('EventCard__title', {
    'EventCard__title--draft': !published
  });

  const bannerBlock = (
    <>
      <CommonBanner
        className="EventCard__banner"
        imgUrl={bannerUrl}
        isDraft={!published}
        path={path}
      />

      <BrandLogo logo={source.logo} url={sourceUrl} />
    </>
  );

  return (
    <article className="EventCard">
      <EventDates endDate={end} startDate={start} />

      <div className="EventCard__content">
        <div className="EventCard__main">
          {title && (
            <h3 className={titleCls}>
              <Link to={path}>{title}</Link>
            </h3>
          )}

          {/*{location && (*/}
          {/*  <div className="EventCard__location">{locationLabel}</div>*/}
          {/*)}*/}

          <div className="EventCard__banner--md">{bannerBlock}</div>

          {shortDescription && (
            <p className={descCls}>
              <Link to={path}>{shortDescription}</Link>
            </p>
          )}

          {/*<Button icon="check" shape="round" size="large" className="app-btn-min-w-170">*/}
          {/*  Add to calendar*/}
          {/*</Button>*/}
        </div>

        <div className="EventCard__banner--lg">{bannerBlock}</div>
      </div>
    </article>
  );
};

EventCard.propTypes = {
  event: PropTypes.object.isRequired
};

export default EventCard;
