import {
  errorAction,
  okAction,
  TAction,
  THttpResponse
} from 'react-fishfacts/dist';

import { buildQueryString } from '../_utils/helpers';
import { createAsyncActions } from '../_utils/createAsyncActions';
import { ENDPOINTS, ROUTES, SUB_POINTS } from '../../other/config';
import http from '../../services/http';
import { pluckFromFields } from '../../other/formAndValidation/formUtils';
import { promoInitialState } from './promoInitialState';

import { EPromoActions } from './promoConstants';
import {
  EPromotedType,
  TPaymentReport,
  TPromoted,
  TPromotion,
  TPromotionCost
} from '../../types/promotion';
import { TFormFields } from '../../types/formFields';
import { TPromoState } from './promoModel';
import { TState } from '../appStateModel';

export let fetchSet, submitSet, fetchReportSet;

/**
 * Fetch promoted articles
 */
export function fetchPromotedAction() {
  return (dispatch, getState) => {
    const {
      promo: { isPending, articles }
    } = getState() as TState;

    if (isPending || articles.length > 0) return;

    fetchSet = createAsyncActions<TPromoState>(
      dispatch,
      EPromoActions.FETCH_ARTICLES
    ).request();

    http
      .send(`${ENDPOINTS.PROMOTION}?type=NEWS`)
      .then(({ data }: THttpResponse<TPromoted[]>) =>
        fetchSet.success({ articles: data })
      )
      .catch(fetchSet.error);
  };
}

export function calculateCostAction(fields: TFormFields, articleId: number) {
  return (dispatch, getState) => {
    const { promo } = getState() as TState;
    if (promo.isCalculating) return;

    const body = createPromoBody(fields, articleId);
    const url = `${ENDPOINTS.PROMOTION_COST}?${buildQueryString(body)}`;

    dispatch(updateFieldsAction(fields));
    dispatch(
      okAction(EPromoActions.CALCULATE_COST_REQUEST, { isCalculating: true })
    );

    http
      .send(url)
      .then(({ data }: THttpResponse<TPromotionCost>) =>
        dispatch(
          okAction(EPromoActions.CALCULATE_COST_SUCCESS, {
            cost: data,
            isCalculating: false
          })
        )
      )
      .catch((e: Error) =>
        dispatch(
          errorAction(EPromoActions.CALCULATE_COST_FAIL, e, {
            isCalculating: false
          })
        )
      );
  };
}

export function submitPromotionAction(articleId: number) {
  return (dispatch, getState) => {
    const {
      promo: { fields }
    } = getState() as TState;

    submitSet = createAsyncActions<TPromoState>(
      dispatch,
      EPromoActions.SUBMIT_PROMOTION
    ).request();

    http
      .send({
        body: createPromoBody(fields, articleId),
        method: 'POST',
        url: ENDPOINTS.PROMOTION
      })
      .then(({ data }: THttpResponse<TPromoted>) => {
        submitSet.success();
        postPayment(data);
        dispatch(clearPromotionAction());
      })
      .catch(submitSet.error);
  };
}

function createPromoBody(
  fields: TFormFields,
  articleId: number
): Partial<TPromotion> {
  const values = pluckFromFields(fields);
  return {
    ...values,
    end: values.end ? values.end.utc().format() : void 0,
    newsId: articleId,
    start: values.start ? values.start.utc().format() : void 0,
    type: EPromotedType.NEWS
  };
}

export function fetchPaymentReportAction(promoId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.PROMOTION}/${promoId}${SUB_POINTS.PAYMENTS}`;
    fetchReportSet = createAsyncActions<TPromoState>(
      dispatch,
      EPromoActions.FETCH_PAYMENT_REPORT
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TPaymentReport[]>) =>
        fetchReportSet.success({
          report: data[0] || null
        })
      )
      .catch(fetchReportSet.error);
  };
}

export function postPayment(promotion: TPromoted): void {
  const url = `${ENDPOINTS.PROMOTION}/${promotion.id}${SUB_POINTS.PAYMENTS}`;
  const body = {
    redirectUrl: `${window.location.origin}${ROUTES.NEWS_EDITOR}/${promotion.news.id}`
  };

  http
    .send({
      body: body,
      method: 'POST',
      url: url
    })
    .then(({ data }: THttpResponse<TPaymentReport>) => {
      window.location.href = decodeURIComponent(data.checkoutLink);
    })
    .catch(window.console.error);
}

export const clearPromotionAction = (): TAction<
  TPromoState,
  EPromoActions
> => ({
  type: EPromoActions.RESET,
  payload: promoInitialState
});

export const updateFieldsAction = (
  promo: TFormFields
): TAction<TPromoState, EPromoActions> => ({
  type: EPromoActions.UPDATE_PROMOTION,
  payload: { fields: promo }
});
