import moment from 'moment';
import { TLabelValue } from 'react-fishfacts/dist';

import {
  EPaymentStatus,
  TPaymentReport,
  TShortPaymentReport
} from '../../../../types/promotion';
import { TTreeSelectOption } from '../../../../other/formAndValidation/formUtils';

export const NONE_VALUE = '-1';

export function getServiceIds(categories: TTreeSelectOption[]): string[] {
  return categories
    .map(extractServiceId)
    .flat(2)
    .filter((id: string) => id !== NONE_VALUE)
    .sort();
}

function extractServiceId({
  children,
  value
}: TTreeSelectOption): string[] | string[][] {
  if (children) {
    // @ts-ignore
    return [value, ...children.map(extractServiceId)];
  }
  return [value];
}

export function getTypeIds(vesselTypes: TLabelValue[]): string[] {
  return vesselTypes
    .map(({ value }: TLabelValue): string => value)
    .filter((id: string) => id !== NONE_VALUE)
    .sort();
}

export const statusMap: Record<EPaymentStatus, Partial<TShortPaymentReport>> = {
  CREATED: {
    method: 'info',
    message: 'Your payment has been created.',
    status: EPaymentStatus.CREATED
  },
  PENDING: {
    method: 'info',
    message: 'Your payment is still pending.',
    status: EPaymentStatus.PENDING
  },
  CANCELLED: {
    method: 'warning',
    message: 'Your payment has been cancelled.',
    status: EPaymentStatus.CANCELLED
  },
  SUCCESS: {
    method: 'success',
    message: 'Your payment has been successfully processed.',
    status: EPaymentStatus.SUCCESS
  },
  ERROR: {
    method: 'error',
    message: 'Error occurred while processing your payment!',
    status: EPaymentStatus.ERROR
  },
  EXPIRED: {
    method: 'warning',
    message: 'Your payment info has expired.',
    status: EPaymentStatus.EXPIRED
  }
};

export function checkPromoPaymentReport(
  report: TPaymentReport,
  promotionId?: number
): TShortPaymentReport {
  if (!report) return null;
  if (promotionId && promotionId !== report.promotionId) return null;

  // Report shouldn't be of more that 2 minutes ago.
  const isOutdated = moment(report.creationDate)
    .add(2, 'minutes')
    .add(moment().utcOffset() + 2, 'minutes')
    .isBefore(moment());

  return {
    ...statusMap[report.status],
    isOutdated: isOutdated,
    promotionId: report.promotionId
  } as any;
}
