import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { ExpanderHeader, IconCustom } from 'react-fishfacts/dist';
import Scrollbar from 'react-perfect-scrollbar';

import './Expander.scss';
import { isMobile } from '../../helpers/helpers';

type Props = {
  buttonText?: string;
  children: ReactElement | ReactElement[];
  isExpanded?: boolean;
  itemWidth: number;
  onAdd?: () => void;
  title: string;
};

const scrollerProps = {
  className: 'Expander__scroll',
  options: {
    suppressScrollY: true,
    swipeEasing: true
  }
};

const Expander = ({
  buttonText,
  children,
  itemWidth,
  onAdd,
  title
}: Props): ReactElement => {
  const header = (
    <ExpanderHeader buttonText={buttonText} onAdd={onAdd} title={title} />
  );
  const expandIcon = () => <IconCustom type="chevron-up" />;

  const Container = ({ children }): ReactElement =>
    isMobile() ? (
      <div className="Expander__container">
        {header}
        {children}
      </div>
    ) : (
      <Collapse
        accordion={true}
        bordered={false}
        className="Expander__collapse"
        defaultActiveKey="1"
        expandIcon={expandIcon}
      >
        <Collapse.Panel header={header} key="1">
          {children}
        </Collapse.Panel>
      </Collapse>
    );

  const contentStyle = Array.isArray(children)
    ? { width: `${itemWidth * children.length}px` }
    : { margin: 'auto', width: itemWidth, justifyContent: 'center' };

  return (
    <section className="Expander">
      <Container>
        <Scrollbar {...scrollerProps}>
          <div className="app-d-flex Expander__content" style={contentStyle}>
            {children}
          </div>
        </Scrollbar>
      </Container>
    </section>
  );
};

Expander.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  isExpanded: PropTypes.bool,
  itemWidth: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired
};

export default Expander;
