import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';

import {
  clearEditorAction,
  fetchProductAndEditAction,
  submitProductAction,
  updateFormFieldsAction,
  uploadProductBannerAction
} from '../../../store/product/productEditor/productEditorActions';
import { ObjectChecker } from '../../../store/provider/providerEditor/helpers/ObjectChecker';
import ProductEditorWrapper from './ProductEditorWrapper';
import { removeProduct } from '../../../store/product/product/productActions';
import { WYSIWYG_EMPTY_STRING } from '../../../other/config';

import { TFormFields } from '../../../types/formFields';
import { TProductEditorState } from '../../../store/product/productEditor/productEditor';
import { TState } from '../../../store/appStateModel';

function isProductEmpty(productEditor: TProductEditorState): boolean {
  const {
    banner,
    fields,
    longDescription,
    shortDescription,
    title
  } = productEditor;
  if (!fields) return false;

  const {
    longDescription: _longDescription,
    shortDescription: _shortDescription,
    title: _title
  }: TFormFields = fields;

  if (!_longDescription || !_shortDescription || !_title) return false;
  return (
    !banner &&
    !longDescription &&
    !shortDescription &&
    !title &&
    !_title.value &&
    !_shortDescription.value &&
    (!_longDescription.value || _longDescription.value === WYSIWYG_EMPTY_STRING)
  );
}

function isProductModified(productEditor: TProductEditorState): boolean {
  const { fields, longDescription, shortDescription, title } = productEditor;
  if (!fields) return false;

  const {
    longDescription: _longDescription,
    shortDescription: _shortDescription,
    title: _title
  }: TFormFields = fields;

  if (!_longDescription || !_shortDescription || !_title) return false;

  return (
    longDescription !== _longDescription.value ||
    shortDescription !== _shortDescription.value ||
    title !== _title.value
  );
}

function isBlueprint(productEditor: TProductEditorState): boolean {
  const { banner, longDescription, shortDescription, title } = productEditor;
  const isEmpty: Function = ObjectChecker.isEmptyOrFalsy;

  return (
    isEmpty(banner) &&
    isEmpty(longDescription) &&
    isEmpty(shortDescription) &&
    isEmpty(title)
  );
}

const mapStateToProps = (state: TState, ownProps) => {
  const { productEditor } = state;
  const { providerId, productId } = ownProps.match.params;

  return {
    ...productEditor,
    isBlueprint: isBlueprint(productEditor),
    isEmpty: isProductEmpty(productEditor),
    isModified: isProductModified(productEditor),
    productId: productId,
    providerId: providerId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        editProduct: fetchProductAndEditAction,
        onChange: updateFormFieldsAction,
        onLeave: clearEditorAction,
        onRemove: removeProduct,
        onSubmit: submitProductAction,
        onUpload: uploadProductBannerAction
      },
      dispatch
    ),
    onCancel: () => dispatch(goBack())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductEditorWrapper);
