import cssVars from 'css-vars-ponyfill';

// css variables
import darkCssTheme from '../../theme/cssVariables/dark.json';
import defaultCssTheme from '../../theme/cssVariables/default.json';
// antd variables
import darkTheme from '../../theme/antdVariables/dark.json';
import defaultTheme from '../../theme/antdVariables/default.json';

import {
  IS_DARK_THEME,
  readSettings,
  writeSettings
} from '../../services/settings';
import { themeInitialState } from './themeInitialState';
import { EThemeActions } from './themeConstants';

export const initializeThemeAction = () => {
  return (dispatch) => {
    const { isDark: isDarkDefault } = themeInitialState;
    const isDarkTheme = readSettings()[IS_DARK_THEME];
    const isDark = isDarkTheme === void 0 ? isDarkDefault : isDarkTheme;

    writeSettings({ [IS_DARK_THEME]: isDark });

    // toggling antd theme is quite expensive
    if (isDarkDefault !== isDark) {
      setTimeout(() => {
        toggleTheme(isDark);
      });
    } else {
      // css variables should be applied
      toggleCssVariables(isDark);
    }

    dispatch({
      type: EThemeActions.INIT_THEME,
      payload: { isDark }
    });
  };
};

export const setTheme = (isDark: boolean) => {
  return (dispatch) => {
    writeSettings({ [IS_DARK_THEME]: isDark });
    toggleTheme(isDark);
    dispatch({
      type: EThemeActions.SET_THEME,
      payload: { isDark }
    });
  };
};

const toggleTheme = (isDark: boolean): void => {
  toggleCssVariables(isDark);
  const lessVars = isDark ? darkTheme : defaultTheme;
  // @ts-ignore
  window.less.modifyVars(lessVars);
};

const toggleCssVariables = (isDark: boolean): void => {
  const themeData = isDark ? darkCssTheme : defaultCssTheme;
  cssVars({ variables: themeData });
};
