import { THttpRequestOptions } from 'react-fishfacts/dist';
import { ProviderUtil } from './ProviderUtil';
import { EProviderMediaObjectType } from '../../../../types/providers';
import { TProviderEditorState } from '../providerEditor';

/**
 * A parent class for the provider media helpers.
 */
export abstract class ProviderMediaHandlingUtil extends ProviderUtil {
  protected readonly id: number;
  protected readonly objectId: number;

  constructor(
    type: EProviderMediaObjectType,
    model: TProviderEditorState,
    objectId?: number
  ) {
    super(type, model);
    this.id = model.id;
    this.objectId = objectId;
  }

  /**
   * Returns endpoint URL and other request parameters according to given media object type.
   */
  abstract getRequestParams(): THttpRequestOptions;

  /**
   * Returns endpoint suffix according to given media object type.
   */
  protected getSuffix(): string {
    switch (this.type) {
      case EProviderMediaObjectType.ADDRESS:
        return 'addresses';
      case EProviderMediaObjectType.CONTACT:
        return 'contact';
      case EProviderMediaObjectType.PRODUCT:
        return 'product';
      case EProviderMediaObjectType.VIDEO:
        return 'video';
      default:
        throw new Error('ProviderMediaHandlingUtil: Bad object type');
    }
  }

  /**
   * Returns partial model state update to be used as an action payload.
   * @param data
   */
  abstract getPayload(data: any): Partial<TProviderEditorState>;
}
