import React from 'react';
import PropTypes from 'prop-types';

import {
  EManeuveringThruster,
  EPitch,
  EPowerDrive,
  EPropulsionThruster,
  TPropulsionAndManeuvering
} from 'react-fishfacts/dist';

type Props = {
  getEngineFieldById: (number) => string | JSX.Element;
  getManufacturerFieldById: (number) => string | JSX.Element;
  propulsionAndManeuvering: TPropulsionAndManeuvering;
};

const PropulsionAndManeuvering = ({
  propulsionAndManeuvering,
  getEngineFieldById,
  getManufacturerFieldById
}: Props) => {
  const {
    mainPowerDriveMethod,
    mainEngines,
    auxiliaryEngines,
    propellers,
    steeringGear,
    reductionGear,
    propulsionThrusters,
    maneuveringThrusters,
    dynamicPositioningAvailable
  } = propulsionAndManeuvering;

  return (
    <table className="ExtendedFields__table">
      <tbody>
        {mainPowerDriveMethod && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Propulsion method</th>
            </tr>
            <tr>
              <th>Provider</th>
              <td>{EPowerDrive[mainPowerDriveMethod]}</td>
            </tr>
          </>
        )}

        {mainEngines && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Main engines</th>
            </tr>
            {mainEngines.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getEngineFieldById(mainEngines.manufacturerId)}</td>
              </tr>
            )}
            {mainEngines.details && (
              <tr>
                <th>Details</th>
                <td>{mainEngines.details}</td>
              </tr>
            )}
            {mainEngines.power && (
              <tr>
                <th>Power</th>
                <td>{mainEngines.power} kW</td>
              </tr>
            )}
            {mainEngines.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{mainEngines.quantity}</td>
              </tr>
            )}
          </>
        )}

        {auxiliaryEngines && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Auxiliary engines</th>
            </tr>
            {auxiliaryEngines.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(auxiliaryEngines.manufacturerId)}
                </td>
              </tr>
            )}
            {auxiliaryEngines.details && (
              <tr>
                <th>Details</th>
                <td>{auxiliaryEngines.details}</td>
              </tr>
            )}
            {auxiliaryEngines.power && (
              <tr>
                <th>Power</th>
                <td>{auxiliaryEngines.power} kW</td>
              </tr>
            )}
            {auxiliaryEngines.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{auxiliaryEngines.quantity}</td>
              </tr>
            )}
          </>
        )}

        {propellers && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Propellers</th>
            </tr>
            {propellers.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(propellers.manufacturerId)}</td>
              </tr>
            )}
            {propellers.pitch && (
              <tr>
                <th>Pitch</th>
                <td>{EPitch[propellers.pitch]}</td>
              </tr>
            )}
            {propellers.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{propellers.quantity}</td>
              </tr>
            )}
            {propellers.details && (
              <tr>
                <th>Details</th>
                <td>{propellers.details}</td>
              </tr>
            )}
          </>
        )}

        {steeringGear && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Steering gear</th>
            </tr>
            {steeringGear.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(steeringGear.manufacturerId)}</td>
              </tr>
            )}
            {steeringGear.details && (
              <tr>
                <th>Details</th>
                <td>{steeringGear.details}</td>
              </tr>
            )}
          </>
        )}

        {reductionGear && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Reduction gear</th>
            </tr>
            {reductionGear.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(reductionGear.manufacturerId)}
                </td>
              </tr>
            )}
            {reductionGear.type && (
              <tr>
                <th>Type</th>
                <td>{reductionGear.type}</td>
              </tr>
            )}
            {reductionGear.details && (
              <tr>
                <th>Details</th>
                <td>{reductionGear.details}</td>
              </tr>
            )}
          </>
        )}

        {propulsionThrusters && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Propulsion thrusters</th>
            </tr>
            {propulsionThrusters.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(propulsionThrusters.manufacturerId)}
                </td>
              </tr>
            )}
            {propulsionThrusters.type && (
              <tr>
                <th>Type</th>
                <td>{EPropulsionThruster[propulsionThrusters.type]}</td>
              </tr>
            )}
            {propulsionThrusters.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{propulsionThrusters.quantity}</td>
              </tr>
            )}
            {propulsionThrusters.details && (
              <tr>
                <th>Details</th>
                <td>{propulsionThrusters.details}</td>
              </tr>
            )}
          </>
        )}

        {maneuveringThrusters && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Maneuvering thrusters</th>
            </tr>
            {maneuveringThrusters.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(
                    maneuveringThrusters.manufacturerId
                  )}
                </td>
              </tr>
            )}
            {maneuveringThrusters.type && (
              <tr>
                <th>Type</th>
                <td>{EManeuveringThruster[maneuveringThrusters.type]}</td>
              </tr>
            )}
            {maneuveringThrusters.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{maneuveringThrusters.quantity}</td>
              </tr>
            )}
            {maneuveringThrusters.details && (
              <tr>
                <th>Details</th>
                <td>{maneuveringThrusters.details}</td>
              </tr>
            )}
          </>
        )}

        {dynamicPositioningAvailable && (
          <tr className="ExtendedFields__paragraph">
            <th colSpan={2}>Dynamic positioning available</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

PropulsionAndManeuvering.propTypes = {
  propulsionAndManeuvering: PropTypes.shape({
    mainPowerDriveMethod: PropTypes.object,
    mainEngines: PropTypes.object,
    auxiliaryEngines: PropTypes.object,
    propellers: PropTypes.object,
    steeringGear: PropTypes.object,
    reductionGear: PropTypes.object,
    propulsionThrusters: PropTypes.object,
    maneuveringThrusters: PropTypes.object,
    dynamicPositioningAvailable: PropTypes.bool
  }).isRequired,
  getEngineFieldById: PropTypes.func.isRequired,
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default PropulsionAndManeuvering;
