import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './EventTitle.scss';
import EventDates from './EventDates';
import { TEvent } from '../../../types/event';

type Props = {
  event: TEvent;
};

const EventTitle = ({ event }: Props): ReactElement => {
  const { end, start, title } = event;

  return (
    <header className="EventTitle">
      <EventDates endDate={end} startDate={start} />
      <div>
        <h3 className="EventTitle__title">{title}</h3>
      </div>
    </header>
  );
};

EventTitle.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  time: PropTypes.string,
  title: PropTypes.string
};

export default EventTitle;
