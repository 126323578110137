import { EProviderMediaObjectType, TProvider } from '../../../types/providers';

export enum EProviderEditorActions {
  FETCH_PROVIDER = 'providerEditor/FETCH_PROVIDER',
  CREATE_PROVIDER = 'providerEditor/CREATE_PROVIDER',
  SUBMIT_PROVIDER = 'providerEditor/SUBMIT_PROVIDER',

  UPLOAD_FILE = 'providerEditor/UPLOAD_FILE',
  FILE_UPLOAD_PROGRESS = 'providerEditor/FILE_UPLOAD_PROGRESS',

  CREATE_MEDIA = 'providerEditor/CREATE_MEDIA',
  REMOVE_MEDIA = 'providerEditor/REMOVE_MEDIA',

  UPDATE_HANDLED_OBJECT = 'providerEditor/UPDATE_HANDLED_OBJECT',
  HANDLE_OBJECT = 'providerEditor/HANDLE_OBJECT',

  ASSIGN_FORM_FIELDS = 'providerEditor/ASSIGN_FORM_FIELDS',
  CLEAR_EDITOR = 'providerEditor/CLEAR_EDITOR',
  UPDATE_VALUES = 'providerEditor/UPDATE_FIELD'
}

export const providerBlueprint: Partial<TProvider> = {
  addresses: [],
  branches: [],
  contacts: [],
  longDescription: '',
  name: '',
  products: [],
  published: false,
  shortDescription: '',
  website: ''
};

export const mediaBluePrintMap = {
  [EProviderMediaObjectType.ADDRESS]: {
    address: '',
    city: '',
    country: '',
    latitude: null,
    longitude: null,
    postcode: '',
    title: ''
  },
  [EProviderMediaObjectType.CONTACT]: {
    email: '',
    firstName: '',
    lastName: '',
    position: '',
    telephone: ''
  },
  [EProviderMediaObjectType.VIDEO]: {
    title: '',
    type: 'VIDEO'
  }
};
