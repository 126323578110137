import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './ArticleEditorBanner.scss';
import ArticleLogo from '../../logos/ArticleLogo/ArticleLogo';
import ImageInput2 from '../../inputs/ImageInput2/ImageInput2';
import ProgressBar2 from '../../ProgressBar/ProgressBar2';
import { TArticleSource } from '../../../../types/article';

type Props = {
  imgUrl: string;
  onUpload: (file: File) => void;
  source: TArticleSource;
  showUploadList?: boolean;
  uploadProgress?: number;
};

const ArticleEditorBanner = ({
  imgUrl,
  onUpload,
  showUploadList,
  source,
  uploadProgress
}: Props): ReactElement => (
  <div className="ArticleEditorBanner">
    <ImageInput2
      imgUrl={imgUrl}
      onUpload={onUpload}
      showUploadList={showUploadList}
    >
      <ProgressBar2 progress={uploadProgress} />
    </ImageInput2>
    <ArticleLogo source={source} />
  </div>
);

ArticleEditorBanner.propTypes = {
  imgUrl: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  showUploadList: PropTypes.bool,
  source: PropTypes.object,
  uploadProgress: PropTypes.number
};

export default ArticleEditorBanner;
