import React, { CSSProperties, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './CommonBanner.scss';
import DraftOverlay from '../overlays/DraftOverlay/DraftOverlay';

type Props = {
  className?: string;
  imgUrl?: string;
  isDraft?: boolean;
  path?: string;
};

const CommonBanner: React.FC<Props> = ({
  className,
  imgUrl,
  isDraft,
  path
}): ReactElement => {
  const cls: string = classnames(
    'app-fixed-ratio-container CommonBanner',
    className
  );
  const figureCls: string = classnames(
    'app-fixed-ratio-content CommonBanner__figure',
    {
      'CommonBanner__figure--draft': isDraft
    }
  );
  const _imgUrl: string =
    imgUrl || '/assets/placeholders/banner_placeholder.svg';

  const figureStyle: CSSProperties = {
    backgroundImage: 'url(' + encodeURI(_imgUrl) + ')'
  };

  const Wrapper: Function = (children: ReactElement): ReactElement =>
    path ? (
      <Link className={cls} children={children} to={path} />
    ) : (
      <div className={cls} children={children} />
    );

  return Wrapper(
    <>
      <figure className={figureCls} style={figureStyle} />
      <DraftOverlay isShown={isDraft} />
    </>
  );
};

CommonBanner.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string,
  isDraft: PropTypes.bool,
  path: PropTypes.string
};

export default CommonBanner;
