export enum ENewsActions {
  FETCH_NEWS = 'news/FETCH_NEWS',
  FETCH_ARTICLE = 'news/FETCH_ARTICLE',
  REMOVE_ARTICLE = 'news/REMOVE_ARTICLE',

  NEWS_BODY_COMPOSED = 'news/NEWS_BODY_COMPOSED',
  CLEAR_NEWS = 'news/CLEAR_NEWS',
  CLEAR_NEWS_ARTICLE = 'news/CLEAR_NEWS_ARTICLE',
  SAVE_SCROLL_TOP = 'news/SAVE_SCROLL_TOP'
}
