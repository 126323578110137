import { TProduct } from '../../../types/product';

export enum EProductEditorActions {
  FETCH_PRODUCT = 'productEditor/FETCH_PRODUCT',
  CREATE_PRODUCT = 'productEditor/CREATE_PRODUCT',
  FETCH_PRODUCT_BODY = 'productEditor/FETCH_PRODUCT_BODY',
  SUBMIT_PRODUCT = 'productEditor/SUBMIT_PRODUCT',
  BANNER_UPLOAD = 'productEditor/BANNER_UPLOAD',
  BANNER_UPLOAD_PROGRESS = 'productEditor/BANNER_UPLOAD_PROGRESS',
  UPDATE_FIELD = 'productEditor/UPDATE_FIELD',
  CLEAR_EDITOR = 'productEditor/CLEAR_EDITOR'
}

export const productBlueprint: Partial<TProduct> = {
  longDescription: null,
  published: true,
  title: ''
};
