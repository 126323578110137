import {
  createAsyncActions,
  Uploader,
  TAction,
  THttpResponse
} from 'react-fishfacts/dist';
import { goBack, push } from 'connected-react-router';

import { API_URL, ENDPOINTS, ROUTES } from '../../../other/config';
import {
  bf2,
  pretendUTC,
  stringDate2moment
} from '../../../other/formAndValidation/formUtils';
import { eventEditorInitialState } from './eventEditorInitialState';
import { getToken } from '../../../services/auth';
import http from '../../../services/http';
import { resetEventsAction } from '../events/eventsActions';
import { retrieveLongMediaDescription } from '../../_utils/longMediaDescriptionUtils';
import { updateSessionAction } from '../../session/sessionActions';

import { EEventEditorActions, eventBlueprint } from './eventEditorConstants';
import { TEvent, TScheduleItem } from '../../../types/event';
import { TEventEditorState } from './eventEditor';
import { TFormFields } from '../../../types/formFields';
import { TState } from '../../appStateModel';

export let createSet;
export let fetchSet;
export let fetchBodySet;
export let submitSet;
let uploadSet;

/**
 * Simply redirects to the event editor page.
 * @param {number} id
 */
export function gotoEventEditor(id: number) {
  return (dispatch) => dispatch(push(`${ROUTES.EVENT_EDITOR}/${id}`));
}

/**
 * Dispatches actions depending on event-under-change existence.
 */
export function editEventAction(eventId?: number) {
  return (dispatch, getState) => {
    const {
      session: { user }
    }: TState = getState();
    // Fast escape on create mode.
    if (!eventId) return dispatch(createEventBlueprintAction());

    // if user attempts to edit another's event -- sends him home
    const idArr: ReadonlyArray<number> =
      user && user.userInfo.eventsId ? user.userInfo.eventsId : [];
    if (!idArr.includes(eventId)) return dispatch(push(ROUTES.EVENTS));

    dispatch(fetchEventAction(eventId));
  };
}

/**
 * Posts a dump event, so as to associate any media with the event ID.
 */
export function createEventBlueprintAction() {
  return (dispatch) => {
    dispatch(clearEditorAction());
    createSet = createAsyncActions<TEventEditorState>(
      dispatch,
      EEventEditorActions.CREATE_EVENT_BLUEPRINT
    ).request();

    http
      .send({
        body: eventBlueprint,
        method: 'POST',
        url: ENDPOINTS.EVENT
      })
      .then(({ data }: THttpResponse<TEvent>) => {
        createSet.success({
          ...data,
          utcOffset: '+01:00'
        });
        dispatch(assignFormFieldsAction());
        // update session so as to sync user's events
        dispatch(updateSessionAction());
      })
      .catch(createSet.error);
  };
}

/**
 * Retrieves event by ID.
 */
export function fetchEventAction(id: number) {
  return (dispatch) => {
    fetchSet = createAsyncActions<TEventEditorState>(
      dispatch,
      EEventEditorActions.FETCH_EVENT
    ).request();

    http
      .send(`${ENDPOINTS.EVENT}/${id}`)
      .then(({ data }: THttpResponse<TEvent>) => {
        fetchSet.success({
          ...data,
          isPending: true
        });
        dispatch(fetchEventBodyAction(data.longDescriptionMedia.path));
      })
      .catch(fetchSet.error);
  };
}

/**
 * Helper action; fetches event body (longDescription part).
 */
export function fetchEventBodyAction(path: string) {
  return (dispatch) => {
    fetchBodySet = createAsyncActions<TEventEditorState>(
      dispatch,
      EEventEditorActions.FETCH_EVENT_BODY
    ).request();

    retrieveLongMediaDescription(path)
      .then((longDescription: string) => {
        fetchBodySet.success({ longDescription });
        dispatch(assignFormFieldsAction());
      })
      .catch(fetchBodySet.error);
  };
}

/**
 * Assigns values to be used as form fields in the event editor.
 */
export function assignFormFieldsAction() {
  return (dispatch, getState) => {
    const {
      eventEditor: {
        end,
        longDescription,
        shortDescription,
        start,
        title,
        utcOffset
      }
    }: TState = getState();

    const fields = bf2([
      { longDescription: longDescription },
      { shortDescription: shortDescription },
      { title: title },
      { utcOffset: utcOffset || '+01:00' },
      {
        dates: [stringDate2moment(start) as any, stringDate2moment(end) as any]
      }
    ]);

    dispatch({
      type: EEventEditorActions.ASSIGN_FORM_FIELDS,
      payload: { fields }
    });
  };
}

export const updateFormFieldsAction = (
  fields: TFormFields
): TAction<TEventEditorState, EEventEditorActions> => ({
  type: EEventEditorActions.UPDATE_FIELD,
  payload: {
    fields: fields
  }
});

/**
 * Clears up the event editor.
 */
export const clearEditorAction = (): TAction<
  TEventEditorState,
  EEventEditorActions
> => ({
  type: EEventEditorActions.CLEAR_EDITOR,
  payload: eventEditorInitialState
});

/**
 * Submits changes to an event. Purges state and redirects to the event static page on success.
 */
export function submitEventAction(isDraft: boolean) {
  return (dispatch, getState) => {
    const {
      eventEditor: { fields, id, schedule }
    }: TState = getState();

    submitSet = createAsyncActions<TEventEditorState>(
      dispatch,
      EEventEditorActions.SUBMIT_EVENT
    ).request();

    http
      .send({
        body: createEventBody(fields, schedule, isDraft),
        method: 'PUT',
        url: `${ENDPOINTS.EVENT}/${id}`
      })
      .then(() => {
        submitSet.success();
        dispatch(clearEditorAction());
        dispatch(resetEventsAction());
        dispatch(push(`${ROUTES.EVENTS}/${id}`));
      })
      .catch(submitSet.error);
  };
}

const uploadProgressAction = (
  uploadProgress: number
): TAction<TEventEditorState, EEventEditorActions> => ({
  type: EEventEditorActions.BANNER_UPLOAD_PROGRESS,
  payload: { uploadProgress }
});

/**
 * Uploads a banner image. Associates it with an event-under-change.
 * @param {File} file
 * @param {number} eventId
 */
export function eventBannerUploadAction(file: File, eventId: number) {
  return (dispatch) => {
    const url = `${API_URL}${ENDPOINTS.EVENT}/${eventId}/banner`;

    uploadSet = createAsyncActions<TEventEditorState>(
      dispatch,
      EEventEditorActions.BANNER_UPLOAD
    );

    const onError = (e: Error) => {
      dispatch(uploadProgressAction(null));
      uploadSet.error(e);
    };
    const onProgress = (progress: number): void =>
      dispatch(uploadProgressAction(progress));
    const onSuccess = ({ data }): void => {
      dispatch(uploadProgressAction(null));
      uploadSet.success(data);
    };

    uploadSet.request();

    new Uploader({
      onError: onError,
      onProgress: onProgress,
      onSuccess: onSuccess as any,
      token: getToken
    }).upload(file, url);
  };
}

/**
 * Converts dates into UTC timezone by simply resetting UTC offset. Also if an item is brand new,
 * which is evident by string type of its ID (ID of string type means it's temporary, front-end side only),
 * the ID is excluded. And also an appointee instance is replaced with its ID if any.
 */
export function mapSchedule(schedule: TScheduleItem[]): {}[] {
  return schedule.map((item: TScheduleItem): {} => {
    const { appointee, end, id, start, ...rest } = item;

    const _item = {
      ...rest,
      appointee: appointee ? appointee.id : appointee,
      end: item.end.substring(0, 19),
      start: item.start.substring(0, 19)
    };

    return typeof id === 'string'
      ? (_item as TScheduleItem)
      : {
          ..._item,
          id: id
        };
  });
}

/**
 * Creates submit request body.
 */
export function createEventBody(
  fields: TFormFields,
  schedule: TScheduleItem[],
  isDraft: boolean
): {} {
  const {
    dates,
    longDescription,
    shortDescription,
    title,
    utcOffset
  }: TFormFields = fields;
  const [start, end] = (dates.value || []) as any;

  return {
    end: end ? `${pretendUTC(end)}T23:59:59` : null,
    longDescription: longDescription.value || '',
    published: !isDraft,
    schedule: mapSchedule(schedule),
    shortDescription: shortDescription.value,
    start: start ? `${pretendUTC(start)}T00:00:00` : null,
    title: title.value,
    utcOffset: utcOffset.value
  };
}

// SCHEDULE ITEM MANIPULATIONS

/**
 * Inserts a new schedule item.
 */
export function addScheduleItemAction(item: TScheduleItem) {
  return (dispatch, getState) => {
    const {
      eventEditor: { schedule }
    }: TState = getState();

    const update: TScheduleItem[] = [...schedule, item];

    dispatch({
      type: EEventEditorActions.ADD_SCHEDULE_ITEM,
      payload: {
        schedule: update
      }
    });
  };
}

/**
 * Updates a schedule item by its ID.
 */
export function updateScheduleItemAction(item: TScheduleItem) {
  return (dispatch, getState) => {
    const {
      eventEditor: { schedule }
    }: TState = getState();

    const scheduleUpdate: TScheduleItem[] = schedule.map(
      (_item: TScheduleItem) => {
        if (item.id !== _item.id) return _item;
        return item;
      }
    );
    dispatch({
      type: EEventEditorActions.UPDATE_SCHEDULE_ITEM,
      payload: {
        schedule: scheduleUpdate
      }
    });
  };
}

/**
 * Removes a schedule item by its ID.
 */
export function removeScheduleItemAction(itemId: number) {
  return (dispatch, getState) => {
    const {
      eventEditor: { schedule }
    }: TState = getState();

    const update: TScheduleItem[] = schedule.filter(
      ({ id }: TScheduleItem) => id !== itemId
    );

    dispatch({
      type: EEventEditorActions.REMOVE_SCHEDULE_ITEM,
      payload: {
        schedule: update
      }
    });
  };
}

export function cancelEditingAction() {
  return (dispatch) => dispatch(goBack());
}
