import React from 'react';

import '../VesselMap.scss';
import { areEqual } from '../../../other/helpers';
import { commonMapPropTypes } from './mapPropTypes';
import MapInner from './../MapInner';

import { IWindyApi } from '../types/windy';
import { TCommonMapProps } from '../types/common';

type AbstractMapState = {
  hasInitPassed: boolean;
};

abstract class AbstractMap extends React.Component<
  TCommonMapProps,
  AbstractMapState
> {
  protected mapApi?: IWindyApi;
  static propTypes;

  rootId?: string;
  state: AbstractMapState = { hasInitPassed: false };

  shouldComponentUpdate(nextProps: TCommonMapProps) {
    if (!nextProps.vessels && !nextProps.providers) return false;
    const {
      mapOptions,
      providers,
      selectedEntry,
      tracks,
      vessels
    } = this.props;
    return !(
      this.state.hasInitPassed &&
      areEqual(mapOptions, nextProps.mapOptions) &&
      areEqual(providers, nextProps.providers) &&
      areEqual(selectedEntry, nextProps.selectedEntry) &&
      areEqual(tracks, nextProps.tracks) &&
      areEqual(vessels, nextProps.vessels)
    );
  }

  componentDidMount() {
    this.initMap();
  }

  postInit(api: IWindyApi): void {}

  abstract initMap(): void;

  render() {
    return (
      <div id={this.rootId} className="VesselsMap__layout">
        {this.state.hasInitPassed && (
          <MapInner
            {...this.props}
            api={this.mapApi}
            postInitCallback={this.postInit}
          />
        )}
      </div>
    );
  }
}

AbstractMap.propTypes = commonMapPropTypes;
export default AbstractMap;
