import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import PhoneInput from 'react-phone-input-2';

import './ContactForm.scss';
import {
  emailRules,
  firstNameRules,
  lastNameRules,
  mobileRules,
  positionRules,
  telephoneRules
} from '../other/validationRules';
import SaveCancelGroup from '../../../../components/common/buttons/SaveCancelGroup/SaveCancelGroup';
import { TPerson } from '../../../../types/person';

type TProviderInfoFormProps = {
  onCancel: () => void;
  onSubmit: (obj: Partial<TPerson>) => void;
  values: Partial<TPerson>;
};

class ContactForm extends React.PureComponent<TProviderInfoFormProps> {
  static propTypes;
  private form: FormInstance;

  handleSubmit = () => this.form.validateFields().then(this.props.onSubmit);

  render() {
    return (
      <Form
        className="ContactForm"
        initialValues={this.props.values}
        onFinish={this.handleSubmit}
        ref={(el) => (this.form = el)}
      >
        <Form.Item name="firstName" rules={firstNameRules}>
          <Input placeholder="First name" />
        </Form.Item>

        <Form.Item name="lastName" rules={lastNameRules}>
          <Input placeholder="Last name" />
        </Form.Item>

        <Form.Item name="position" rules={positionRules}>
          <Input placeholder="Position" />
        </Form.Item>

        <Form.Item name="telephone" rules={telephoneRules}>
          <PhoneInput placeholder="Telephone" />
        </Form.Item>

        <Form.Item name="mobile" rules={mobileRules}>
          <PhoneInput placeholder="Mobile" />
        </Form.Item>

        <Form.Item name="email" rules={emailRules}>
          <Input placeholder="Email" />
        </Form.Item>

        <SaveCancelGroup onCancel={this.props.onCancel} />
      </Form>
    );
  }
}

ContactForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ContactForm;
