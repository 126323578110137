import React, { ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import Scrollbar from 'react-perfect-scrollbar';

import './Expander2.scss';
import ExpanderHeader from './ExpanderHeader';
import { IconCustom } from 'react-fishfacts/dist';

type Props = {
  buttonText?: string;
  children: ReactElement[];
  isExpanded?: boolean;
  itemWidth: number;
  onAdd?: () => void;
  title: string;
};
type State = {
  isExpanded: boolean;
};

const scrollerProps = {
  className: 'Expander2__scroll',
  options: {
    suppressScrollY: true,
    swipeEasing: true
  }
};

class Expander2 extends React.PureComponent<Props, State> {
  private KEY = '1';
  static propTypes;
  state: State = { isExpanded: false };

  static getDerivedStateFromProps({ isExpanded }) {
    return isExpanded === true ? { isExpanded } : null;
  }

  handleChange = (key: string) =>
    this.setState({ isExpanded: key === this.KEY });

  render() {
    const { isExpanded } = this.state;
    const { buttonText, children, itemWidth, onAdd, title } = this.props;
    const defaultActiveKey: string[] = isExpanded ? [this.KEY] : [];
    const header: ReactNode = (
      <ExpanderHeader buttonText={buttonText} onAdd={onAdd} title={title} />
    );

    const contentStyle = {
      width: `${itemWidth * children.length}px`
    };
    const slider: ReactNode = (
      <Scrollbar {...scrollerProps}>
        <div className="app-d-flex Expander2__content" style={contentStyle}>
          {children}
        </div>
      </Scrollbar>
    );
    const list: ReactNode = <div className="Expander2__list">{children}</div>;
    const expandIcon = () => <IconCustom type="chevron-up" />;

    return (
      <section className="Expander2">
        <Collapse
          accordion={true}
          bordered={false}
          className="Expander2__collapse"
          defaultActiveKey={defaultActiveKey}
          expandIcon={expandIcon}
          onChange={this.handleChange}
        >
          <Collapse.Panel children={list} header={header} key={this.KEY} />
        </Collapse>

        {isExpanded ? null : slider}
      </section>
    );
  }
}

Expander2.propTypes = {
  buttonText: PropTypes.string,
  isExpanded: PropTypes.bool,
  itemWidth: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired
};

export default Expander2;
