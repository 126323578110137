import { THttpRequestOptions } from 'react-fishfacts/dist';

import { ENDPOINTS } from '../../../../other/config';
import { mediaBluePrintMap } from '../providerEditorConstants';
import { ProviderMediaHandlingUtil } from './ProviderMediaHandlingUtil';

import { EProviderMediaObjectType } from '../../../../types/providers';
import { TProviderEditorState, THandledObject } from '../providerEditor';

/**
 * Handles provider-associated media objects creation.
 */
export class ProviderMediaCreateUtil extends ProviderMediaHandlingUtil {
  getRequestParams(): THttpRequestOptions {
    const prefix = `${ENDPOINTS.SERVICE_PROVIDER}/${this.id}`;
    const body = mediaBluePrintMap[this.type];

    return {
      body: body,
      method: 'POST',
      url: `${prefix}/${this.getSuffix()}`
    };
  }

  getPayload(resp: THandledObject): Partial<TProviderEditorState> {
    return {
      ...this.getArrayUpdate(resp),
      handledObject: resp,
      handledObjectType: this.type
    };
  }

  private getArrayUpdate(resp: THandledObject): Partial<TProviderEditorState> {
    const item = resp as any;

    switch (this.type) {
      case EProviderMediaObjectType.ADDRESS:
        return {
          addresses: [item, ...this.addresses]
        };
      case EProviderMediaObjectType.CONTACT:
        return {
          contacts: [item, ...this.contacts]
        };
      case EProviderMediaObjectType.PRODUCT:
        item.published = true; // A brand new product is published by default
        item.longDescriptionMedia = null; // Hack: server returns consistent 'longMediaDescription' on POST

        return {
          products: [item, ...this.products]
        };
      case EProviderMediaObjectType.VIDEO:
        return {
          videos: [item, ...this.videos]
        };
    }
  }
}
