import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';

import './GoBackButton.scss';
import { ButtonShape, ButtonType } from 'antd/lib/button/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type Props = {
  history: RouteComponentProps;
  className?: string;
  pathBack?: string;
  size?: SizeType;
  shape?: ButtonShape;
  type?: ButtonType;
};

const GoBackButton = ({
  history: { goBack, push },
  className,
  pathBack,
  size = 'large',
  shape = 'round',
  type = 'primary'
}: Props) => {
  const cls = classNames('GoBackButton', className);
  const icon = <ArrowLeftOutlined />;
  const handleClick = () => (pathBack ? push(pathBack) : goBack());

  return (
    <Button
      className={cls}
      onClick={handleClick}
      type={type}
      size={size}
      shape={shape}
      icon={icon}
    >
      Back
    </Button>
  );
};

GoBackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  className: PropTypes.string,
  pathBack: PropTypes.string,
  size: PropTypes.oneOf(['small', 'middle', 'large', undefined]),
  shape: PropTypes.oneOf(['circle', 'circle-outline', 'round']),
  type: PropTypes.oneOf([
    'default',
    'primary',
    'ghost',
    'dashed',
    'link',
    'text'
  ])
};

export default withRouter(GoBackButton);
