import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './Navigation.scss';
import AccountLink from '../../../common/AccountLink/AccountLink';
import { isMobile } from '../../../helpers/helpers';
import { ROUTES } from '../../../../other/config';

import { TNavigationCategory } from '../../../../types/navigation';
import { TState } from '../../../../store/appStateModel';

type Props = {
  closeAside: () => void;
  categories: TNavigationCategory[];
};

const Navigation: FunctionComponent<Props> = ({ closeAside, categories }) => (
  <ul className="Navigation">
    <li className="Navigation__accountLinkItem">
      <AccountLink
        className="Navigation__link"
        activeClassName="Navigation__link--active"
        onClick={closeAside}
      />
    </li>

    <li>
      <NavLink
        activeClassName="Navigation__link--active"
        className="Navigation__link"
        exact={true}
        to={ROUTES.HOME}
        onClick={closeAside}
      >
        Home
      </NavLink>
    </li>

    {categories &&
      categories.map(({ path, title, isHiddenForMobile }) => {
        if (isHiddenForMobile && isMobile()) return null;

        return (
          <li key={title}>
            <NavLink
              activeClassName="Navigation__link--active"
              className="Navigation__link"
              to={path}
              onClick={closeAside}
            >
              {title}
            </NavLink>
          </li>
        );
      })}

    <li>
      <NavLink
        activeClassName="Navigation__link--active"
        className="Navigation__link"
        to={ROUTES.EVENTS}
        onClick={closeAside}
      >
        Events
      </NavLink>
    </li>
  </ul>
);

Navigation.propTypes = {
  closeAside: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.any)
};

const mapStateToProps = (state: TState, ownProps) => ({
  categories: state.serviceBranches.navigationCategories,
  ...ownProps
});

export default connect(mapStateToProps)(Navigation);
