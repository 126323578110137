import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { TElectricalPower } from 'react-fishfacts/dist';

type Props = {
  electricalPower: TElectricalPower;
  getManufacturerFieldById: (number) => string | JSX.Element;
};

const ElectricalPower = ({
  electricalPower,
  getManufacturerFieldById
}: Props): ReactElement => {
  const {
    primaryGenerators,
    secondaryGenerators,
    turbineSet,
    shaftGenerators,
    emergencyGenerator,
    batterySystem,
    electricalInstallation,
    shoreChargingAvailable
  } = electricalPower;

  const primaryGeneratosBlock = (
    <>
      <tr className="ExtendedFields__paragraph">
        <th colSpan={2}>Primary generator engines</th>
      </tr>
      {primaryGenerators ? (
        primaryGenerators.map(
          ({ manufacturerId, details, power, quantity }, index) => (
            <React.Fragment key={`primaryGenerators-${manufacturerId}`}>
              <tr className="ExtendedFields__listItem">
                <th colSpan={2}>Generator Engine {index + 1}</th>
              </tr>
              {manufacturerId && (
                <tr>
                  <th>Provider</th>
                  <td>{getManufacturerFieldById(manufacturerId)}</td>
                </tr>
              )}
              {quantity && (
                <tr>
                  <th>Quantity</th>
                  <td>{quantity}</td>
                </tr>
              )}
              {power && (
                <tr>
                  <th>Power</th>
                  <td>{power} kW</td>
                </tr>
              )}
              {details && (
                <tr>
                  <th>Details</th>
                  <td>{details}</td>
                </tr>
              )}
              {index + 1 < primaryGenerators.length && (
                <tr>
                  <td colSpan={2} height={25} />
                </tr>
              )}
            </React.Fragment>
          )
        )
      ) : (
        <tr>
          <td colSpan={2}>
            No additional power source. Same as propulsion engine/s
          </td>
        </tr>
      )}
    </>
  );

  return (
    <table className="ExtendedFields__table">
      <tbody>
        {primaryGeneratosBlock}

        {secondaryGenerators && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Secondary generator engines</th>
            </tr>
            {secondaryGenerators.map(
              ({ manufacturerId, details, power, quantity }, index) => (
                <React.Fragment key={`secondaryGenerators-${manufacturerId}`}>
                  <tr className="ExtendedFields__listItem">
                    <th colSpan={2}>Generator Engine {index + 1}</th>
                  </tr>
                  {manufacturerId && (
                    <tr>
                      <th>Provider</th>
                      <td>{getManufacturerFieldById(manufacturerId)}</td>
                    </tr>
                  )}
                  {quantity && (
                    <tr>
                      <th>Quantity</th>
                      <td>{quantity}</td>
                    </tr>
                  )}
                  {power && (
                    <tr>
                      <th>Power</th>
                      <td>{power} kW</td>
                    </tr>
                  )}
                  {details && (
                    <tr>
                      <th>Details</th>
                      <td>{details}</td>
                    </tr>
                  )}
                  {index + 1 < secondaryGenerators.length && (
                    <tr>
                      <td colSpan={2} height={25} />
                    </tr>
                  )}
                </React.Fragment>
              )
            )}
          </>
        )}

        {turbineSet && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Turbine set</th>
            </tr>
            {turbineSet.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>{getManufacturerFieldById(turbineSet.manufacturerId)}</td>
              </tr>
            )}
            {turbineSet.details && (
              <tr>
                <th>Details</th>
                <td>{turbineSet.details}</td>
              </tr>
            )}
            {turbineSet.power && (
              <tr>
                <th>Power</th>
                <td>{turbineSet.power} kW</td>
              </tr>
            )}
            {turbineSet.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{turbineSet.quantity}</td>
              </tr>
            )}
          </>
        )}
        {shaftGenerators && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Shaft generators</th>
            </tr>
            {shaftGenerators.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(shaftGenerators.manufacturerId)}
                </td>
              </tr>
            )}
            {shaftGenerators.details && (
              <tr>
                <th>Details</th>
                <td>{shaftGenerators.details}</td>
              </tr>
            )}
            {shaftGenerators.power && (
              <tr>
                <th>Power</th>
                <td>{shaftGenerators.power} kW</td>
              </tr>
            )}
            {shaftGenerators.quantity && (
              <tr>
                <th>Quantity</th>
                <td>{shaftGenerators.quantity}</td>
              </tr>
            )}
          </>
        )}

        {emergencyGenerator && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Emergency generator</th>
            </tr>
            {emergencyGenerator.manufacturerId && (
              <tr>
                <th>Provider</th>
                <td>
                  {getManufacturerFieldById(emergencyGenerator.manufacturerId)}
                </td>
              </tr>
            )}
            {emergencyGenerator.details && (
              <tr>
                <th>Details</th>
                <td>{emergencyGenerator.details}</td>
              </tr>
            )}
            {emergencyGenerator.power && (
              <tr>
                <th>Power</th>
                <td>{emergencyGenerator.power} kW</td>
              </tr>
            )}
          </>
        )}

        {batterySystem && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Battery system</th>
            </tr>
            <tr>
              <th>Provider</th>
              <td>{getManufacturerFieldById(batterySystem.manufacturerId)}</td>
            </tr>
          </>
        )}

        {electricalInstallation && (
          <>
            <tr className="ExtendedFields__paragraph">
              <th colSpan={2}>Electrical installation</th>
            </tr>
            <tr>
              <th>Provider</th>
              <td>
                {getManufacturerFieldById(
                  electricalInstallation.manufacturerId
                )}
              </td>
            </tr>
          </>
        )}

        {shoreChargingAvailable && (
          <tr className="ExtendedFields__paragraph">
            <th colSpan={2}>Shore charging available</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

ElectricalPower.propTypes = {
  electricalPower: PropTypes.shape({
    shoreChargingAvailable: PropTypes.bool,
    primaryGenerators: PropTypes.array,
    secondaryGenerators: PropTypes.array,
    turbineSet: PropTypes.object,
    batterySystem: PropTypes.object,
    shaftGenerators: PropTypes.object,
    emergencyGenerator: PropTypes.object,
    electricalInstallation: PropTypes.object
  }).isRequired,
  getManufacturerFieldById: PropTypes.func.isRequired
};

export default ElectricalPower;
