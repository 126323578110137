import { ActionFactory, TAction, THttpResponse } from 'react-fishfacts/dist';

import {
  ENDPOINTS,
  VESSELS_LOCATIONS_UPDATE_INTERVAL
} from '../../../other/config';
import http from '../../../services/http';
import store from '../../store';

import { EVesselsLocationsActions } from './vesselsLocationsConstants';
import { TState } from '../../appStateModel';
import { TVesselLocation } from '../../../types/vessel';
import { TVesselsFilterState } from '../../vessel/vesselsFilter/vesselsFilterModel';
import { TVesselsLocationsState } from './vesselsLocationsModel';

const af = new ActionFactory<TVesselsLocationsState, EVesselsLocationsActions>(
  store
);
const locationsSet = af.createAsyncActions(
  EVesselsLocationsActions.FETCH_LOCATIONS
);

/**
 * Starts interval location retrieves. First stop the previous job if any. If the provider filter
 * is different from its defaults, it means that no vessel locations should be shown. So, no task
 * is triggered.
 */
export function watchLocationsAction() {
  // trafficWatchLocations
  return (dispatch, getState) => {
    const {
      providers: { filterSettings }
    } = getState() as TState;

    dispatch(unwatchLocationsAction());

    if (filterSettings.categoryIds !== null && filterSettings.isFilterSet)
      return;

    const action: TAction<TVesselsLocationsState, EVesselsLocationsActions> = {
      type: EVesselsLocationsActions.START_WATCHING_LOCATIONS,
      payload: {
        locationTimer: startLocationWatch() as any
      }
    };
    dispatch(action);
  };
}

/**
 * Cancels interval location updates.
 */
export function unwatchLocationsAction() {
  // trafficUnwatchLocations
  return (dispatch, getState) => {
    const {
      vesselsLocations: { locationTimer }
    } = getState() as TState;
    clearInterval(locationTimer);

    if (locationTimer) {
      const action: TAction<
        TVesselsLocationsState,
        EVesselsLocationsActions
      > = {
        type: EVesselsLocationsActions.STOP_WATCHING_LOCATIONS,
        payload: {
          locationTimer: null,
          locations: null
        }
      };
      dispatch(action);
    }
  };
}

/**
 * Fires interval location fetch task.
 */
function startLocationWatch() {
  store.dispatch(fetchLocations());
  return setInterval(
    () => store.dispatch(fetchLocations()),
    VESSELS_LOCATIONS_UPDATE_INTERVAL
  );
}

/**
 * Fetches locations (map vessel entries) with respect to the filter settings.
 */
function fetchLocations() {
  return (dispatch, getState) => {
    const { vesselsFilter } = getState() as TState;
    locationsSet.request();

    http
      .send(getLocationQuery(vesselsFilter))
      .then(({ data }: THttpResponse<TVesselLocation[]>) =>
        locationsSet.success({
          locations: data
        })
      )
      .catch(locationsSet.error);
  };
}

/**
 * Returns a query string with respect to the filter settings.
 * @param vesselFilterSettings
 */
function getLocationQuery(vesselFilterSettings: TVesselsFilterState): string {
  const {
    filterBruttoTons,
    filterBuildYear,
    filterCountry,
    filterEngineModel,
    filterEnginePower,
    filterVesselType,
    filterFleet,
    filterLength
  } = vesselFilterSettings;
  let req = '';

  if (filterFleet.length > 0) req += `&fleetId=${filterFleet.join(',')}`;
  if (filterCountry.length > 0) req += `&flag=${filterCountry.join(',')}`;
  if (filterVesselType.length > 0)
    req += `&vesselTypeId=${filterVesselType.join(',')}`;
  if (filterEngineModel.length > 0)
    req += `&engineModelId=${filterEngineModel.join(',')}`;
  if (filterBruttoTons)
    req += `&bruttoTonsMax=${filterBruttoTons.max}&bruttoTonsMin=${filterBruttoTons.min}`;
  if (filterLength)
    req += `&lengthMax=${filterLength.max}&lengthMin=${filterLength.min}`;
  if (filterEnginePower)
    req += `&enginePowerMax=${filterEnginePower.max}&enginePowerMin=${filterEnginePower.min}`;
  if (filterBuildYear)
    req += `&buildYearMax=${filterBuildYear.max}&buildYearMin=${filterBuildYear.min}`;

  return `${ENDPOINTS.VESSELS_LOCATION}?${req.slice(1)}`;
}
