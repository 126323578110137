import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import './OwnersItem.scss';
import { COLOR_MAP } from '../../../helpers';
import RejectApprove from '../../../Confirmation/RejectApprove';
import TimeSlot from './TimeSlot';
import UserDetails from './UserDetails';
import { EScheduleItemStatus, TScheduleItem } from '../../../../../types/event';

type Props = {
  item: TScheduleItem;
  onApproveReject: (item: TScheduleItem, shouldReject?: boolean) => void;
  utcOffset: string;
};

const OwnersItem = ({
  item,
  onApproveReject,
  utcOffset
}: Props): ReactElement => {
  const { appointee, end, notes, status, start, title } = item;

  const handleApprove = () => onApproveReject(item);
  const handleReject = () => onApproveReject(item, true);

  const getStatusLabel = (): string => {
    if (status === EScheduleItemStatus.PENDING) return 'Requested';
    if (status === EScheduleItemStatus.BOOKED) return 'Booked';
    return 'Vacant';
  };

  const getStatusColorKey = (): string => {
    if (status === EScheduleItemStatus.PENDING)
      return EScheduleItemStatus.PENDING;
    if (status === EScheduleItemStatus.BOOKED)
      return EScheduleItemStatus.BOOKED;
    return EScheduleItemStatus.AVAILABLE;
  };

  const tag = (
    <Tag className="app-custom-tag" color={COLOR_MAP[getStatusColorKey()]}>
      {getStatusLabel()}
    </Tag>
  );

  const controls = status === EScheduleItemStatus.PENDING && (
    <RejectApprove onApprove={handleApprove} onReject={handleReject} />
  );

  const footer = notes && (
    <footer className="ScheduleItem__footer">
      <h5>
        Notes{' '}
        <small>
          <sup>*</sup>visible only to you
        </small>
      </h5>
      <div className="ScheduleItem__notes">{notes}</div>
    </footer>
  );

  return (
    <article className="OwnersItem">
      <header className="OwnersItem__header">
        <div className="OwnersItem__info">
          <TimeSlot endTime={end} startTime={start} utcOffset={utcOffset} />
          <div className="OwnersItem__title">{title}</div>
        </div>
        {tag}
      </header>

      <UserDetails appointee={appointee} />

      {controls}
      {footer}
    </article>
  );
};

OwnersItem.propTypes = {
  item: PropTypes.object.isRequired,
  onApproveReject: PropTypes.func.isRequired
};

export default OwnersItem;
