import { areEqual, TCountry, TLabelValue } from 'react-fishfacts/dist';

import {
  buildFields,
  TTreeSelectOption
} from '../../../../../other/formAndValidation/formUtils';
import { getServiceIds, getTypeIds, NONE_VALUE } from '../helpers';
import { TFormFields } from '../../../../../types/formFields';
import moment, { Moment } from 'moment';

export type PromotionFormProps = {
  countries: TCountry[];
  fields: TFormFields;
  onChange: (values: { [fieldName: string]: any }) => void;
  onSubmit: (values: {}) => void;
  serviceOptions: TTreeSelectOption[];
  vesselTypesOptions: TLabelValue[];
};

export const startRules = [
  {
    message: 'Start date is required!',
    required: true
  }
];
export const endRules = [
  {
    message: 'End date is required!',
    required: true
  }
];

export const datePickerProps = {
  allowClear: false,
  format: 'YYYY-MM-DD HH:mm',
  showTime: { format: 'HH' },
  showToday: false
};

export const today: Moment = moment().add(1, 'hour').minutes(0);
const endDate: Moment = today.clone().add(1, 'week');

export function getInitials(
  countries: TCountry[],
  serviceOptions: TTreeSelectOption[],
  vesselTypesOptions: TLabelValue[]
): TFormFields {
  const fields = {
    countries: countries.map(({ value }: TCountry) => value),
    end: endDate,
    serviceCategories: getServiceIds(serviceOptions),
    start: today,
    utcOffset: '+01:00',
    vesselTypes: getTypeIds(vesselTypesOptions)
  };
  return buildFields(
    [
      'countries',
      'end',
      'serviceCategories',
      'start',
      'utcOffset',
      'vesselTypes'
    ],
    fields
  );
}

export function getSelectorValue(
  optionsIds: string[],
  value: string[]
): string[] {
  if (!value) {
    return;
  } else if (value.length === 0) {
    return [NONE_VALUE];
  }

  return areEqual(value.sort(), optionsIds) ? [] : value.map(String);
}

export function getSelectorUpdate(
  values: string[],
  inputValue: string[],
  optionsIds: string[]
): string[] {
  if (
    areEqual(values, [NONE_VALUE]) ||
    // @ts-ignore
    (inputValue.length > 0 && values.includes(parseInt(NONE_VALUE)))
  ) {
    return [];
  } else if (values.length === 0) {
    return optionsIds;
  } else {
    // @ts-ignore
    return values.filter((id: number) => id !== parseInt(NONE_VALUE));
  }
}
