import React, { PureComponent } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  IconCustom,
  Search as SearchComponent,
  THandler,
  TSearchResult
} from 'react-fishfacts/dist';

import './Search.scss';
import { hideKeyboard } from '../../../helpers/utils';
import {
  searchRequestAction,
  selectSearchResultAction
} from '../../../../store/search/searchActions';

import { TSearchItemExtended } from '../../../../types/search';
import { TState } from '../../../../store/appStateModel';

type Props = {
  className?: string;
  getPopupContainer?: () => HTMLElement;
  isPending: boolean;
  onSearch: THandler;
  onSelect: THandler;
  query: string;
  results: TSearchResult[];
};

type State = {
  isExpanded: boolean;
};

const iconCustomStyle = { fontSize: 20 };

class Search extends PureComponent<Props, State> {
  state: State = {
    isExpanded: false
  };

  toggleSearch = () => this.setState({ isExpanded: !this.state.isExpanded });

  onSelect = (item: TSearchItemExtended) => {
    const { onSelect } = this.props;
    this.toggleSearch();
    hideKeyboard();
    onSelect(item);
  };

  render() {
    const {
      className,
      getPopupContainer,
      isPending,
      onSearch,
      query,
      results
    } = this.props;
    const { isExpanded } = this.state;
    const searchClassName = classNames('HeaderSearch', className);

    const searchInputWrapperClassName = classNames(
      'HeaderSearch__inputWrapper',
      {
        'HeaderSearch__inputWrapper--active': isExpanded
      }
    );

    return (
      <div className={searchClassName}>
        <Button
          type="link"
          className="HeaderSearch__icon"
          onClick={this.toggleSearch}
        >
          <IconCustom type="search" style={iconCustomStyle} />
        </Button>

        <div className={searchInputWrapperClassName}>
          <SearchComponent
            className="HeaderSearch__input"
            isPending={isPending}
            onSearch={onSearch}
            onSelect={this.onSelect}
            query={query}
            results={results}
            {...(getPopupContainer && { getPopupContainer })}
          />
          <Button
            className="HeaderSearch__closeButton"
            type="link"
            onClick={this.toggleSearch}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  search: { isPending, searchQuery: query, searchResults: results }
}: TState) => ({
  isPending,
  query,
  results
});

const mapDispatchToProps = {
  onSearch: searchRequestAction,
  onSelect: selectSearchResultAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
