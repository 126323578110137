import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ServiceCard.scss';
import BrandLogo from '../../../components/common/logos/BrandLogo/BrandLogo';
import { PROVIDER_IMAGE_URL, ROUTES } from '../../../other/config';
import { TProvider, TProviderAddress } from '../../../types/providers';

type Props = {
  provider: TProvider;
};


const ServiceCard = ({ provider }: Props) => {
	const { addresses, banner, id, logo, name, published, shortDescription } = provider;
	const bannerImgSrc = banner ? `${PROVIDER_IMAGE_URL}/${banner.path}` : null;
	const path = `${ROUTES.SERVICE_PROVIDER}/${id}`;

	const countriesReducer = (sum: string, current: TProviderAddress) => {
		return current.country
			? sum + ',' + current.country.iso
			: sum
	};
	const countries: string = addresses
    ? addresses.reduce(countriesReducer, '').slice(1)
    : '';

	return (
    <article className="app-fixed-ratio-container ServiceCard" data-countries={countries}>
      <div className="app-fixed-ratio-content">
        <div className="ServiceCard__content">
          <BrandLogo logo={logo} url={path} />

          <Link to={path} className="ServiceCard__banner">
            <img src={bannerImgSrc} alt={name} />
          </Link>

          <Link to={path} className="ServiceCard__text">
            <h5 className="ServiceCard__name">{name}</h5>
            <p className="ServiceCard__description">{shortDescription}</p>
            {!published &&
              <h4 className="app-error-msg ServiceCategory__draft">Draft!</h4>
            }
          </Link>
        </div>
      </div>
    </article>
	);
};

ServiceCard.propTypes = {
  provider: PropTypes.shape({
    addresses: PropTypes.array,
    banner: PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string
    }),
    id: PropTypes.number.isRequired,
    logo: PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    name: PropTypes.string,
  }).isRequired
};

export default ServiceCard;
