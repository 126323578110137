// Sorts addresses, contacts, products and videos by their ids.
import { TProvider } from '../../../../types/providers';

export function sortArraysForProvider(provider: TProvider): TProvider {
  const { addresses, contacts, products, videos, ...rest } = provider;
  return {
    ...rest,
    addresses: (addresses || []).sort(sortById),
    contacts: (contacts || []).sort(sortById),
    products: (products || []).sort(sortById),
    videos: (videos || []).sort(sortById)
  };
}

function sortById(a, b) {
  return b.id - a.id;
}
