import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import '../ServiceProvider.scss';
import AddressCard from './AddressCard';
import Expander from '../../../../components/common/Expander/Expander';
import { TProviderAddress } from '../../../../types/providers';

type Props = {
  addresses: TProviderAddress[];
};


const Locations: React.FC<Props> = ({ addresses }): ReactElement | null => {
  if (!addresses || !addresses.length) return null;

  const slides: ReactElement[] = addresses.map((location: TProviderAddress): ReactElement => (
    <AddressCard key={location.id} location={location}/>
  ));

  return (
    <section className="ServiceProvider__group">
      <Expander itemWidth={220} title="Locations">
        {slides}
      </Expander>
    </section>
  );
};


Locations.propTypes = {
  addresses: PropTypes.array,
};

export default React.memo(Locations);
