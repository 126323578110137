import React from 'react';
import PropTypes from 'prop-types';
import { TVessel } from 'react-fishfacts/dist';

import AddButton from 'components/common/buttons/AddButton/AddButton';
import withFleets from 'components/common/vessel/withFleets/withFleets';

type Props = {
  onFavClick: (vessels: TVessel[]) => void;
  vessels: TVessel[];
};

const FavManager = ({ onFavClick, vessels }: Props) => {
  const handleAdd = () => onFavClick(vessels);

  return (
    <div className="VesselListPage__favBtn">
      <AddButton label="Add all to fleet" onAdd={handleAdd} />
    </div>
  );
};

FavManager.propTypes = {
  onFavClick: PropTypes.func.isRequired,
  vessels: PropTypes.array
};

export default withFleets(FavManager);
