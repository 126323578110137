import React from 'react';
import PropTypes from 'prop-types';

import { getSelectorUpdate, getSelectorValue } from './helpers';
import ServiceCategoriesSelect from '../../../../../components/selects/ServiceCategoriesSelect/ServiceCategoriesSelect';
import { TTreeSelectOption } from '../../../../../other/formAndValidation/formUtils';

type Props = {
  className?: string;
  onChange?: (types: string[]) => void;
  options: TTreeSelectOption[];
  optionsIds: string[];
  value?: string[];
};

class ServiceCategoriesSelector extends React.PureComponent<Props> {
  static propTypes;

  handleChange = (values: string[]): void => {
    const { onChange, optionsIds, value } = this.props;
    onChange(getSelectorUpdate(values, value, optionsIds));
  };

  render() {
    const { className, options, optionsIds, value } = this.props;
    const _value = getSelectorValue(optionsIds, value);

    return (
      <ServiceCategoriesSelect
        className={className}
        onChange={this.handleChange as any}
        options={options}
        value={_value}
      />
    );
  }
}

ServiceCategoriesSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

export default ServiceCategoriesSelector;
