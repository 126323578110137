import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter, RouteComponentsProps } from 'react-router';

import './Main.scss';
import Aside from '../Aside/Aside';
import Header from '../Header/Header';
import { isIOS } from '../helpers/helpers';
import { ROUTES } from '../../other/config';
import RouteSwitch from '../RouteSwitch';

type Props = RouteComponentsProps & {
  clearSearch: () => void;
  isLoggedIn: boolean;
  prefetchData: () => void;
};

type State = {
  isAsideOpen: boolean;
};

class Main extends React.Component<Props, State> {
  static propTypes;

  state: State = {
    isAsideOpen: false
  };

  constructor(props: Props) {
    super(props);
    props.prefetchData();
  }

  toggleAside = () =>
    this.setState({
      isAsideOpen: !this.state.isAsideOpen
    });

  render(): React.ReactNode {
    const {
      isLoggedIn,
      location: { pathname }
    } = this.props;

    const isHomePage = !isLoggedIn && pathname === ROUTES.HOME;
    const isWideLayout =
      pathname === ROUTES.ANALYTICS ||
      pathname === ROUTES.MAP ||
      pathname === ROUTES.VESSELS ||
      isHomePage;
    const asideIsHiddenOnWideLayout = pathname === ROUTES.MAP || isHomePage;

    const mainCls: string = classnames('Main', {
      Main__wideLayout: isWideLayout,
      Main__hiddenAside: asideIsHiddenOnWideLayout,
      'Main--ios': isIOS()
    });

    return (
      <div className={mainCls}>
        <Header
          toggleAside={this.toggleAside}
          isAsideOpen={this.state.isAsideOpen}
          isLoggedIn={isLoggedIn}
        />

        <div className="Main__container" id="Main__container">
          <div className="Main__holder">
            <div className="app-container">
              <div className="Main__aside">
                <Aside
                  toggleAside={this.toggleAside}
                  isAsideOpen={this.state.isAsideOpen}
                />
              </div>

              <div className="Main__content">
                <RouteSwitch isLoggedIn={isLoggedIn} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mainComponentPropTypes = {
  clearSearch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  prefetchData: PropTypes.func.isRequired
};

Main.propTypes = mainComponentPropTypes;

export default withRouter(Main);
