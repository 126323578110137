import { push } from 'connected-react-router';
import {
  Uploader,
  TAction,
  THttpResponse,
  THttpRequestOptions
} from 'react-fishfacts/dist';

import { buildFields } from '../../../other/formAndValidation/formUtils';
import { CHUNK_LIMIT, ENDPOINTS, ROUTES } from '../../../other/config';
import { createAsyncActions } from '../../_utils/createAsyncActions';
import { fetchEventsAction } from '../../events/events/eventsActions';
import { fetchServiceBranchesAction } from '../../serviceBranches/serviceBranchesActions';
import { getToken } from '../../../services/auth';
import http from '../../../services/http';
import { ObjectChecker } from './helpers/ObjectChecker';
import { providerEditorInitialState } from './providerEditorInitialState';
import { ProviderMediaCreateUtil } from './helpers/ProviderMediaCreateUtil';
import { ProviderMediaUploadUtil } from './helpers/ProviderMediaUploadUtil';
import { ProviderMediaRemoveUtil } from './helpers/ProviderMediaRemoveUtil';
import { ProviderUpdateUtil } from './helpers/ProviderUpdateUtil';
import { sortArraysForProvider } from './helpers/helpers';
import { updateSessionAction } from '../../session/sessionActions';

import {
  EProviderEditorActions,
  providerBlueprint
} from './providerEditorConstants';
import {
  EProviderMediaObjectType,
  TProvider,
  TProviderAddress,
  TServiceBranch
} from '../../../types/providers';
import { TFormFields } from '../../../types/formFields';
import { THandledObject, TProviderEditorState } from './providerEditor';
import { TPerson } from '../../../types/person';
import { TState } from '../../appStateModel';

export let fetchSet,
  createProviderSet,
  uploadSet,
  createMediaSet,
  removeMediaSet,
  submitSet;

/**
 * Dispatches actions depending on provider-under-change existence: whether to edit or create.
 * @param {number} providerId
 */
export function editProviderAction(providerId: number) {
  return (dispatch, getState) => {
    const {
      session: { user }
    }: TState = getState();
    dispatch(clearFieldsAction());

    // Fast escape on create mode.
    if (!providerId) return dispatch(createProviderBlueprintAction());

    // if user attempts to edit another's provider -- sends him home
    // @ts-ignore
    const idArr: number[] = user ? user.userInfo.serviceProvidersId : [];
    if (!idArr.includes(providerId)) {
      return dispatch(push(ROUTES.NEWS));
    }

    dispatch(fetchProviderAction(providerId));
    dispatch(fetchEventsAction());
  };
}

/**
 * Retrieves service provider by ID.
 * @param {number} providerId
 */
export function fetchProviderAction(providerId: number) {
  return (dispatch) => {
    const url = `${ENDPOINTS.SERVICE_PROVIDER}/${providerId}`;

    fetchSet = createAsyncActions<TProviderEditorState>(
      dispatch,
      EProviderEditorActions.FETCH_PROVIDER
    ).request();

    http
      .send(url)
      .then(({ data }: THttpResponse<TProvider>) => {
        fetchSet.success(sortArraysForProvider(data));
        dispatch(assignFormFieldsAction());
      })
      .catch(fetchSet.error);
  };
}

/**
 * Assigns values to be used as form fields in the service provider editor.
 */
export function assignFormFieldsAction() {
  return (dispatch, getState) => {
    const { providerEditor }: TState = getState();

    const fields: TFormFields = buildFields(
      ['longDescription', 'name', 'shortDescription', 'website'],
      providerEditor
    );

    dispatch({
      type: EProviderEditorActions.ASSIGN_FORM_FIELDS,
      payload: { fields }
    });
  };
}

/**
 * Direct model update used to modify string type fields: longDescription, name, shortDescription, website.
 * @param payload
 */
export function updateProviderValuesAction(
  payload: Partial<TProviderEditorState>
) {
  return (dispatch) => {
    const update: Partial<TProviderEditorState> = {
      ...payload,
      isModified: true
    };

    dispatch({
      type: EProviderEditorActions.UPDATE_VALUES,
      payload: update
    });
    dispatch(submitProviderAction());
  };
}

/**
 * Updates fields of array type.
 */
export function updateProviderObjectAction(
  type: EProviderMediaObjectType,
  obj: Partial<TProviderAddress> | Partial<TPerson> | number
) {
  return async (dispatch, getState) => {
    const {
      dictionaries: { countriesISO },
      providerEditor,
      serviceBranches: { serviceCategories }
    }: TState = getState();
    const references = {
      countries: countriesISO,
      categories: serviceCategories
    };

    const util = new ProviderUpdateUtil(type, providerEditor, references);
    const update: Partial<TProviderEditorState> = await util.getPartialUpdate(
      obj
    );
    dispatch(updateProviderValuesAction(update));
  };
}

/**
 * Posts a dump service provider, so as to associate any media with the provider ID.
 */
export function createProviderBlueprintAction() {
  return (dispatch) => {
    createProviderSet = createAsyncActions<TProviderEditorState>(
      dispatch,
      EProviderEditorActions.CREATE_PROVIDER
    ).request();

    http
      .send({
        body: providerBlueprint,
        method: 'POST',
        url: ENDPOINTS.SERVICE_PROVIDER
      })
      .then(({ data }: THttpResponse<TProvider>) => {
        createProviderSet.success(data);
        dispatch(assignFormFieldsAction());
        // update session so as to sync user's providers
        dispatch(updateSessionAction());
      })
      .catch(createProviderSet.error);
  };
}

export const clearFieldsAction = (): TAction<
  TProviderEditorState,
  EProviderEditorActions
> => ({
  type: EProviderEditorActions.CLEAR_EDITOR,
  payload: providerEditorInitialState
});

export function clearEditorOnLeave() {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    dispatch(clearFieldsAction());

    providerEditor.isModified && dispatch(fetchServiceBranchesAction());
  };
}

const uploadProgressAction = (
  handledObjectType: EProviderMediaObjectType,
  uploadProgress: number
): TAction<TProviderEditorState, EProviderEditorActions> => ({
  type: EProviderEditorActions.FILE_UPLOAD_PROGRESS,
  payload: {
    handledObjectType,
    uploadProgress
  }
});

/**
 * Uploads a file of EProviderMediaObjectType.
 * @param file
 * @param objectType
 * @param objectId
 */
export function uploadFileAction(
  file: File,
  objectType: EProviderMediaObjectType,
  objectId?: number
) {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    const util = new ProviderMediaUploadUtil(
      objectType,
      providerEditor,
      objectId
    );

    const { url } = util.getRequestParams();
    uploadSet = createAsyncActions<TProviderEditorState>(
      dispatch,
      EProviderEditorActions.UPLOAD_FILE
    );

    // The chunk mode is topical only for videos.
    const chunkSize: number =
      objectType === EProviderMediaObjectType.VIDEO ? CHUNK_LIMIT : null;

    const onError = (e: Error) => {
      dispatch(uploadProgressAction(objectType, null));
      uploadSet.error(e);
    };
    const onProgress = (progress: number): void =>
      dispatch(uploadProgressAction(objectType, progress));
    const onSuccess = ({ data }): void => {
      dispatch(uploadProgressAction(objectType, null));
      uploadSet.success(util.getPayload(data));
    };

    uploadSet.request();
    new Uploader({
      chunkSize: chunkSize,
      onError: onError,
      onProgress: onProgress,
      onSuccess: onSuccess as any,
      token: getToken
    }).upload(file, url);
  };
}

/**
 * Creates a media object of EProviderMediaObjectType, associated to the provider.
 * @param type
 */
export function createMediaObjectAction(type: EProviderMediaObjectType) {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    const util = new ProviderMediaCreateUtil(type, providerEditor);
    const params: THttpRequestOptions = util.getRequestParams();

    createMediaSet = createAsyncActions<TProviderEditorState>(
      dispatch,
      EProviderEditorActions.CREATE_MEDIA
    ).request();

    http
      .send(params)
      .then(({ data }: THttpResponse<THandledObject>) =>
        createMediaSet.success(util.getPayload(data))
      )
      .catch(createMediaSet.error);
  };
}

/**
 * Assigns the handled object and its type.
 * @param object
 * @param type
 */
export function handleObjectAction(
  type?: EProviderMediaObjectType,
  object?: THandledObject
) {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    const action: TAction<TProviderEditorState, EProviderEditorActions> = {
      type: EProviderEditorActions.HANDLE_OBJECT,
      payload: {
        handledObject: object,
        handledObjectType: type
      }
    };

    if (object) {
      return dispatch(action);
    }

    // Scenario: user had created an object and didn't touch it. Now they're cancelling, so we will remove the object.
    if (!object) {
      const isBlueprintLike: boolean = new ObjectChecker(
        type,
        providerEditor
      ).isBlueprintLike();

      if (isBlueprintLike) {
        dispatch(
          removeMediaObjectAction(type, providerEditor.handledObject.id)
        );
      } else {
        dispatch(action);
      }
    }
  };
}

export function updateHandledObjectAction(update: Partial<THandledObject>) {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    dispatch({
      type: EProviderEditorActions.UPDATE_HANDLED_OBJECT,
      payload: {
        handledObject: {
          ...providerEditor.handledObject,
          ...update
        }
      }
    });
  };
}

/**
 * Removes media object of EProviderMediaObjectType, associated to the provider.
 * @param type
 * @param objectId
 */
export function removeMediaObjectAction(
  type: EProviderMediaObjectType,
  objectId: number
) {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    const util = new ProviderMediaRemoveUtil(type, providerEditor, objectId);
    const params: THttpRequestOptions = util.getRequestParams();

    removeMediaSet = createAsyncActions<TProviderEditorState>(
      dispatch,
      EProviderEditorActions.REMOVE_MEDIA
    ).request();

    http
      .send(params)
      .then(() => removeMediaSet.success(util.getPayload()))
      .catch(removeMediaSet.error);
  };
}

export function submitProviderAction() {
  return (dispatch, getState) => {
    const { providerEditor } = getState() as TState;
    submitSet = createAsyncActions<TProviderEditorState>(
      dispatch,
      EProviderEditorActions.SUBMIT_PROVIDER
    ).request({
      handledObject: null,
      handledObjectType: null
    });

    http
      .send({
        body: createProviderBody(providerEditor),
        method: 'PUT',
        url: `${ENDPOINTS.SERVICE_PROVIDER}/${providerEditor.id}`
      })
      .then(() => submitSet.success())
      .catch(submitSet.error);
  };
}

function createProviderBody(model: TProviderEditorState): {} {
  const {
    addresses,
    branches,
    contacts,
    longDescription,
    name,
    published,
    shortDescription,
    videos,
    website
  } = model;

  const mapAddress = (a: TProviderAddress): {} => ({
    ...a,
    country: a.country ? a.country.iso : null
  });

  return {
    addresses: addresses.map(mapAddress),
    branches: branches.map((b: TServiceBranch): {} => ({ id: b.id })),
    contacts: contacts,
    longDescription: longDescription,
    name: name,
    products: [],
    published: published,
    shortDescription: shortDescription,
    videos: videos,
    website: website
  };
}
