import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './FleetsMenu.scss';
import ArticleEditorHeader from '../../articles/ArticleEditor/ArticleEditorHeader';
import FleetsList from './FleetsList';
import { TFleetExt } from '../../../../types/fleet';

type Props = {
  fleets: TFleetExt[];
  onCancel: () => void;
  onCreate: (fleetName: string) => void;
  onVesselToggle: (vesselIds: number[], fleet: TFleetExt) => void;
  vesselIds?: number[];
};


const FleetsMenu = ({
  fleets,
  onCancel,
  onCreate,
  onVesselToggle,
  vesselIds
}: Props): ReactElement => (
  <div className="FleetsMenu">
    <ArticleEditorHeader onCancel={onCancel} title="Manage associated fleets" />
    <h3 className="FleetsMenu__subheader">
      Select fleets to associate the vessel with.
    </h3>

    <FleetsList
      favVesselIds={vesselIds}
      fleets={fleets}
      onCreate={onCreate}
      onToggle={onVesselToggle}
    />
  </div>
);

FleetsMenu.propTypes = {
  fleets: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onVesselToggle: PropTypes.func.isRequired,
  vesselIds: PropTypes.arrayOf(
    PropTypes.number
  )
};

export default FleetsMenu;
