import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

import './FishingRights.scss';
import ExpanderHeader from '../../../../components/common/Expander/ExpanderHeader';
import FishingLicence from '../FishingLicence/FishingLicence';
import { isTouchDevice } from '../../../../components/helpers/helpers';
import { TFishingLicence } from '../../../../types/fishingLicence';
import { IconCustom } from 'react-fishfacts/dist';

type Props = {
  licences: TFishingLicence[];
};

const FishingRights = ({ licences }: Props): ReactElement | null => {
  if (!licences || !licences.length) return null;

  const thisYear: number = new Date().getFullYear();
  const header = <ExpanderHeader title="Show elder licences" />;

  const list: TFishingLicence[] = licences.sort(
    (lA: TFishingLicence, lB: TFishingLicence): number =>
      +lB.end.substring(0, 4) - +lA.end.substring(0, 4)
  );

  const valid: ReactElement[] = list.map((ls: TFishingLicence) => {
    if (+ls.end.substring(0, 4) < thisYear) return null;
    return <FishingLicence key={ls.id} licence={ls} />;
  });

  const outdated: ReactElement[] = list.map((ls: TFishingLicence) => {
    if (+ls.end.substring(0, 4) >= thisYear) return null;
    return <FishingLicence key={ls.id} licence={ls} />;
  });

  const handleOpen = (x) => {
    if (!x.length) return;

    setTimeout(() => {
      const rights = document.getElementsByClassName('ExpanderHeader__header');
      if (rights.length === 0) return;

      rights[0].scrollIntoView({ behavior: 'smooth' });
    }, 400);
  };

  const classes = isTouchDevice()
    ? 'FishingLicence__scrollHorizontal FishingLicence__touchDevice'
    : 'FishingLicence__scrollHorizontal';

  const expandIcon = () => <IconCustom type="chevron-up" />;

  return (
    <div className="FishingRights">
      {valid.length > 0 && (
        <div className={classes}>
          <table className="FishingLicence">
            <thead>
              <tr>
                <th className="FishingLicence__specie">Specie</th>
                <th className="FishingLicence__area">Area</th>
                <th className="FishingLicence__quota">Quota</th>
                <th className="FishingLicence__type">Type</th>
                <th className="FishingLicence__dates">Start \ End</th>
              </tr>
            </thead>
          </table>
          <div className="FishingLicence__scrollVertical">
            <table className="FishingLicence">
              <tbody>{valid}</tbody>
            </table>
          </div>
        </div>
      )}

      {outdated.length > 0 && (
        <Collapse
          bordered={false}
          className="FishingRights__collapse"
          expandIcon={expandIcon}
          onChange={handleOpen}
        >
          <Collapse.Panel header={header} key="1">
            <div className={classes}>
              <table className="FishingLicence">
                <thead>
                  <tr>
                    <th className="FishingLicence__specie">Specie</th>
                    <th className="FishingLicence__area">Area</th>
                    <th className="FishingLicence__quota">Quota</th>
                    <th className="FishingLicence__type">Type</th>
                    <th className="FishingLicence__dates">Start \ End</th>
                  </tr>
                </thead>
              </table>
              <div className="FishingLicence__scrollVertical">
                <table className="FishingLicence">
                  <tbody>{outdated}</tbody>
                </table>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};

FishingRights.propTypes = {
  licences: PropTypes.array
};

export default FishingRights;
