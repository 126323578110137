import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RTEBasic, TLanguage } from 'react-fishfacts/dist';

import './ArticleForm.scss';
import { formFields2Dict } from '../../../../other/formAndValidation/formUtils';
import { languageRules, shortDescRules, titleRules } from './helpers';
import { TFormFields } from '../../../../types/formFields';

type TArticleFormProps = {
  children: ReactElement;
  fields: TFormFields;
  languages?: TLanguage[];
  onChange: (fields: TFormFields) => void;
  onSubmit: () => void;
};

class ArticleForm extends React.PureComponent<TArticleFormProps> {
  static propTypes;
  private form: FormInstance;
  private SHORT_MAX = 1000;
  private TITLE_MAX = 160;

  handleChange = (_, fields /*: TFormField[]*/) =>
    this.props.onChange({
      ...this.props.fields,
      ...formFields2Dict(fields)
    });

  handleRef = (form) => form && (this.form = form);
  handleSubmit = () => this.props.onSubmit();

  render() {
    const { children, fields, languages } = this.props;
    const { shortDescription, title } = fields;
    const fieldsArr = Object.values(fields) as any;

    const titleCount = title && title.value ? title.value.length : 0;
    const titleCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': titleCount > this.TITLE_MAX
    });

    const shortCount =
      shortDescription && shortDescription.value
        ? shortDescription.value.length
        : 0;
    const shortCls = classnames('EditModeForm__counter', {
      'EditModeForm__counter--warning': shortCount > this.SHORT_MAX
    });

    const languageSelect = languages && (
      <Form.Item name="languageIso" rules={languageRules}>
        <Select options={languages} placeholder="Language" />
      </Form.Item>
    );

    return (
      <div className="ArticleForm EditModeForm">
        <Form
          className="ArticleForm__content"
          fields={fieldsArr}
          onFieldsChange={this.handleChange}
          onFinish={this.handleSubmit}
          onFinishFailed={this.handleSubmit}
          ref={this.handleRef}
          scrollToFirstError={true}
        >
          <div className="EditModeForm__title">
            <Form.Item name="title" rules={titleRules}>
              <Input className="ArticleForm__area" placeholder="Title" />
            </Form.Item>
            <span className={titleCls}>
              {titleCount}/{this.TITLE_MAX}
            </span>
          </div>

          <div className="EditModeForm__title">
            <Form.Item name="shortDescription" rules={shortDescRules}>
              <Input.TextArea
                className="ArticleForm__input"
                placeholder="Brief summary"
              />
            </Form.Item>
            <span className={shortCls}>
              {shortCount}/{this.SHORT_MAX}
            </span>
          </div>

          <div className="ArticleForm__select">{languageSelect}</div>

          <Form.Item name="longDescription">
            {/*// @ts-ignore*/}
            <RTEBasic />
          </Form.Item>

          {children}
        </Form>
      </div>
    );
  }
}

ArticleForm.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ArticleForm;
