import { basicInitialState } from '../_utils/basicInitialState';
import { BASIC_HOME_CATEGORIES } from './serviceBranchesConstants';
import {
  PROVIDERS_FILTER_BY_COUNTRY,
  readSettings
} from '../../services/settings';
import { TServiceBranchesState } from './serviceBranchesModel';

export const defaultFilterSettings = {
  providerFilterCountries: []
};
const settings = readSettings();

export const serviceBranchesInitialState: TServiceBranchesState = {
  ...basicInitialState,
  branch: null,
  category: null,
  categoryFiltered: null,
  navigationCategories: BASIC_HOME_CATEGORIES,
  providerFilterSettings:
    settings[PROVIDERS_FILTER_BY_COUNTRY] || defaultFilterSettings,
  serviceBranches: [],
  serviceCategories: [],
  serviceProviders: null,
  error: null,
  isPending: false
};
