import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons/lib';
import './ExpanderHeader.scss';

type Props = {
  buttonText?: string;
  onAdd?: () => void;
  title: string;
};
// @ts-ignore
const icon = <PlusOutlined />;

const ExpanderHeader: React.FC<Props> = ({
  buttonText,
  onAdd,
  title
}): ReactElement => {
  const handleBtnClick = (e) => {
    e.stopPropagation();
    onAdd();
  };

  const button = (
    <Button icon={icon} shape="round" onClick={handleBtnClick} size="large">
      {buttonText}
    </Button>
  );

  return (
    <header className="app-d-flex app-justify-content-between ExpanderHeader__header">
      <h3 className="ExpanderHeader__title">{title}</h3>
      {onAdd && button}
    </header>
  );
};

ExpanderHeader.propTypes = {
  buttonText: PropTypes.string,
  onAdd: PropTypes.func,
  title: PropTypes.string.isRequired
};

export default ExpanderHeader;
