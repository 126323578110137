export enum ESessionActions {
  CHECK_SESSION = 'session/CHECK_SESSION',
  INVALIDATE_SESSION = 'session/INVALIDATE_SESSION',
  UPDATE_SESSION = 'session/UPDATE_SESSION',
  UPDATE_SESSION_REQUEST = 'session/UPDATE_SESSION_REQUEST',

  STORE_SESSION = 'session/STORE_SESSION',
  STORE_REDIRECT_PATH = 'session/STORE_REDIRECT_PATH',
  CLEAR_REDIRECT_PATH = 'session/CLEAR_REDIRECT_PATH'
}
