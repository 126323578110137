import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import './AddressForm.scss';
import { addressRules, cityRules, countryRules, postcodeRules, titleRules } from '../other/validationRules';
import SaveCancelGroup from '../../../../components/common/buttons/SaveCancelGroup/SaveCancelGroup';

import { TCountry } from '../../../../types/dictionaries';
import { TProviderAddress } from '../../../../types/providers';

type TAddressFormProps = {
  countries: TCountry[];
  onCancel: () => void;
  onSubmit: (obj: Partial<TProviderAddress>) => void;
  values: Partial<TProviderAddress>;
};


class AddressForm extends React.PureComponent<TAddressFormProps> {
  static propTypes;
  private form: FormInstance;

  handleSubmit = () => this.form.validateFields()
    .then(this.props.onSubmit);

  render() {
    const { countries, values } = this.props;

    return (
      <Form className="AddressForm"
            initialValues={values}
            onFinish={this.handleSubmit}
            ref={(el) => this.form = el} >

        <Form.Item name="title" rules={titleRules}>
          <Input placeholder="Location title" />
        </Form.Item>

        <Form.Item name="country" rules={countryRules}>
          <Select options={countries} placeholder="Country" />
        </Form.Item>

        <Form.Item name="address" rules={addressRules}>
          <Input placeholder="Address" />
        </Form.Item>

        <Form.Item name="city" rules={cityRules}>
          <Input placeholder="City" />
        </Form.Item>

        <Form.Item name="postcode" rules={postcodeRules}>
          <Input placeholder="Post code" />
        </Form.Item>

        <SaveCancelGroup onCancel={this.props.onCancel} />
      </Form>
    );
  }
}

AddressForm.propTypes = {
  countries: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AddressForm;
