import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import './Intro.scss';
import Logo from '../../../components/common/logos/Logo/Logo';
import { ROUTES, SUB_PROJECTS } from '../../../other/config';
import withAuth from '../../../components/withAuth';

const Intro = ({ isLoggedIn }) => {
  const link = ROUTES._ABOUT;
  const linkToRegistration = SUB_PROJECTS.REGISTRATION;
  if (isLoggedIn) return null;

  return (
    <section className="Intro">
      <div className="Intro__main">
        <div className="Intro__holder">
          <div className="Intro__col">
            <Logo imageUrl="/assets/logo/Logo-2.svg" />

            <p>
              The worlds first digital platform for fisheries. Connecting
              skippers and shipowners with service providers. Receive access to
              4000 fishing vessels, technical details, owners, financial
              statements, Analytics, and satellite AIS.
            </p>

            <div className="Intro__btns">
              <a
                href={linkToRegistration}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="app-btn-min-w-170 app-text-uppercase">
                  Try trial now
                </Button>
              </a>
              <Link to={link}>
                <Button
                  ghost
                  className="app-btn-min-w-170 ant-btn-translucent app-text-uppercase"
                >
                  About fishfacts
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Intro.propTypes = {
  isLoggedIn: PropTypes.bool
};

export default withAuth(Intro);
