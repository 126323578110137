/**
 * Hides the virtual keyboard on mobile devices.
 */
export function hideKeyboard(): void {
  const field = document.createElement('input');
  field.setAttribute('type', 'text');
  field.style.position = 'fixed';
  field.style.left = '-1000px';
  document.body.appendChild(field);

  setTimeout(() => {
    field.focus();
    setTimeout(() => {
      field.setAttribute('style', 'display:none;');
    }, 50);
    setTimeout(() => {
      document.body.removeChild(field);
    }, 50);
  }, 50);
}
