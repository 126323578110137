import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoaderOverlay } from 'react-fishfacts/dist';

import deleteConfirm from '../../../components/common/modals/deleteConfirm';
import {
  fetchProductAction,
  removeProduct
} from '../../../store/product/product/productActions';
import GoBackButton from '../../../components/common/buttons/GoBackButton/GoBackButton';
import { gotoProductEditorAction } from '../../../store/product/productEditor/productEditorActions';
import ProductArticle from './ProductArticle';
import { ROUTES } from '../../../other/config';
import store from '../../../store/store';

import { TProduct } from '../../../types/product';
import { TState } from '../../../store/appStateModel';

type Props = {
  article: TProduct;
  canEdit: boolean;
  editArticle: (providerId: number, productId: number) => void;
  getProduct: (providerId: number, productId: number) => void;
  isLoading: boolean;
  productId: number;
  providerId: number;
  removeArticle: (productId: number, providerId: number) => void;
};

class ProductArticleWrapper extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { getProduct, productId, providerId } = this.props;
    getProduct(providerId, productId);
  }

  handleEdit = () => {
    const { editArticle, productId, providerId } = this.props;
    editArticle(providerId, productId);
  };

  handleRemove = () => {
    const {
      article: { id, source },
      removeArticle
    } = this.props;
    if (!source) return;
    removeArticle(id, source.id);
  };

  render() {
    const { article, canEdit, isLoading, providerId } = this.props;
    if (!article) return <h4>Loading...</h4>;
    const goBackPath = `${ROUTES.SERVICE_PROVIDER}/${providerId}`;

    return (
      <LoaderOverlay className="Main__content--narrow" isLoading={isLoading}>
        {article ? (
          <ProductArticle
            article={article}
            canEdit={canEdit}
            editArticle={this.handleEdit}
            removeArticle={this.handleRemove}
          >
            <GoBackButton pathBack={goBackPath} />
          </ProductArticle>
        ) : (
          <h4>Not found</h4>
        )}
      </LoaderOverlay>
    );
  }
}

ProductArticleWrapper.propTypes = {
  article: PropTypes.object,
  editArticle: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired,
  ownedProviderIds: PropTypes.array,
  removeArticle: PropTypes.func.isRequired
};

const removeConfirm = (productId: number, providerId: number) =>
  deleteConfirm('Remove this product permanently?', () =>
    store.dispatch(removeProduct(productId, providerId))
  );

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      editArticle: gotoProductEditorAction,
      getProduct: fetchProductAction
    },
    dispatch
  ),
  removeArticle: (productId: number, providerId: number) =>
    removeConfirm(productId, providerId)
});

const mapStateToProps = (state: TState, ownProps) => {
  const {
    product: { isPending, product },
    session: { user }
  } = state;

  const { providerId, productId } = ownProps.match.params;
  const _providerId = parseInt(providerId);
  const ownedProviderIds: Readonly<number[]> = user
    ? user.userInfo.serviceProvidersId
    : [];
  const canEdit: boolean = ownedProviderIds.includes(_providerId);

  return {
    article: product,
    canEdit: canEdit,
    isLoading: isPending,
    productId: parseInt(productId),
    providerId: _providerId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductArticleWrapper);
