import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './ArticleEditorControls.scss';
import CloseButton from '../../buttons/CloseButton/CloseButton';
import { THandler } from '../../../../types/handlers';

type Props = {
  isDisabled?: boolean;
  onCancel: THandler;
  onDraft: THandler;
  onPublish: THandler;
};


const ArticleEditorControls = ({
  isDisabled,
  onCancel,
  onDraft,
  onPublish
}: Props): ReactElement => (
  <footer className="ArticleEditorControls">
    <Button
      disabled={isDisabled}
      onClick={onDraft}
      size="large"
      className="app-btn-min-w-170"
    >
      Save as draft
    </Button>

    <Button
      disabled={isDisabled}
      onClick={onPublish}
      type="primary"
      size="large"
      className="app-btn-min-w-170"
    >
      Publish
    </Button>

    <CloseButton
      className="ArticleEditorControls__cancel"
      onClick={onCancel}
      title="Cancel"
    />
  </footer>
);


ArticleEditorControls.propTypes = {
  isDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired
};

export default ArticleEditorControls;
