import React from 'react';
import { Button } from 'antd';

import LoaderOverlay from '../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import './Page404.scss';

export default class Page404 extends React.Component {
  render() {
    return (
      <LoaderOverlay className="Page404">
        <img src="assets/placeholders/page404_placeholder.svg" alt="" />
        <strong className="Page404__title">404</strong>
        <p>Sorry, the requested resource can’t be found</p>
        <Button type="link">Go back &gt;</Button>
      </LoaderOverlay>
    );
  }
}
