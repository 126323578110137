import { THttpRequestOptions, TVessel } from 'react-fishfacts/dist';

import { ENDPOINTS } from '../../other/config';
import { TFleetExt } from '../../types/fleet';
import { TFleetsState } from './fleetsModel';

/**
 * Handles 'add vessel(s) to fleet', 'remove vessel(s) from fleet`, 'rename fleet' operations.
 */
export class FleetUpdateUtil {
  private readonly fleet: TFleetExt; // the fleet we add\remove a vessel to\from
  private readonly fleets: TFleetExt[]; // all the fleets
  private readonly vesselIds?: number[]; // the operated vessel ID
  private readonly vessels?: TVessel[]; // all the vessels

  constructor(
    fleet: TFleetExt,
    fleets: TFleetExt[],
    vesselIds?: number[],
    vessels?: TVessel[]
  ) {
    this.fleet = fleet;
    this.fleets = fleets;
    this.vesselIds = vesselIds;
    this.vessels = vessels;
  }

  /// 'ADD VESSEL TO FLEET' OPERATION SECTION ///
  getAddOperationRequestParams(): THttpRequestOptions {
    return this.getRequestParams('PUT');
  }

  /** Returns the fleet model partial update for 'add vessel to fleet' case. */
  getAddOperationPayload(): Partial<TFleetsState> {
    const addedVessels: TVessel[] = this.vessels.filter(
      ({ id }: TVessel): boolean => this.vesselIds.includes(id)
    );
    const vesselsUpdate = this.fleet.vessels.concat(addedVessels);
    const vesselIdsUpdate = this.fleet.vesselIds.concat(this.vesselIds);

    return {
      fleets: this.updateFleets(vesselsUpdate, vesselIdsUpdate)
    };
  }

  /// 'REMOVE VESSEL FROM FLEET' OPERATION SECTION ///
  getRemoveOperationRequestParams(): THttpRequestOptions {
    return this.getRequestParams('DELETE');
  }

  /** Returns the fleet model partial update for 'remove vessel from fleet' case. */
  getRemoveOperationPayload(): Partial<TFleetsState> {
    const vesselsUpdate = this.fleet.vessels.filter(
      ({ id }: TVessel): boolean => !this.vesselIds.includes(id)
    );
    const vesselIdsUpdate = this.fleet.vesselIds.filter(
      (id: number): boolean => !this.vesselIds.includes(id)
    );

    return {
      fleets: this.updateFleets(vesselsUpdate, vesselIdsUpdate)
    };
  }

  /// REMOVE SECTION ///
  getRenameOperationRequestParams(name: string): THttpRequestOptions {
    const url = `${ENDPOINTS.FLEET}/${this.fleet.id}`;

    return {
      body: { name: name },
      method: 'PUT',
      url: url
    };
  }

  /** Returns the fleet model partial update for 'remove vessel from fleet' case. */
  getRenameOperationPayload(name: string): Partial<TFleetsState> {
    const updatedFleet: TFleetExt = {
      ...this.fleet,
      name: name
    };

    return {
      fleets: this.updateFleetInList(updatedFleet)
    };
  }

  /// GENERAL SECTION
  getRequestParams(method: 'PUT' | 'DELETE'): THttpRequestOptions {
    const url = `${ENDPOINTS.FLEET}/${this.fleet.id}/vessels`;

    return {
      body: {
        vesselId: this.vesselIds
      },
      method: method,
      url: url
    };
  }

  /** Updates a particular fleet in the list. */
  private updateFleets(vessels: TVessel[], vesselIds: number[]): TFleetExt[] {
    const updatedFleet: TFleetExt = {
      ...this.fleet,
      vesselIds: vesselIds,
      vessels: vessels
    };
    return this.updateFleetInList(updatedFleet);
  }

  /** Updates a particular fleet in the list by replacement. */
  private updateFleetInList(updatedFleet: TFleetExt): TFleetExt[] {
    return this.fleets.map(
      (f: TFleetExt): TFleetExt => (f.id === this.fleet.id ? updatedFleet : f)
    );
  }
}
