import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import { editorProps } from '../../news/NewsEditor/helpers';
import LoaderOverlay from '../../../components/common/overlays/LoaderOverlay/LoaderOverlay';
import ProductEditor from './ProductEditor/ProductEditor';
import { showValidationWarning } from '../../../other/formAndValidation/showValidationWarning';

import { TFormFields } from '../../../types/formFields';
import { TNewsEditorState } from '../../../store/news/newsEditor/newsEditorModel';

const confirmation = () => `Discard changes and leave the page?`;
type Props = TNewsEditorState & {
  editProduct: Function;
  isBlueprint: boolean;
  isEmpty: boolean;
  isModified: boolean;
  onCancel: (providerId: number, productId: number) => void;
  onChange: (fields: TFormFields) => void;
  onLeave: Function;
  onRemove: Function;
  onSubmit: (isDraft?: boolean) => void;
  onUpload: (file: File, productId: number) => void;
  productId: number;
  providerId: number;
};
type State = {
  isCancelled: boolean;
};

class ProductEditorWrapper extends React.Component<Props, State> {
  static propTypes;
  state: State = { isCancelled: false };

  componentDidMount() {
    const { editProduct, productId, providerId } = this.props;
    editProduct(providerId, productId);
  }

  componentWillUnmount(): void {
    const {
      isBlueprint,
      isEmpty,
      onLeave,
      onRemove,
      productId,
      providerId
    } = this.props;
    // Remove pristine blueprint or touched blueprint + user cancelled
    (isEmpty || (isBlueprint && this.state.isCancelled)) &&
      onRemove(productId, providerId);

    onLeave();
  }

  handleCancel = () => {
    const { onCancel, productId, providerId } = this.props;
    this.setState({ isCancelled: true });
    onCancel(providerId, productId);
  };

  handleSubmit = (isDraft: boolean): void => {
    const { fields, onSubmit } = this.props;
    if (isDraft) return onSubmit(true);
    showValidationWarning(fields) && onSubmit();
  };

  render() {
    const {
      editProduct,
      isModified,
      isPending,
      onSubmit,
      ...restOfProps
    } = this.props;
    const isLoading: boolean = isPending && !restOfProps.id;
    const editorProps = {
      ...restOfProps,
      isModified
    };

    return (
      <LoaderOverlay isLoading={isLoading}>
        <Prompt when={isModified} message={confirmation} />
        {restOfProps.id && (
          <ProductEditor
            {...editorProps}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        )}
      </LoaderOverlay>
    );
  }
}

ProductEditorWrapper.propTypes = {
  ...editorProps,
  editProduct: PropTypes.func.isRequired,
  isBlueprint: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isModified: PropTypes.bool,
  onLeave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired
};

export default ProductEditorWrapper;
