import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { THandler } from '../../../types/handlers';

/**
 * Modal confirmation dialog.
 * @param message
 * @param onOk
 */
export default function deleteConfirm(
  message: string,
  onOk: THandler
): {
  destroy: () => void;
  update: (newConfig: ModalFuncProps) => void;
} {
  return Modal.confirm({
    title: message,
    // icon: <Icon type="exclamation-circle" />,
    okText: 'Yes',
    // @ts-ignore
    okType: 'danger',
    cancelText: 'No',
    onOk: onOk
  });
}
