import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TLabelValue, TLocaleSet } from 'react-fishfacts/dist';

import './PromotionInfo.scss';
import { getServiceIds, getTypeIds } from './helpers';
import { TPromoted, TShortPaymentReport } from '../../../../types/promotion';
import { TTreeSelectOption } from '../../../../other/formAndValidation/formUtils';

type Props = {
  article: TPromoted;
  report: TShortPaymentReport;
  serviceOptions: TTreeSelectOption[];
  vesselTypesOptions: TLabelValue[];
};

function reduceList(list: { value: TLocaleSet }[], refLength?: number): string {
  if (!list || list.length === 0) {
    return 'None';
  } else if (list.length === refLength) {
    return 'All';
  } else {
    return list.map(({ value }): string => value.en_GB).join(', ');
  }
}

function formatDate(date: string): string {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

const PromotionInfo = ({
  article,
  report,
  serviceOptions,
  vesselTypesOptions
}: Props) => {
  const {
    countries,
    end,
    serviceCategories,
    start,
    utcOffset,
    vesselTypes
  } = article;
  const serviceOptionsLength = getServiceIds(serviceOptions).length;
  const typesLength = getTypeIds(vesselTypesOptions).length;

  return (
    <section className="PromotionInfo">
      <h3 className="PromotionInfo__title">Promotion information</h3>

      <table>
        <tbody>
          <tr>
            <th>Countries:</th>
            <td>{reduceList(countries as any)}</td>
          </tr>
          <tr>
            <th>Vessel types:</th>
            <td>{reduceList(vesselTypes, typesLength)}</td>
          </tr>
          <tr>
            <th>Service categories:</th>
            <td>
              {reduceList(serviceCategories as any, serviceOptionsLength)}
            </td>
          </tr>
          <tr>
            <th>Start date (UTC):</th>
            <td>{formatDate(start)}</td>
          </tr>
          <tr>
            <th>End date (UTC):</th>
            <td>{formatDate(end)}</td>
          </tr>
          <tr>
            <th>UTC offset:</th>
            <td>{utcOffset}</td>
          </tr>
          {report && (
            <tr>
              <th>Payment status:</th>
              <td>{report.status}</td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

PromotionInfo.propTypes = {
  article: PropTypes.object.isRequired,
  report: PropTypes.object,
  serviceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  vesselTypesOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default PromotionInfo;
