import { MAP_SETTINGS, readSettings } from '../../../services/settings';

/**
 * Sorts vessel array by distance to the given lat-lng point;
 */
export function orderVesselsByDistance(vessels, { lat, lng }) {
	const getDistance = ({ latitude, longitude }) => Math.pow(latitude - lat, 2) + Math.pow(longitude - lng, 2);
	return vessels.slice().sort((a, b) => getDistance(a) - getDistance(b));
}

/**
 * Sorts provider array by distance to the given lat-lng point;
 */
export function orderProvidersByDistance(providers, { lat, lng }) {
	const getDistance = ([latitude, longitude]) => Math.pow(latitude - lat, 2) + Math.pow(longitude - lng, 2);
	return providers.slice().sort((a, b) => getDistance(a.position) - getDistance(b.position));
}


export const mapOptions = {
  key: 'F5idqHrwPoxQwydITswnkQ3jNvZGSUT0',
  // key: 'TOqVnSHViJI9cWjN0L9GsR00k89pGphX',
  opacity: 0.3,
  preferCanvas: true,
  maxZoom: 14,
  minZoom: 3,
  // zoomControl: false,
  verbose: false,
};


export function getMapSettings() {
	return readSettings()[MAP_SETTINGS] || {};
}

export const ZOOM_THRESHOLD = 8;

/* eslint-disable */
export default function convertDDToDMS(D, lng, minPrecision = 10000) {
	return {
		dir: D < 0 ? lng ? 'W' : 'S' : lng ? 'E' : 'N',
		deg: 0 | (D < 0 ? D = -D : D),
		min: (0 | D % 1 * 60 * minPrecision) / minPrecision,
		sec: (0 | D * 60 % 1 * 6000) / 100,
	};
}

/*
A cross-browser event handling assigner.
 */
export function addEventHandler(event, element, func) {
  if (element.addEventListener) {
    element.addEventListener(event, func, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + event, func);
  } else {
    element['on' + event] = func;
  }
}

/*
A cross-browser event handling detach util.
 */
export function removeEventHandler(event, element, func) {
  if (element.removeEventListener) {
    element.removeEventListener(event, func, false);
  } else if (element.detachEvent) {
    element.detachEvent('on' + event, func);
  }
}

/*
A polyfill for `closest`. Five-element depth
 */
export function closest(target, cssClass){
  let node = target;

  for (let i = 0; i < 5; i++) {
  	if (!node || !node.classList) return null;
    if (node.classList.contains(cssClass)) return node;
    node = node.parentElement;
  }
  return null;
}
