import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoaderOverlay } from 'react-fishfacts/dist';
import { RouteProps } from 'react-router-dom';

import './ServiceBranch.scss';
import { LOCALE, PROVIDER_IMAGE_URL, ROUTES } from '../../../other/config';
import CategoryList from '../../../components/common/CategoryList/CategoryList';
import EmptyList from '../../../components/common/EmptyList/EmptyList';
import { pickServiceBranchAction } from '../../../store/serviceBranches/serviceBranchesActions';
import ServiceBranchHeader from './ServiceBranchHeader';

import { EUserAuthorities } from '../../../store/session/sessionModel';
import { TServiceBranchGroup } from '../../../types/service';
import { TState } from '../../../store/appStateModel';

type Props = {
  branch: TServiceBranchGroup;
  branchId: number;
  canCreate: boolean;
  getBranch: (branchId: number) => void;
  isLoading: boolean;
  providerId: number;
};

class ServiceBranch extends React.Component<Props> {
  static propTypes;

  componentDidMount() {
    const { branchId, getBranch } = this.props;
    getBranch(branchId);
  }
  componentDidUpdate(prevProps: Props) {
    const { branchId, getBranch } = this.props;
    if (prevProps.branchId !== branchId) {
      getBranch(branchId);
    }
  }

  render() {
    const { branch, canCreate, isLoading, providerId } = this.props;
    if (!branch && !isLoading)
      return (
        <EmptyList
          placeholder="The list is empty"
          placeholderImgUrl="/assets/placeholders/empty_serviceprovider.svg"
        />
      );

    const categories =
      branch &&
      branch.subBranches.map(({ branch }: TServiceBranchGroup): {} => {
        const { id, media, value } = branch;
        return {
          imageSrc: `${PROVIDER_IMAGE_URL}/${media.path}`,
          path: `${ROUTES.SERVICE_CATEGORY}/${id}`,
          title: value[LOCALE]
        };
      });

    return (
      <LoaderOverlay className="Services" isLoading={isLoading}>
        <ServiceBranchHeader canCreate={canCreate} providerId={providerId} />
        <CategoryList
          categories={categories}
          className="Services__list"
          itemClassName="Services__item"
        />
      </LoaderOverlay>
    );
  }
}

ServiceBranch.propTypes = {
  branch: PropTypes.shape({
    branch: PropTypes.object.isRequired,
    subBranches: PropTypes.array.isRequired
  }),
  canCreate: PropTypes.bool,
  getBranch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired,
  providerId: PropTypes.number
};

const mapStateToProps = (
  { serviceBranches, session: { user } }: TState,
  ownProps: { match: RouteProps }
) => {
  const { branch, isPending } = serviceBranches || {};
  const { authorities, serviceProvidersId } = user
    ? user.userInfo
    : ({} as any);
  const isLoading = isPending || branch === null;

  return {
    branch: branch,
    branchId: parseInt(ownProps.match.params.id),
    canCreate:
      authorities && authorities.includes(EUserAuthorities.SERVICE_PROVIDER),
    isLoading,
    providerId: Array.isArray(serviceProvidersId) ? serviceProvidersId[0] : null
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBranch: pickServiceBranchAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBranch);
