import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './NewsRow.scss';
import { areEqual } from '../../../other/helpers';
import { BANNER_LINK } from '../../../other/config';
import { getStatus } from '../helpers';
import NewsCard from './articles/NewsCard';

class NewsListInner extends React.Component {
  shouldComponentUpdate({ list: _newList, chunkSize: newChunkSize }) {
    const { chunkSize, list } = this.props;
    return !areEqual(list, _newList) || chunkSize !== newChunkSize;
  }

  render() {
    const { list, promotedArticles, usersNews } = this.props;
    const key = list.reduce((a, c) => a + c, '');

    return (
      <>
        <div className="NewsRow__banner app-d-xs-none">
          <Link to={BANNER_LINK}>
            <img
              src="/assets/banner/fishfacts/analytics/1010x60.jpg"
              alt="Banner"
              width="100%"
            />
          </Link>
        </div>

        <NewsRow
          key={key}
          list={list}
          promotedArticles={promotedArticles}
          usersNews={usersNews}
        />
      </>
    );
  }
}

NewsListInner.propTypes = {
  chunkSize: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  promotedArticles: PropTypes.array.isRequired,
  usersNews: PropTypes.arrayOf(PropTypes.number).isRequired
};

const NewsRow = ({ list, promotedArticles, usersNews }) => {
  return list.map((article) => {
    const status = getStatus(article.id, promotedArticles, usersNews);
    return <NewsCard key={article.id} article={article} status={status} />;
  });
};

NewsRow.propTypes = {
  list: PropTypes.array.isRequired,
  promotedArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
  usersNews: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default NewsListInner;
