import { TAction } from 'react-fishfacts/dist';

import { MAP_SETTINGS, writeSettings } from '../../../services/settings';
import { EMapOptionsActions } from './mapOptionsConstants';
import { TMapOptionsState } from './mapOptionsModel';
import { TState } from '../../appStateModel';

export function updateMapOptionsAction(options: Partial<TMapOptionsState>) {
  return (dispatch, getState) => {
    const { mapOptions } = getState() as TState;
    const update = {
      ...mapOptions,
      ...options
    };
    const action: TAction<TMapOptionsState, EMapOptionsActions> = {
      type: EMapOptionsActions.MAP_SET_OPTIONS,
      payload: update
    };

    writeSettings({ [MAP_SETTINGS]: update });
    dispatch(action);
  };
}
