import React from 'react';
import PropTypes from 'prop-types';

import PlanCard from './PlanCard';
import './PlanList.scss';


const PlanList =({ plans, selectedPlanId, selectPlan }) => {
	
	return (
		<div className="PlanList">
			{ plans.map((plan) => {
				const isSelected = plan.id === selectedPlanId;
				return <PlanCard key={plan.id} isSelected={isSelected} onClick={selectPlan} plan={plan} />
			})}
		</div>
	);
};


PlanList.propTypes = {
	plans: PropTypes.array.isRequired,
	selectedPlanId: PropTypes.number,
	selectPlan: PropTypes.func.isRequired,
};

export default PlanList;
