import {
  createAsyncActions,
  TCountry,
  TCountryISO,
  TLanguage,
  TLanguageISO,
  THttpResponse
} from 'react-fishfacts/dist';

import { ENDPOINTS, LOCALE } from '../../other/config';
import { fetchFleetsAction } from '../fleets/fleetsActions';
import { fetchServiceBranchesAction } from '../serviceBranches/serviceBranchesActions';
import { fetchVesselsAction } from '../vessel/vessels/vesselsActions';
import http from '../../services/http';
import store from '../store';

import { EDictionariesActions } from './dictionariesConstants';
import { TDictionariesState } from './dictionariesModel';
import { TState } from '../appStateModel';

export let fetchSet;
/**
 * Dispatches given action in delay.
 * @param action
 */
function runDeferred(action: Function): void {
  setTimeout(() => store.dispatch(action()), 100);
}

/**
 * Delegates fetching of some commonly used data structures.
 */
export function prefetchDataAction(isForce?: boolean) {
  return (dispatch, getState) => {
    const { session } = getState() as TState;
    const isUserSessionActive = session.error || !session.user;
    if (isUserSessionActive && !isForce) {
      return dispatch(fetchServiceBranchesAction());
    }

    const arr: Function[] = [
      fetchServiceBranchesAction,
      fetchFleetsAction,
      fetchDictionariesAction,
      fetchVesselsAction
    ];
    arr.forEach(runDeferred);
  };
}

/**
 * Fetches dictionaries used across the application.
 */
export function fetchDictionariesAction() {
  return (dispatch) => {
    fetchSet = createAsyncActions<TDictionariesState>(
      dispatch,
      EDictionariesActions.FETCH_DICTIONARIES
    );

    const onSuccess = ({ data }: THttpResponse<any>) => {
      const { countries, languages, promotionCountries, ...rest } = data;
      const sortedCountries: TCountryISO[] = sortCountries(countries);

      const payload: Partial<TDictionariesState> = {
        countries: mapISOCountries(sortedCountries),
        countriesISO: sortedCountries,
        languages: handleLanguages(languages),
        promotionCountries: mapISOCountries(sortCountries(promotionCountries)),
        ...rest
      };
      fetchSet.success(payload);
    };

    fetchSet.request();
    http.send(ENDPOINTS.DICTIONARIES).then(onSuccess).catch(fetchSet.error);
  };
}

/** Sorts them alphabetically */
function sortCountries(countries: TCountryISO[]): TCountryISO[] {
  const locale: string = LOCALE;

  countries.sort((a: TCountryISO, b: TCountryISO): -1 | 0 | 1 => {
    if (a.value[locale] < b.value[locale]) return -1;
    if (a.value[locale] > b.value[locale]) return 1;
    return 0;
  });

  return countries;
}

/** Converts ISO type into label-value */
function mapISOCountries(countries: TCountryISO[]): TCountry[] {
  const locale: string = LOCALE;

  return countries.map(
    (c: TCountryISO): TCountry => ({
      value: c.iso,
      label: c.value[locale]
    })
  );
}

/**
 * Converts languages representation and sorts them alphabetically.
 */
function handleLanguages(languages: TLanguageISO[]): TLanguage[] {
  const locale: string = LOCALE;

  return languages
    .map(
      (l: TLanguageISO): TLanguage => ({
        value: l.iso,
        label: l.value[locale]
      })
    )
    .sort((a: TLanguage, b: TLanguage): -1 | 0 | 1 => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
}
