import { readData, writeData } from './storage';

const SETTINGS_KEY = 'FF_COM';

export function clearSettings() {
  return writeData(SETTINGS_KEY, {});
}

export function readSettings() {
  return readData(SETTINGS_KEY) || {};
}

export function writeSettings(data) {
  const current = readData(SETTINGS_KEY);
  return writeData(SETTINGS_KEY, { ...current, ...data });
}

export const APP_VERSION = 'APP_VERSION';
export const IS_DARK_THEME = 'IS_DARK_THEME';
// filter
export const PROVIDERS_FILTER_BY_CATEGORY = 'PROVIDERS_FILTER_BY_CATEGORY';
export const PROVIDERS_FILTER_BY_COUNTRY = 'PROVIDERS_FILTER_BY_COUNTRY';
export const VESSELS_FILTER = 'VESSELS_FILTER';

// map
export const MAP_SETTINGS = 'MAP_SETTINGS';
export function getMapSettings() {
  return readSettings()[MAP_SETTINGS] || {};
}

export enum ESettingsSelectOptions {
  AUTO = 'AUTO',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  TRACK_ONLY = 'TRACK_ONLY'
}
