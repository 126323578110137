import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

import './ProviderInfoForm.scss';
import {
  longDescriptionRules,
  serviceNameRules,
  shortDescriptionRules,
  websiteRules
} from '../other/validationRules';
import SaveCancelGroup from '../../../../components/common/buttons/SaveCancelGroup/SaveCancelGroup';
import { TProviderEditorState } from '../../../../store/provider/providerEditor/providerEditor';

type Props = {
  onCancel: () => void;
  onSubmit: (values: Partial<TProviderEditorState>) => void;
  values: Partial<TProviderEditorState>;
};

class ProviderInfoForm extends React.PureComponent<Props> {
  static propTypes;
  private form: FormInstance;

  handleSubmit = () => this.form.validateFields().then(this.props.onSubmit);

  render() {
    return (
      <Form
        className="ProviderInfoForm"
        initialValues={this.props.values}
        onFinish={this.handleSubmit}
        ref={(el) => (this.form = el)}
      >
        <Form.Item name="website" rules={websiteRules}>
          <Input placeholder="Web site" />
        </Form.Item>

        <Form.Item name="name" rules={serviceNameRules}>
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item name="shortDescription" rules={shortDescriptionRules}>
          <Input.TextArea placeholder="Short description" />
        </Form.Item>

        <Form.Item name="longDescription" rules={longDescriptionRules}>
          <Input.TextArea placeholder="About service provider" />
        </Form.Item>

        <SaveCancelGroup onCancel={this.props.onCancel} />
      </Form>
    );
  }
}

ProviderInfoForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    longDescription: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    website: PropTypes.string
  }).isRequired
};

export default ProviderInfoForm;
