import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withEllipsisMenu } from 'react-fishfacts/dist';
import './BannerMenuOverlay.scss';

type Props = {
  actions: ReactElement;
  className?: string;
};

const BannerMenuOverlay: FC<Props> = ({ actions, className }) => {
  const cls = classnames('BannerMenuOverlay', className);
  const Menu = withEllipsisMenu(actions);

  return (
    <div className={cls}>
      <div className="BannerMenuOverlay__content">
        <Menu />
      </div>
    </div>
  );
};

BannerMenuOverlay.propTypes = {
  actions: PropTypes.element.isRequired,
  className: PropTypes.string
};

export default BannerMenuOverlay;
