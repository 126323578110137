import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { IconCustom, TVessel } from 'react-fishfacts/dist';

import './CompanyFleet.scss';
import ShortVessel from 'components/common/vessel/ShortVessel/ShortVessel';
import withFleets from 'components/common/vessel/withFleets/withFleets';
import { TVesselOfCompany } from 'types/vessel';

type Props = {
  favVesselsIds: number[];
  onFavClick: (vessels: TVessel[]) => void;
  vessels?: TVesselOfCompany[];
};

const CompanyFleet = ({ favVesselsIds, onFavClick, vessels }: Props) => {
  if (!vessels || vessels.length === 0) return null;

  const handleFav = () =>
    onFavClick(
      vessels.map(({ vesselId }: TVesselOfCompany) => ({ id: vesselId })) as any
    );

  const vesselsList = vessels.map((vessel) => (
    <ShortVessel
      favVesselsIds={favVesselsIds}
      key={vessel.vesselId}
      onFavClick={onFavClick}
      vessel={vessel}
    />
  ));

  return (
    <article className="CompanyFleet">
      <header className="CompanyFleet__header">
        <h2 className="CompanyFleet__title">Vessels</h2>
        <Button
          className="CompanyFleet__btn"
          onClick={handleFav}
          size="large"
          shape="round"
        >
          <IconCustom type="star" />
          Add all to fleet
        </Button>
      </header>

      <div className="VesselPage">{vesselsList}</div>
    </article>
  );
};

CompanyFleet.propTypes = {
  favVesselsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  vessels: PropTypes.array
};

export default withFleets(CompanyFleet);
