import PropTypes from 'prop-types';
import { TVessel } from 'react-fishfacts/dist';

import { isMobile } from '../../../components/helpers/helpers';
import { THandler } from '../../../types/handlers';

export type TVesselsListBaseProps = {
  favVesselsIds: number[];
  onFavClick: THandler;
  vesselsComparator: {
    vesselA: TVessel;
    vesselB: TVessel;
  };
  vesselComparisonAdd: THandler;
  vesselComparisonRemove: THandler;
  vessels?: TVessel[];
};

export const vesselsListBasePropTypes = {
  favVesselsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFavClick: PropTypes.func.isRequired,
  vesselsComparator: PropTypes.shape({
    vesselA: PropTypes.object,
    vesselB: PropTypes.object
  }),
  vesselComparisonAdd: PropTypes.func.isRequired,
  vesselComparisonRemove: PropTypes.func.isRequired,
  vessels: PropTypes.array
};

/**
 * Returns a set of parameters expected by 'react-virtualized' Grid component.
 * We set them depending on the viewport width and the list items count.
 */
export function getGridParams(_width: number, arrLength: number) {
  const width: number = isMobile() ? _width : _width - 16;
  const cellRatio = 1.2;
  let columnCount: number = 2;

  if (width < 300) {
    columnCount = 1;
  } else {
    columnCount = Math.ceil(width / 300);
  }

  const columnWidth = Math.floor(width / columnCount);

  return {
    columnCount,
    columnWidth,
    rowCount: Math.ceil(arrLength / columnCount),
    rowHeight: Math.round(columnWidth / cellRatio)
  };
}
