export enum ESearchCategory {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  VESSEL = 'VESSEL'
}

export type TSearchResult = {
  type: ESearchCategory;
  items: ReadonlyArray<TSearchItem>;
};

export type TSearchItem = {
  id: number;
  text: string;
};

export type TSearchItemExtended = {
  category: ESearchCategory;
  id: string;
  text: string;
};
