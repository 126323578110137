import React from 'react';
import PropTypes from 'prop-types';
import Swipeable from 'react-swipeable';
import './SwipeMenu.scss';

export const SWIPE_BOTTOM = 'bottom';
export const SWIPE_LEFT = 'left';
export const SWIPE_RIGHT = 'right';
export const SWIPE_TOP = 'top';

class SwipeMenu extends React.PureComponent {
  state = { open: false };

  onSwipedDown = () => {
    const { side } = this.props;
    side === SWIPE_BOTTOM && this.close();
  };

  onSwipedLeft = () => {
    const { side } = this.props;

    side === SWIPE_LEFT && this.close();
    side === SWIPE_RIGHT && this.open();
  };

  onSwipedRight = e => {
    const { side } = this.props;
    if (e.target.className === 'input-range__slider') return;

    side === SWIPE_RIGHT && this.close();
    side === SWIPE_LEFT && this.open();
  };

  onSwipedUp = () => {
    const { side } = this.props;
    side === SWIPE_BOTTOM && this.open();
  };

  close = () => this.setState({ open: false });
  open = () => this.setState({ open: true });
  toggle = () => this.setState(state => ({ open: !state.open }));

  render() {
    const { children, menuIcon, shift, side, top } = this.props;
    const { open } = this.state;

    const outerClass = `slideout slideout-${side}`;
    const outerStyle = { [side]: !open ? '0' : `${shift}px` };

    const innerClass = `slideout-inner slideout-inner-${side}`;
    const innerStyle = {
      [side]: !open ? `-${shift}px` : '0',
      top: `${top - 8}px`,
      height: `calc(100vh - ${top - 8}px)`
    };

    return (
      <Swipeable
        onSwipedDown={this.onSwipedDown}
        onSwipedLeft={this.onSwipedLeft}
        onSwipedRight={this.onSwipedRight}
        onSwipedUp={this.onSwipedUp}
      >
        <div className={outerClass} style={outerStyle}>
          <span className="slider" onClick={this.toggle}>
            {menuIcon()}
          </span>

          <div className={innerClass} style={innerStyle}>
            {children}
          </div>
        </div>
      </Swipeable>
    );
  }
}

SwipeMenu.propTypes = {
  children: PropTypes.object.isRequired,
  menuIcon: PropTypes.func.isRequired,
  shift: PropTypes.number.isRequired,
  side: PropTypes.string.isRequired,
  top: PropTypes.number
};

export default SwipeMenu;
