import React from 'react';
import PropTypes from 'prop-types';

import SpeciesTable from '../SpeciesTable/SpeciesTable';
import './FishingLicence.scss';
import { TFishingLicence } from '../../../../types/fishingLicence';

type Props = {
  licence: TFishingLicence;
};


const FishingLicence = ({ licence }: Props) => {
  const { end, species, start } = licence;

  return (
    <tr>
      <SpeciesTable species={species}/>
      <td className="FishingLicence__dates">{start}<br/>{end}</td>
    </tr>
  );
};


FishingLicence.propTypes = {
  licence: PropTypes.shape({
    species: PropTypes.array,
  }).isRequired
};

export default FishingLicence;
