import React from 'react';
import PropTypes from 'prop-types';

import './Company.scss';
import AnnualReports from '../../components/company/AnnualReports';
import { ENDPOINTS, SUB_POINTS } from '../../other/config';
import CompanyFleet from './CompanyFleet';
import CompanyData from '../../components/company/CompanyData';
import GoBackButton from '../../components/common/buttons/GoBackButton/GoBackButton';
import http from '../../services/http';

class Company extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchCompany();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const oldId = prevProps.match.params.companyId;
    const newId = match.params.companyId;
    if (newId !== oldId) this.fetchCompany();
  }

  fetchCompany() {
    // todo move to redux
    const { match } = this.props;
    const { companyId } = match.params;
    const base = `${ENDPOINTS.COMPANIES}/${companyId}`;
    this.setState({ isLoading: true });

    Promise.all([
      http.send(base),
      http.send(`${base}/vessels`),
      http.send(`${base}${SUB_POINTS.ANNUAL_REPORTS}`)
    ])
      .then(
        async ([
          { data: company },
          { data: vessels },
          { accountsByGroups, data }
        ]) =>
          this.setState({
            annualReport: {
              accounts: accountsByGroups,
              items: data ? data.slice(0, 2) : null
            },
            company,
            isLoading: false,
            vessels
          })
      )
      .catch(({ message }) =>
        this.setState({
          isLoading: false,
          message
        })
      );
  }

  render() {
    const { company, vessels, annualReport, message, isLoading } = this.state;

    if (isLoading)
      return (
        <div>
          <h2>Loading...</h2>
        </div>
      );
    if (!company)
      return (
        <div>
          <h2>No Company Found</h2>
        </div>
      );

    return (
      <div className="Company">
        <GoBackButton type="text" size="small" />
        {message && <h2 className="message-red">{message}</h2>}

        <h1>{company.name}</h1>
        <CompanyData company={company} />
        <CompanyFleet vessels={vessels} />

        {annualReport.items.length > 0 && (
          <AnnualReports company={company} annualReport={annualReport} />
        )}
      </div>
    );
  }
}

Company.propTypes = {
  match: PropTypes.object.isRequired
};

export default Company;
