import React, { CSSProperties, ReactElement } from 'react';
import PropTypes from 'prop-types';

import './IconCustom.scss';

type Props = {
  style?: CSSProperties;
  title?: string;
  type: string;
};

const IconCustom: React.FC<Props> = ({ title, type, style }): ReactElement => (
  <span className={`icon-custom icon-${type}`} style={style} title={title}>
    <span className="path1" />
    <span className="path2" />
  </span>
);

IconCustom.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default IconCustom;
